import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import history from '@history';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Toolbar,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectDashboardData } from 'app/store/userSlice';
import {
  getEncryptedData,
  getDecryptData,
  capitalizeFirstLetter,
  findExpireDay,
} from '../../common/common';
import userService from '../../../services';
import planQuery from '../../query/plan';
import Pagination from '../../../component/table/Pagination';
import TableHeader from '../../../component/table/TableHeader';
import ContactDialog from './ContactDialog';
import {
  getPackages,
  getPlansAndFeaturesData,
  getUserSubscriptionPackage,
  redirectPortalForSubscription,
  payUpdate,
  getInvoiceHistoryForRazorpay,
  openContactDialog,
  getPaymentHistory,
  getPaymentInvoice,
  reSetPlan,
  cancelSubscription,
  cancelSubscriptionForRazorpay,
} from './store/planSlice';
import reducer from './store';

function Plan() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginUserData = userService.getUserData();

  const headerDataActiveAddon = [
    t('plan.CurrentSubscription.column.name'),
    t('plan.CurrentSubscription.column.unit'),
    t('plan.CurrentSubscription.column.total'),
  ];

  const headerDataHistory = [
    t('plan.CurrentSubscription.column.date'),
    t('plan.CurrentSubscription.column.description'),
    t('plan.CurrentSubscription.column.amount'),
    t('plan.CurrentSubscription.column.action'),
  ];

  const getPackageResponse = useSelector(
    ({ planReducer }) => planReducer?.plan?.getPackagesData?.data?.getPackages?.data || []
  );
  const appDialog = useSelector(({ planReducer }) => planReducer?.plan?.contactDialog);

  const getPlansAndFeature = useSelector(
    ({ planReducer }) =>
      planReducer?.plan?.getPlansAndFeaturesDataValue?.data?.getPlansAndFeaturesData?.data || []
  );

  const getSubscriptionPackage = useSelector(
    ({ planReducer }) =>
      planReducer?.plan?.getUserSubscriptionPackageData?.data?.getUserSubscriptionPackage?.data ||
      ''
  );

  const getPaymentHistoryValue = useSelector(({ planReducer }) => {
    if (loginUserData?.countryId === 2) {
      return (
        planReducer?.plan?.getInvoiceHistoryForRazorpayData?.data?.getInvoiceHistoryForRazorpay
          ?.data || []
      );
    }
    return planReducer?.plan?.getPaymentHistoryData?.data?.getPaymentHistory?.data || [];
  });

  const subscriptionStatus = useSelector(
    ({ planReducer }) =>
      planReducer?.plan?.redirectPortalForSubscriptionData?.data?.redirectPortalForSubscription ||
      {}
  );

  const invoiceStatus = useSelector(
    ({ planReducer }) => planReducer?.plan?.getPaymentInvoiceData?.data?.getPaymentInvoice || {}
  );

  const cancelSubscriptionStatus = useSelector(({ planReducer }) => {
    if (loginUserData?.countryId === 2) {
      return (
        planReducer?.plan?.cancelSubscriptionForRazorpayData?.data?.cancelSubscriptionForRazorpay ||
        {}
      );
    }
    return planReducer?.plan?.cancelSubscriptionData?.data?.cancelSubscription || {};
  });

  const [loading, setLoading] = useState(true);
  const [packageLoader, setPackageLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeInterval, setActiveInterval] = useState('monthActive');
  const [pdfInvoice, setPdfInvoice] = useState('');
  const [managePlan, setManagePlan] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshCancelSubscriptionIcon, setRefreshCancelSubscriptionIcon] = useState(false);
  const [
    refreshUpgradeDowngradeSubscriptionIcon,
    setRefreshUpgradeDowngradeCancelSubscriptionIcon,
  ] = useState(false);
  const [upgradeDowngradeSubscriptionId, setUpgradeDowngradeCancelSubscriptionId] = useState();
  const [subscriptionId, setSubscriptionId] = useState();
  const [isCancelSubscription, setIsCancelSubscription] = useState();
  const [isCancelSubscriptionForUpgradeDownGrade, setIsCancelSubscriptionForUpgradeDownGrade] =
    useState();
  const planIdHirachy = [1, 5, 6, 4];
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  let subscriptionDetail;
  if (getSubscriptionPackage) {
    subscriptionDetail = JSON.parse(getDecryptData(getSubscriptionPackage));
  }

  const currentPlanIndex = planIdHirachy.indexOf(subscriptionDetail?.packageTypeId);

  let prevItem = null;

  useEffect(() => {
    let unmounted = false;
    setPackageLoader(true);
    const fetchData = async () => {
      const payload = {
        query: planQuery.getPackages,
        variables: {
          countryId: loginUserData?.countryId,
          packageInterval: `${activeInterval === 'monthActive' ? 'month' : 'year'}`,
        },
      };
      await dispatch(getPackages(payload));
      setLoading(false);
      setPackageLoader(false);
    };

    fetchData();

    return () => {
      unmounted = true;
    };
  }, [dispatch, activeInterval, loginUserData?.countryId]);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    const getPlansAndFeatures = async () => {
      const payload = {
        query: planQuery.getPlansAndFeaturesData,
      };
      await dispatch(getPlansAndFeaturesData(payload));

      const payloadData = {
        query: planQuery.getUserSubscriptionPackage,
      };
      await dispatch(getUserSubscriptionPackage(payloadData));

      if (loginUserData?.countryId === 2) {
        const payloadPayment = {
          query: planQuery.getInvoiceHistoryForRazorpay,
        };
        await dispatch(getInvoiceHistoryForRazorpay(payloadPayment));
      } else {
        const payloadPayment = {
          query: planQuery.getPaymentHistory,
        };
        await dispatch(getPaymentHistory(payloadPayment));
      }
    };
    getPlansAndFeatures();
    return () => {
      unmounted = true;
      dispatch(reSetPlan());
    };
  }, [dispatch, loginUserData?.countryId]);

  useEffect(() => {
    if (subscriptionStatus && subscriptionStatus.status === 400) {
      enqueueSnackbar(subscriptionStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (subscriptionStatus && subscriptionStatus.status === 200) {
      window.open(subscriptionStatus?.data?.url, '_blank');
    }
  }, [subscriptionStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (cancelSubscriptionStatus && cancelSubscriptionStatus.status === 400) {
      enqueueSnackbar(cancelSubscriptionStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setOpenDialog(false);
    } else if (cancelSubscriptionStatus && cancelSubscriptionStatus.status === 200) {
      enqueueSnackbar(cancelSubscriptionStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setManagePlan(false);
      setIsCancelSubscription(false);
      setOpenDialog(false);
      const payloadData = {
        query: planQuery.getUserSubscriptionPackage,
      };
      dispatch(getUserSubscriptionPackage(payloadData));
    }
  }, [cancelSubscriptionStatus, enqueueSnackbar, closeSnackbar, dispatch]);

  useEffect(() => {
    if (invoiceStatus && invoiceStatus.status === 400) {
      enqueueSnackbar(invoiceStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (
      invoiceStatus &&
      invoiceStatus.status === 200 &&
      invoiceStatus.message === 'Success'
    ) {
      setPdfInvoice(invoiceStatus?.data?.invoice_pdf);
    }
  }, [invoiceStatus, enqueueSnackbar, closeSnackbar, pdfInvoice]);

  useEffect(() => {
    if (pdfInvoice) {
      const link = document.createElement('a');
      link.href = pdfInvoice;
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  }, [pdfInvoice]);

  useEffect(() => {
    if (getSubscriptionPackage) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [getSubscriptionPackage]);

  const handleDownlodInvoice = async (transactionId) => {
    if (loginUserData?.countryId === 2) {
      window.open(transactionId, '_blank');
    } else {
      const payloadInvoice = {
        query: planQuery.getPaymentInvoice,
        variables: {
          id: transactionId,
        },
      };
      await dispatch(getPaymentInvoice(payloadInvoice));
    }
  };

  const handleSubscription = async (priceId) => {
    const payloadData = {
      query: planQuery.redirectPortalForSubscription,
      variables: {
        countryId: loginUserData?.countryId,
        priceId,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    await dispatch(redirectPortalForSubscription(payloadData));
  };

  const handleUpgradeDowngrade = async (planId, type) => {
    try {
      if (loginUserData?.countryId === 2) {
        setIsCancelSubscriptionForUpgradeDownGrade(true);
        setOpenDialog(true);
      } else {
        setRefreshUpgradeDowngradeCancelSubscriptionIcon(true);
        const payloadData = {
          query: planQuery.payUpdate,
          variables: {
            stripeSubscriptionId: subscriptionDetail?.stripeSubscriptionId,
            type,
            countryId: loginUserData?.countryId,
            upgradePlanId: planId,
          },
        };
        const result = await dispatch(payUpdate(payloadData));
        if (result?.payload?.data?.payUpdate?.status === 200) {
          setRefreshUpgradeDowngradeCancelSubscriptionIcon(false);
          window.open(result?.payload?.data?.payUpdate?.data?.url, '_blank');
        } else {
          enqueueSnackbar(result?.payload?.data?.payUpdate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setRefreshUpgradeDowngradeCancelSubscriptionIcon(false);
        }
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleCancelSubscription = async () => {
    setRefreshCancelSubscriptionIcon(true);
    setIsCancelSubscription(true);

    if (loginUserData?.countryId === 2) {
      const cancelSubscriptionData = JSON.stringify({
        razorpaySubscriptionId: subscriptionId,
      });

      const payloadData = {
        query: planQuery.cancelSubscriptionForRazorpay,
        variables: { data: getEncryptedData(cancelSubscriptionData) },
      };
      await dispatch(cancelSubscriptionForRazorpay(payloadData));
    } else {
      const cancelSubscriptionData = JSON.stringify({
        stripeSubscriptionId: subscriptionId,
      });

      const payloadData = {
        query: planQuery.cancelSubscription,
        variables: { data: getEncryptedData(cancelSubscriptionData) },
      };
      await dispatch(cancelSubscription(payloadData));
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      {loginUserData?.subUserType === 'client' ? (
        <div className="p-20 lg:p-32 text-center">
          <div className="bg-white mt-36 h-full lg:p-44 p-16 flex items-center">
            <h2 className="text-20 font-semibold max-w-640 mx-auto">{t('plan.clientPlan')}</h2>
          </div>
        </div>
      ) : (
        <div className="p-20 lg:p-32">
          <div className="flex w-full items-center justify-between mb-36">
            <div className="items-center justify-between">
              {managePlan && (
                <Toolbar className="px-0 font-semibold min-h-fit mb-20">
                  <Icon className="text-20 cursor-pointer" onClick={() => setManagePlan(false)}>
                    west
                  </Icon>
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className="flex-1 px-12 font-bold text-18"
                  >
                    {t('plan.title')}
                  </Typography>
                </Toolbar>
              )}
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold"
              >
                {managePlan ? `${t('plan.changePlan')}` : `${t('plan.title')}`}
              </Typography>
            </div>
            {/* {managePlan && (
          <Button
            onClick={() => {
              loginUserData?.countryId === 2
                ? dispatch(openContactDialog())
                : history.push('/manage-plan');
            }}
            variant="contained"
            color="secondary"
            size="small"
            className="rounded-md px-24"
          >
            {t('plan.addon')}
          </Button>
        )} */}
          </div>

          <>
            {!managePlan &&
            (subscriptionDetail?.isSubscription ||
              // && findExpireDay(subscriptionDetail?.expireDate) <= 0
              (!subscriptionDetail?.isSubscription &&
                (subscriptionDetail?.stripeSubscriptionId ||
                  subscriptionDetail?.razorpaySubscriptionId))) ? (
              <>
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-32">
                  <div className="bg-darkgreen-100 border border-dashed border-darkgreen rounded-md p-20">
                    <div className="flex justify-between items-center">
                      <h3 className="text-28 font-bold">
                        {subscriptionDetail?.name} {t('plan.plan')}
                      </h3>
                      <span className="text-24 font-bold capitalize">
                        <span className="text-28">
                          {subscriptionDetail?.currencySymbol}{' '}
                          {subscriptionDetail?.totalAmount ||
                            subscriptionDetail?.offerAmount ||
                            subscriptionDetail?.amount}
                          /
                        </span>
                        {subscriptionDetail?.packageInterval}
                      </span>
                    </div>
                    <Typography className="text-16 font-medium mt-8 mb-20">
                      Perfect for individuals or small businesses that want to increase their
                      ratings and reviews.
                    </Typography>
                    <div className="flex items-center justify-between">
                      <Button
                        size="small"
                        variant="contained"
                        className="rounded-md px-28"
                        color="secondary"
                        onClick={() => setManagePlan(true)}
                      >
                        {t('plan.CurrentSubscription.mangePlane')}
                      </Button>
                      {subscriptionDetail?.isSubscription ? (
                        <>
                          {subscriptionDetail?.isSubscription &&
                          findExpireDay(subscriptionDetail?.expireDate) <= 0 ? (
                            <span className="text-red-600 font-semibold text-16">
                              Plan Expired on{' '}
                              {moment(subscriptionDetail?.expireDate).format('MMM DD, YYYY')}.
                            </span>
                          ) : (
                            <Button
                              size="small"
                              variant="text"
                              className="rounded-none ml-12 semibold text-16 border-b-1 border-solid p-0 min-w-0 leading-4 hover:bg-transparent hover:text-black hover:border-black"
                              color="secondary"
                              disabled={isCancelSubscription}
                              onClick={() => {
                                setSubscriptionId(
                                  subscriptionDetail?.stripeSubscriptionId ||
                                    subscriptionDetail?.razorpaySubscriptionId
                                );
                                setOpenDialog(true);
                              }}
                            >
                              {t('plan.CurrentSubscription.cancelSubscription')}
                              {isCancelSubscription && (
                                <CircularProgress
                                  size={24}
                                  className="text-darkgreen absolute mx-auto"
                                />
                              )}
                            </Button>
                          )}
                        </>
                      ) : (
                        <span className="text-red-600 font-semibold text-16">
                          Your plan has been canceled. It will expire on{' '}
                          {moment(subscriptionDetail?.expireDate).format('MMM DD, YYYY')}.
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="bg-white rounded-md relative p-20">
                      <h4 className="text-18 font-semibold mb-16">
                        {t('plan.CurrentSubscription.billingPayment')}
                      </h4>
                      <div className="flex justify-between items-center mb-12">
                        <span className="text-16 font-medium">
                          {t('plan.CurrentSubscription.nextPaymentDate')}
                        </span>
                        <span className="font-semibold text-16">
                          {moment(subscriptionDetail?.expireDate).format('MMM DD, YYYY')}
                        </span>
                      </div>
                      <div className="flex justify-between items-center mb-12">
                        <span className="text-16 font-medium">
                          {t('plan.CurrentSubscription.nextRecurring')}
                        </span>
                        <span className="font-semibold text-16">
                          {subscriptionDetail?.currencySymbol}{' '}
                          {subscriptionDetail?.packageOfferAmount ||
                            subscriptionDetail?.packageAmount}
                          /{subscriptionDetail?.packageInterval}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-16 font-medium">
                          {t('plan.CurrentSubscription.paymentMethod')}
                        </span>
                        <span className="font-semibold text-16 capitalize">
                          {subscriptionDetail?.paymentMethodType}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-32">
                  {subscriptionDetail?.addOnDetails > 0 && (
                    <div>
                      <h4 className="text-18 font-semibold mt-28 mb-16">
                        {t('plan.CurrentSubscription.activeAddon')}
                      </h4>
                      <div className="bg-white rounded-md relative shadow">
                        <TableContainer>
                          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                            <TableHeader headerData={headerDataActiveAddon} />
                            <TableBody>
                              {subscriptionDetail?.addOnDetails?.length > 0 &&
                                subscriptionDetail?.addOnDetails?.map((item, i) => {
                                  return (
                                    <TableRow
                                      hover
                                      className="border border-t-2 border-t-grey-100"
                                      role="checkbox"
                                      key={i}
                                      tabIndex={-1}
                                    >
                                      <TableCell
                                        className="text-16 color-black font-semibold w-[10%]"
                                        align="left"
                                      >
                                        {i + 1 + (page - 1) * rowsPerPage}
                                      </TableCell>
                                      <TableCell
                                        className="text-16 color-black font-semibold"
                                        align="left"
                                      >
                                        {item.businessName}
                                      </TableCell>
                                      <TableCell
                                        className="text-16 color-black font-semibold capitalize"
                                        align="left"
                                      >
                                        {item.promotionType}
                                      </TableCell>
                                      <TableCell
                                        className="text-16 color-black font-semibold"
                                        align="left"
                                      >
                                        {moment(item?.cts).format('MMM DD, YYYY')}
                                      </TableCell>
                                      <TableCell
                                        className="text-16 color-black font-semibold"
                                        align="left"
                                      >
                                        {item.button}
                                      </TableCell>
                                      <TableCell
                                        className="text-16 color-black font-semibold"
                                        align="left"
                                      >
                                        <Select
                                          className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                          sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                          variant="filled"
                                          value="action"
                                          fullWidth
                                          // onChange={handleActionDropdownChange}
                                          // disabled={selectedRow !== row}
                                        >
                                          <MenuItem
                                            value="action"
                                            className="capitalize mx-5 rounded-md"
                                            disabled
                                          >
                                            {t('business.businessList.column.action')}{' '}
                                          </MenuItem>
                                          <MenuItem
                                            value="view"
                                            className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                            // onClick={() => handleRedirect(item)}
                                          >
                                            {t('business.businessList.column.viewDetails')}
                                          </MenuItem>
                                        </Select>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4 className="text-18 font-semibold mt-28 mb-16">
                    {t('plan.CurrentSubscription.billingHistory')}
                  </h4>
                  <div className="bg-white rounded-md relative shadow">
                    <TableContainer>
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                        <TableHeader headerData={headerDataHistory} />
                        <TableBody>
                          {getPaymentHistoryValue?.length > 0 &&
                            getPaymentHistoryValue?.map((item, i) => {
                              return (
                                <TableRow
                                  hover
                                  className="border border-t-2 border-t-grey-100"
                                  role="checkbox"
                                  key={i}
                                  tabIndex={-1}
                                >
                                  <TableCell
                                    className="text-16 color-black font-semibold"
                                    align="left"
                                  >
                                    {loginUserData?.countryId === 2
                                      ? item?.startDate
                                        ? moment(item.startDate).format('MMM DD, YYYY')
                                        : ''
                                      : item?.cts
                                      ? moment.unix(item.cts / 1000).format('MMM DD, YYYY')
                                      : ''}
                                  </TableCell>
                                  <TableCell
                                    className="text-16 color-black font-semibold"
                                    align="left"
                                  >
                                    {t('plan.CurrentSubscription.invoice for')}{' '}
                                    {loginUserData?.countryId === 2
                                      ? item?.startDate
                                        ? moment(item.startDate).format('MMM DD, YYYY')
                                        : ''
                                      : item?.cts
                                      ? moment.unix(item.cts / 1000).format('MMM DD, YYYY')
                                      : ''}
                                  </TableCell>
                                  <TableCell
                                    className="text-16 color-black font-semibold capitalize"
                                    align="left"
                                  >
                                    {loginUserData?.countryId === 2 ? '₹' : '$'}
                                    {loginUserData?.countryId === 2
                                      ? item?.invoiceAmount
                                      : item?.paymentIntentsDescTwo?.amount}

                                    {}
                                  </TableCell>
                                  <TableCell
                                    className="text-16 color-black font-semibold"
                                    align="left"
                                  >
                                    <Button
                                      onClick={() =>
                                        handleDownlodInvoice(
                                          loginUserData?.countryId === 2
                                            ? item?.invoiceRedirectLink
                                            : item?.transactionId
                                        )
                                      }
                                      className="rounded-md font-semibold"
                                      size="small"
                                      variant="contained"
                                      color="inherit"
                                    >
                                      {t('plan.CurrentSubscription.downloadInvoice')}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-white rounded-md relative shadow md:p-48 p-20">
                <div className="create-bot-part mx-auto">
                  <div className="sm:flex block justify-between items-center">
                    <div>
                      <h2 className="text-18 md:text-28 text-darkgreen font-semibold mb-12 md:mb-12">
                        {t('plan.inTitle')}
                      </h2>
                      <Typography className="text-14 md:text-18 font-medium md:mx-auto mb-16 md:mb-32 text-black">
                        {t('plan.desc')}
                      </Typography>
                    </div>
                    {!managePlan && (
                      <div className="max-w-320 bg-darkgreen rounded-md p-14 mb-20 sm:mb-0">
                        {!subscriptionDetail?.isSubscription &&
                        (subscriptionDetail?.stripeSubscriptionId ||
                          subscriptionDetail?.razorpaySubscriptionId) ? (
                          <>
                            <span className="flex justify-between items-center">
                              <span className="text-white text-14 font-semibold">
                                {/* {subscriptionDetail?.name} */}
                                Subscription Expires In{' '}
                                {findExpireDay(subscriptionDetail?.expireDate)} Days
                              </span>
                            </span>
                            <Typography className="text-13 text-white mt-10">
                              your subscription is canceled.
                            </Typography>
                          </>
                        ) : !subscriptionDetail?.isSubscription &&
                          (!subscriptionDetail?.stripeSubscriptionId ||
                            !subscriptionDetail?.razorpaySubscriptionId) ? (
                          <>
                            <span className="flex justify-between items-center">
                              <span className="text-white text-14 font-semibold">
                                {/* {subscriptionDetail?.name} */}
                                {findExpireDay(subscriptionDetail?.expireDate) > 0
                                  ? `Plan expires in ${findExpireDay(
                                      subscriptionDetail?.expireDate
                                    )} Days`
                                  : 'Your plan is expired'}
                              </span>
                            </span>
                            <Typography className="text-13 text-white mt-10">
                              You can continue to use quick reviews after your trial period by
                              purchasing the application.
                            </Typography>
                          </>
                        ) : subscriptionDetail?.isSubscription &&
                          (subscriptionDetail?.stripeSubscriptionId ||
                            subscriptionDetail?.razorpaySubscriptionId) ? (
                          <>
                            <span className="flex justify-between items-center">
                              <span className="text-white text-14 font-semibold">
                                {/* {subscriptionDetail?.name} */}
                                {findExpireDay(subscriptionDetail?.expireDate) > 0
                                  ? `Subscription expires in ${findExpireDay(
                                      subscriptionDetail?.expireDate
                                    )} Days`
                                  : 'Your plan is expired'}
                              </span>
                            </span>
                            <Typography className="text-13 text-white mt-10">
                              {findExpireDay(subscriptionDetail?.expireDate) > 0
                                ? `${subscriptionDetail?.name} plan is your active plan`
                                : `${subscriptionDetail?.name} plan was your active plan`}
                              {/* {!subscriptionDetail?.isSubscription &&
                        findExpireDay(subscriptionDetail?.expireDate) > 0
                          ? `${t('plan.trial.cancelDesc')}`
                          : `${t('plan.trial.desc')}`} */}
                            </Typography>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mb-28">
                    <span className="rounded-3xl border border-darkgreen inline-block p-4">
                      <span
                        className={`${
                          activeInterval === 'monthActive'
                            ? 'bg-darkgreen text-white'
                            : 'bg-white text-darkgreen'
                        } cursor-pointer text-16 font-medium py-6 inline-block rounded-3xl min-w-112 text-center`}
                        aria-hidden="true"
                        onClick={() => {
                          setActiveInterval('monthActive');
                        }}
                      >
                        Monthly
                      </span>
                      <span
                        className={`${
                          activeInterval === 'yearActive'
                            ? 'bg-darkgreen text-white'
                            : 'bg-white text-darkgreen'
                        } cursor-pointer text-16 font-medium py-6 inline-block rounded-3xl min-w-112 text-center`}
                        aria-hidden="true"
                        onClick={() => {
                          setActiveInterval('yearActive');
                        }}
                      >
                        Yearly
                      </span>
                    </span>
                  </div>
                  {!packageLoader ? (
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32 ">
                      {getPackageResponse
                        .filter((plan) => plan?.id !== 1)
                        .sort((a, b) => {
                          if (a?.packageIndex < b?.packageIndex) {
                            return -1;
                          }
                          if (a?.packageIndex > b?.packageIndex) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((plan) => {
                          const previousItem = prevItem;
                          prevItem = plan;
                          return (
                            <div
                              className={`bg-white border-2 border-solid rounded-md p-16 md:p-28 inline-block w-full w-auto md:mb-0 relative max-w-360 ${
                                subscriptionDetail?.packageTypeId === plan?.packageTypeId &&
                                subscriptionDetail?.packageInterval === plan?.packageInterval
                                  ? 'border-darkgreen'
                                  : ''
                              }`}
                              key={plan.id}
                            >
                              {subscriptionDetail?.packageTypeId === plan?.packageTypeId &&
                                subscriptionDetail?.packageInterval === plan?.packageInterval && (
                                  <span className="bg-darkgreen text-14 font-medium text-white px-16 py-5 absolute top-0 rounded-b-md right-10">
                                    {!subscriptionDetail?.isSubscription &&
                                    !subscriptionDetail?.stripeSubscriptionId &&
                                    !subscriptionDetail?.razorpaySubscriptionId
                                      ? 'Free Trial'
                                      : subscriptionDetail?.isSubscription &&
                                        (subscriptionDetail?.stripeSubscriptionId ||
                                          subscriptionDetail?.razorpaySubscriptionId) &&
                                        findExpireDay(subscriptionDetail?.expireDate) <= 0
                                      ? 'Expire Plan'
                                      : `${t('plan.activePlan')}`}
                                  </span>
                                )}
                              <div className="flex items-center">
                                <div className="h-full w-full">
                                  <h4 className="text-darkgreen text-24 md:text-28 font-bold text-darkgreen mb-10 md:mb-16 mt-0">
                                    {plan.name}
                                  </h4>
                                  <div className="flex min-h-92">
                                    <div>
                                      {plan?.amount && plan?.amount !== '0' ? (
                                        <>
                                          <div className="mb-5 mt-0 ">
                                            <h3
                                              className={`inline-block my-0 ${
                                                plan?.offerAmount
                                                  ? 'line-through text-black font-medium text-20 md:text-24'
                                                  : 'text-darkgreen font-semibold text-24 md:text-28'
                                              }`}
                                            >
                                              <span>
                                                {loginUserData?.countryId === 2 ? '₹' : '$'}
                                              </span>
                                              {plan.amount}
                                            </h3>

                                            {plan?.offerAmount && (
                                              <h3 className="my-0 font-semibold inline-block ml-10 text-darkgreen text-24 md:text-28">
                                                <span>
                                                  {loginUserData?.countryId === 2 ? '₹' : '$'}
                                                </span>
                                                {plan.offerAmount}
                                              </h3>
                                            )}
                                          </div>
                                          <span className="block text-16 font-medium text-grey-700 mb-20 md:mb-28">
                                            {activeInterval === 'monthActive'
                                              ? 'Per Month* (for a limited time)'
                                              : 'Per Year* (for a limited time)'}
                                          </span>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  {loginUserData?.roles?.type !== 'agent' && (
                                    <>
                                      {(!managePlan ||
                                        (managePlan &&
                                          findExpireDay(subscriptionDetail?.expireDate) <= 0 &&
                                          subscriptionDetail?.isSubscription) ||
                                        (managePlan && !subscriptionDetail?.isSubscription)) && (
                                        <span className="bg-darkgreen rounded-full text-18 font-medium text-white text-center block mb-40 py-9">
                                          <>
                                            <Button
                                              className="hover:text-darkgreen-100 text-white text-18 p-0 min-h-0 h-auto max-h-0"
                                              variant="text"
                                              onClick={() => {
                                                handleSubscription(
                                                  loginUserData?.countryId === 2
                                                    ? plan?.razorpayPlanId
                                                    : plan?.stripePriceId
                                                );
                                              }}
                                            >
                                              {t('plan.trial.purchasePlan')}
                                            </Button>
                                          </>
                                        </span>
                                      )}

                                      {/* <>
                                          {subscriptionDetail?.packageTypeId ===
                                          plan?.packageTypeId ? (
                                            <span className="block mb-40 h-40" />
                                          ) : (
                                            <span
                                              className={`bg-darkgreen rounded-full text-18 font-medium text-white text-center block  min-h-28 mb-40 py-9 ${
                                                upgradeDowngradeSubscriptionId ===
                                                  plan?.packageTypeId &&
                                                refreshUpgradeDowngradeSubscriptionIcon
                                                  ? 'bg-grey-300'
                                                  : ''
                                              }`}
                                            >
                                              <>
                                                <Button
                                                  className="hover:text-darkgreen-100 text-white text-18 p-0 min-h-28 h-auto max-h-0"
                                                  variant="text"
                                                  disabled={
                                                    upgradeDowngradeSubscriptionId ===
                                                      plan?.packageTypeId &&
                                                    refreshUpgradeDowngradeSubscriptionIcon
                                                  }
                                                  onClick={() => {
                                                    handleUpgradeDowngrade(
                                                      plan?.packageTypeId,
                                                      currentPlanIndex >
                                                        planIdHirachy.indexOf(plan?.packageTypeId)
                                                        ? 'downgradeSubscription'
                                                        : 'upgradeSubscription'
                                                    );
                                                    setUpgradeDowngradeCancelSubscriptionId(
                                                      plan?.packageTypeId
                                                    );
                                                  }}
                                                >
                                                  {currentPlanIndex >
                                                  planIdHirachy.indexOf(plan?.packageTypeId)
                                                    ? 'Downgrade Plan'
                                                    : 'Upgrade Plan'}{' '}
                                                  {upgradeDowngradeSubscriptionId ===
                                                    plan?.packageTypeId &&
                                                    refreshUpgradeDowngradeSubscriptionIcon && (
                                                      <CircularProgress
                                                        size={24}
                                                        className="text-darkgreen absolute mx-auto"
                                                      />
                                                    )}
                                                </Button>
                                              </>
                                            </span>
                                          )}
                                        </> */}
                                    </>
                                  )}

                                  <ul className="text-16 font-medium leading-snug">
                                    <h4 className="text-18 mb-20 font-bold">
                                      {plan?.packageIndex === 2
                                        ? `${plan?.name} Plan Features:`
                                        : `All ${previousItem?.name} Features, Plus:`}
                                    </h4>
                                    {Object.values(JSON.parse(plan.packageDetails))
                                      .filter((item, idx) => idx < 5)
                                      .map((feature, i) => (
                                        <li
                                          className="flex items-center justify-start mb-14"
                                          key={i}
                                        >
                                          {feature?.isAvailable === true ? (
                                            <img
                                              src="/assets/images/icon/reviewCheck.svg"
                                              alt=".."
                                              className="w-16 h-16 mr-8"
                                            />
                                          ) : (
                                            <img
                                              src="/assets/images/icon/close.png"
                                              alt=".."
                                              className="w-16 h-16 mr-8"
                                            />
                                          )}
                                          <span className="text-16 font-semibold">
                                            {feature.name}
                                          </span>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div className="text-center">
                      <CircularProgress size={96} className="text-darkgreen mx-auto" />
                    </div>
                  )}
                </div>
                <div className="container">
                  <Typography className="text-20 text-black my-20 md:my-40 font-semibold">
                    {t('plan.comparePLanFeatures')}
                  </Typography>
                  <div className="overflow-x-auto">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-darkgreen font-[600] border-b-1 border-grey-300 text-16 md:text-24 p-5 md:p-16">
                            Features
                          </TableCell>
                          {getPlansAndFeature?.heading
                            ?.slice()
                            .sort((a, b) => a.index - b.index)
                            .map((item, i) => (
                              <TableCell
                                key={i}
                                className="text-darkgreen font-[600] text-center border-b-1 border-grey-300 text-16 md:text-24 p-5 md:p-16"
                              >
                                {item.planName}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getPlansAndFeature?.featuresData
                          ?.slice()
                          .sort((a, b) => a.index - b.index)
                          .map((feature, i) => (
                            <TableRow key={i}>
                              <TableCell className="text-center flex items-center text-black text-12 md:text-16 border-b-1 border-r-1 border-grey-300 font-bold p-5 md:p-16">
                                {feature.featureName
                                  .split(' ')
                                  .map((word) => capitalizeFirstLetter(word))
                                  .join(' ')}
                                <Tooltip
                                  className="hidden md:inline-block"
                                  title={feature.description}
                                >
                                  <img
                                    className="w-14 h-14 md:w-16 md:h-16 m-auto ml-4"
                                    src="/assets/images/icon/info_icon.png"
                                    alt="info"
                                  />
                                </Tooltip>
                              </TableCell>
                              {getPlansAndFeature?.heading
                                ?.slice()
                                .sort((a, b) => a.index - b.index)
                                .map((plan, j) => {
                                  const valueForPlan = feature.value.find(
                                    (value) => value.packageTypeId === plan.packageTypeId
                                  );
                                  return (
                                    <TableCell
                                      key={j}
                                      className="text-center text-black text-12 md:text-16 border-b-1 border-grey-300 font-bold p-5 md:p-16"
                                    >
                                      {valueForPlan?.value ? (
                                        valueForPlan?.value === 'true' ? (
                                          <img
                                            className="w-20 h-20 m-auto"
                                            src="/assets/images/icon/reviewCheck.svg"
                                            alt="right"
                                          />
                                        ) : (
                                          valueForPlan?.value
                                        )
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            )}
            {appDialog?.props?.open && <ContactDialog />}
            {openDialog && (
              <>
                <Dialog
                  classes={{
                    paper:
                      'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-red-600',
                  }}
                  open={openDialog}
                  onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      setOpenDialog(false);
                      setIsCancelSubscriptionForUpgradeDownGrade(false);
                    }
                  }}
                  fullWidth
                  maxWidth="xs"
                >
                  <DialogTitle className="p-0 text-center">
                    <img
                      src="/assets/images/business/warning_alert.png"
                      className="img-fluid mx-auto w-136 h-136"
                      alt="..."
                    />
                    <Typography className="text-20 font-semibold text-black text-center w-full pb-10 mt-28 mb-0">
                      {isCancelSubscriptionForUpgradeDownGrade
                        ? t('plan.cancelSubscription.currentPlanCancel')
                        : t('plan.cancelSubscription.title')}
                    </Typography>
                    <Icon
                      onClick={() => {
                        setOpenDialog(false);
                        setIsCancelSubscriptionForUpgradeDownGrade(false);
                      }}
                      className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                    >
                      close
                    </Icon>
                  </DialogTitle>
                  <DialogContent className="p-0 mb-28">
                    <DialogContentText
                      id="alert-dialog-description"
                      className="text-14 font-medium text-center text-black max-w-sm"
                    >
                      {isCancelSubscriptionForUpgradeDownGrade
                        ? t('plan.cancelSubscription.currentPlanCancelDesc')
                        : t('plan.cancelSubscription.desc')}
                    </DialogContentText>
                  </DialogContent>
                  {!isCancelSubscriptionForUpgradeDownGrade && (
                    <DialogActions className="p-0 justify-center">
                      <Button
                        variant="outlined"
                        color="secondary"
                        className="md:text-16 font-medium disabled:text-black rounded-md px-24 pb-6"
                        aria-label={t('plan.cancelSubscription.keep')}
                        onClick={() => {
                          setOpenDialog(false);
                        }}
                        type="button"
                        size="small"
                      >
                        {t('plan.cancelSubscription.keep')}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        className="md:text-16 font-medium disabled:text-black rounded-md px-24 pb-6"
                        aria-label={t('plan.CurrentSubscription.cancelSubscription')}
                        onClick={() => handleCancelSubscription()}
                        disabled={refreshCancelSubscriptionIcon}
                        type="button"
                        size="small"
                      >
                        {t('plan.CurrentSubscription.cancelSubscription')}
                        {refreshCancelSubscriptionIcon && (
                          <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                        )}
                      </Button>
                    </DialogActions>
                  )}
                </Dialog>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

export default withReducer('planReducer', reducer)(Plan);
