import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import jwtDecode from 'jwt-decode';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import FuseLoading from '@fuse/core/FuseLoading';
import TablePagination from '@mui/material/TablePagination';
import userService from 'src/app/services';
import DeletePopUp from '../../business/manage-business/PopUp';
import { getDataToServer, handleApiRequest, getEncryptedData } from '../../common/common';
import agencyData from '../../query/agency';
import history from '../../../../@history/@history';

function Business() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loginUserData = userService.getUserData();
  const [agencyBusinessDetail, setAgencyBusinessDetail] = useState([]);
  const [totalBusiness, setTotalBusiness] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [businessId, setBusinessId] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: agencyData.getBusinessListForAgencyAndClient,
        variables: {
          pageSize: 12,
          pageNumber: 1,
          searchText: '',
          isActive: true,
        },
      };
      const result = await getDataToServer(payload);
      if (result?.getBusinessListForAgencyAndClient?.status === 200) {
        setPageLoading(false);
      }
      setAgencyBusinessDetail(result?.getBusinessListForAgencyAndClient?.data);
      setTotalBusiness(result?.getBusinessListForAgencyAndClient?.totalCount);
    };
    fetchDashboard();
  }, [dispatch]);

  const deleteBusiness = async () => {
    const payload = {
      query: agencyData.removeUserBusinessDetail,
      variables: {
        businessId: parseInt(businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.removeUserBusinessDetail?.status === 200) {
      cancelDeleteBusiness();
    }
  };

  const cancelDeleteBusiness = async () => {
    setBusinessId('');
    setOpenDeleteDialogue(false);

    const payload = {
      query: agencyData.getBusinessListForAgencyAndClient,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    const result = await getDataToServer(payload);
    setAgencyBusinessDetail(result?.getBusinessListForAgencyAndClient?.data);
    setTotalBusiness(result?.getBusinessListForAgencyAndClient?.totalCount);
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    const payload = {
      query: agencyData.getBusinessListForAgencyAndClient,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getBusinessListForAgencyAndClient?.status === 200) {
      setAgencyBusinessDetail(result?.getBusinessListForAgencyAndClient?.data);
      setTotalBusiness(result?.getBusinessListForAgencyAndClient?.totalCount);
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }
  const handleRemove = (value) => {
    setBusinessId(value);
    setOpenDeleteDialogue(true);
  };

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: agencyData.getBusinessListForAgencyAndClient,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getBusinessListForAgencyAndClient?.status === 200) {
      setAgencyBusinessDetail(result?.getBusinessListForAgencyAndClient?.data);
      setTotalBusiness(result?.getBusinessListForAgencyAndClient?.totalCount);
    }
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  const addBusiness = () => {
    history.push('/agency/add-business');
  };

  const redirectClient = async (item) => {
    const data = JSON.stringify({
      agentId: parseInt(loginUserData?.id, 10),
      userId: item?.userId,
    });
    const payload = {
      query: agencyData.getTokenUsingUserIdAndBusinessId,
      variables: { data: getEncryptedData(data) },
    };
    const result = await handleApiRequest(payload);
    if (result?.getTokenUsingUserIdAndBusinessId?.status === 200) {
      const userDetails = jwtDecode(result?.getTokenUsingUserIdAndBusinessId?.accessToken);
      sessionStorage.setItem(`selectedBusinessId_${userDetails?.id}`, item?.businessId);
      sessionStorage.setItem(
        `jwt_access_token_${userDetails?.id}`,
        result?.getTokenUsingUserIdAndBusinessId?.accessToken
      );
      window.open(`business/dashboard/${userDetails?.id}`, '_blank');
    }
  };

  return (
    <div className="p-20 lg:p-32">
      <div className="sm:flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('dashboard.dashboardAgency.business')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center sm:px-12 my-16 sm:my-0">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none border-1 border-solid border-grey-300"
          >
            <Input
              placeholder={t('agencyBusiness.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end">
          {agencyBusinessDetail && agencyBusinessDetail?.length > 0 && (
            <Button
              className="text-16 px-32 rounded-md"
              variant="contained"
              color="secondary"
              aria-label="Edit"
              onClick={(e) => addBusiness()}
            >
              {t('agencyBusiness.addBusiness')}
            </Button>
          )}
        </div>
      </div>
      {agencyBusinessDetail && agencyBusinessDetail?.length > 0 ? (
        <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-28">
          {agencyBusinessDetail?.map &&
            agencyBusinessDetail.map((item, index) => (
              <div
                className="bg-white p-14 rounded-md cursor-pointer text-center relative"
                key={index}
                aria-hidden="true"
                onClick={() => redirectClient(item)}
              >
                <img
                  src={
                    item?.profileURL
                      ? item?.profileURL
                      : '/assets/images/agency/businessprofile.png'
                  }
                  className="img-fluid mx-auto wax-w-200 mb-16 max-h-80 rounded-md"
                  alt="..."
                />
                <h3 className="text-16 font-bold">
                  <span className="block">
                    {item?.name}{' '}
                    <Icon
                      className="text-red bg-red-100 rounded-full w-24 h-24 text-14 text-center pt-5 top-12 right-12 cursor-pointer absolute z-30"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(item?.businessId);
                      }}
                    >
                      delete
                    </Icon>
                  </span>
                </h3>
                <span className="text-14 font-semibold text-grey-600 my-8 block">
                  {item?.users && `${t('agencyBusiness.client')}`}
                  {item?.users?.firstName} {item?.users?.lastName}
                </span>
                <Typography className="text-14 font-semibold text-grey-600">
                  {t('agencyBusiness.address')} {item?.address || item?.address2}
                </Typography>
              </div>
            ))}
        </div>
      ) : (
        <div className="bg-white rounded-md relative shadow">
          {searchValue ? (
            <div className="bg-white rounded-md relative shadow">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
              >
                <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                  {t('agencyBusiness.noBusinessFound')}
                </Typography>
              </motion.div>
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center noOrder-height"
            >
              <div className="mx-auto text-center">
                <span className="block text-center">
                  <img
                    src="/assets/images/agency/nobusiness.png"
                    className="img-fluid mx-auto w-144 h-144 mb-36"
                    alt="..."
                  />
                </span>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className="text-20 py-12 block font-semibold text-black"
                >
                  {t('agencyBusiness.noBusiness')}
                </Typography>
                <Typography
                  variant="h5"
                  className="text-16 font-medium py-12 mb-16 text-grey-600 max-w-512"
                >
                  {t('agencyBusiness.noBusinessDesc')}
                </Typography>
                <Button
                  className="text-18 px-32 rounded-md"
                  variant="contained"
                  color="secondary"
                  aria-label="Edit"
                  onClick={(e) => addBusiness()}
                >
                  {t('agencyBusiness.addFirst')}
                </Button>
              </div>
            </motion.div>
          )}
        </div>
      )}
      {agencyBusinessDetail?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalBusiness}
          rowsPerPage={12}
          rowsPerPageOptions={[]}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
        />
      )}
      <DeletePopUp
        openValue={openDeleteDialogue}
        eventCall={deleteBusiness}
        cancelCall={cancelDeleteBusiness}
        popUpMessage={t('agencyBusiness.popUpMessage')}
        popUpDescription={t('agencyBusiness.popUpDescription')}
        popUpButtonName={t('agencyBusiness.popUpButtonName')}
      />
    </div>
  );
}
export default Business;
