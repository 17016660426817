import Waba from './Waba';
import WabaBroadcast from './WabaBroadcast';
import WabaContact from './WabaContact';
import WabaInbox from './WabaInbox';

const WabaConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-chat/waba/:id?',
      element: <Waba />,
    },
    {
      path: 'quick-chat/waba-inbox/:id?',
      element: <WabaInbox />,
    },
    {
      path: 'quick-chat/waba-broadcast/:id?',
      element: <WabaBroadcast />,
    },
    {
      path: 'quick-chat/waba-contacts/:id?',
      element: <WabaContact />,
    },
  ],
};

export default WabaConfigs;
