import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import selectedBusinessData from 'src/app/main/query/selectedBusiness';
import { useParams } from 'react-router-dom';
import { handleApiRequest } from 'src/app/main/common/common';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

const FollowUpDetails = () => {
  const classes = useStyles();
  const defaultValues = {
    sliderName: '',
    des: '',
    buttonName: '',
    urlValue: '',
    packageType: '',
  };

  const routeParams = useParams();

  const [followUpData, setFollowUpData] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectDate, setSelectDate] = useState('');
  const [selectTime, setSelectTime] = useState('');
  const [feedback, setFeedback] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fetchFollowUpData = useCallback(async () => {
    const payload = {
      query: selectedBusinessData.getDemoBusinessFollowUpData,
      variables: {
        businessId: parseInt(routeParams.businessId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getDemoBusinessFollowUpData?.status === 200) {
      setFollowUpData(result?.getDemoBusinessFollowUpData?.data);
    }
  }, [routeParams.businessId]);

  useEffect(() => {
    fetchFollowUpData();
  }, [fetchFollowUpData]);

  const handleSelectDate = (data) => {
    const originalDate = new Date(data);
    const formattedDate = originalDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDate.split('/');
    const desiredFormat = `${day}-${month}-${year}`;
    setSelectDate(desiredFormat);
  };

  const handleSelectTime = (data) => {
    const formattedTime = data.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    setSelectTime(formattedTime);
  };

  const clearData = () => {
    setIsDialogOpen(false);
    setSelectDate('');
    setSelectTime('');
    setFeedback('');
  };

  const onSubmit = async () => {
    if (!selectDate || !selectTime || !feedback) {
      enqueueSnackbar(t('business.requiredMessage'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }

    const payload = {
      query: selectedBusinessData.setNewFollowUpBySalesPerson,
      variables: {
        businessId: parseInt(routeParams.businessId, 10),
        followUpDate: {
          date: selectDate,
          time: selectTime,
        },
        note: feedback,
      },
    };
    const result = await handleApiRequest(payload);

    if (result?.setNewFollowUpBySalesPerson?.status === 200) {
      fetchFollowUpData();
      clearData();
    } else {
      enqueueSnackbar(result?.setNewFollowUpBySalesPerson?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <div className="bg-grey-100 rounded-md p-20">
      <div className="flex justify-between items-center">
        <Typography className="text-black font-bold text-15 md:text-18">
          {t('business.selectedBusinessDetail.labels.followUpDetail')}
        </Typography>
        <Button
          className="bg-darkgreen hover:bg-darkgreen text-white h-28 min-h-auto px-12"
          onClick={() => setIsDialogOpen(true)}
          type="small"
        >
          <AddIcon /> {t('business.selectedBusinessDetail.labels.addNew')}
        </Button>
      </div>
      <hr className="border-dashed border-black mt-16" />
      <div className="mt-20 md:h-[350px] overflow-scroll pl-8">
        {followUpData?.length > 0 &&
          followUpData?.map((item, i) => {
            const reversedIndex = followUpData.length - i;
            return (
              <div className="border-l-2 pl-20 pt-14 relative border-darkgreen" key={i}>
                <div
                  className={`absolute left-[-10px] text-center w-[20px] h-[20px] border border-darkgreen rounded-xl ${
                    i === 0 ? 'bg-darkgreen text-white' : 'bg-white text-black'
                  }`}
                >
                  {reversedIndex}
                </div>

                <div className="flex gap-14">
                  <Typography>{moment(item?.followUpDate).format('MMM DD, YYYY')}</Typography>
                  <Typography>{moment.utc(item.followUpDate).format('h:mm A')}</Typography>
                </div>
                <Typography>{item?.note}</Typography>
              </div>
            );
          })}
      </div>
      <Dialog
        classes={{
          root: classes.root,
          paper: 'lg:max-w-[30%] overflow:scroll business-type-dropdown rounded-md',
        }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-20">
          <div className="bg-darkgreen h-[15px] text-white relative">.</div>
          <Icon
            onClick={() => {
              setIsDialogOpen(false);
            }}
            className="text-24 absolute text-end top-20 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            {t('appPromotionSlider.cancel')}
          </Icon>
        </DialogTitle>
        <DialogContent
          className={`${classes.attDialog} p-0 mb-8 p-28 business-type-dropdown-content`}
        >
          <Typography className="text-16 font-bold">{t('business.addNewFollowUpTitle')}</Typography>
          <hr className="border border border-1 border-darkgreen-200 mt-12" />
          <div className="mt-16">
            <div className="w-full">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="mt-4 w-full !text-darkgreen"
                  id="note"
                  variant="outlined"
                  required
                  fullWidth
                  disablePast
                  label={t('business.selectedBusinessDetail.labels.nextFollowUpDate')}
                  onChange={handleSelectDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="w-full mt-16">
              <TimePicker
                className="mt-4 w-full text-darkgreen"
                label={t('business.selectedBusinessDetail.labels.nextFollowUpTime')}
                onChange={handleSelectTime}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
            <div className="mt-12">
              <TextField
                className="mt-4 text-black"
                id="followUpNote"
                multiline
                placeholder={t('business.selectedBusinessDetail.labels.nextFollowUpNote')}
                rows={2}
                type="text"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                variant="outlined"
                required
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-0 m-auto mb-48">
          <Button
            className="md:text-18 px-40 font-semibold bg-white hover:bg-white text-darkgreen border border-solid border-darkgreen rounded-md"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => clearData()}
          >
            {t('business.buttons.cancel')}
          </Button>
          <Button
            className="md:text-18 font-semibold hover:bg-darkgreen disabled:text-black rounded-md"
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => onSubmit()}
          >
            {t('business.buttons.addFollowUp')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FollowUpDetails;
