import { Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

const SupportForPhysicalOrOnline = (props) => {
  const { message } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  return (
    <div className="bg-white h-screen flex justify-center items-center p-20">
      <div className="m-auto">
        <img className="m-auto" src="/assets/images/business/support.svg" alt="" />
        <Typography className="text-center py-16 font-medium text-24">
          {t('support.support')}
        </Typography>
        <Typography className="text-16 max-w-512 sm:m-auto text-center">
          {userCurrentBusinessDetails?.isOnlineBusiness
            ? t('support.onlineSupportMessage')
            : message || t('support.physicalSupportMessage')}
        </Typography>
      </div>
    </div>
  );
};

export default SupportForPhysicalOrOnline;
