import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SocialReviews from './SocialReviews';

const CreateReviewPost = ({ open, onClose, textColor, selectedImage }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown border-t-8 border-solid text-center border-t-${textColor} border-${textColor} rounded-md`,
      }}
      PaperProps={{
        style: { maxWidth: '1750px', minWidth: '1750px' },
      }}
    >
      <h3 className="text-left font-semibold">Select Review</h3>
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-32 h-32 text-grey-700" />
      </IconButton>

      <DialogContent className="p-0 mb-24">
        <SocialReviews selectedImage={selectedImage} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateReviewPost;
