const getQuickSocialChannelWiseData = ` 
query($businessId: Int, $domain: String){
	getQuickSocialChannelWiseData(businessId: $businessId, domain: $domain) {
    status
    message
    data {
      reviewPlatformId
      name
      logo
      isConnected
    }
	}
  }  
`;

const getFacebookPageDetails = `query ($accessToken: String!, $userId: String){
  getFacebookPageDetails(accessToken: $accessToken, userId: $userId) {
    status
    message
    data {
      id
      name
      accessToken
    }
  }
}`;

const getFacebookBusiness = `query ($access_token: String!){
  getFacebookBusiness(access_token: $access_token) {
    status
    message
    data
  }
}`;

const saveFacebookAuthToken = ` 
mutation($accessToken: String!, $facebookUserId: String!, $pageId: String!, $pageName: String!, $userBusinessId: Int!){
	saveFacebookAuthToken(accessToken: $accessToken, facebookUserId: $facebookUserId, pageId: $pageId, pageName: $pageName, userBusinessId: $userBusinessId) {
    status
    message
	}
  }  
`;

const saveInstagramBusiness = ` 
mutation ($access_token: String!, $pageId: String!, $userBusinessId: Int!){
  saveInstagramBusiness(access_token: $access_token, pageId: $pageId, userBusinessId: $userBusinessId) {
    message
    status
  }
}`;

const suggestionPostV2 = ` query ($pageNumber: Int, $pageSize: Int, $platformId: Int!, $rating: Int, $userBusinessId: Int!){
  suggestionPostV2(pageNumber: $pageNumber, pageSize: $pageSize, platformId: $platformId, rating: $rating, userBusinessId: $userBusinessId) {
    status
    message
    totalCount
    data {
      editableText{
key
value
}
      postJsonData
      htmlTemplate
      rating
      reviewId
      sharePostImage
    }
  }
}
`;

const getQuickSocialSchedulePost = `query($userBusinessId: Int!, $pageSize:Int , $pageNumber: Int) {
  getQuickSocialSchedulePost(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
    status
    message
    data {
      id
      sharedChannels{
        id
        logo
      }
      scheduledDate
      isScheduleDone
      postData{
        imageUrl
        caption
      }
      editableText{
        key
        value
      }
      html
    }
    }
  }`;
const getQuickSocialBrandHealthMatrices = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialBrandHealthMatrices(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      brandHealthData
    }
	}
  }  
`;

const getQuickSocialEngagementMetrics = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialEngagementMetrics(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      engagementData
    }
	}
  }  
`;

const getQuickSocialMatrices = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialMatrices(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      followerGrowthData{
        key
        value
      }
      summary{
        key
        value
      }
      title
    }
	}
  }  
`;

const getAllQuickSocialProfileDetails = `
query ($userBusinessId: Int!, $mediaChannelId: Int!){
  getAllQuickSocialProfileDetails(userBusinessId: $userBusinessId, mediaChannelId: $mediaChannelId) {
    status
    message
    data {
      id
      name
      profilePictureUrl
      userName
      followersCount
      followsCount
      totalPosts
      fbPageUrl
      fbPageCategory
      fbPageAbout
      emails
      igId
      igFacebookPageId
      ldVanityName
      ldOrganizationId
      ldStaffCountRange
    }
  }
}`;

const disconnectInstagramAndFacebook = `
mutation ($isFacebook: Boolean!, $isInstagram: Boolean!, $userBusinessId: Int!){
  disconnectInstagramAndFacebook(isFacebook: $isFacebook, isInstagram: $isInstagram, userBusinessId: $userBusinessId){
    message
    status
  }
}`;

const getAllFacebookChannelData = ` 
query($userBusinessId: Int!, $pageSize: Int, $pageNumber: Int){
	getAllFacebookChannelData(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber) {
    status
    message
    totalCount
      data{
      id
      pageName
      profilePictureUrl
      uts
      postId
      createdTime
      description
      media {
        image
        permalink_url
      }
      activity {
        likes
        comments
        shares
      }
      insights {
        title
        value
      }
    }
	}
  }  
`;

const getInstagramPosts = `
query ($userBusinessId: Int!, $pageSize: Int, $pageNumber: Int){
  getInstagramPosts(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
    data{
      id
      postUrl
      userName
      userProfile
      postId
      mediaUrl
      mediaType
      caption
      activity{
        comments_count
        impression
        like_count
      }
      createTime
     insights{
      title
      value
    }
    }
    message
    status
    totalCount
  }
}`;

const quickSocialQuery = {
  getQuickSocialChannelWiseData,
  getFacebookPageDetails,
  getFacebookBusiness,
  saveFacebookAuthToken,
  saveInstagramBusiness,
  suggestionPostV2,
  getQuickSocialSchedulePost,
  getQuickSocialBrandHealthMatrices,
  getQuickSocialEngagementMetrics,
  getQuickSocialMatrices,
  getAllQuickSocialProfileDetails,
  disconnectInstagramAndFacebook,
  getAllFacebookChannelData,
  getInstagramPosts,
};

export default quickSocialQuery;
