import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo, useCallback } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import { useDispatch, useSelector } from 'react-redux';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import history from '@history';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import paymentData from '../query/payment';
import { getEncryptedData, getDecryptData } from '../common/common';
import {
  createSubscriptionV1,
  getPackageDataOnPackageIdAndCountryId,
  checkPromoCode,
} from './store/paymentSlice';
import reducer from './store';

function Payment() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  const countryData = useMemo(
    () => [
      {
        id: 2,
        name: 'India',
        countryCode: 91,
        isoCode: 'IND',
        isActive: true,
        currency: 'INR',
        symbol: '₹',
        flag: 'https://flagcdn.com/w320/in.png',
        countryId: 2,
      },
      {
        id: 1,
        name: 'United States',
        countryCode: 1,
        isoCode: 'USA',
        isActive: true,
        currency: 'USD',
        symbol: '$',
        flag: 'https://flagcdn.com/w320/us.png',
        countryId: 1,
      },
    ],
    []
  );
  const subscriptionStatus = useSelector(
    ({ paymentReducers }) =>
      paymentReducers?.payment?.createSubscriptionData?.data?.createSubscriptionV1 || ''
  );
  const getPackageData = useSelector(
    ({ paymentReducers }) =>
      paymentReducers?.payment?.getPackageDataOnPackageIdAndCountryIdData?.data
        ?.getPackageDataOnPackageIdAndCountryId || ''
  );

  const getPromoCodeData = useSelector(
    ({ paymentReducers }) =>
      paymentReducers?.payment?.checkPromoCodeData?.data?.checkPromoCode || {}
  );

  let promoCodeDecryptValue;

  if (Object.keys(getPromoCodeData).length > 0 && getPromoCodeData?.status === 200) {
    promoCodeDecryptValue = JSON.parse(getDecryptData(getPromoCodeData?.data));
  }

  const [loading, setLoading] = useState(true);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [totalLocation, setTotalLocation] = useState(0);
  const [subscriptionPackageStripePriceId, setSubscriptionPackageStripePriceId] = useState('');
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [promoCodeInfo, setPromoCodeInfo] = useState(false);
  const [ipBasedData, setIpBasedData] = useState();
  const [amountPerLocation, setAmountPerLocation] = useState();
  const [packageDetails, setPackageDetails] = useState();
  const [routeToken] = useState(jwtDecode(routeParams?.token));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [timeRemaining, setTimeRemaining] = useState();
  const [isTimeOver, setIsTimeOver] = useState(false);
  const stripePriceIds = packageDetails?.map((product) => product.stripePriceId);

  let selectedPackageDetails;
  let nextRecurringAmount;
  let calculateDiscountAmount;
  let calculateTotalAmount;
  let withPromoCodeAmount;
  let stripeAmountOff;

  useEffect(() => {
    if (getPackageData && !packageDetails) {
      setPackageDetails(JSON.parse(getDecryptData(getPackageData?.data)));
    }
  }, [getPackageData, packageDetails]);

  const ipAddressData = useCallback(async () => {
    if (routeToken) {
      if (!ipBasedData) {
        setIpBasedData(countryData.find((o) => o.id === routeToken?.countryId));
      }

      if (routeToken?.getWaySupport !== 'razorpay') {
        const data = JSON.stringify({
          countryId: routeToken?.countryId,
          packageId: routeToken?.packageId,
          packageInterval: routeToken?.packageInterval,
        });
        if (ipBasedData && getPackageData?.status !== 200) {
          const payload = {
            query: paymentData.getPackageDataOnPackageIdAndCountryId,
            variables: { data: getEncryptedData(data) },
          };
          await dispatch(getPackageDataOnPackageIdAndCountryId(payload));
        }
      }

      setLoading(false);
    }
  }, [dispatch, routeToken, ipBasedData, getPackageData, countryData]);

  useEffect(() => {
    setLoading(true);
    ipAddressData();
  }, [ipAddressData]);

  useEffect(() => {
    const tokenExpiryTimestamp = routeToken?.exp;
    const currentDate = new Date();
    if (tokenExpiryTimestamp * 1000 < currentDate.getTime()) {
      setIsTimeOver(true);
    } else {
      const tokenDate = new Date(tokenExpiryTimestamp * 1000);
      const timeDiff = Math.abs(tokenDate.getTime() - currentDate.getTime());
      const timeDiffInSecond = Math.ceil(timeDiff / 1000);
      setTimeRemaining(timeDiffInSecond);
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
  }, [routeToken.exp]);

  useEffect(() => {
    setLoading(true);
    if (routeToken?.getWaySupport === 'razorpay') {
      history.push(`/payment/${routeParams?.token}`);
    }
  }, [routeParams?.token, routeToken?.getWaySupport]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      setIsTimeOver(true);
    }
  }, [timeRemaining]);

  if (getPackageData?.data) {
    selectedPackageDetails = packageDetails?.find((element) => {
      return element.stripePriceId === subscriptionPackageStripePriceId;
    });
    if (Array.isArray(packageDetails) && packageDetails.length > 0) {
      calculateTotalAmount = packageDetails.reduce((sum, product) => {
        const price = product.offerAmount
          ? parseInt(product.offerAmount, 10)
          : parseInt(product.amount, 10);
        return sum + price;
      }, 0);
      nextRecurringAmount = calculateTotalAmount;
    } else {
      console.log('Products is not a valid array or is empty');
    }

    // calculateTotalAmount = parseInt(selectedPackageDetails?.amount, 10);

    if (promoCodeInfo && promoCodeDecryptValue && Object?.keys(promoCodeDecryptValue)?.length > 0) {
      if (promoCodeDecryptValue?.coupon?.percent_off) {
        calculateDiscountAmount =
          (calculateTotalAmount * parseInt(promoCodeDecryptValue?.coupon?.percent_off, 10)) / 100;
        withPromoCodeAmount = calculateTotalAmount - parseInt(calculateDiscountAmount, 10);
      } else if (promoCodeDecryptValue?.coupon?.amount_off) {
        const stripeDiscount = {
          amount_off: promoCodeDecryptValue?.coupon?.amount_off,
          currency: promoCodeDecryptValue?.coupon?.currency,
        };
        stripeAmountOff = formatStripeAmount(stripeDiscount);
        withPromoCodeAmount = calculateTotalAmount - parseInt(stripeAmountOff, 10);
      }
    }
  }

  useEffect(() => {
    if (
      packageDetails?.length > 0 &&
      packageDetails[0]?.stripePriceId &&
      !subscriptionPackageStripePriceId
    ) {
      setSubscriptionPackageStripePriceId(packageDetails[0]?.stripePriceId);
    }
  }, [packageDetails, subscriptionPackageStripePriceId]);

  useEffect(() => {
    if (subscriptionStatus?.status) {
      if (subscriptionStatus?.status !== 200) {
        enqueueSnackbar(subscriptionStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }

      if (subscriptionStatus?.data) {
        const decryptSubscriptionData = JSON.parse(getDecryptData(subscriptionStatus?.data));
        setSubscriptionData({
          subscriptionId: decryptSubscriptionData.subscriptionId,
          clientSecret: decryptSubscriptionData.clientSecret,
        });
      }
    }
  }, [subscriptionStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (getPromoCodeData?.status) {
      if (getPromoCodeData?.status !== 200 && getPromoCodeData?.status !== 401) {
        enqueueSnackbar('coupon code not valid', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
      if (getPromoCodeData?.status === 200) {
        enqueueSnackbar(getPromoCodeData?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setPromoCodeInfo(true);
      }
      if (getPromoCodeData?.status === 401) {
        enqueueSnackbar(getPromoCodeData?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
        setPromoCodeInfo(true);
      }
    }
  }, [getPromoCodeData, enqueueSnackbar, closeSnackbar]);

  if (subscriptionData) {
    return (
      <Subscribe
        location={subscriptionData}
        totalPayableAmount={withPromoCodeAmount || calculateTotalAmount}
        nextRecurringAmount={nextRecurringAmount}
        selectedPackageValue={selectedPackageDetails}
        ipBasedValue={ipBasedData}
        setSubscriptionData={setSubscriptionData}
      />
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangeLocation = (event, amountSingleLocation) => {
    setTotalLocation(event.target.value);
    setAmountPerLocation(amountSingleLocation);
  };

  const handleSubscription = async () => {
    setSubscriptionLoading(true);
    let packagePriceData = {
      countryId: ipBasedData?.countryId,
      packageInterval: routeToken?.packageInterval,
      priceId: stripePriceIds,
      ...(routeToken?.userBusinessId && { userBusinessId: routeToken.userBusinessId }),
    };

    if (promoCodeValue) {
      if (promoCodeDecryptValue && Object?.keys(promoCodeDecryptValue)?.length > 0) {
        packagePriceData = {
          ...packagePriceData,
          promotionCode: promoCodeDecryptValue?.id,
        };
      }
    }
    if (totalLocation > 0) {
      packagePriceData = {
        ...packagePriceData,
      };
    }

    // return;
    const data = JSON.stringify(packagePriceData);

    try {
      const registrationPayload = {
        query: paymentData.createSubscriptionV1,
        variables: { data: getEncryptedData(data) },
        url: routeParams.token,
      };
      await dispatch(createSubscriptionV1(registrationPayload));
      setSubscriptionLoading(false);
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const handleApplyCoupon = async () => {
    setPromoCodeLoading(true);
    const data = JSON.stringify({
      promoCode: promoCodeValue,
      amount: calculateTotalAmount,
      stripePriceId: selectedPackageDetails?.stripePriceId,
    });
    try {
      const payload = {
        query: paymentData.checkPromoCode,
        variables: { data: getEncryptedData(data) },
        url: routeParams.token,
      };
      await dispatch(checkPromoCode(payload));
    } catch (error) {
      console.log(error, 'error');
    }
    setPromoCodeLoading(false);
  };

  function clearPromoCode() {
    setPromoCodeInfo(false);
  }

  function formatStripeAmount(discount) {
    const formattedAmount = (discount.amount_off / 100)
      .toLocaleString(undefined, {
        currency: discount.currency,
      })
      .replace(/,/g, '');
    return parseInt(formattedAmount, 10);
  }

  return (
    <>
      <div className="text-center items-center flex mx-auto rounded-md w-full md:w-auto md:min-w-586">
        <div className="w-full rounded-md m-16" style={{ backgroundColor: '#FAFBFC' }}>
          <div className="bg-white flex items-center justify-between px-16 sm:px-32 py-16  rounded-t-md">
            <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
            {!isTimeOver && <span className="inline-block font-semibold text-16">Step 1 of 2</span>}
          </div>
          {isTimeOver ? (
            <h2 className="font-semibold text-24 py-16">unauthorized</h2>
          ) : (
            <>
              <p style={{ backgroundColor: '#FFFAE5' }} className="py-10 font-medium text-16">
                Please complete the payment within{' '}
                <span className="text-red">
                  {Math.floor(timeRemaining / 60)}:
                  {(timeRemaining % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}{' '}
                  seconds.
                </span>
              </p>
              <div className="text-left px-16 sm:px-32">
                <div className="my-28">
                  <Typography className="mb-0 text-16 md:text-20 font-medium">
                    Subscription will renew automatically
                  </Typography>
                </div>
                <div className="bg-white border py-12 px-14">
                  {packageDetails &&
                    packageDetails?.length > 0 &&
                    packageDetails?.map((packageValue, index) => (
                      <div
                        key={index}
                        className="block border-b last:border-b-0 mb-12 pb-12 last:mb-0 last:pb-0"
                      >
                        <h2 className="text-18 font-bold mb-8">{packageValue?.name}</h2>
                        <div>
                          <span className="inline-block text-16 mb-5 capitalize">
                            <span
                              className={` ${
                                packageValue?.offerAmount
                                  ? 'line-through text-black font-semibold text-14'
                                  : 'text-darkgreen font-semibold md:text-16'
                              }`}
                            >
                              {ipBasedData?.symbol}
                              {packageValue?.amount}
                            </span>{' '}
                            {packageValue?.offerAmount && (
                              <h3 className="my-0 font-semibold inline-block text-darkgreen text-16">
                                <span>{ipBasedData?.symbol}</span>
                                {packageValue.offerAmount}
                              </h3>
                            )}
                            <span className="font-semibold text-16">
                              /{packageValue?.packageInterval}
                            </span>
                          </span>
                          <span className="inline-block text-12 sm:text-14 font-medium ml-5">
                            (
                            {packageValue?.packageInterval === 'month'
                              ? 'Billed monthly'
                              : 'Billed annually'}
                            )
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mt-12">
                  <Accordion className="shadow-none border">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-darkgreen" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className="py-12 px-14"
                      sx={{
                        '& .MuiAccordionSummary-content, &.Mui-expanded': { m: '0 !important' },
                        '&.Mui-expanded': { minHeight: '48px', borderBottom: '1px dashed #A5D6A7' },
                      }}
                    >
                      <Typography className="text-16 font-semibold">
                        {' '}
                        <Icon className="text-20 align-middle mr-12 text-darkgreen">
                          library_add
                        </Icon>
                        Apply coupon
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="pb-15" style={{ padding: '15px' }}>
                      <div>
                        {promoCodeDecryptValue && promoCodeInfo ? (
                          <div>
                            <span className="bg-grey-200 rounded-md font-semibold text-14  py-8 px-12 inline-block items-center">
                              <img
                                src="assets/images/icon/sell_tag.png"
                                className="h-12 w-12 mr-5 mt-5 float-left"
                                alt="..."
                              />{' '}
                              {promoCodeValue}
                              <span
                                className="inline-block relative top-4 ml-10"
                                onClick={() => clearPromoCode()}
                                aria-hidden="true"
                                role="button"
                              >
                                <Icon className="text-16 text-grey-600">close</Icon>
                              </span>
                            </span>
                            <span className="block mt-4 text-12 text-grey-600">
                              {promoCodeDecryptValue?.coupon?.percent_off
                                ? `${promoCodeDecryptValue?.coupon?.percent_off}%`
                                : `${ipBasedData?.symbol}${stripeAmountOff}`}{' '}
                              off
                            </span>
                          </div>
                        ) : (
                          <FormControl variant="standard" className="relative w-full">
                            <TextField
                              placeholder="Enter coupon code"
                              size="small"
                              variant="outlined"
                              className="w-full rounded-md"
                              value={promoCodeValue}
                              onChange={(e) => setPromoCodeValue(e.target.value)}
                              id="bootstrap-input"
                            />

                            <Button
                              variant="text"
                              className={`rounded-md max-w-120 inline-block border-0 hover:border-0 hover:border-0 font-bold absolute -right-2 top-0 -bottom-1 rounded-l-none rounded-r-md ${
                                promoCodeValue
                                  ? 'scale-100 opacity-1'
                                  : 'scale-0 ease-in-out duration-300 opacity-0'
                              }`}
                              color="secondary"
                              disabled={!promoCodeValue || promoCodeLoading}
                              onClick={() => {
                                handleApplyCoupon();
                              }}
                            >
                              Apply{' '}
                              {promoCodeLoading && (
                                <CircularProgress
                                  size={18}
                                  className="text-darkgreen absolute mx-auto right-20 top-12"
                                />
                              )}
                            </Button>
                          </FormControl>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="bg-white border mt-16 py-12 px-14 rounded-md leading-loose">
                  <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 font-medium">Base price</span>
                    <span className="text-14 sm:text-16 font-semibold">
                      {ipBasedData?.symbol}
                      {calculateTotalAmount}
                    </span>
                  </div>
                  {(calculateDiscountAmount || stripeAmountOff) && (
                    <div className="flex items-center justify-between">
                      <span className="text-16 text-grey-600 font-medium">Coupon</span>
                      <span className="text-16 font-semibold">
                        - {ipBasedData?.symbol} {calculateDiscountAmount || stripeAmountOff}
                      </span>
                    </div>
                  )}
                  <div className="flex items-center justify-between pt-5 mt-5 border-t-1 border-t-grey-100">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      Total amount
                    </span>
                    <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                      {ipBasedData?.symbol}
                      {withPromoCodeAmount || calculateTotalAmount}/
                      {selectedPackageDetails?.packageInterval}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-14 sm:text-16 text-grey-600 text-black font-semibold">
                      Next recurring amount
                    </span>
                    <span className="text-14 sm:text-16 text-black font-semibold capitalize">
                      {ipBasedData?.symbol}
                      {calculateTotalAmount}/{selectedPackageDetails?.packageInterval}
                    </span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  className="rounded-md w-full text-18 sm:text-20 font-semibold mt-28"
                  onClick={() => handleSubscription()}
                  disabled={subscriptionLoading}
                  color="secondary"
                >
                  Continue{' '}
                  {subscriptionLoading && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <div className="text-16 text-medium text-center my-20">
                  You can cancel the subscription anytime
                </div>
              </div>
              <div className="bg-white py-10">
                By continuing you agree to our <br />
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.privacyPolicy')}
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  Subscriber Agreement.
                </a>
                ,{' '}
                <a
                  href={process.env.REACT_APP_EULA_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.eula')}
                </a>{' '}
                and{' '}
                <a
                  href={process.env.REACT_APP_DISCLAIMER_LINK}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
                >
                  {t('signUpPage.labels.disclaimers')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function Subscribe({
  location,
  totalPayableAmount,
  selectedPackageValue,
  ipBasedValue,
  nextRecurringAmount,
  selectedPackageDetails,
  setSubscriptionData,
}) {
  const [name, setName] = useState('');
  const [successPayment, setSuccessPayment] = useState(false);
  const [failedPayment, setFailedPayment] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [preComponent, setPreComponent] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // const history = useHistory();

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  if (successPayment) {
    return <Success planName={selectedPackageDetails?.name} />;
  }

  if (failedPayment) {
    return <Failed />;
  }

  if (preComponent) {
    setSubscriptionData(null);
    return <Payment />;
  }

  if (loading) {
    return <FuseLoading />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPayLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (location.clientSecret) {
      await stripe
        .confirmCardPayment(location.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name,
            },
          },
        })
        .then((result) => {
          const { paymentIntent } = result;
          setPayLoading(false);
          if (paymentIntent === undefined) {
            enqueueSnackbar('Invalid card details. Please check and try again', {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          if (paymentIntent?.status === 'succeeded') {
            setSuccessPayment(true);
          }
        })
        .catch((error) => {
          enqueueSnackbar('An error occurred during the payment process. Please try again later.', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    }
  };

  return (
    <>
      <div className="text-center md:h-screen items-center flex mx-auto rounded-md w-full md:w-auto md:min-w-586">
        <div className="w-full rounded-md m-16" style={{ backgroundColor: '#FAFBFC' }}>
          <div className="bg-white flex items-center justify-between px-16 sm:px-32 py-16  rounded-t-md">
            <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
            <span className="inline-block font-semibold text-16">Step 2 of 2</span>
          </div>
          <div className="px-16 sm:px-32 mt-20 sm:mt-40">
            <div className="text-left">
              <Icon
                className="text-22 cursor-pointer text-darkgreen bg-darkgreen-100 w-28 h-28 rounded-full flex items-center justify-center mb-20 sm:mb-40"
                onClick={() => {
                  setPreComponent(true);
                }}
              >
                chevron_left
              </Icon>
            </div>
            <h2 className="text-24 font-semibold text-left">Set Up Your Card Details</h2>
            <Typography className="text-16 md:text-20 text-left mt-5 text-medium mb-40">
              Subscription will renew automatically
            </Typography>

            <form onSubmit={handleSubmit}>
              <CardElement
                onReady={() => {
                  console.log('CardElement [ready]');
                }}
                onChange={(event) => {
                  console.log('CardElement [change]', event);
                }}
                onBlur={() => {
                  console.log('CardElement [blur]');
                }}
                onFocus={() => {
                  console.log('CardElement [focus]');
                }}
              />
              {/* <CardNumberElement id="cardNumber" />*/}
              {/* <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-20">*/}
              {/*  <CardExpiryElement />*/}
              {/*  <CardCvcElement />*/}
              {/* </div>*/}
              {/* <input
                type="text"
                className="StripeElement w-full"
                placeholder="Name on card"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              /> */}

              <Button
                variant="contained"
                color="secondary"
                className="w-full rounded-md text-18 sm:text-20 font-semibold"
                type="submit"
                disabled={!stripe || payLoading}
              >
                {`Pay ${ipBasedValue?.symbol}${totalPayableAmount}`}
                {payLoading && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </form>
            <Typography className="text-14 md:text-16 max-w-512 mt-20 mb-32">
              {ipBasedValue?.countryId === 2
                ? `Your card will be securely stored as per RBI guideline and you will be charged ${ipBasedValue?.symbol} ${nextRecurringAmount}
              every ${selectedPackageValue?.packageInterval} until you cancel. You can cancel the subscription anytime.`
                : `You will be charged  ${ipBasedValue?.symbol} ${nextRecurringAmount} every ${selectedPackageValue?.packageInterval} until you cancel. You can cancel the subscription anytime. You will finish your current subscription period without receiving money back. Once the subscription expires, your account will revert to a free account.`}
            </Typography>
          </div>
          <div className="bg-white py-10">
            By continuing you agree to our <br />
            <a
              href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
            >
              {t('signUpPage.labels.privacyPolicy')}
            </a>
            ,{' '}
            <a
              href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
            >
              Subscriber Agreement.
            </a>
            ,{' '}
            <a
              href={process.env.REACT_APP_EULA_LINK}
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
            >
              {t('signUpPage.labels.eula')}
            </a>{' '}
            and{' '}
            <a
              href={process.env.REACT_APP_DISCLAIMER_LINK}
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              className="text-darkgreen link-color cursor-pointer !bg-white font-medium"
            >
              {t('signUpPage.labels.disclaimers')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

function Success({ planName }) {
  useEffect(() => {
    return () => {
      setTimeout(() => {
        history.push(
          'firstTimeSubscription://quickReviewsPurchasePlanSuccess' ||
            'subscription://quickreviewsSubscriptionSuccess'
        );
      }, 2000);
    };
  }, []);

  const handleBackToApp = () => {
    // history.push(
    //   'firstTimeSubscription://quickReviewsPurchasePlanSuccess' ||
    //     'subscription://quickreviewsSubscriptionSuccess'
    // );
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = 'subscription://quickreviewsSubscriptionSuccess';
    } else {
      window.location.href = process.env.REACT_APP_PORTAL_URL;
    }
    // const deepLink = 'quickreviews.click://PlanDetailsScreen';
    // const newWindow = window.open(deepLink, '_blank');
    // if (newWindow) {
    //   newWindow.focus();
    // } else {
    //   // Handle cases where the deep link couldn't be opened (e.g., app not installed)
    //   console.log('Failed to open the app. Please make sure the app is installed.');
    // }
    // window.open('about:blank', '_self');
    // window.close(); // This will close the tab if the user confirms.
  };

  return (
    <div
      className="text-center md:h-screen items-center text-center md:flex mx-auto rounded-md"
      style={{ maxWidth: '586px' }}
    >
      <div className="rounded-md m-16 sm:m-0" style={{ backgroundColor: '#FAFBFC' }}>
        <div className="bg-white px-16 sm:px-32 py-16  rounded-t-md">
          <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
        </div>
        <div className="px-16 sm:px-32 mt-68 pb-32">
          <img src="assets/images/icon/done.png" className="h-144 w-144 mx-auto mb-28" alt="..." />
          <h2 className="text-24 font-semibold">Purchase Successful!</h2>
          <Typography className="md:text-18 text-16 mt-12 text-medium mb-40 max-w-400">
            Congratulations! You have successfully purchased the Plan. You now have access to all
            the fantastic features and benefits it offers.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className="w-full rounded-md text-18 sm:text-20 font-semibold"
            type="button"
            onClick={handleBackToApp}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

function Failed() {
  const handleBackToApp = () => {
    window.location.href = 'URL_of_Your_App';
  };

  return (
    <div
      className="text-center md:h-screen items-center text-center md:flex mx-auto rounded-md"
      style={{ maxWidth: '586px' }}
    >
      <div className="rounded-md m-16 sm:m-0" style={{ backgroundColor: '#FAFBFC' }}>
        <div className="bg-white px-16 sm:px-32 py-16  rounded-t-md">
          <img src="assets/images/logo/logo.png" className="w-[170px]" alt="logo" />
        </div>
        <div className="px-16 sm:px-32 mt-68 pb-32">
          <img
            src="assets/images/icon/attention.png"
            className="h-144 w-144 mx-auto mb-28"
            alt="..."
          />
          <h2 className="text-24 font-semibold">Payment Failed!</h2>
          <Typography className="md:text-18 text-16 mt-12 text-medium mb-40 max-w-400">
            Oops! It seems there was an issue with your payment. Please check your payment
            information and try again.
          </Typography>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-20">
            <Button
              variant="outlined"
              color="secondary"
              className="w-full rounded-md text-18 sm:text-20 font-semibold"
              type="button"
              onClick={handleBackToApp}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="w-full rounded-md text-18 sm:text-20 font-semibold"
              type="button"
              onClick={() => <Payment />}
            >
              Retry Payment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withReducer('paymentReducers', reducer)(Payment);
