import { Button, Icon, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import history from '@history';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import businessData from '../../../query/business';
import { handleApiRequest } from '../../../common/common';

const DashboardAlerts = () => {
  const [cardData, setCardData] = useState([]);
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const settings = {
    dots: false,
    infinite: cardData?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCardV1,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            productTypeId: 7,
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.getBusinessWhatsNewCardV1?.status === 200 &&
          result.getBusinessWhatsNewCardV1?.data
        ) {
          setCardData(JSON.parse(result.getBusinessWhatsNewCardV1?.data?.cardJson));
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchCardData();
    }
  }, [userCurrentBusinessDetails?.id]);

  const handleCardClick = (item) => {
    const { cardName, portalURL, placeId, reviewPlatformId, competitorURL } = item;
    const { id } = params;

    const basePath = `/${portalURL}/${id || ''}`;

    let state = null;

    switch (cardName) {
      case 'quickReviewCompetitorReview':
        if (competitorURL) {
          window.open(competitorURL, '_blank');
        } else {
          console.error('Competitor URL is missing');
        }
        break;

      case 'quickReviewDailyReview':
      case 'quickReviewNewReview':
      case 'quickReviewReplyToLatest':
      case 'quickReviewOnlineReviewAltert':
      case 'quickReviewOnlineOverall':
      case 'quickReviewOnlineWeeklyReview':
        state = {
          placeIdForRedirect: placeId,
          ratingTypeForRedirect: 'delivery',
          platformsId: reviewPlatformId,
        };
        history.push({ pathname: basePath }, state);
        break;

      default:
        history.push({ pathname: basePath });
        break;
    }
  };

  return (
    <>
      {cardData?.length > 0 && (
        <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
          <Slider {...settings}>
            {cardData
              ?.filter((item) => item?.cardName !== 'defaultRatingCounter')
              ?.map((item, index) => {
                return (
                  <div className="pr-0 md:pr-16" key={index}>
                    {item && (
                      <div
                        className="flex gap-16 items-center w-full min-h-200 max-h-200 justify-start p-12 py-16 rounded-md"
                        style={{ backgroundColor: item?.backgroundColor }}
                      >
                        <div>
                          <Typography
                            className="font-semibold text-20"
                            style={{ color: item?.textColor }}
                          >
                            {item?.description}
                          </Typography>
                          <Button
                            className="rounded-md text-white max-w-144 min-w-112 sm:max-w-144 sm:min-w-120 mt-20"
                            style={{
                              backgroundColor: item?.buttonColor,
                              '--hover-bg': item?.buttonColor,
                              color: item?.buttonTextColor,
                            }}
                            onClick={() => handleCardClick(item)}
                          >
                            {item?.ctaButton}
                          </Button>
                        </div>
                        <div>
                          <img className="hidden w-192 sm:block" src={item?.icon} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default DashboardAlerts;
