import { Button, Dialog, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import history from '@history';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import businessData from '../../../query/business';
import { handleApiRequest } from '../../../common/common';
import CreateTemplate from './CreateTemplate';
import userService from '../../../../services';

const Template = (props) => {
  const { showInPopUp } = props;
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state?.tabValue || 2);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState();
  const [openDialogToShowTemplate, setOpenDialogToShowTemplate] = useState(false);
  const [subcategoryData, setSubcategoryData] = useState([]);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (tabValue === 3) {
      const categoryForWhatsapp = category?.filter((item) =>
        tabValue === 3 ? item.type === 'Invite Review' : true
      );
      setSelectedCategory(categoryForWhatsapp?.[0]);
    }
  }, [tabValue, category]);

  useEffect(() => {
    const fetchTemplateCategory = async () => {
      try {
        const payload = {
          query: businessData.getCampaignServicesMessagesBusinessType,
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignServicesMessagesBusinessType?.status === 200) {
          setCategory(result.getCampaignServicesMessagesBusinessType?.data);
          setSelectedCategory(result.getCampaignServicesMessagesBusinessType?.data?.[0]);
        }
      } catch (error) {
        console.error('Error white fetching template category', error);
      }
    };
    fetchTemplateCategory();
  }, []);

  useEffect(() => {
    const fetchSubcategory = async () => {
      try {
        const payload = {
          query: businessData.getCampaignServicesMessagesBusinessSubType,
          variables: {
            campaignBusinessTypeId: Number(selectedCategory?.id),
            countryId: Number(loginUserData?.countryId),
          },
        };
        const result = await handleApiRequest(payload);

        if (result?.getCampaignServicesMessagesBusinessSubType?.status === 200) {
          setSubcategoryData(result.getCampaignServicesMessagesBusinessSubType?.data);
        } else {
          enqueueSnackbar(result?.deleteDraftAndScheduleCamping?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    if (loginUserData?.countryId && selectedCategory?.isSubCategory) {
      fetchSubcategory();
    }
  }, [loginUserData?.countryId, selectedCategory, enqueueSnackbar]);

  const fetchTemplate = useCallback(async () => {
    if (userCurrentBusinessDetails?.id && tabValue && selectedCategory?.id) {
      try {
        const payload = {
          query: businessData.getCampaignStandardMessages,
          variables: {
            campaignPlatformId: tabValue,
            messagesBusinessTypeId: Number(selectedCategory?.id),
            ...(Number(selectedCategory?.id) === 1 && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
            ...(selectedSubcategoryId && {
              messagesBusinessSubTypeId: Number(selectedSubcategoryId),
            }),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignStandardMessages?.status === 200) {
          setTemplates(result.getCampaignStandardMessages?.data);
        }
      } catch (error) {
        console.error('Error white fetching template', error);
      }
    }
  }, [selectedCategory, tabValue, userCurrentBusinessDetails, selectedSubcategoryId]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const handleGetTemplateCategoryWise = async (id) => {
    setSelectedCategory(id);
    setSelectedSubcategoryId('');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    handleGetTemplateCategoryWise(selectedCategory, newValue);
    setSelectedSubcategoryId('');
  };

  return (
    <div className="p-24 h-max">
      <div className="sm:flex justify-between sm:mb-0 mb-10">
        <Typography className="font-bold text-28">{t('quickCampaign.template.title')}</Typography>
        {Number(tabValue) !== 3 && !showInPopUp && (
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => setOpenDialog(true)}
            size="medium"
            className="rounded-md gap-10 md:px-20 md:py-12"
          >
            <p className="text-16 font-medium"> {t('quickCampaign.campaign.createTemplate')}</p>
          </Button>
        )}
      </div>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
        <Tabs
          value={tabValue}
          className="sm:overflow-hidden overflow-scroll quick-campaign"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: { xs: 'scroll !important', sm: 'hidden !important' },
            },
          }}
          onChange={handleTabChange}
          textColor="quickCampaign"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 2 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={2}
            label={t('quickCampaign.template.sms')}
          />
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              tabValue === 1 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={1}
            label={t('quickCampaign.template.email')}
          />
          <Tab
            className={`sm:text-18 font-bold p-0 ${
              tabValue === 3 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={3}
            label={t('quickCampaign.template.whatsapp')}
          />
        </Tabs>
      </div>
      <div
        className={`lg:flex md:flex gap-24 ${
          showInPopUp
            ? 'min-w-[1350px] max-w-[1350px] h-[850px] min-w-[400px] max-w-[400px] sm:min-w-[800px] sm:-max-w-[800px] md:max-w-[1150px] md:min-w-[1150px]'
            : 'w-full'
        }`}
      >
        <div className="mb-10 sm:mb-10 overflow-x-auto md:overscroll-hidden">
          <div className="sm:flex flex md:block">
            {category?.length > 0 &&
              category
                ?.filter((item) => (tabValue === 3 ? item.type === 'Invite Review' : true))
                ?.sort((a, b) => a.id - b.id)
                ?.map((item, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    className={`flex px-12 py-16 cursor-pointer gap-8  items-center flex-shrink-0 ${
                      selectedCategory?.id === item?.id
                        ? 'bg-quick-campaign text-white '
                        : ' bg-white '
                    }`}
                    onClick={() => handleGetTemplateCategoryWise(item)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectedCategory(item);
                      }
                    }}
                  >
                    <img className="w-60" src={item?.icon} alt={item?.name} />
                    <h3 className="text-16 font-medium flex-grow">{item?.type}</h3>
                  </div>
                ))}
          </div>
        </div>
        <div className="w-full">
          <div className="w-auto overflow-x-auto mb-8">
            {selectedCategory?.isSubCategory === true && (
              <div className=" gap-10 flex flex-wrap">
                {subcategoryData?.length > 0 &&
                  subcategoryData
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((subcategory) => (
                      <div
                        key={subcategory?.id}
                        role="button"
                        tabIndex={0}
                        className={`flex gap-8 border-1 p-20 text-14 rounded-full justify-center items-center cursor-pointer mb-12 h-32 ${
                          selectedSubcategoryId === subcategory?.id
                            ? 'bg-quick-campaign text-white'
                            : 'border-grey-300 text-black'
                        }`}
                        onClick={() => setSelectedSubcategoryId(subcategory?.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            setSelectedSubcategoryId(subcategory?.id);
                          }
                        }}
                      >
                        <span className="h-24 w-24 bg-white p-4 rounded-full">
                          <img src={subcategory?.icon} alt={subcategory?.type} />
                        </span>
                        <p className="text-ellipsis whitespace-nowrap ">{subcategory?.type}</p>
                      </div>
                    ))}
              </div>
            )}
          </div>
          {templates?.length > 0 ? (
            <div className="grid grid-cols-6 gap-16 h-max w-full">
              {templates?.map((item, i) => {
                return (
                  <div
                    className="bg-white p-16 md:col-span-2 sm:col-span-3 col-span-6 h-max rounded-md w-full"
                    key={i}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setSelectTemplate(item);
                      setOpenDialogToShowTemplate(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setSelectTemplate(item);
                      }
                    }}
                  >
                    <Typography className="font-bold text-16">{item?.title}</Typography>
                    {tabValue === 2 && (
                      <Typography className="bg-gray-A500 p-8 mt-16 rounded-md h-112 overflow-scroll">
                        {item?.messages}
                      </Typography>
                    )}
                    {tabValue === 1 && (
                      <div className="bg-gray-A500 p-8 mt-16 rounded-md h-256 overflow-scroll">
                        <Typography className="">
                          {t('quickCampaign.template.subject')} : <span>{item?.htmlSubject}</span>
                        </Typography>
                        <Typography
                          className="mt-8"
                          dangerouslySetInnerHTML={{ __html: item?.htmlTemplate }}
                        />
                      </div>
                    )}
                    {tabValue === 3 && (
                      <div className="mt-16 rounded-md h-312 overflow-scroll">
                        <Typography
                          className="mt-8 w-full"
                          dangerouslySetInnerHTML={{ __html: item?.htmlTemplate }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center text-center w-full">
              {tabValue === 1 && (
                <div className="pt-160 w-full">
                  <img className="m-auto w-192" src="assets/images/business/email.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noEmailFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noEmailFoundMessage')}
                  </Typography>
                </div>
              )}
              {tabValue === 2 && (
                <div className="pt-160 w-full">
                  <img className="m-auto w-192" src="assets/images/business/sms.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noSmsFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noSmsFoundMessage')}
                  </Typography>
                </div>
              )}
              {tabValue === 3 && (
                <div className="pt-160 w-full">
                  <img className="m-auto w-192" src="assets/images/business/whatsapp.png" alt="" />
                  <Typography className="py-12 font-semibold text-20">
                    {t('quickCampaign.template.noWhatsAppFound')}
                  </Typography>
                  <Typography className="font-semibold text-16 text-gray max-w-400 m-auto">
                    {t('quickCampaign.template.noWhatsAppFoundMessage')}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CreateTemplate
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        category={category}
        tabValue={tabValue}
        fetchTemplate={fetchTemplate}
      />
      <Dialog
        classes={{
          paper: 'm-24 sm:p-16 pt-0 rounded-md max-w-400',
        }}
        open={openDialogToShowTemplate}
        fullWidth
        maxWidth="sm"
      >
        <div className="text-right">
          <Button
            className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
            onClick={() => setOpenDialogToShowTemplate(false)}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="bg-white p-2 h-max rounded-md w-full">
          <Typography className="font-bold text-16">{selectTemplate?.title}</Typography>
          {tabValue === 2 && (
            <Typography className="bg-gray-200 p-8 mt-16 rounded-md">
              {selectTemplate?.messages}
            </Typography>
          )}
          {tabValue === 1 && (
            <div className="bg-gray-A500 p-8 mt-16 rounded-md h-256">
              <Typography className="">
                {t('quickCampaign.template.subject')} : <span>{selectTemplate?.htmlSubject}</span>
              </Typography>
              <Typography
                className="mt-8"
                dangerouslySetInnerHTML={{ __html: selectTemplate?.htmlTemplate }}
              />
            </div>
          )}
          {tabValue === 3 && (
            <div className="mt-16 rounded-md h-312 overflow-scroll">
              <Typography
                className="mt-8 w-full"
                dangerouslySetInnerHTML={{ __html: selectTemplate?.htmlTemplate }}
              />
            </div>
          )}
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() =>
              history.push('quick-campaign/createCampaign', {
                template: selectTemplate,
                platForm: tabValue,
                selectedCategoryId: selectedCategory?.id,
                categoryName: selectedCategory?.type,
              })
            }
            size="medium"
            className="rounded-md w-full mt-20 gap-10 md:px-20 md:py-12"
          >
            <p className="text-16 font-medium"> {t('quickCampaign.campaign.selectTemplate')}</p>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Template;
