import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import moment from 'moment/moment';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import MessageIcon from '@mui/icons-material/Message';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { selectDashboardData } from 'src/app/store/userSlice';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useLocation, useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import history from '@history';
import { useSelector } from 'react-redux';
import {
  Button,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Drawer,
  Pagination,
  Dialog,
  IconButton,
} from '@mui/material';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import Template from '../template/Template';
import UserProfile from '../../../common/userProfile';

const Campaign = () => {
  const params = useParams();
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [selectedTab, setSelectedTab] = useState(location?.state?.selectTab || 0);
  const [campaignData, setCampaignData] = useState('');
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [details, setDetails] = useState('');
  const [engagementData, setEngagementData] = useState('');
  const [contactData, setContactData] = useState('');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [page, setPage] = useState(1);
  const [openCreateCampaignDialog, setOpenCreateCampaignDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [contactDataTotalCount, setContactDataTotalCount] = useState();

  const getCampaignData = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.getSendCampaignMessages,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          messageType,
          isCallFromHomePage: false,
          pageNumber: page,
          rowsPerPage: 10,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getSendCampaignMessages?.status === 200) {
        setCampaignData(result?.getSendCampaignMessages?.data);
        setTotal(result?.getSendCampaignMessages?.totalCount);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [messageType, page, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getCampaignData();
    }
  }, [getCampaignData, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        setIsLoading(true);
        const payload = {
          query: manageBusinessData.getAllQueueMessages,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAllQueueMessages?.status === 200) {
          setDetails(result?.getAllQueueMessages?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        setIsLoading(true);
        const payload = {
          query: manageBusinessData.getCampaignEngagement,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignEngagement?.status === 200) {
          setEngagementData(result?.getCampaignEngagement?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  useEffect(() => {
    const getDetailData = async () => {
      if (!selectedData?.id) return;
      try {
        setIsLoading(true);
        const payload = {
          query: manageBusinessData.getQueueMessagesContactData,
          variables: {
            campaignServicesId: parseInt(selectedData?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQueueMessagesContactData?.status === 200) {
          setContactData(result?.getQueueMessagesContactData?.data);
          setContactDataTotalCount(result?.getQueueMessagesContactData?.totalCount);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };
    getDetailData();
  }, [selectedData?.id]);

  const deleteScheduledCampaign = async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.deleteDraftAndScheduleCamping,
        variables: {
          campaignServicesId: parseInt(popupData?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteDraftAndScheduleCamping?.status === 200) {
        enqueueSnackbar(result?.deleteDraftAndScheduleCamping?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setIsDelPopupOpen(false);
        getCampaignData();
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setPage(1);
    setSelectedTab(newValue);

    switch (newValue) {
      case 0:
        setMessageType(null);
        break;
      case 1:
        setMessageType('scheduled');
        break;
      case 2:
        setMessageType('draft');
        break;
      default:
        setMessageType(null);
        break;
    }
  };

  const handleRowClick = (data) => {
    setSelectedData(data);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedData(null);
  };
  const handleDelClosePopUp = () => {
    setIsDelPopupOpen(false);
  };

  const handleDeleteClick = (data) => {
    setPopupData(data);
    setIsDelPopupOpen(true);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  const handleEditDraft = (data) => {
    history.push('quick-campaign/createCampaign', data);
  };

  return (
    <div>
      <div className="md:p-32 p-16 ">
        <div className="sm:flex justify-between sm:mb-0 mb-10">
          <h1 className=" md:text-28 text-24 font-semibold sm:mb-32 mb-24">
            {t('quickCampaign.campaign.title')}
          </h1>
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => setOpenCreateCampaignDialog(true)}
            size="medium"
            className="rounded-md gap-10 md:px-20 md:py-12"
          >
            <AddIcon />
            <p className="text-16 font-medium"> {t('quickCampaign.campaign.createCampaign')}</p>
          </Button>
        </div>
        <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-32 overflow-x-auto">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="quickCampaign"
            indicatorColor="quickCampaign"
            aria-label="secondary tabs example"
          >
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                selectedTab === 0 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={0}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab1')}</span>
                  {selectedTab === 0 && total ? (
                    <span className="ml-2 text-quick-campaign  border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
            <Tab
              className={`sm:text-18 font-bold mr-20 p-0 ${
                selectedTab === 1 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={1}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab2')}</span>
                  {selectedTab === 1 && total ? (
                    <span className="ml-2 text-quick-campaign border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
            <Tab
              className={`sm:text-18 font-bold p-0 ${
                selectedTab === 2 ? 'text-quick-campaign' : 'text-black'
              }`}
              value={2}
              label={
                <div className="flex items-center gap-10">
                  <span>{t('quickCampaign.campaign.tab3')}</span>
                  {selectedTab === 2 && total ? (
                    <span className="ml-2 text-quick-campaign border-1 border-grey rounded-full px-10">
                      {total}
                    </span>
                  ) : null}
                </div>
              }
            />
          </Tabs>
        </div>

        {selectedTab === 0 && (
          <div>
            <TableContainer component={Paper} className="rounded-md border-none">
              <Table>
                <TableHead className="border-b-2 border-black">
                  <TableRow>
                    <TableCell className="font-semi-bold  text-18">
                      {t('quickCampaign.campaign.srNo')}
                    </TableCell>
                    <TableCell className="font-semi-bold  text-18">
                      {t('quickCampaign.campaign.campaignName')}
                    </TableCell>
                    <TableCell className="font-semi-bold  text-18">
                      {t('quickCampaign.campaign.date')}
                    </TableCell>
                    <TableCell className="font-semi-bold  text-18 w-640">
                      {t('quickCampaign.campaign.templateName')}
                    </TableCell>
                    <TableCell className="font-semi-bold  text-18">
                      {t('quickCampaign.campaign.channel')}
                    </TableCell>
                    <TableCell className="font-semi-bold  text-18">
                      {t('quickCampaign.campaign.status')}
                    </TableCell>
                    <TableCell className="font-semi-bold text-18">
                      {t('quickCampaign.campaign.recipients')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaignData &&
                    campaignData?.map((data, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(data)}
                        className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 border-b-2"
                      >
                        <TableCell className="font-medium text-16">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell className="font-medium text-16">{data?.campaignTitle}</TableCell>
                        <TableCell className="font-medium text-16">
                          {moment(Number(data?.cts)).format('MMM DD, YYYY')}
                        </TableCell>
                        <TableCell className="font-medium text-16">
                          {data?.campaign_services_standard_messages?.title}
                        </TableCell>
                        <TableCell className="font-medium text-16">
                          {data?.campaign_platform?.name}
                        </TableCell>
                        <TableCell className="items-center justify-center">
                          <p
                            className={`${
                              data?.status === 'pending'
                                ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                                : data?.status === 'sent'
                                ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                                : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                            } rounded-full px-0 font-medium text-16 text-center flex items-center justify-center`}
                          >
                            {data?.status === 'pending'
                              ? 'In Progress'
                              : data?.status === 'sent'
                              ? 'Completed'
                              : data?.status}
                          </p>
                        </TableCell>
                        <TableCell className="font-medium text-16">
                          {data?.totalCustomers}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <div>
          {selectedTab === 1 && (
            <div>
              <TableContainer component={Paper} className="rounded-md border-none">
                <Table>
                  <TableHead className="border-b-2 border-black">
                    <TableRow>
                      <TableCell className="font-semi-bold text-18">
                        {' '}
                        {t('quickCampaign.campaign.srNo')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.campaignName')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.date')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.templateName')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.channel')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.scheduleDate')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaignData &&
                      campaignData?.map((data, index) => (
                        <TableRow
                          key={index}
                          onClick={() => handleRowClick(data)}
                          className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 border-b-2"
                        >
                          <TableCell className="font-medium text-16">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {data?.campaignTitle}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {moment(Number(data?.cts)).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {data?.campaign_services_standard_messages?.title}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {data?.campaign_platform?.name}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {moment(Number(data?.cts)).format('MMM DD, YYYY')}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ConformationDialogBox
                open={isDelPopupOpen}
                onClose={handleDelClosePopUp}
                additionalInfo="Deleting this campaign will permanently remove it from your system. This action cannot be undone. Are you sure you want to proceed?"
                onDelete={() => deleteScheduledCampaign()}
                confirmationText="Are You Sure?"
                deleteImage="/assets/images/icon/campaignDeleteIcon.png"
                backgroundColor="quick-campaign"
                textColor="quick-campaign"
                buttonText={t('manageBusiness.button.delete')}
              />
            </div>
          )}
        </div>
        <div>
          {selectedTab === 2 && (
            <div>
              <TableContainer component={Paper} className="rounded-md border-none">
                <Table>
                  <TableHead className="border-b-2 border-black">
                    <TableRow>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.srNo')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.campaignName')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18">
                        {t('quickCampaign.campaign.date')}
                      </TableCell>
                      <TableCell className="font-semi-bold text-18"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaignData &&
                      campaignData?.map((data, index) => (
                        <TableRow
                          key={index}
                          className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 border-b-2"
                          onMouseEnter={() => setHoveredRow(index)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          <TableCell className="font-medium text-16">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {data?.campaignTitle}
                          </TableCell>
                          <TableCell className="font-medium text-16">
                            {moment(Number(data?.cts)).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell>
                            {hoveredRow === index ? (
                              <div className="flex gap-16 justify-end">
                                <button
                                  type="button"
                                  className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                                  onClick={() => handleEditDraft(data)}
                                  aria-label="Edit"
                                >
                                  <EditIcon className=" h-16 w-16 text-grey-600" />
                                </button>
                                <button
                                  type="button"
                                  className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                                  onClick={() => handleDeleteClick(data)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      handleDeleteClick();
                                    }
                                  }}
                                  aria-label="Delete"
                                >
                                  <DeleteIcon className="h-16 w-16 text-grey-600" />
                                </button>
                              </div>
                            ) : (
                              <div className="flex gap-16 ">
                                <div className="p-3">
                                  <div className="bg-transparent rounded-full h-16 w-16" />
                                </div>
                                <div className=" p-3">
                                  <div className="bg-transparent rounded-full h-16 w-16" />
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ConformationDialogBox
                open={isDelPopupOpen}
                onClose={handleDelClosePopUp}
                additionalInfo="Deleting this campaign will permanently remove it from your system. This action cannot be undone. Are you sure you want to proceed?"
                onDelete={() => deleteScheduledCampaign()}
                confirmationText="Are You Sure?"
                deleteImage="/assets/images/icon/campaignDeleteIcon.png"
                backgroundColor="quick-campaign"
                textColor="quick-campaign"
                buttonText={t('manageBusiness.button.delete')}
              />
            </div>
          )}
        </div>
        <Drawer anchor="right" open={isPopupOpen} onClose={handleClosePopup} className="w-1/3">
          <div className=" bg-gray-A500  max-w-[466px] h-full rounded-md">
            <div className="flex justify-between p-20 bg-white">
              <h2 className="text-20 font-bold mb-4 text-center justify-center items-center flex">
                {messageType === null
                  ? t('quickCampaign.campaign.campaignDetails')
                  : messageType === 'scheduled'
                  ? t('quickCampaign.campaign.scheduleCampaignDetails')
                  : ''}
              </h2>
              <CloseIcon onClick={handleClosePopup} className="cursor-pointer" />
            </div>

            {selectedData && (
              <div className="bg-gray-A500 p-16">
                <div className="bg-white p-16 mb-16">
                  <div className="flex justify-between mb-20 bg-white ">
                    <h2 className="text-20 font-medium">{selectedData?.campaignTitle}</h2>
                    <p
                      className={`${
                        selectedData?.status === 'pending'
                          ? 'bg-yellow-100 text-quick-chat border-1 border-quick-chat '
                          : selectedData?.status === 'sent'
                          ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                          : ''
                      } rounded-full px-10 py-5 text-16 text-center flex items-center justify-center`}
                    >
                      {selectedData?.status !== 'scheduled' ? (
                        selectedData?.status === 'pending' ? (
                          'In Progress'
                        ) : selectedData?.status === 'sent' ? (
                          'Completed'
                        ) : (
                          selectedData?.status
                        )
                      ) : messageType === 'scheduled' ? (
                        <div className="flex gap-16">
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                            aria-label="Edit"
                            onClick={() => handleEditDraft(selectedData)}
                          >
                            <EditIcon className=" h-16 w-16 text-grey-600" />
                          </button>
                          <button
                            type="button"
                            className="cursor-pointer bg-quick-campaign-100 rounded-full p-3 opacity-100 flex justify-center items-center"
                            onClick={() => handleDeleteClick(selectedData)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                handleDeleteClick();
                              }
                            }}
                            aria-label="Delete"
                          >
                            <DeleteIcon className="h-16 w-16 text-grey-600" />
                          </button>
                        </div>
                      ) : null}
                    </p>
                  </div>
                  <div className="bg-gray-A500 p-16 rounded-md mb-20 ">
                    <p className="text-16 font-500 mb-14">
                      {selectedData?.campaign_services_standard_messages?.title}
                    </p>
                    {selectedData?.campaign_platform?.id !== 2 ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedData?.campaign_services_standard_messages?.htmlTemplate,
                        }}
                      />
                    ) : (
                      <p className="bg-white rounded-md p-12">
                        {selectedData?.campaign_services_standard_messages?.messages}
                      </p>
                    )}
                    <p className="text-16 font-500 my-14">{t('quickCampaign.campaign.channel')}</p>
                    <p className="bg-white p-8 rounded-md">
                      {selectedData?.campaign_platform?.id === 1 ? (
                        <EmailIcon className="text-black text-20 mx-6" />
                      ) : selectedData?.campaign_platform?.id === 2 ? (
                        <MessageIcon className="text-black text-20 mx-6" />
                      ) : (
                        <WhatsAppIcon className="text-black text-20 mx-6" />
                      )}
                      {selectedData?.campaign_platform?.name}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="flex gap-11">
                      <ScheduleIcon className="text-quick-campaign" fontSize="medium" />
                      <p className="text-15 font-medium">
                        {moment(Number(selectedData?.cts)).format('MMMM DD, YYYY h:mmA')}
                      </p>
                    </p>
                    <p>
                      <PersonIcon className="text-quick-campaign" fontSize="medium" />
                      {selectedData?.totalCustomers}
                    </p>
                  </div>
                </div>
                <div className="bg-white rounded-md p-20 mb-20 ">
                  <h2 className="mb-10 font-medium text-18">
                    {t('quickCampaign.campaign.details')}
                  </h2>
                  <div className="sm:flex sm:gap-14 ">
                    <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                      <p className="font-bold text-16">{details?.statusCountData?.recipient}</p>
                      <p className="font-semibold">{t('quickCampaign.campaign.recipient')}</p>
                    </div>
                    <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium ">
                      <p className="font-bold text-16">{details?.statusCountData?.delivered}</p>
                      <p className="font-semibold">{t('quickCampaign.campaign.delivered')}</p>
                    </div>
                    <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                      <p className="font-bold text-16">{details?.statusCountData?.failed}</p>
                      <p className="font-semibold">{t('quickCampaign.campaign.failed')}</p>
                    </div>
                    <div className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium">
                      <p className="font-bold text-16">{details?.statusCountData?.pending}</p>
                      <p className="font-semibold">{t('quickCampaign.campaign.pending')}</p>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md p-20 mb-20 font-medium text-18">
                  <h2 className="mb-10">{t('quickCampaign.campaign.engagement')}</h2>
                  <div className="sm:flex gap-14">
                    {Array.isArray(engagementData) &&
                      engagementData?.map((item, index) => (
                        <div
                          key={index}
                          className="bg-gray-A500 rounded-md p-12 sm:mb-0 mb-10 text-14 font-medium"
                        >
                          <p className="font-bold text-16">{item?.value}</p>
                          <p className="font-semibold">{item?.key}</p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="bg-white rounded-md p-20">
                  <h2 className="mb-10 pb-12 border-b-3 border-dashed border-grey-300 font-medium text-18">
                    {t('quickCampaign.campaign.sendTo')}
                  </h2>
                  <h2 className="font-medium text-18">
                    {t('quickCampaign.campaign.contact')} ( {contactDataTotalCount} )
                  </h2>
                  <div>
                    {Array.isArray(contactData) &&
                      contactData.map((item, index) => (
                        <div key={index} className="rounded-md p-12 flex justify-between">
                          <div className="sm:flex sm:gap-14">
                            <div>
                              <UserProfile
                                name={item?.name}
                                fontSize="16px"
                                fontWeight="bold"
                                height="40px"
                                width="40px"
                              />
                            </div>
                            <div>
                              <p>
                                {item?.name
                                  ?.split(' ')
                                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                  ?.join(' ')}
                              </p>
                              <p>
                                {selectedData?.campaign_platform?.id === 1
                                  ? item?.email
                                  : `${item?.countryCode} ${item?.phoneNumber}`}
                              </p>
                            </div>
                          </div>
                          {item?.status !== 'scheduled' && (
                            <div>
                              <p
                                className={`${
                                  item?.status === 'failed'
                                    ? 'bg-red-100 text-red'
                                    : item?.status === 'delivered'
                                    ? 'bg-green-100 text-quick-review'
                                    : ''
                                } p-5 rounded-md text-14 font-semibold text-center flex items-center justify-center`}
                              >
                                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Drawer>
      </div>
      <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
        <Pagination
          count={Math.ceil(total / 10)}
          page={page}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      </div>
      <Dialog
        open={openCreateCampaignDialog}
        classes={{
          paper: `m-24 px-24 py-16 bg-gray-A500 border-t-8 border-solid border-t-quick-campaign border-quick-campaign rounded-md`,
        }}
        maxWidth="xl"
      >
        <div>
          <IconButton
            className="absolute right-0"
            onClick={() => setOpenCreateCampaignDialog(false)}
            color="inherit"
          >
            <CloseIcon className="text-24 text-grey-600" />
          </IconButton>
          <Template showInPopUp="true" />
        </div>
      </Dialog>
    </div>
  );
};

export default Campaign;
