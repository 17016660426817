import React, { useState } from 'react';
import { t } from 'i18next';
import { Button, Typography, Checkbox, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { storeCsvFileDataToServer } from 'src/app/main/common/common';
import businessData from 'src/app/main/query/business';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';

const CSVUpload = ({ fetchData, handleCloseUploadCSV, csvFileData, open, isPassFrom }) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      setFile(droppedFile);
    } else {
      console.log('Please drop a valid CSV file.');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileInputChange = async (e) => {
    const payload = {
      query: businessData.saveUserCSV,
      variables: {
        file,
        defaultSave: isChecked,
        variables: {
          callFrom: 'campaign',
        },
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const result = await storeCsvFileDataToServer(payload);
    if (result?.saveUserCSV?.status === 200) {
      enqueueSnackbar(result?.saveUserCSV?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      fetchData();
      handleCloseUploadCSV();
    } else {
      enqueueSnackbar(result?.saveUserCSV?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="p-5 w-full">
      {!isPassFrom && (
        <>
          {!open ? (
            <Typography variant="h6" className=" my-32">
              {t(`quickCampaignsContacts.tab2`)}
            </Typography>
          ) : (
            <Typography variant="h6" className=" my-20">
              Upload CSV FIle
            </Typography>
          )}
        </>
      )}

      <div
        className={`bg-quick-campaign-100 border-2 w-512 opacity-80 border-dashed border-quick-campaign rounded-md mb-5 py-40 ${
          dragging ? 'bg-quick-campaign-100' : ''
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {file ? (
          <div className="text-center w-full">
            <img src="/assets/images/icon/csv.png" alt="CSV" className="w-76 m-auto" />
            <Typography variant="subtitle1" className="font-bold text-20">
              {file?.name}
            </Typography>
            <Typography variant="body2" className="text-gray-700 text-16 font-semibold">
              {file?.size} {t(`quickCampaignsContacts.title`)}
            </Typography>

            <div className="flex justify-center my-20 gap-8 items-center w-full">
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                icon={<div className="h-16 w-16 border border-gray-800 rounded-sm" />}
                checkedIcon={
                  <div className="h-16 w-16 bg-gray-900 rounded-sm flex items-center justify-center">
                    <CheckIcon className="text-white text-xs w-20 h-20" />
                  </div>
                }
                className="p-0 mr-2"
              />
              <Typography variant="body2" className=" font-semibold text-gray-900 text-16">
                {t(`quickCampaignsContacts.dragAndDropContent`)}
              </Typography>
            </div>

            <div className="flex justify-center gap-16 mt-6">
              <Button
                variant="outlined"
                component="label"
                className="text-quick-campaign border-quick-campaign hover:bg-quick-campaign-100 bg-white px-32 rounded-md"
              >
                {t(`quickCampaignsContacts.change`)}
                <input type="file" accept=".csv" onChange={handleFileChange} hidden />
              </Button>
              <Button
                variant="contained"
                className="bg-quick-campaign text-white hover:bg-quick-campaign rounded-md px-20"
                onClick={handleFileInputChange}
              >
                {t(`quickCampaignsContacts.importFile`)}
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full text-center pb-16 w-640">
            <img
              src="/assets/images/icon/CampaignCSVUpload.png"
              alt="Upload"
              className="justify-center items-center m-auto"
            />
            <Typography
              variant="body1"
              className="text-gray-700 text-16 text-center font-medium mb-16"
            >
              {t(`quickCampaignsContacts.dragAndDrop`)}
            </Typography>
            <Button
              variant="contained"
              component="label"
              className="mt-4 text-white border-quick-campaign hover:bg-quick-campaign rounded-md text-16"
              color="quickCampaign"
            >
              {t(`quickCampaignsContacts.selectCSVFile`)}
              <input type="file" accept=".csv" onChange={handleFileChange} hidden />
            </Button>
          </div>
        )}
      </div>
      {!open && (
        <Button
          variant="outlined"
          component="a"
          href="/quick-campaign/contact/"
          className="text-quick-campaign border-quick-campaign hover:bg-quick-campaign-100 rounded-md mt-24 text-16"
        >
          {t('quickCampaignsContacts.downloadDemoCSV')}
        </Button>
      )}
    </div>
  );
};

export default CSVUpload;
