const getDashboardAdminSectionSalesPerson = `
   query{
    getDashboardAdminSectionSalesPerson{
      data{
        dashboardResponse
      }
   }
  }
`;

const getBusinessAndUserAddedLastMonth = `
  query{
    getBusinessAndUserAddedLastMonth{
      data{
        businessesData{
          count
          cts
        }
        businessesInMonth
        usersData{
          count
          cts
        }
        usersInMonth
      }
    }
  }
`;

const getDashboardAdmin = `
  query{
    getDashboardAdmin{
      status
      message
      data{
        dashboardResponse
      }
    }
  }
`;

const getPlansAndFeaturesData = `
query ($pageNumber: Int, $pageSize: Int){
  getPlansAndFeaturesData(pageNumber:$pageNumber, pageSize:$pageSize) {
    data {
      heading {
        index
        packageTypeId
        planName
      }
      featuresData {
        id
        index
        featureName
        description
        value {
          index
          packageTypeId
          value
        }
      }
    }
    status
    message
  }
}
`;

const addPlanAndFeatureByAdmin = `
mutation ($featureName: String!, $description: String!, $index: Int!, $input: [PlansDataInput!]!){
  addPlanAndFeatureByAdmin(
    featureName: $featureName
    description: $description
    index: $index
    input: $input
  ) {
    status
    message
  }
}
`;

const deletePlanAndFeatureByAdmin = `
query ($packagesFeatureId: Int!, $input: [DeletePlanAndFeatureDto!]!){
  deletePlanAndFeatureByAdmin(
    packagesFeatureId: $packagesFeatureId
    input: $input
  ) {
    message
    status
  }
}`;

const updatePlanAndFeatureByAdmin = `
mutation ($featureName: String, $index: Int, $description: String, $packagesFeatureId: Int!, $input: [UpdatePlanAndFeatureDto!]!){
  updatePlanAndFeatureByAdmin(
    featureName: $featureName
    index: $index
    description: $description
    packagesFeatureId: $packagesFeatureId
    input: $input
  ){
    status
    message
  }
}
`;

const updateAppMaintenanceStatus = `
mutation($isAppUnderMaintenance: Boolean!){
  updateAppMaintenanceStatus(isAppUnderMaintenance: $isAppUnderMaintenance){
    message
    status
  }
}
`;

const getAppMaintenanceStatus = `
query{
  getAppMaintenanceStatus{
    data{
      isAppUnderMaintenance
    }
    message
    status
  }
}
`;

const getPartnerRequest = `query($userStatusId: Int!, $searchText: String, $pageNumber: Int, $pageSize: Int, $userId: Int) {
  getPartnerRequest(userStatusId:$userStatusId , searchText:$searchText, pageNumber: $pageNumber, pageSize:$pageSize, userId: $userId){
    status
    message
    totalCount
    data {
      id
      fullName
      agencyName
      phoneNumber{
        countryCode
        phoneNumber
        isoCode
      }
      isPasswordSet
      email
      agencyUrl
      agencyAddress
      countries {
        name
      }
    }
  }
}
`;

const updateAgentByAdmin = `mutation($userId: Int!, $userStatusId: Int!) {
  updateAgentByAdmin(userId:$userId , userStatusId: $userStatusId){
    status
    message
  }
}
`;

const adminData = {
  getDashboardAdminSectionSalesPerson,
  getBusinessAndUserAddedLastMonth,
  getDashboardAdmin,
  getPlansAndFeaturesData,
  addPlanAndFeatureByAdmin,
  deletePlanAndFeatureByAdmin,
  updatePlanAndFeatureByAdmin,
  updateAppMaintenanceStatus,
  getAppMaintenanceStatus,
  getPartnerRequest,
  updateAgentByAdmin,
};

export default adminData;
