import { Icon, Toolbar, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import history from '@history';
import { handleApiRequest } from '../../common/common';
import salesData from '../../query/sales';
import Pagination from '../../../component/table/Pagination';

const { default: UpcomingFollowUp } = require('./followUp/UpcomingFollowUp');

const ViewAllUpComingFollowUp = () => {
  const [followUpData, setFollowUpData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchFollowUps = async () => {
      const payload = {
        query: salesData.getDemoBusinessUpcomingFollowUpDataBySalesPerson,
      };
      const result = await handleApiRequest(payload);
      if (result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.status === 200) {
        setFollowUpData(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.data);
        setTotalCount(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.totalCount);
      }
    };
    fetchFollowUps();
  }, []);

  const handleChangePage = async (event, value) => {
    await setPage(value + 1);
    const payload = {
      query: salesData.getDemoBusinessUpcomingFollowUpDataBySalesPerson,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.status === 200) {
      setFollowUpData(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.data);
      setTotalCount(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    await setRowsPerPage(event.target.value);
    await setPage(1);
    const payload = {
      query: salesData.getDemoBusinessUpcomingFollowUpDataBySalesPerson,
      variables: {
        pageSize: event.target.value,
        pageNumber: page,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.status === 200) {
      setFollowUpData(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.data);
      setTotalCount(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.totalCount);
    }
  };

  const handleRedirect = (id) => {
    history.push(`visited-business-details/${id}`);
  };

  return (
    <div className="p-20 lg:p-32">
      <div>
        <Toolbar className="px-0 font-semibold min-h-fit">
          <Icon
            className="text-20 cursor-pointer"
            onClick={() => {
              history.go(-1);
            }}
          >
            west
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 px-12 text-20 font-bold"
          >
            {t('dashboard.dashboardSales.dashboard')}
          </Typography>
        </Toolbar>
        <div className="flex gap-16 items-center">
          <div className="bg-white rounded p-6">
            <img className="w-[20px]" src="/assets/images/sales-icon/upcomingfollowup.png" alt="" />
          </div>
          <Typography className="font-bold md:text-24 text-20 py-16">
            {t('dashboard.dashboardSales.upcomingFollowUp')}
          </Typography>
        </div>
      </div>
      <UpcomingFollowUp
        followUpData={followUpData}
        handleRedirect={handleRedirect}
        totalCount={totalCount}
      />
      <Pagination
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ViewAllUpComingFollowUp;
