import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import DeleteIcon from '@mui/icons-material/Delete';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation } from 'react-router-dom';
import { selectDashboardData } from 'app/store/userSlice';
import businessData from '../../../query/business';
import { getParamsId, handleApiRequest, storeCsvFileDataToServer } from '../../../common/common';
import { storeCustomerList } from '../store/targetMessageSlice';
import reducer from '../store';

const SelectCustomerList = (props) => {
  const { remainingCount } = props;
  const [customerData, setCustomerData] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [csvFileData, setCsvFileData] = useState([]);
  const [selectCsvFile, setSelectCsvFile] = useState(0);
  const [totalSelectedCustomers, setTotalSelectedCustomers] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const fileRef = useRef();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const customerList = useSelector(
    ({ state }) => state.targetMessageSlice?.customerListData?.queue
  );
  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  useEffect(() => {
    dispatch(storeCustomerList({ queue: [] }));
  }, [dispatch]);

  useEffect(() => {
    const id = getParamsId();
    if (
      (location.pathname === '/select-invite-review-customer' ||
        location.pathname === `/select-invite-review-customer/${id}`) &&
      !sendSmsOrEmailDetailsData?.businessId
    ) {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    }
    if (
      (location.pathname === '/select-send-message-customer' ||
        location.pathname === `/select-send-message-customer/${id}`) &&
      !sendSmsOrEmailDetailsData?.businessId
    ) {
      if (id) {
        history.push(`send-message/${id}`);
      } else {
        history.push('send-message/');
      }
    }
  }, [location.pathname, sendSmsOrEmailDetailsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const payload = {
          query: businessData.getUserCSV,
          variables: {
            userBusinessId: userCurrentBusinessDetails?.id,
            callFrom: 'campaign',
            defaultSave: false,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserCSV?.status === 200) {
          const data = result.getUserCSV?.data?.userCSVData;
          const sortData = data?.sort((a, b) => a.id - b.id);
          setCsvFileData(sortData);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    setTotalSelectedCustomers(customerList?.length);
    if (customerData?.length === customerList?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [customerData, customerList]);

  useEffect(() => {
    if (remainingCount < totalSelectedCustomers) {
      enqueueSnackbar(
        `you have send only ${remainingCount} messages because your message send limit is over.`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    }
  }, [enqueueSnackbar, remainingCount, totalSelectedCustomers]);

  const handleFileInputChange = async (e) => {
    try {
      const selectedFile = e.target.files[0];
      if (selectedFile?.type === 'text/csv' || selectedFile?.name?.endsWith('.csv')) {
        const payload = {
          query: businessData.saveUserCSV,
          variables: {
            file: selectedFile,
            userBusinessId: userCurrentBusinessDetails?.id,
          },
        };
        const result = await storeCsvFileDataToServer(payload);
        if (result?.saveUserCSV?.status === 200) {
          const obj = {
            query: businessData.getUserCSV,
            variables: {
              userBusinessId: userCurrentBusinessDetails?.id,
              callFrom: 'campaign',
              defaultSave: false,
            },
          };
          const res = await handleApiRequest(obj);
          if (res?.getUserCSV?.status === 200) {
            const data = res.getUserCSV?.data?.userCSVData;
            const sortData = data?.sort((a, b) => a.id - b.id);
            setCsvFileData(sortData);
          }
        } else {
          enqueueSnackbar(result?.saveUserCSV?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred while processing the CSV file.', error);
    } finally {
      fileRef.current.value = '';
    }
  };

  const handleSelectCsvFile = async (data) => {
    try {
      setSelectCsvFile(data.id);
      const payload = {
        query: businessData.getUserCSVOnCsvId,
        variables: {
          csvId: data?.id,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCSVOnCsvId?.status === 200) {
        const csvWithUniqueId = result?.getUserCSVOnCsvId?.data?.map((item, index) => ({
          ...item,
          countryCode: item.countryCode,
          id: `${data.id}_${index + 1}`,
        }));
        setCustomerData(csvWithUniqueId);
      } else {
        setCustomerData([]);
      }
    } catch (error) {
      console.error('Error handling CSV file:', error);
      setCustomerData();
    }
  };

  const previousCsvFileDataRef = useRef();

  useEffect(() => {
    if (csvFileData?.length > 0) {
      if (csvFileData !== previousCsvFileDataRef.current) {
        const fetchCsvData = async () => {
          try {
            const firstObject = csvFileData[0];
            setSelectCsvFile(firstObject.id);
            setLoading(true);
            const payload = {
              query: businessData.getUserCSVOnCsvId,
              variables: {
                csvId: firstObject?.id,
              },
            };
            const result = await handleApiRequest(payload);
            setLoading(false);
            if (result?.getUserCSVOnCsvId?.status === 200) {
              const csvWithUniqueId = result?.getUserCSVOnCsvId?.data?.map((item, index) => ({
                ...item,
                countryCode: item.countryCode,
                id: `${firstObject.id}_${index + 1}`,
              }));
              setCustomerData(csvWithUniqueId);
              previousCsvFileDataRef.current = csvFileData;
            } else {
              setCustomerData([]);
            }
          } catch (error) {
            setLoading(false);
            console.error('Error fetching CSV data:', error);
          }
        };

        fetchCsvData();
      }
    }
  }, [csvFileData]);

  const handleDeleteCsvFile = async (id) => {
    const payload = {
      query: businessData.deleteUserCsv,
      variables: {
        csvId: parseInt(id, 10),
        businessId: userCurrentBusinessDetails?.id,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteUserCsv?.status === 200) {
      const obj = {
        query: businessData.getUserCSV,
        variables: {
          userBusinessId: userCurrentBusinessDetails?.id,
          callFrom: 'campaign',
          defaultSave: false,
        },
      };
      const res = await handleApiRequest(obj);
      if (res?.getUserCSV?.status === 200) {
        const data = res.getUserCSV?.data?.userCSVData;
        const sortData = data?.sort((a, b) => a.id - b.id);
        setCsvFileData(sortData);
      }
    }
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    }
  };

  const redirectNewSms = () => {
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`create-new-review/${id}`);
      } else {
        history.push('create-new-review');
      }
    } else if (id) {
      history.push(`create-new-message/${id}`);
    } else {
      history.push('create-new-message');
    }
  };
  const handleSelectCustomer = (value, i) => {
    const existingCustomer = customerList?.find((customer) => customer.id === value.id);

    if (existingCustomer) {
      const updatedData = customerList?.filter((customer) => customer.id !== value.id);
      dispatch(storeCustomerList({ queue: [...updatedData] }));
    } else {
      const updatedData = [...(customerList || ''), value];
      dispatch(storeCustomerList({ queue: updatedData }));
    }
  };
  const handleSelectAllCustomer = () => {
    const allCustomersSelected = customerData?.every((customer) =>
      customerList?.some((selectedCustomer) => selectedCustomer.id === customer.id)
    );
    if (allCustomersSelected) {
      dispatch(storeCustomerList({ queue: [] }));
      setSelectAll(false);
    } else {
      dispatch(storeCustomerList({ queue: customerData }));
      setSelectAll(true);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <Typography className="text-28 text-black font-bold">
        {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
          ? t('inviteReviews.title')
          : t('sendMessage.title')}
      </Typography>
      <div className="bg-white rounded-md relative shadow p-20 mt-20">
        <div className="flex items-center justify-between pb-16">
          <Typography className="text-20 text-black font-[600]">
            {t('sendMessage.selectFile')}
          </Typography>
          <Button
            className="border border-[#0BA861] border-solid text-darkgreen border-solid rounded px-16 hover:bg-white"
            onClick={() => handleDownloadCsvFile()}
          >
            {t('sendMessage.downloadCsvFile')}
          </Button>
        </div>
        <hr className="border-dashed !border-[#707070]" />
        <Typography className="pt-16 text-black text-14 font-[600]">
          {t('sendMessage.selectFileMessage')}
        </Typography>
        <div className="grid grid-cols-12 gap-16 pt-16">
          {csvFileData?.length > 0 &&
            csvFileData?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="md:col-span-2 width_set text-center sm:col-span-4 col-span-6"
                >
                  <div
                    className={`border relative border-solid ${
                      selectCsvFile === item.id ? 'border-darkgreen' : 'border-solid'
                    } rounded-[4px]`}
                  >
                    <div className="absolute top-[5px] right-[15px]">
                      {selectCsvFile === item.id && (
                        <CheckOutlinedIcon className="w-[18px] h-[18px] text-[10px] text-white rounded-3xl bg-darkgreen" />
                      )}
                    </div>
                    <Button
                      className="hover:bg-white py-44"
                      onClick={() => handleSelectCsvFile(item)}
                    >
                      <img
                        className="w-[60px]"
                        src="/assets/images/admin-icon/csv_file.png"
                        alt=""
                      />
                    </Button>
                    <div className="absolute bottom-[5px] p-0 right-[8px]">
                      <Button
                        className="bg-[#006B5A33] opacity-50 min-w-[0px] h-[28px] min-h-[0px]"
                        onClick={() => handleDeleteCsvFile(item.id)}
                      >
                        <DeleteIcon className="text-darkgreen w-[15px]" />
                      </Button>
                    </div>
                  </div>
                  <Typography className="text-14 text-black font-bold text-center pt-4">
                    {item?.fileName}
                  </Typography>
                </div>
              );
            })}
          <div className="md:col-span-2 width_set sm:col-span-4 text-center  col-span-6">
            <div className="border relative border-solid bg-[#B4D4CF] border-solid rounded-[4px]">
              <label htmlFor="button-file">
                <input
                  className="hidden w-full"
                  id="button-file"
                  type="file"
                  ref={fileRef}
                  onChange={handleFileInputChange}
                  aria-labelledby="upload-label"
                />
                <Button
                  className="border hover:bg-[#B4D4CF] rounded-[4px] bg-[#B4D4CF] px-56 py-44"
                  component="span"
                  id="upload-label"
                >
                  <img
                    className="w-[60px]"
                    src="/assets/images/admin-icon/upload_image.png"
                    alt=""
                  />
                </Button>
              </label>
            </div>
            <Typography className="text-12 text-black font-bold pt-4">
              {t('sendMessage.uploadCsvFile')}
            </Typography>
          </div>
        </div>
      </div>
      {customerData?.length > 0 && (
        <div className="mt-20">
          <div className="flex justify-between items-center">
            <Typography className="text-16 text-black font-[600]">
              {t('sendMessage.selectCustomers')}
            </Typography>
            <div className="flex justify-between items-center gap-20">
              {totalSelectedCustomers > 0 && (
                <Typography className="text-16 text-black font-medium">
                  {totalSelectedCustomers} {t('sendMessage.selected')}
                </Typography>
              )}
              <Button
                className="bg-darkgreen rounded-[4px] hover:bg-darkgreen disabled:text-black disabled:bg-gray text-16 px-48 text-white"
                onClick={() => redirectNewSms()}
                disabled={customerList?.length === 0 || remainingCount < totalSelectedCustomers}
              >
                {t('sendMessage.next')}
              </Button>
            </div>
          </div>
          <div className="bg-white rounded-md relative shadow px-4 mt-20">
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHead>
                  <TableCell
                    className="text-16 color-black pl-0 font-semibold w-[4%]"
                    align="center"
                  >
                    <Checkbox
                      className="p-0 hover:bg-white"
                      checked={selectAll}
                      onChange={() => handleSelectAllCustomer()}
                    />
                  </TableCell>
                  <TableCell
                    className="text-16 color-black pl-0 font-semibold w-[23%]"
                    align="left"
                  >
                    {t('sendMessage.customerData.column.firstName')}
                  </TableCell>
                  <TableCell
                    className="text-16 color-black pl-0 font-semibold w-[23%]"
                    align="left"
                  >
                    {t('sendMessage.customerData.column.lastName')}
                  </TableCell>
                  <TableCell
                    className="text-16 color-black pl-0 font-semibold w-[25%]"
                    align="left"
                  >
                    {t('sendMessage.customerData.column.email')}
                  </TableCell>
                  <TableCell
                    className="text-16 color-black pl-0 font-semibold w-[24%]"
                    align="left"
                  >
                    {t('sendMessage.customerData.column.phoneNo')}
                  </TableCell>
                </TableHead>
                <TableBody>
                  {customerData?.length > 0 &&
                    customerData?.map((customer, i) => {
                      const checkCustomer = customerList?.some((item) => item.id === customer.id);
                      return (
                        <TableRow
                          hover
                          key={i}
                          className="border-t-2 border-t-[#eeeeee]"
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell
                            className="text-16 color-black pl-0 font-semibold w-[4%]"
                            align="center"
                          >
                            <Checkbox
                              className="p-0 hover:bg-white"
                              checked={checkCustomer}
                              onChange={() => handleSelectCustomer(customer, i)}
                            />
                          </TableCell>
                          <TableCell
                            className="text-16 color-black py-4 pl-0 font-semibold w-[23%]"
                            align="left"
                          >
                            {customer.name}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black py-4 pl-0 font-semibold w-[23%]"
                            align="left"
                          >
                            {customer.name}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black py-4 pl-0 font-semibold w-[25%]"
                            align="left"
                          >
                            {customer.email}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black py-4 pl-0 font-semibold w-[24%]"
                            align="left"
                          >
                            {customer.countryCode} {customer.phoneNumber}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default withReducer('state', reducer)(SelectCustomerList);
