import EditBusinessDetails from './EditBusinessDetails';

const EditBusinessDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/editBusinessDetails/:id?',
      element: <EditBusinessDetails />,
    },
  ],
};

export default EditBusinessDetailsConfig;
