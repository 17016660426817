import SelectBusiness from './SelectBusiness';
import ManageBusiness from './ManageBusiness';
import themesConfig from '../../../configs/themesConfig';

const mangeBusinessConfig = {
  settings: {
    theme: {
      main: themesConfig.legacy,
    },
    layout: {
      config: {
        containerWidth: 1100,
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'select-business/:id?',
      element: <SelectBusiness />,
    },
    {
      path: 'manage-business/:id?',
      element: <ManageBusiness />,
    },
  ],
};

export default mangeBusinessConfig;
