import { useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import WabaContact from '../../waba/WabaContact';

const GetAllContacts = () => {
  const [loading, setLoading] = useState(false);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <WabaContact
        setLoading={setLoading}
        openCreateDialogue={openCreateDialogue}
        setOpenCreateDialogue={setOpenCreateDialogue}
      />
    </div>
  );
};

export default GetAllContacts;
