import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

export const createSubscriptionV1 = createAsyncThunk(
  'payment/createSubscriptionV1',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const newObj = { ...data };
      delete newObj.url;
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getPackageDataOnPackageIdAndCountryId = createAsyncThunk(
  'payment/getPackageDataOnPackageIdAndCountryId',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getPackageAddOn = createAsyncThunk('payment/getPackageAddOn', async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const checkPromoCode = createAsyncThunk('payment/checkPromoCode', async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
  try {
    const newObj = { ...data };
    delete newObj.url;
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const getSubcriptionAndAddOnUsingUserId = createAsyncThunk(
  'payment/getSubcriptionAndAddOnUsingUserId',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const newObj = { ...data };
      delete newObj.url;
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const upGradeAndDowngradeSubscriptionAddOn = createAsyncThunk(
  'payment/upGradeAndDowngradeSubscriptionAddOn',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const newObj = { ...data };
      delete newObj.url;
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const addSubscriptionAddOn = createAsyncThunk(
  'payment/addSubscriptionAddOn',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const newObj = { ...data };
      delete newObj.url;
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const upgradeAndDowngradeSubscription = createAsyncThunk(
  'payment/upgradeAndDowngradeSubscription',
  async (data) => {
    instance.defaults.headers.common.Authorization = `Bearer ${data.url}`;
    try {
      const newObj = { ...data };
      delete newObj.url;
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, newObj);
      return await response.data;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: null,
  reducers: {
    resetPayment: (state, action) => {
      if (state) {
        state.createSubscriptionData = {};
        state.getPackageDataOnPackageIdAndCountryIdData = {};
        state.getPackageAddOnData = {};
        state.checkPromoCodeData = {};
        state.upGradeAndDowngradeSubscriptionAddOnData = {};
        state.addSubscriptionAddOnData = {};
      }
    },
  },
  extraReducers: {
    [createSubscriptionV1.fulfilled]: (state, action) => ({
      ...state,
      createSubscriptionData: action.payload,
    }),
    [getPackageDataOnPackageIdAndCountryId.fulfilled]: (state, action) => ({
      ...state,
      getPackageDataOnPackageIdAndCountryIdData: action.payload,
    }),
    [getPackageAddOn.fulfilled]: (state, action) => ({
      ...state,
      getPackageAddOnData: action.payload,
    }),
    [checkPromoCode.fulfilled]: (state, action) => ({
      ...state,
      checkPromoCodeData: action.payload,
    }),
    [getSubcriptionAndAddOnUsingUserId.fulfilled]: (state, action) => ({
      ...state,
      getSubcriptionAndAddOnUsingUserIdData: action.payload,
    }),
    [upGradeAndDowngradeSubscriptionAddOn.fulfilled]: (state, action) => ({
      ...state,
      upGradeAndDowngradeSubscriptionAddOnData: action.payload,
    }),
    [addSubscriptionAddOn.fulfilled]: (state, action) => ({
      ...state,
      addSubscriptionAddOnData: action.payload,
    }),
  },
});

export const { resetPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
