import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const getCountries = createAsyncThunk('sign-up/getCountries', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    const result = await response.data;
    return result;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const getPackages = createAsyncThunk('sign-up/getPackages', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    const result = await response.data;
    return result;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const signUpPerson = createAsyncThunk('sign-up/signUpPerson', async (data) => {
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  instance.defaults.headers.common.isrequestfrom = true;
  instance.defaults.headers.common.timeZone = getTimeZone;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data?.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const getLocation = createAsyncThunk('payment/getLocation', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

const signUpSlice = createSlice({
  name: 'sign-up',
  initialState: null,
  reducers: {
    resetsignUp: (state, action) => {
      if (state) {
        state.getCountriesData = {};
        state.signUpPersonData = {};
        state.getLocationData = {};
        state.getPackagesData = {};
      }
    },
  },
  extraReducers: {
    [getCountries.fulfilled]: (state, action) => ({
      ...state,
      getCountriesData: action.payload,
    }),
    [signUpPerson.fulfilled]: (state, action) => ({
      ...state,
      signUpPersonData: action.payload,
    }),
    [getLocation.fulfilled]: (state, action) => ({
      ...state,
      getLocationData: action.payload,
    }),
    [getPackages.fulfilled]: (state, action) => ({
      ...state,
      getPackagesData: action.payload,
    }),
  },
});

export const { resetsignUp } = signUpSlice.actions;

export default signUpSlice.reducer;
