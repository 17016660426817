import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import history from '@history';
import { getParamsId } from 'src/app/services/userService';
import { capitalizeFirstLetter, handleApiRequest } from '../../../common/common';
import UserProfile from '../../../common/userProfile';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import SupportForPhysicalOrOnline from '../supportForPhysicalOrOnline/SupportForPhysicalOrOnline';

const Staff = () => {
  const [staffData, setStaffData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [agentSupportStatus, setAgentSupportStatus] = useState();
  const [staffDetails, setStaffDetails] = useState({
    name: '',
    role: '',
  });
  const [isEmpty, setIsEmpty] = useState({ name: false, role: false });
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const fetchAgentData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      const payload = {
        query: manageBusinessData.getBusinessAgentList,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getBusinessAgentList?.status === 200) {
        setStaffData(result?.getBusinessAgentList?.data);
      } else {
        enqueueSnackbar(result?.getBusinessAgentList?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      const payloadData = {
        query: manageBusinessData.getAgentSupportStatus,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (resultData?.getAgentSupportStatus?.status === 200) {
        setAgentSupportStatus(resultData?.getAgentSupportStatus?.data?.agentSupport);
      } else {
        enqueueSnackbar(resultData?.getAgentSupportStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchAgentSupportStatus();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const handleRemove = () => {
    setOpenDialogue(false);
    setStaffDetails({
      name: '',
      role: '',
    });
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;
    setStaffDetails({ ...staffDetails, [field]: value });
    setIsEmpty({ ...isEmpty, [field]: value.trim() === '' });
  };

  const addEditStaffMember = async () => {
    const isNumeric = (str) => /^\d+$/.test(str);
    if (isNumeric(staffDetails?.name) || isNumeric(staffDetails?.role)) {
      enqueueSnackbar(t('manageBusiness.addBusinessSteps.staffMember.validationMessage'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.addBusinessAgent,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        name: staffDetails?.name,
        role: staffDetails?.role,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const res = result?.addBusinessAgent;
    if (res?.status === 200) {
      fetchAgentData();
      handleRemove();
      setStaffDetails({
        name: '',
        role: '',
      });
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const staffMemberSupport = async () => {
    if (userCurrentBusinessDetails?.id) {
      setAgentSupportStatus(!agentSupportStatus);
      const payload = {
        query: manageBusinessData.setAgentSupportStatus,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          isActive: !agentSupportStatus,
        },
      };
      const result = await handleApiRequest(payload);
    }
  };
  const redirectUrl = (id) => {
    const paramsId = getParamsId();
    if (paramsId) {
      history.push(`quick-reviews/staffProfile/${id}/${paramsId}`);
    } else {
      history.push(`quick-reviews/staffProfile/${id}`);
    }
  };

  return (
    <div className="p-20 lg:p-32">
      {!userCurrentBusinessDetails?.isOnlineBusiness ? (
        <>
          <div className="flex items-center justify-between">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('staff.title')}
            </Typography>
            <div className="md:flex gap-24 text-center">
              <Button
                className="bg-quick-review rounded md:px-36 text-white disabled:bg-gray disabled:border-gray border border-solid border-quick-review hover:bg-quick-review mx-10 md:mx-0"
                type="button"
                disabled={!userCurrentBusinessDetails?.id}
                onClick={() => setOpenDialogue(true)}
              >
                {t('staff.button.add')}
              </Button>
            </div>
          </div>
          <div className="bg-white p-28 rounded-md mt-20">
            <div className="flex items-center justify-between">
              <div className={`${staffData?.length > 0 ? 'w-5/6' : 'w-full '}`}>
                <Typography className="font-bold text-20">{t('staff.staffSupport')}</Typography>
                <Typography
                  className={`font-semibold text-14 leading-8 mt-16 ${
                    staffData?.length > 0 ? 'w-5/6' : 'w-full'
                  }`}
                >
                  {t('staff.staffSupportMessage')}
                </Typography>
              </div>
              {staffData?.length > 0 && (
                <Switch
                  checked={agentSupportStatus}
                  disabled={!userCurrentBusinessDetails?.id}
                  onChange={() => staffMemberSupport()}
                />
              )}
            </div>
          </div>

          <div className="bg-white p-28 rounded-md mt-20">
            {staffData?.length > 0 ? (
              <>
                <Typography className="font-bold text-20">{t('staff.staffList')}</Typography>
                <hr className="border border-dashed my-16 border-solid !border-gray" />
                {staffData?.map((item, i) => {
                  return (
                    <div className="sm:flex justify-between bg-gray-100 p-16 mb-16" key={i}>
                      <div className="flex items-center gap-16">
                        <div>
                          <UserProfile name={item?.name} />
                        </div>
                        <div>
                          <Typography className="font-semibold text-16">
                            {capitalizeFirstLetter(item.name)}
                          </Typography>
                          <Typography>{capitalizeFirstLetter(item.role)}</Typography>
                        </div>
                      </div>
                      <div className="mt-12 flex items-center gap-20 sm:mt-0">
                        {item.businessQRId && (
                          <img className="w-28" src="/assets/images/icon/qr_code.svg" alt="" />
                        )}
                        <Button
                          className="bg-quick-review-100 rounded-3xl px-36 text-quick-review"
                          onClick={() => redirectUrl(item.id)}
                        >
                          {t('staff.button.viewDetails')}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex items-center h-full justify-center">
                <div className="my-96 w-full sm:w-4/5 md:w-3/5 text-center">
                  <img
                    className="m-auto w-144"
                    src="/assets/images/business/group-staff.png"
                    alt=""
                  />
                  <Typography className="font-bold text-20 mt-40">
                    {t('staff.addStaffMember')}
                  </Typography>
                  <Typography className="font-semibold text-14 leading-6 mt-16">
                    {t('staff.addStaffMemberDescription')}
                  </Typography>
                  <Button
                    className="bg-quick-review mt-28 rounded md:px-36 text-white disabled:bg-gray disabled:border-gray border border-solid border-quick-review hover:bg-quick-review mx-10 md:mx-0"
                    type="button"
                    disabled={!userCurrentBusinessDetails?.id}
                    onClick={() => setOpenDialogue(true)}
                  >
                    {t('staff.button.addMember')}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <Dialog
            classes={{
              paper:
                'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
            }}
            open={openDialogue}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle className="p-0 mb-28">
              <Icon
                onClick={handleRemove}
                className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              >
                cancel
              </Icon>
              <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
                {t('staff.addStaff')}
              </Typography>
            </DialogTitle>

            <form noValidate className="flex flex-col md:overflow-hidden">
              <DialogContent className="p-0 mb-24">
                <div className="block mb-24">
                  <InputLabel
                    htmlFor="bAttribute"
                    className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                  >
                    {t('staff.field.staff.name')}
                  </InputLabel>
                  <TextField
                    placeholder={t('staff.field.staff.placeholder')}
                    onChange={handleInputChange('name')}
                    value={staffDetails.name}
                    size="small"
                    id="bAttribute"
                    variant="outlined"
                    required
                    fullWidth
                  />
                  {isEmpty.name && (
                    <Typography className="text-red pt-8 text-14">
                      {t('staff.field.staff.placeholder')}
                    </Typography>
                  )}
                </div>
                <div className="block">
                  <InputLabel
                    htmlFor="bAttribute"
                    className="text-16 font-medium mb-12 text-grey-900"
                  >
                    {t('staff.field.role.name')}
                  </InputLabel>
                  <TextField
                    placeholder={t('staff.field.role.placeholder')}
                    onChange={handleInputChange('role')}
                    value={staffDetails.role}
                    size="small"
                    id="bAttribute"
                    variant="outlined"
                    required
                    fullWidth
                  />
                  {isEmpty.role && (
                    <Typography className="text-red pt-8 text-14">
                      {t('staff.field.role.placeholder')}
                    </Typography>
                  )}
                </div>
              </DialogContent>
              <DialogActions className="p-0 mt-80 justify-center">
                <Button
                  className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md min-w-256"
                  variant="contained"
                  color="secondary"
                  type="button"
                  size="small"
                  disabled={!staffDetails.role || !staffDetails.name || refreshIcon}
                  onClick={() => addEditStaffMember()}
                >
                  {t('staff.button.add')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      ) : (
        <SupportForPhysicalOrOnline />
      )}
    </div>
  );
};

export default Staff;
