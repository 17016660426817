import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Icon, Toolbar, Typography } from '@mui/material';
import history from '@history';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import adminData from '../../query/admin';
import {
  capitalizeFirstLetter,
  getDataToServerForAuth,
  getEncryptedData,
  handleApiRequest,
} from '../../common/common';
import Query from '../../query/auth';

const PartnerDetails = () => {
  const paramsId = useParams();
  const [agentData, setAgentData] = useState();
  const [statusId, setStatusId] = useState();
  const [loading, setLoading] = useState(true);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [sendEmailLoader, setSendEmailLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userStatusId = parseInt(params.get('status'), 10);
    setStatusId(userStatusId);
  }, []);

  useEffect(() => {
    const fetchPartners = async () => {
      const userId = parseInt(paramsId?.id, 10);
      const payload = {
        query: adminData.getPartnerRequest,
        variables: {
          userId,
          userStatusId: statusId,
        },
      };

      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getPartnerRequest?.status === 200) {
        setAgentData(result?.getPartnerRequest?.data[0]);
      } else {
        enqueueSnackbar(result?.getPartnerRequest?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (paramsId?.id && statusId) {
      fetchPartners();
    }
  }, [paramsId, statusId, enqueueSnackbar]);

  const acceptRequest = async () => {
    setAcceptLoader(true);
    const payload = {
      query: adminData.updateAgentByAdmin,
      variables: {
        userId: parseInt(paramsId?.id, 10),
        userStatusId: 1,
      },
    };
    const result = await handleApiRequest(payload);
    setAcceptLoader(false);
    if (result?.updateAgentByAdmin?.status === 200) {
      history.push(`/partners/${statusId}`);
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const rejectRequest = async () => {
    setRejectLoader(true);
    const payload = {
      query: adminData.updateAgentByAdmin,
      variables: {
        userId: parseInt(paramsId?.id, 10),
        userStatusId: 5,
      },
    };
    const result = await handleApiRequest(payload);
    setRejectLoader(false);
    if (result?.updateAgentByAdmin?.status === 200) {
      history.push(`/partners/${statusId}`);
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const deleteAgent = async () => {
    setRejectLoader(true);
    const payload = {
      query: adminData.updateAgentByAdmin,
      variables: {
        userId: parseInt(paramsId?.id, 10),
        userStatusId: 4,
      },
    };
    const result = await handleApiRequest(payload);
    setRejectLoader(false);
    if (result?.updateAgentByAdmin?.status === 200) {
      history.push(`/partners/${statusId}`);
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.updateAgentByAdmin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const onSubmitForgotPassword = async () => {
    setSendEmailLoader(true);

    try {
      const payload = {
        query: Query.sendForgotPasswordLink,
        variables: {
          data: getEncryptedData(`{"e": "${agentData?.email}"}`),
        },
      };

      const result = await getDataToServerForAuth(payload);
      setSendEmailLoader(false);

      if (result?.sendForgotPasswordLink?.status === 200) {
        enqueueSnackbar(result?.sendForgotPasswordLink?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setConfirmationEmail(agentData?.email);
      } else {
        enqueueSnackbar(result?.sendForgotPasswordLink?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error', error);
      setSendEmailLoader(false);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <Toolbar className="px-0 font-semibold min-h-fit mb-20">
        <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
          west
        </Icon>
        <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-bold text-18">
          {statusId === 2 && t('partners.tabs.newRequest')}
          {statusId === 1 && t('partners.tabs.partners')}
          {statusId === 5 && t('partners.tabs.rejected')}
          {statusId === 4 && t('partners.tabs.deleted')}
        </Typography>
      </Toolbar>
      <Typography className="font-bold text-24">
        {agentData?.agencyName && capitalizeFirstLetter(agentData.agencyName)}
      </Typography>
      <div className="bg-white p-28 rounded-md mt-28 md:w-1/2">
        <Typography className="font-semibold text-20">
          {' '}
          {agentData?.agencyName && capitalizeFirstLetter(agentData.agencyName)}{' '}
          {t('partners.overView')}
        </Typography>
        <hr className="border-dashed border-gray my-12" />
        <div className="grid grid-cols-2 col-span-1">
          <div>
            <Typography className="font-semibold">{t('partners.details.name')}</Typography>
            <Typography>
              {agentData?.fullName ? capitalizeFirstLetter(agentData.fullName) : '-'}
            </Typography>
          </div>
          <div>
            <Typography className="font-semibold">{t('partners.details.address')}</Typography>
            <Typography>{agentData?.agencyAddress || '-'}</Typography>
          </div>
        </div>
        <Typography className="font-semibold mt-16">{t('partners.details.agencyName')}</Typography>
        <Typography className="">
          {agentData?.agencyName ? capitalizeFirstLetter(agentData.agencyName) : '-'}
        </Typography>
        <div className="grid grid-cols-2 col-span-1 mt-16">
          <div>
            <Typography className="font-semibold">{t('partners.details.phoneNumber')}</Typography>
            <Typography>
              {agentData?.phoneNumber
                ? `${agentData?.phoneNumber?.countryCode} ${agentData?.phoneNumber?.phoneNumber}`
                : '-'}
            </Typography>
          </div>
          <div>
            <Typography className="font-semibold">{t('partners.details.country')}</Typography>
            <Typography>{agentData?.countries?.name || '-'}</Typography>
          </div>
        </div>
        <Typography className="font-semibold mt-16">{t('partners.details.emailId')}</Typography>
        <Typography className="">{agentData?.email || '-'}</Typography>
        <Typography className="font-semibold mt-16">{t('partners.details.agencyUrl')}</Typography>
        <Typography className="break-all">{agentData?.agencyUrl || '-'}</Typography>
        {!agentData?.isPasswordSet && agentData?.email && statusId !== 4 && statusId !== 2 && (
          <div className="w-full border border-dashed rounded-8 mt-28 border-blue-600 p-20 bg-blue-600 bg-opacity-10">
            <Typography className="font-normal text-14">
              {t('partners.details.emailDescription')}
            </Typography>
            <Button
              className="bg-blue-600 hover:bg-blue-700 disabled:bg-gray disabled:text-black rounded-full px-48 py-8 mt-16 text-white font-semibold text-16"
              disabled={sendEmailLoader}
              onClick={() => onSubmitForgotPassword()}
            >
              {t('partners.resendEmail')}
              {sendEmailLoader && (
                <CircularProgress size={24} className="text-white absolute mx-auto" />
              )}
            </Button>
          </div>
        )}
        <div className="flex gap-16 my-24">
          {(statusId === 2 || statusId === 5) && (
            <Button
              className="rounded-full px-48 py-8 hover:bg-darkgreen bg-darkgreen disabled:bg-gray disabled:text-black text-white font-semibold text-16"
              disabled={acceptLoader}
              onClick={() => acceptRequest()}
            >
              {t('partners.accept')}
              {acceptLoader && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          )}
          {statusId === 2 && (
            <Button
              className="bg-red-A500 hover:bg-red-A500 text-red disabled:bg-gray disabled:text-black font-semibold text-16 px-48"
              disabled={rejectLoader}
              onClick={() => rejectRequest()}
            >
              {t('partners.reject')}
              {rejectLoader && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          )}
          {statusId !== 4 && statusId !== 2 && (
            <Button
              className="bg-red-A500 hover:bg-red-A500 text-red font-semibold disabled:bg-gray disabled:text-black text-16 px-48"
              disabled={rejectLoader}
              onClick={() => deleteAgent()}
            >
              {t('partners.delete')}
              {rejectLoader && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerDetails;
