import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { t } from 'i18next';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import ForumIcon from '@mui/icons-material/Forum';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Controller, useForm } from 'react-hook-form';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { useSelector } from 'react-redux';
import UserProfile from 'src/app/main/common/userProfile';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectDashboardData } from '../../../../store/userSlice';
import { handleApiRequest } from '../../../common/common';
import ConnectYourBusiness from '../businessCompetitors/ConnectYourBusiness';

const Faq = () => {
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isBusinessConnect, setIsBusinessConnect] = useState(false);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [faqData, setFaqData] = useState([]);
  const [openAnsDialog, setOpenAnsDialog] = useState(false);
  const [ans, setAns] = useState();
  const [loading, setLoading] = useState(true);
  const [locationId, setLocationId] = useState();
  const [selectQuestionAndAnswer, setSelectQuestionAndAnswer] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const schema = yup.object().shape({
    question: yup.string().required(t('businessCompetitor.form.question.required')),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  const handleClose = () => {
    setDialogOpen(false);
  };

  const fetchBusinessDetails = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessesGoogleDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessesGoogleDetails?.status === 200) {
          const data = result.getUserBusinessesGoogleDetails?.data;
          const parsedData = JSON.parse(data);
          setGoogleBusinessData(parsedData);
          setLocationId(parsedData?.name);
        } else {
          enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching business details:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  const fetchFaqData = useCallback(async () => {
    if (locationId) {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getFAQQuestions,
          variables: {
            locationId: locationId?.split('/')[1],
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getFAQQuestions?.status === 200) {
          setFaqData(result?.getFAQQuestions?.data);
        } else {
          enqueueSnackbar(result?.getFAQQuestions?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching FAQ data:', error);
      }
    }
  }, [locationId, enqueueSnackbar]);

  useEffect(() => {
    fetchFaqData();
  }, [fetchFaqData]);

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.createFAQQuestions,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          questionAnsData: {
            question: data?.question,
            answer: data?.answer,
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.createFAQQuestions?.status === 200) {
        fetchFaqData();
        setDialogOpen(false);
      } else {
        enqueueSnackbar(result?.createFAQQuestions?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error submitting FAQ data:', error);
    }
  };

  const selectQuestionForEditAnswer = (data) => {
    setSelectQuestionAndAnswer(data);
    const findMerchantAns = data?.google_faq_answer?.find((item) => item.authorName === 'MERCHANT');
    if (findMerchantAns?.answerText) {
      setAns(findMerchantAns?.answerText);
    }
    setOpenAnsDialog(true);
  };

  const editAns = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.ansFAQQuestions,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          answer: ans,
          questionId: parseInt(selectQuestionAndAnswer?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.ansFAQQuestions?.status === 200) {
        setAns('');
        setOpenAnsDialog(false);
        setSelectQuestionAndAnswer();
        fetchFaqData();
      } else {
        enqueueSnackbar(result?.ansFAQQuestions?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error editing the answer:', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="h-full overflow-scroll">
      <div>
        {isBusinessConnect ? (
          <div className="p-20 lg:p-32">
            {faqData?.length > 0 ? (
              <div className="p-24 h-640">
                <div className="flex justify-between items-center mb-40">
                  <Typography className="font-bold text-28">
                    {t('businessCompetitor.faq')}
                  </Typography>
                  <Button
                    className="bg-quick-review text-white font-semibold rounded px-40 hover:bg-quick-review"
                    onClick={() => setDialogOpen(true)}
                  >
                    {t('businessCompetitor.addNewFaq')}
                  </Button>
                </div>
                {faqData?.map((item, index) => {
                  return (
                    <div
                      className="bg-white p-24 px-40 border border-solid border-gray rounded-md mb-12"
                      key={index}
                    >
                      <div className="relative ">
                        {item?.authorName === 'MERCHANT' ? (
                          <>
                            <div className="bg-gray-300 absolute rounded-full p-8 -left-20">
                              <AccountCircleOutlinedIcon className="text-28 text-gray" />
                            </div>
                            <img
                              className="w-28 h-28 absolute top-24"
                              src="/assets/images/business/googleLogo.png"
                              alt=""
                            />
                          </>
                        ) : (
                          <div className="absolute rounded-full -left-20">
                            {item?.profilePhotoUrl ? (
                              <img className="-40 h-40" src={item?.profilePhotoUrl} alt="" />
                            ) : (
                              <UserProfile name={item?.authorName} />
                            )}
                          </div>
                        )}
                        <div
                          className={`${
                            item?.google_faq_answer.length > 0 &&
                            'border-l-4 border-solid border-gray'
                          } pl-40`}
                        >
                          <Typography className="text-16 font-bold text-20">
                            {googleBusinessData?.title}{' '}
                            <span className="text-gray block sm:contents text-14 font-bold pr-16">
                              {moment(item.cts).format('DD MMM YYYY')}
                            </span>
                            <span
                              className={`rounded-full text-12 px-8 sm:ml-8 py-4 ${
                                item?.authorName === 'MERCHANT'
                                  ? 'bg-quick-review text-white'
                                  : 'bg-orange text-white'
                              }`}
                            >
                              {item?.authorName === 'MERCHANT' ? 'Ask by owner' : 'Ask by user'}
                            </span>
                          </Typography>
                          <Typography className="text-16 font-semibold text-14 text-gray">
                            {googleBusinessData?.title}
                          </Typography>
                          <Typography className="text-16 font-semibold text-18 mt-12 pb-24">
                            {item?.questionText}{' '}
                          </Typography>
                        </div>
                      </div>
                      {item?.google_faq_answer?.length > 0 &&
                        item?.google_faq_answer?.map((data, i) => {
                          return (
                            <div className="relative">
                              {data?.authorName === 'MERCHANT' ? (
                                <>
                                  <div className="bg-gray-300 absolute rounded-full p-8 -left-20">
                                    <AccountCircleOutlinedIcon className="text-28 text-gray" />
                                  </div>
                                  <img
                                    className="w-28 h-28 absolute top-24"
                                    src="/assets/images/business/googleLogo.png"
                                    alt=""
                                  />
                                </>
                              ) : (
                                <div className="absolute rounded-full -left-20">
                                  {data?.profilePhotoUrl ? (
                                    <img
                                      className="w-40 h-40 rounded-full"
                                      src={data?.profilePhotoUrl}
                                      alt=""
                                    />
                                  ) : (
                                    <div className="rounded-full">
                                      <UserProfile
                                        name={data?.authorName}
                                        width="40px"
                                        height="40px"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              <div
                                className={`${
                                  item?.google_faq_answer?.length > i + 1 &&
                                  'border-l-4 border-solid border-gray'
                                } pl-40 pb-16`}
                              >
                                {data?.authorName !== 'MERCHANT' && (
                                  <Typography className="text-gray font-semibold pb-8">
                                    <ForumIcon className="text-20 mr-8" />
                                    {t('businessCompetitor.faqReplied')}
                                  </Typography>
                                )}
                                <Typography className="bg-gray-200 text-gray-600 text-16 font-semibold p-16 h-128 rounded-md">
                                  {data?.answerText}
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                      {item?.google_faq_answer?.find(
                        (value) => value?.authorName === 'MERCHANT'
                      ) ? (
                        <Button
                          className="bg-quick-review text-white rounded-md mt-8 ml-40 hover:bg-quick-review px-16"
                          onClick={() => selectQuestionForEditAnswer(item)}
                        >
                          {t('businessCompetitor.editYourReply')}
                        </Button>
                      ) : (
                        <Button
                          className="bg-quick-review text-white rounded-md mt-8 ml-40 hover:bg-quick-review px-16"
                          onClick={() => selectQuestionForEditAnswer(item)}
                        >
                          {t('businessCompetitor.addYourReply')}
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="bg-white rounded-md w-full m-auto text-center py-256">
                <img className="m-auto" src="/assets/images/business/nofaqs.png" alt="" />
                <Typography className="my-16 font-semibold text-16">
                  {googleBusinessData?.isVerifiedOnGoogle
                    ? t('businessCompetitor.noFaqMessage')
                    : t('businessCompetitor.businessNotVerified')}
                </Typography>
                <Button
                  className="bg-quick-review text-white font-semibold disabled:bg-gray rounded px-40 hover:bg-quick-review"
                  onClick={() => setDialogOpen(true)}
                  disabled={!googleBusinessData?.isVerifiedOnGoogle}
                >
                  {t('businessCompetitor.addNewFaq')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <ConnectYourBusiness setIsBusinessConnect={setIsBusinessConnect} />
        )}
      </div>
      <Dialog
        classes={{
          paper:
            'm-24 p-28 border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
        }}
        open={dialogOpen}
        onKeyDown={(event) => {
          if (event.keyCode === 27) {
            handleClose();
          }
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={() => handleClose()}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
        </DialogTitle>

        <form name="addClient" onSubmit={handleSubmit(onSubmit)}>
          <div className="block mb-20">
            <InputLabel id="firstName" className="text-16 font-semibold mb-4 text-grey-900">
              {t('businessCompetitor.newQuestion')}
            </InputLabel>
            <Controller
              name="question"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder={t('businessCompetitor.form.question.placeHolder')}
                  error={!!errors.question}
                  helperText={errors?.question?.message}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="block mb-20">
            <InputLabel id="mail" className="text-16 font-semibold mb-4 text-grey-900">
              {t('businessCompetitor.yourAnswer')}
            </InputLabel>
            <Controller
              name="answer"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  placeholder={t('businessCompetitor.form.answer.placeHolder')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>

          <DialogActions className="p-0 mt-20 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
            >
              {t('businessCompetitor.addNewFaq')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          paper:
            'm-24 p-28 border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
        }}
        open={openAnsDialog}
        onKeyDown={(event) => {
          if (event.keyCode === 27) {
            setOpenAnsDialog(false);
            setSelectQuestionAndAnswer();
            setAns();
          }
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={() => {
              setOpenAnsDialog(false);
              setSelectQuestionAndAnswer();
              setAns();
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
        </DialogTitle>
        <form name="addClient" onSubmit={handleSubmit(onSubmit)}>
          <Typography className="font-semibold text-16">
            {t('businessCompetitor.que')}. {selectQuestionAndAnswer?.questionText}
          </Typography>
          <div className="block my-20">
            <TextField
              type="text"
              variant="outlined"
              size="small"
              value={ans}
              fullWidth
              placeholder="Enter you answer"
              onChange={(e) => setAns(e.target.value)}
            />
          </div>
          <DialogActions className="p-0 mt-20 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
              variant="contained"
              color="secondary"
              onClick={() => editAns()}
              disabled={!ans || refreshIcon}
            >
              {selectQuestionAndAnswer?.google_faq_answer?.find(
                (item) => item?.authorName !== 'MERCHANT'
              )?.authorName === 'MERCHANT' ||
              selectQuestionAndAnswer?.google_faq_answer?.length === 0
                ? t('businessCompetitor.addAns')
                : t('businessCompetitor.editAns')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Faq;
