import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState, useCallback, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import CloseIcon from '@mui/icons-material/Close';
import { Editor, EditorState, RichUtils, Modifier, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import EmojiPicker from 'emoji-picker-react';
import 'draft-js/dist/Draft.css';
import './Waba.css';
import AddVariable from './AddVariable';
import wabaQuery from '../../query/waba';
import {
  handleWabaApiRequest,
  wabaImageSendToServer,
  convertTemplateToHtml,
} from '../../common/common';

const CreateTemplate = (props) => {
  const { openValue, cancelCall, popUpMessage, popUpButtonName, dialogType, templateId } = props;

  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [categoryList, setCategoryList] = useState();
  const [selectCategory, setSelectCategory] = useState();
  const [selectPreviewTemplate, setSelectPreviewTemplate] = useState();
  const [templateName, setTemplateName] = useState();
  const [footerText, setFooterText] = useState();
  const [msgTitle, setMsgTitle] = useState();
  const [viewWhatsAppTemplateDetails, setViewWhatsAppTemplateDetails] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [callAction, setCallAction] = useState('none');
  const [callActionButton, setCallActionButton] = useState('URL');
  const [buttonText, setButtonText] = useState();
  const [buttonData, setButtonData] = useState();
  const [openCreateVariableDialogue, setOpenCreateVariableDialogue] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [mediaRemove, setMediaRemove] = useState();
  const [imageUpload, setImageUpload] = useState();
  const fileImgRef = useRef();

  const getBodyText = (editorStates) => {
    const contentState = editorStates.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let formattedText = '';
    const bodyVariables = new Set();

    rawContent.blocks.forEach((block) => {
      const blockText = block.text;
      const styles = new Map();

      const placeholders = blockText.match(/\[.*?\]/g);
      if (placeholders) {
        placeholders.forEach((placeholder) => {
          bodyVariables.add(placeholder.replace(/[[]]/g, ''));
        });
      }

      block.inlineStyleRanges.forEach((styleRange) => {
        const { style, offset, length } = styleRange;
        const key = `${offset}-${offset + length}`;
        const existing = styles.get(key) || [];
        styles.set(key, [...existing, style]);
      });

      let lastIndex = 0;
      Array.from(styles.entries()).forEach(([range, stylesW]) => {
        const [start, end] = range.split('-').map(Number);
        const textToFormat = blockText.slice(start, end);
        let formattedSegment = textToFormat;

        stylesW.forEach((style) => {
          if (style === 'BOLD') {
            formattedSegment = `*${formattedSegment}*`;
          } else if (style === 'ITALIC') {
            formattedSegment = `_${formattedSegment}_`;
          } else if (style === 'STRIKETHROUGH') {
            formattedSegment = `~${formattedSegment}~`;
          } else if (style === 'CODE') {
            formattedSegment = `\`\`\`${formattedSegment}\`\`\``;
          }
        });

        formattedText += blockText.slice(lastIndex, start) + formattedSegment;
        lastIndex = end;
      });

      formattedText += `${blockText.slice(lastIndex)}\n`;
    });

    return { formattedText, bodyVariables: Array.from(bodyVariables) };
  };

  const CustomMenuList = (propsList) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = propsList;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  useEffect(() => {
    if (dialogType !== 'viewTemplate') {
      try {
        const whatAppTemplateCategories = async () => {
          const payload = {
            query: wabaQuery.getTemplateCategories,
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getTemplateCategories?.status === 200) {
            const templateCategoryList = res?.getTemplateCategories?.data?.map((idx) => ({
              value: idx.id,
              label: idx.name,
            }));
            setCategoryList(templateCategoryList);
          }
        };
        whatAppTemplateCategories();
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }, [dialogType]);

  useEffect(() => {
    if (dialogType === 'viewTemplate' && templateId) {
      try {
        const whatAppTemplate = async () => {
          const payload = {
            query: wabaQuery.getWhatsappTemplate,
            templateId,
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getWhatsappTemplate?.status === 200) {
            setViewWhatsAppTemplateDetails(
              res.getWhatsappTemplate.data.find((template) => template.id === templateId)
            );
          }
        };
        whatAppTemplate();
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }, [dialogType, templateId]);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  const handleUploadImageChange = async (e) => {
    const file = e?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.onload = async () => {
      setImageUrl(reader.result);
      setImageUpload(file);
      setMediaRemove(false);
    };

    try {
      reader.readAsDataURL(file);
    } catch (error) {
      enqueueSnackbar('Failed to read the file. Ensure the file is valid.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('File reading error:', error);
    }
    // setNoImgMsg('');
  };

  const handleCreateTemplate = async () => {
    setRefreshIcon(true);
    try {
      const { formattedText, bodyVariables } = getBodyText(editorState);
      const payload = {
        query: wabaQuery.createWhatsappTemplate,
        variables: {
          templateBody: formattedText,
          templateCategoryId: selectCategory?.value,
          templateName,
          ...(imageUpload && {
            templateImage: imageUpload,
          }),
        },
      };
      if (bodyVariables.length > 0) {
        payload.variables.bodyVariables = ['Customer Name'];
      }
      if (msgTitle) {
        payload.variables.templateTitle = msgTitle;
      }
      if (footerText) {
        payload.variables.templateFooter = footerText;
      }
      if (callAction === 'action') {
        payload.variables.templateButton = {
          buttonText: buttonText?.trim(),
          buttonData,
          buttonType: callActionButton,
        };
      }
      const res = await wabaImageSendToServer(payload);
      setRefreshIcon(false);
      if (res.createWhatsappTemplate?.status === 200) {
        handleClose();
        setTemplateName('');
        enqueueSnackbar(`${t('waba.broadcast.AddTemplate.successMsg')}`, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.createWhatsappTemplate?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleKeyCommand = (command, editorStates) => {
    const newState = RichUtils.handleKeyCommand(editorStates, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const onStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'));
  };

  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const addEmoji = (event, emojiObject) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEmoji = Modifier.insertText(
      contentState,
      editorState.getSelection(),
      emojiObject.emoji
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEmoji,
      'insert-characters'
    );
    setEditorState(newEditorState);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const cancelDialogue = async () => {
    setOpenCreateVariableDialogue(false);
  };

  const handleVariableSelect = (variable) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // Insert the selected variable text at the cursor's position
    const contentStateWithVariable = Modifier.insertText(
      contentState,
      selectionState,
      `[${variable.name}]`
    );

    // Push the new content state to update the editor
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithVariable,
      'insert-characters'
    );

    // Update the editor state
    setEditorState(newEditorState);
    cancelDialogue();
  };

  const handleInputNameChange = (e, maxLength, type) => {
    const newValue = e.target.value;
    if (newValue.length <= maxLength) {
      if (type === 'templateName') {
        setTemplateName(newValue);
      } else if (type === 'msgTitle') {
        setMsgTitle(newValue);
      } else if (type === 'footerText') {
        setFooterText(newValue);
      }
    }
  };

  const handleEditorChange = (state) => {
    const currentContent = state.getCurrentContent();
    const plainText = currentContent.getPlainText('');
    const length = plainText?.length;

    if (length <= 750) {
      setEditorState(state);
    } else {
      const trimmedContent = Modifier.removeRange(currentContent, state.getSelection(), 'backward');
      setEditorState(EditorState.push(state, trimmedContent, 'remove-range'));
    }
  };

  const paperClasses = `m-24 ${
    dialogType !== 'viewTemplate' ? 'max-w-screen-lg' : ''
  } width-full border-t-8 border-solid border-t-quick-chat border-quick-chat rounded-md overflow-x-hidden`;

  return (
    <Dialog
      classes={{
        paper: paperClasses,
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="flex">
        {dialogType !== 'viewTemplate' && (
          <div className="w-3/4 p-20 create-broadcast-height overflow-y-auto quick-chat">
            <div className="flex gap-16">
              <div className="block mb-20 md:basis-1/2">
                {dialogType === 'viewTemplate' ? (
                  <>
                    <InputLabel id="templateName" className="text-16 font-medium text-grey-900">
                      {t('waba.broadcast.AddTemplate.name')}
                    </InputLabel>
                    <span className="rounded-md bg-grey-100 mt-4 w-full block px-12 py-8">
                      {viewWhatsAppTemplateDetails?.displayName}
                    </span>
                  </>
                ) : (
                  <>
                    <div className="flex justify-between items-center mb-4">
                      <InputLabel id="templateName" className="text-16 font-medium text-grey-900">
                        {t('waba.broadcast.AddTemplate.name')}
                        <span className="text-red">*</span>
                      </InputLabel>
                      <span className="text-sm text-gray-500">{templateName?.length || 0}/120</span>
                    </div>
                    <TextField
                      name="templateName"
                      type="text"
                      onChange={(e) => handleInputNameChange(e, 120, 'templateName')}
                      placeholder={t('quickCampaign.template.addTemplateName')}
                      value={templateName}
                      className="bg-white"
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxLength={120}
                    />
                  </>
                )}
              </div>

              <div className="md:basis-1/2">
                {dialogType === 'viewTemplate' ? (
                  <>
                    <InputLabel id="templateName" className="text-16 font-medium text-grey-900">
                      {t('waba.broadcast.AddTemplate.category')}
                    </InputLabel>
                    <span className="rounded-md bg-grey-100 mt-4 w-full block px-12 py-8">
                      {viewWhatsAppTemplateDetails?.categoryName}
                    </span>
                  </>
                ) : (
                  <>
                    <InputLabel
                      id="templateName"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('waba.broadcast.AddTemplate.category')}
                    </InputLabel>
                    <ReactSelect
                      labelId="templateCategory"
                      placeholder={t('waba.broadcast.AddTemplate.selectCategory')}
                      id="templateCategory"
                      name="templateCategory"
                      minMenuHeight="45px"
                      onChange={(e) => {
                        setSelectCategory(e);
                      }}
                      value={selectCategory}
                      options={categoryList}
                      components={{
                        MenuList: CustomMenuList,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: '42px',
                          borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          boxShadow: state.isFocused ? '0 0 0 1px #EBB32F' : baseStyles.boxShadow,
                          '&:hover': {
                            borderColor: state.isFocused ? '#EBB32F' : baseStyles.borderColor,
                          },
                        }),
                      }}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="block mb-20 md:basis-1/2">
              {dialogType === 'viewTemplate' ? (
                <>
                  <InputLabel id="templateName" className="text-16 font-medium text-grey-900">
                    {t('waba.broadcast.AddTemplate.msgTitle')}
                  </InputLabel>
                  <span className="rounded-md bg-grey-100 mt-4 w-full block px-12 py-8">
                    {viewWhatsAppTemplateDetails?.categoryName}
                  </span>
                </>
              ) : (
                <>
                  <div className="flex justify-between items-center mb-4">
                    <InputLabel
                      id="messageTitle"
                      className="text-16 font-medium mb-4 text-grey-900 mb-10"
                    >
                      {t('waba.broadcast.AddTemplate.msgTitle')}
                    </InputLabel>
                    <span className="text-sm text-gray-500">{msgTitle?.length || 0}/60</span>
                  </div>
                  <TextField
                    name="messageTitle"
                    placeholder={t('waba.broadcast.AddTemplate.msgTitlePlaceholder')}
                    type="text"
                    onChange={(e) => handleInputNameChange(e, 60, 'msgTitle')}
                    value={msgTitle}
                    className="bg-white"
                    variant="outlined"
                    size="small"
                    fullWidth
                    maxLength={60}
                  />
                </>
              )}
            </div>
            <div className="block mb-20">
              <InputLabel id="media" className="text-16 font-medium mb-4 text-grey-900 mb-10">
                {t('waba.broadcast.AddTemplate.media')}
              </InputLabel>
              {imageUrl ? (
                <div className="relative inline-block">
                  <img alt="..." className="max-w-320 capitalize text-40" src={imageUrl} />{' '}
                  <Button
                    className="bg-white absolute right-10 top-10 w-20 h-20 min-w-20 min-h-20 hover:bg-white"
                    onClick={() => {
                      setMediaRemove(true);
                      setImageUrl();
                    }}
                  >
                    <CloseIcon className="text-black text-lg" />
                  </Button>
                </div>
              ) : (
                <div className="border-2 border-dotted border-quick-chat text-center py-20 rounded-md">
                  <Icon>upload</Icon>
                  <h4 className="text-16 font-semibold">
                    {t('waba.broadcast.AddTemplate.uploadFile')}
                  </h4>
                  <span className="text-14 font-medium block text-grey-600 my-12">
                    {t('waba.broadcast.AddTemplate.uploadInfo')}
                  </span>
                  <div className="relative">
                    <label htmlFor="template-image">
                      <input
                        className="hidden w-full"
                        id="template-image"
                        type="file"
                        ref={fileImgRef}
                        onChange={handleUploadImageChange}
                        accept="image/*"
                        aria-labelledby="upload-label"
                      />
                      <Button
                        className="border rounded-md bg-quick-chat-100 font-semibold text-quick-chat hover:text-white hover:bg-quick-chat"
                        variant="contained"
                        color="secondary"
                        component="span"
                        id="upload-label"
                      >
                        {t('waba.broadcast.AddTemplate.uploadImage')}
                      </Button>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="block mb-20">
              <InputLabel id="body" className="text-16 font-medium mb-4 text-grey-900 mb-10">
                {t('waba.broadcast.AddTemplate.body')}
              </InputLabel>
              {editorState.getCurrentContent().getPlainText('').length}/750
              <Typography className="mb-10">{t('waba.broadcast.AddTemplate.bodyInfo')}</Typography>
              <div className="border-1 border-solid border-grey-400 p-10 rounded-md relative">
                <div className="mb-10 flex gap-10">
                  {/* <div className="bg-grey-50 rounded-md">
                    <Button
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className="min-w-40"
                    >
                      <Icon className="text-16">insert_emoticon</Icon>
                    </Button>
                  </div> */}
                  <div className="bg-grey-50 rounded-md w-full">
                    <Button
                      onClick={onBoldClick}
                      className="text-20 font-bold text-grey-600 min-w-0"
                    >
                      B
                    </Button>
                    <Button
                      onClick={onItalicClick}
                      className="text-20 font-bold italic text-grey-600 min-w-0"
                    >
                      I
                    </Button>
                    <Button
                      onClick={onStrikethroughClick}
                      className="text-20 font-bold line-through text-grey-600 min-w-0"
                      title="Strike-through"
                    >
                      T
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenCreateVariableDialogue(true)}
                      className="rounded-md min-w-136"
                      color="quickChat"
                    >
                      {t('waba.broadcast.AddTemplate.addVariable')}
                    </Button>
                  </div>
                </div>
                {showEmojiPicker && <EmojiPicker onEmojiClick={addEmoji} className="absolute" />}
                <div className="min-h-200">
                  <Editor
                    className="min-h-200"
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={handleEditorChange}
                    placeholder={t('waba.broadcast.AddTemplate.bodyPlaceHolder')}
                  />
                </div>
              </div>
            </div>
            <div className="block mb-20 md:basis-1/2">
              <div className="flex justify-between items-center mb-4">
                <InputLabel id="footer" className="text-16 font-medium mb-4 text-grey-900 mb-10">
                  {t('waba.broadcast.AddTemplate.footer')}
                </InputLabel>
                <span className="text-sm text-gray-500">{footerText?.length || 0}/60</span>
              </div>
              <TextField
                name="footer"
                placeholder={t('waba.broadcast.AddTemplate.placeHolder')}
                type="text"
                onChange={(e) => handleInputNameChange(e, 60, 'footerText')}
                value={footerText}
                className="bg-white"
                variant="outlined"
                size="small"
                fullWidth
                maxLength={60}
              />
            </div>

            <div className="block mb-20">
              <InputLabel id="footer" className="text-16 font-medium mb-4 text-grey-900 mb-5">
                {t('waba.broadcast.AddTemplate.callToAction')}
              </InputLabel>
              <Typography className="mb-10">
                {t('waba.broadcast.AddTemplate.callToActionInfo')}
              </Typography>
              {callAction !== 'none' && callActionButton === 'PHONE_NUMBER' && (
                <span className="text-red block mb-10">
                  note: Please ensure that the phone number includes the country code. Templates
                  cannot be created without a complete phone number, including the country code. For
                  example, use 1 123 456 7890 instead of 123 456 7890.
                </span>
              )}
              <Select
                className="rounded-md p-0 shadow-none pt-5"
                sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                size="small"
                variant="outlined"
                value={callAction}
                fullWidth
                onChange={(e) => setCallAction(e?.target?.value)}
              >
                <MenuItem
                  value="none"
                  className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                >
                  {t('waba.broadcast.AddTemplate.none')}
                </MenuItem>
                <MenuItem
                  value="action"
                  className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                >
                  {t('waba.broadcast.AddTemplate.callToAction')}
                </MenuItem>
              </Select>
            </div>

            {callAction === 'action' && (
              <div className="flex mb-20 gap-16">
                <Select
                  className="rounded-md p-0 shadow-none pt-5"
                  sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                  size="small"
                  variant="outlined"
                  value={callActionButton}
                  fullWidth
                  onChange={(e) => setCallActionButton(e?.target?.value)}
                >
                  <MenuItem
                    value="URL"
                    className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                  >
                    {t('waba.broadcast.AddTemplate.viewWebsite')}
                  </MenuItem>
                  <MenuItem
                    value="PHONE_NUMBER"
                    className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                  >
                    {t('waba.broadcast.AddTemplate.callPhone')}
                  </MenuItem>
                </Select>
                <TextField
                  name="buttonText"
                  placeholder={t('waba.broadcast.AddTemplate.buttonText')}
                  type="text"
                  onChange={(e) => setButtonText(e?.target?.value)}
                  value={buttonText}
                  className="bg-white"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <TextField
                  name="websiteURL"
                  placeholder={
                    callActionButton === 'URL'
                      ? t('waba.broadcast.AddTemplate.websiteURL')
                      : t('waba.broadcast.AddTemplate.phoneNo')
                  }
                  type="text"
                  onChange={(e) => setButtonData(e?.target?.value)}
                  value={buttonData}
                  className="bg-white"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
            )}

            {dialogType !== 'viewTemplate' && (
              <DialogActions className="p-0 mt-20 justify-start">
                <Button
                  className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleCreateTemplate()}
                  type="button"
                  disabled={!templateName || refreshIcon}
                >
                  {popUpButtonName}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-quick-chat absolute mx-auto" />
                  )}
                </Button>
              </DialogActions>
            )}
          </div>
        )}
        <div
          className={`${
            dialogType !== 'viewTemplate' ? 'w-2/4' : 'w-full pt-20'
          } bg-gray-A300 text-center create-broadcast-height sticky top-0`}
        >
          {dialogType !== 'viewTemplate' && (
            <h3 className="text-20 font-semibold text-center py-48">
              {t('waba.broadcast.AddTemplate.preview')}
            </h3>
          )}
          <div className="relative inline-block mx-auto">
            <img
              src="/assets/images/business/mobile_frame_waba.png"
              className="img-fluid mx-auto"
              alt="..."
            />

            {dialogType === 'viewTemplate' ? (
              <div className="absolute top-144 left-0 right-0 bg-white rounded-md px-8 py-8 max-w-256 mx-auto max-h-400 overflow-scroll">
                {viewWhatsAppTemplateDetails?.templateData &&
                  JSON.parse(viewWhatsAppTemplateDetails?.templateData)?.components?.map(
                    (templateValue, i) => (
                      <div className="block max-w-320 text-left" key={i}>
                        <div>
                          {templateValue?.type === 'HEADER' && (
                            <>
                              {templateValue?.format === 'TEXT' && (
                                <Typography className="mb-10 font-bold">
                                  {templateValue?.text}
                                </Typography>
                              )}
                              {templateValue?.format === 'IMAGE' && (
                                <>
                                  {JSON.parse(
                                    viewWhatsAppTemplateDetails?.dynamicData
                                  )?.header?.map((dynamicValue) => (
                                    <>
                                      {dynamicValue?.type === 'image' && (
                                        <img
                                          src={dynamicValue?.value}
                                          className="mb-10 rounded-md"
                                          alt="..."
                                        />
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                          {templateValue?.type === 'BODY' && (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: convertTemplateToHtml(templateValue?.text),
                                }}
                              />
                            </>
                          )}
                          {templateValue?.type === 'FOOTER' && (
                            <>
                              <Typography className="mt-6 text-grey-700">
                                {templateValue?.text}
                              </Typography>
                            </>
                          )}
                          {templateValue?.type === 'BUTTONS' &&
                            templateValue?.buttons?.map((buttonValue, b) => (
                              <div
                                className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                                key={b}
                              >
                                <a
                                  className="font-bold text-quick-chat text-16"
                                  href={`${
                                    buttonValue?.phone_number
                                      ? `tel:${buttonValue?.phone_number}`
                                      : ''
                                  }`}
                                  rel="noreferrer"
                                  target="_blank"
                                  role="button"
                                >
                                  <img
                                    src={
                                      buttonValue?.phone_number
                                        ? '/assets/images/business/telephone.png'
                                        : '/assets/images/business/view.png'
                                    }
                                    className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                    alt="..."
                                  />
                                  {buttonValue?.text}
                                </a>
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                  )}
              </div>
            ) : (
              <>
                {(footerText ||
                  callAction !== 'none' ||
                  msgTitle ||
                  convertContentToHTML(editorState)) && (
                  <div className="absolute top-144 left-0 right-0 bg-white rounded-md px-8 py-8 max-w-256 mx-auto max-h-400 overflow-scroll">
                    <div className="block max-w-320 text-left bg-white">
                      <div>
                        {imageUrl && <img src={imageUrl} className="mb-10 max-h-128" alt="..." />}
                        {msgTitle && <div>{msgTitle}</div>}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertContentToHTML(editorState),
                          }}
                        />

                        {footerText && (
                          <Typography className="mt-6 text-grey-700">{footerText}</Typography>
                        )}

                        {callAction !== 'none' && (
                          <div className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12">
                            <a
                              className="font-bold text-quick-chat text-16"
                              href={`${
                                callActionButton === 'PHONE_NUMBER'
                                  ? `tel:${buttonData}`
                                  : { buttonData }
                              }`}
                              rel="noreferrer"
                              target="_blank"
                              role="button"
                            >
                              {callActionButton === 'PHONE_NUMBER' && (
                                <img
                                  src={
                                    callActionButton === 'PHONE_NUMBER'
                                      ? '/assets/images/business/telephone.png'
                                      : '/assets/images/business/view.png'
                                  }
                                  className="w-14 h-14 align-middle mr-6 inline-block relative -top-1"
                                  alt="..."
                                />
                              )}
                              {buttonText}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {openCreateVariableDialogue && (
        <AddVariable
          openValue={openCreateVariableDialogue}
          cancelCall={cancelDialogue}
          popUpMessage={t('waba.broadcast.AddTemplate.selectVariable')}
          onVariableSelect={handleVariableSelect}
        />
      )}
    </Dialog>
  );
};

export default CreateTemplate;
