/* global FB */
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Icon from '@mui/material/Icon';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect } from 'react';
import history from '@history';
import moment from 'moment';
import userService from '../../services/userService';
import quickSocialQuery from '../query/quickSocial';
import queryData from '../query/common';
import manageBusinessData from '../query/manageBusiness';
import userData from '../query/user';

const phoneUtil = PhoneNumberUtil.getInstance();

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

const forAuthBaseURL = process.env.REACT_APP_AUTH_ENDPOINT;
const instanceForAuth = axios.create({
  forAuthBaseURL,
});

export const countryData = (countryId = '', phoneCountry = '') => {
  const country = [
    {
      id: 2,
      name: 'India',
      countryCode: 91,
      isoCode: 'IND',
      isActive: true,
      currency: 'INR',
      symbol: '₹',
      flag: 'https://flagcdn.com/w320/in.png',
      phoneCountry: 'in',
    },
    {
      id: 1,
      name: 'United States',
      countryCode: 1,
      isoCode: 'USA',
      isActive: true,
      currency: 'USD',
      symbol: '$',
      flag: 'https://flagcdn.com/w320/us.png',
      phoneCountry: 'us',
    },
  ];
  if (countryId) {
    return country.find((element) => {
      return element.id === countryId;
    });
  }
  if (phoneCountry) {
    return country.find((element) => {
      return element.phoneCountry === phoneCountry;
    });
  }
  return country;
};

export const getParamsId = () => {
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  if (/^\d+$/.test(lastPart)) {
    return lastPart;
  }
  return '';
};

export const getEncryptedData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(value, publicKeyValue).toString();
  } catch (error) {
    throw new Error(error);
  }
};

export const getDecryptData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    const decrypted = CryptoJS.AES.decrypt(value, publicKeyValue);
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error(error);
  }
};

export const handleApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  instance.defaults.headers.common['is-call-from-quick-hub-product'] = true;
  try {
    const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common['is-call-from-quick-hub-product'] = true;
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const getDataToServerForAuth = async (data) => {
  instanceForAuth.defaults.headers.common.Authorization = `Bearer ${
    userService.getAccessToken() || null
  }`;
  try {
    const response = await instanceForAuth.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleWabaApiRequest = async (data, token = '') => {
  instance.defaults.headers.common.Authorization = `Bearer ${
    token || userService.getAccessToken()
  }`;
  try {
    const response = await instance.post(process.env.REACT_APP_WABA_API_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const wabaImageSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.templateImage) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.templateImage"]}`);
    formData.append(0, data?.variables?.templateImage);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_WABA_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.icon) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.icon"]}`);
    formData.append(0, data?.variables?.icon);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageDataToSend = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.profileImage) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.profileImage"]}`);
    formData.append(0, data?.variables?.profileImage);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const storeCsvFileDataToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;

  if (data?.variables?.file) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.file"]}`);
    formData.append(0, data?.variables?.file);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const serverApiRequest = async (endpoint, data, additionalHeaders = {}) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  instance.defaults.headers.common.isrequestfrom = true;

  try {
    const response = await instance.post(endpoint, data, { headers: additionalHeaders });
    return response.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleAuthApi = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const imageSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.image) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.image"]}`);
    formData.append(0, data?.variables?.image);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const avatarDataSendToServer = async (data) => {
  instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
  let headerData = {};
  const formData = new FormData();
  let isFile = false;
  if (data?.variables?.avatarImageFile) {
    isFile = true;
    headerData = {
      headers: {
        'apollo-require-preflight': 'true',
        'Content-Type': 'multipart/form-data',
      },
    };
    formData.append('operations', JSON.stringify(data));
    formData.append('map', `{"0":["variables.avatarImageFile"]}`);
    formData.append(0, data?.variables?.avatarImageFile);
  }

  try {
    const response = await instance.post(
      process.env.REACT_APP_API_ENDPOINT,
      !isFile ? { ...data } : formData,
      headerData
    );
    return response.data.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const checkEmailExistOrNot = async (email, enqueueSnackbar) => {
  try {
    const emailPayload = {
      query: queryData.checkEmail,
      variables: { email },
    };
    const { checkEmail: response } = await handleApiRequest(emailPayload);
    const isSuccess = response?.status === 200;
    if (!isSuccess) {
      enqueueSnackbar(response?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    return isSuccess;
  } catch (error) {
    console.error('Error during email handling:', error);
    return false;
  }
};

export const sendOTPOnEmailVerification = async (email, enqueueSnackbar) => {
  try {
    const payload = {
      query: queryData.sendOTPOnEmailVerification,
      variables: { email },
    };
    const { sendOTPOnEmailVerification: response } = await getDataToServerForAuth(payload);
    const isSuccess = response?.status === 200;
    enqueueSnackbar(response?.message, {
      variant: isSuccess ? 'success' : 'error',
      autoHideDuration: 3000,
    });
    return isSuccess;
  } catch (error) {
    console.error('Error sending OTP on email verification:', error);
    return false;
  }
};

export const checkPhoneNumberExistOrNot = async (phoneNumber, enqueueSnackbar) => {
  try {
    const phonePayload = {
      query: queryData.checkAndVerifyPhoneNumber,
      variables: { phoneNumber: parseInt(phoneNumber, 10) },
    };
    const { checkAndVerifyPhoneNumber: response } = await handleApiRequest(phonePayload);
    const isSuccess = response?.status === 200;
    if (!isSuccess) {
      enqueueSnackbar(response?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    return isSuccess;
  } catch (error) {
    console.error('Error during phone number handling:', error);
    return false;
  }
};

export const sendOTPOnPhoneNumberVerification = async (
  phoneNumber,
  countryCode,
  enqueueSnackbar
) => {
  try {
    const payload = {
      query: queryData.sendOTPOnPhoneNumberVerification,
      variables: {
        countryCode,
        phoneNumber: parseInt(phoneNumber, 10),
      },
    };
    const { sendOTPOnPhoneNumberVerification: response } = await getDataToServerForAuth(payload);
    const isSuccess = response?.status === 200;
    enqueueSnackbar(response?.message, {
      variant: isSuccess ? 'success' : 'error',
      autoHideDuration: 3000,
    });
    return isSuccess;
  } catch (error) {
    console.error('Error sending OTP on phone number verification:', error);
    return false;
  }
};

export const verifyEmailOtp = async (email, otp, enqueueSnackbar) => {
  try {
    const payload = {
      query: queryData.verifyOTP,
      variables: {
        data: getEncryptedData(
          `{"email": "${email?.toLocaleLowerCase()}", "otp": ${parseInt(otp, 10)}}`
        ),
      },
    };
    const { verifyOTP: response } = await getDataToServerForAuth(payload);
    const isSuccess = response?.status === 200;
    enqueueSnackbar(response?.message, {
      variant: isSuccess ? 'success' : 'error',
      autoHideDuration: 3000,
    });
    return isSuccess;
  } catch (error) {
    console.error('Error verifying OTP on email verification:', error);
    return false;
  }
};

export const verifyPhoneOtp = async (phoneNumber, countryCode, otp, enqueueSnackbar) => {
  try {
    const payload = {
      query: queryData.verifyPhoneNumberOTP,
      variables: {
        data: getEncryptedData(
          JSON.stringify({
            countryCode,
            phoneNumber: parseInt(phoneNumber, 10),
            otp: parseInt(otp, 10),
          })
        ),
      },
    };
    const { verifyPhoneNumberOTP: response } = await getDataToServerForAuth(payload);
    const isSuccess = response?.status === 200;
    enqueueSnackbar(response?.message, {
      variant: isSuccess ? 'success' : 'error',
      autoHideDuration: 3000,
    });
    return isSuccess;
  } catch (error) {
    console.error('Error verifying OTP on email verification:', error);
    return false;
  }
};

export const Message = {
  emailRegex: /\S+@\S+\.\S+/,
};

export const reviewFormate = (reviewData, text = 16) => {
  return [...Array(5)].map((_, i) => {
    if (i + 1 <= reviewData) {
      return (
        <Icon className={`text-${text} align-middle mr-2 text-yellow-800`} key={`star-${i}`}>
          star
        </Icon>
      );
    }

    if (reviewData - i > 0 && reviewData - i < 1) {
      return (
        <>
          <Icon className={`text-${text} align-middle mr-2 text-yellow-800`} key={`star-half-${i}`}>
            star_half
          </Icon>
        </>
      );
    }

    return (
      <Icon
        className={`text-${text} align-middle mr-2 text-yellow-800 `}
        key={`star-outlined-${i}`}
      >
        star_outlined
      </Icon>
    );
  });
};

export const sortOptions = (inputValues, options) => {
  if (!inputValues) return options;

  const normalizedInputValue = inputValues.toLowerCase();

  return options.sort((a, b) => {
    const aValue = a.label.toLowerCase();
    const bValue = b.label.toLowerCase();

    if (aValue === normalizedInputValue) return -1;
    if (bValue === normalizedInputValue) return 1;

    if (aValue.startsWith(normalizedInputValue) && !bValue.startsWith(normalizedInputValue))
      return -1;
    if (!aValue.startsWith(normalizedInputValue) && bValue.startsWith(normalizedInputValue))
      return 1;

    return aValue.localeCompare(bValue);
  });
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertTemplateToHtml = (template) => {
  let htmlContents = '';

  let bodyText = template || '';

  bodyText = bodyText
    .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    .replace(/_(.*?)_/g, '<em>$1</em>')
    .replace(/~(.*?)~/g, '<del>$1</del>')
    .replace(/```(.*?)```/g, '<code>$1</code>')
    .replace(/\n/g, '<br>');
  htmlContents += `<div className="block">${bodyText}</div>`;

  return htmlContents;
};

export const handlePhoneNumberValidationCheck = (number, isoCode) => {
  const phoneWithoutDialCode = number?.slice(isoCode?.dialCode?.length);

  if (phoneWithoutDialCode?.length === 0) {
    return true;
  }

  if (/^\d+$/.test(phoneWithoutDialCode)) {
    try {
      const parsedNumber = phoneUtil.parse(phoneWithoutDialCode, isoCode?.countryCode);
      const isValid = phoneUtil.isValidNumberForRegion(parsedNumber, isoCode?.countryCode);

      if (isValid === null || isValid === false) {
        return false;
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
};

export const fetchCountries = async () => {
  const payload = {
    query: queryData.getCountries,
  };
  try {
    const res = await handleApiRequest(payload);
    if (res?.getCountries?.status === 200) {
      return res?.getCountries?.data;
    }
  } catch (error) {
    console.error('Failed to fetch countries:', error);
  }
  return null;
};

export const fetchPhysicalBusinessType = async () => {
  try {
    const payload = {
      query: queryData.getBusinessType,
    };
    const res = await handleApiRequest(payload);
    if (res?.getBusinessType?.status === 200) {
      return res?.getBusinessType?.data;
    }
    return null;
  } catch (error) {
    console.error('Error fetching business type:', error);
    return null;
  }
};

export const fetchOnlineBusinessType = async () => {
  try {
    const payload = {
      query: queryData.getBusinessType,
      variables: {
        isOnlineBusiness: true,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.getBusinessType?.status === 200) {
      return res?.getBusinessType?.data;
    }
    return null;
  } catch (error) {
    console.error('Error fetching business type:', error);
    return null;
  }
};

export const fetchReviewPlateForm = async () => {
  const payload = {
    query: manageBusinessData.getReviewPlatforms,
  };
  try {
    const result = await handleApiRequest(payload);
    if (result.getReviewPlatforms?.status === 200) {
      return result.getReviewPlatforms?.data;
    }
  } catch (error) {
    console.error('Failed to fetch review platforms:', error);
  }
  return null;
};

export const fetchBusinesses = async () => {
  try {
    const payload = {
      query: userData.getDashboard,
      variables: {
        pageSize: 100,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getDashboard?.status === 200) {
      return result?.getDashboard?.data;
    }
  } catch (error) {
    console.error('Error fetching business details:', error);
  }
  return null;
};

export const duration = [
  {
    id: 0,
    value: '1 Year',
    interval: 'year',
    oneTimeInterval: 'oneYear',
  },
  {
    id: 1,
    value: '6 Month',
    interval: 'six_month',
    oneTimeInterval: 'sixMonth',
  },
  {
    id: 2,
    value: '3 Month',
    interval: 'three_month',
    oneTimeInterval: 'threeMonth',
  },
  {
    id: 3,
    value: '1 Month',
    interval: 'month',
    oneTimeInterval: 'month',
  },
];

export const findExpireDay = (expiryDate) => {
  const now = moment(new Date()).startOf('day');
  const expiry = moment(expiryDate);
  const data = expiry.diff(now, 'days');
  return data;
};

export const redirectToDescription = (type) => {
  window.scrollTo(0, 0);

  const routesMap = {
    quick_reviews: 'quick-reviews',
    quick_chat: 'quick-chat',
    quick_social: 'quick-social',
    quick_campaigns: 'quick-campaign',
  };
  const route = routesMap[type];
  if (route) {
    history.push(`${route}/description/`);
  }
};

export const formatRating = (value) => {
  return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
};

let isFBSDKInitialized = false;

const initFacebookSDK = (appKey) => {
  return new Promise((resolve) => {
    if (isFBSDKInitialized) {
      resolve();
      return;
    }

    window.fbAsyncInit = function fbAsyncInit() {
      FB.init({
        appId: appKey,
        xfbml: true,
        version: 'v20.0',
      });
      isFBSDKInitialized = true;
      resolve();
    };

    (function loadSDK(d, s, id) {
      let js = d.getElementById(id);
      if (js) return;
      const fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};

export const handleFBLogin = async (appKey, fbConfigKey, instaConfigKey, socialPlatformId) => {
  await initFacebookSDK(appKey);

  return new Promise((resolve, reject) => {
    if (typeof FB !== 'undefined') {
      const fbLoginOptions = {
        response_type: 'token',
      };

      if (socialPlatformId === 9) {
        fbLoginOptions.config_id = fbConfigKey;
        fbLoginOptions.scope = [
          'business_management',
          'read_insights',
          'publish_video',
          'pages_show_list',
          'pages_read_user_content',
          'pages_read_engagement',
          'pages_manage_posts',
          'pages_manage_metadata',
          'pages_manage_instant_articles',
          'pages_manage_engagement',
          'page_events',
          'email',
        ].join(',');
      } else if (socialPlatformId === 10) {
        fbLoginOptions.config_id = instaConfigKey;
        fbLoginOptions.scope = [
          'instagram_basic',
          'instagram_content_publish',
          'instagram_manage_comments',
          'instagram_manage_events',
          'instagram_manage_insights',
          'instagram_manage_messages',
          'publish_video',
          'read_insights',
        ].join(',');
      }

      FB.login(function handleFBLoginResponse(response) {
        console.log('response', response);
        if (response.status === 'connected' && response.authResponse) {
          console.log('response', response);
          resolve(response.authResponse);
        } else if (response.status === 'unknown') {
          resolve(null);
        } else if (response.status === 'not_authorized') {
          console.log('User has not authorized the app');
          resolve(null);
        } else {
          console.log('User cancelled login or did not fully authorize.');
          resolve(null);
        }
      }, fbLoginOptions);
    } else {
      console.error('FB SDK is not available.');
      reject(new Error('FB SDK is not available.'));
    }
  });
};

export const fetchSocialConnections = async (
  socialPlatformId,
  userCurrentBusinessDetails,
  setConnectionData,
  setAccessToken,
  setOpenDialog,
  setSelectedPage,
  setLoading,
  accessToken
) => {
  if (!accessToken || !userCurrentBusinessDetails?.id) return;

  let payloadData;
  if (socialPlatformId === 9) {
    payloadData = {
      query: quickSocialQuery.getFacebookPageDetails,
      variables: { accessToken: String(accessToken) },
    };
  } else if (socialPlatformId === 10) {
    payloadData = {
      query: quickSocialQuery.getFacebookBusiness,
      variables: { access_token: String(accessToken) },
    };
  } else {
    return;
  }

  try {
    setLoading(true);
    const resultData = await handleApiRequest(payloadData);

    if (socialPlatformId === 9 && resultData?.getFacebookPageDetails?.status === 200) {
      setConnectionData(resultData?.getFacebookPageDetails?.data);
      setAccessToken(resultData?.getFacebookPageDetails?.data);
    } else if (socialPlatformId === 10 && resultData?.getFacebookBusiness?.status === 200) {
      const instagramGetData = resultData?.getFacebookBusiness?.data;
      if (instagramGetData) {
        setConnectionData(
          JSON.parse(getDecryptData(instagramGetData))?.business?.filter(
            (item) => item?.isConnectedToInstagram
          )
        );
        setAccessToken(JSON.parse(getDecryptData(instagramGetData))?.longAccessToken);
      }
    }
    setOpenDialog(true);
    setSelectedPage([]);
  } catch (error) {
    console.error('An error occurred while fetching connections:', error);
  } finally {
    setLoading(false);
  }
};

export const handleSocialResponse = async (
  socialPlatformId,
  accessToken,
  facebookUserId,
  selectedPage,
  userCurrentBusinessDetails,
  setLoading,
  enqueueSnackbar,
  fetchAgentSupportStatus,
  setConnectionData,
  setOpenDialog
) => {
  if (!selectedPage) {
    setLoading(false);
    return;
  }

  let payloadData;
  if (socialPlatformId === 9) {
    payloadData = {
      query: quickSocialQuery.saveFacebookAuthToken,
      variables: {
        accessToken: String(selectedPage?.accessToken),
        facebookUserId: String(facebookUserId),
        pageId: selectedPage?.id,
        pageName: selectedPage?.name,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
  } else if (socialPlatformId === 10) {
    payloadData = {
      query: quickSocialQuery.saveInstagramBusiness,
      variables: {
        access_token: String(accessToken),
        pageId: selectedPage?.id,
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
  } else {
    setLoading(false);
    return;
  }

  try {
    setLoading(true);
    const resultData = await handleApiRequest(payloadData);

    const messageKey = socialPlatformId === 9 ? 'saveFacebookAuthToken' : 'saveInstagramBusiness';
    if (resultData?.[messageKey]?.status === 200) {
      enqueueSnackbar(resultData?.[messageKey]?.message, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      setConnectionData([]);
      setOpenDialog(false);
      fetchAgentSupportStatus();
    }
  } catch (error) {
    console.error('An error occurred:', error);
  } finally {
    setLoading(false);
  }
};

export const generateRandomColor = () => {
  let randomColorValue;
  do {
    randomColorValue = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0');
  } while (randomColorValue.toUpperCase() === 'FFFFFF');
  return randomColorValue;
};

export const callPendingTaskFunc = async (
  item,
  productType,
  handleCallFunction,
  userCurrentBusinessDetails,
  botLink,
  route
) => {
  switch (item?.cardName) {
    case 'exploreTemplates':
      if (['quick_social', 'quick_campaigns'].includes(productType)) {
        history.push({ pathname: `${item?.portalURL}` });
        handleCallFunction(item?.cardName);
      }
      break;

    case 'exploreMetrics':
      history.push({
        pathname: `quick-reviews/${item?.portalURL}`,
      });
      handleCallFunction(item?.cardName);
      break;

    case 'addFlipkart':
      if (userCurrentBusinessDetails?.isOnlineBusiness) {
        history.push(`quick-reviews/${item?.portalURL}`, {
          platformsId: 6,
          openDialogue: true,
        });
      }
      break;
    case 'addAmazon':
      if (userCurrentBusinessDetails?.isOnlineBusiness) {
        history.push(`quick-reviews/${item?.portalURL}`, {
          platformsId: 5,
          openDialogue: true,
        });
      }
      break;

    case 'testYourChatBots':
      window.open(botLink, '_blank');
      handleCallFunction(item?.cardName);
      break;

    case 'configureTheBotResponses':
      history.push(`/quick-chat/${item?.portalURL}`, {
        item,
      });
      break;

    case 'addMorePlatforms':
      if (route === 'quick-review') {
        history.push(
          {
            pathname: `/${item?.portalURL}`,
          },
          {
            businessId: Number(userCurrentBusinessDetails?.id),
          }
        );
      }
      break;

    case 'sendYourFirstEmail':
    case 'scheduleYourMessage':
    case 'sendFirstWhatsAppMessage':
    case 'inviteReviewsOnWhatsApp': {
      const tabValueMap = {
        sendYourFirstEmail: 1,
        scheduleYourMessage: 2,
        sendFirstWhatsAppMessage: 3,
        inviteReviewsOnWhatsApp: 3,
      };
      const tabValue = tabValueMap[item?.cardName];
      if (route === 'quick-campaign' && tabValue) {
        history.push(
          {
            pathname: `/${item?.portalURL}`,
          },
          {
            tabValue,
          }
        );
      }
      break;
    }

    default:
      if (['quick-social', 'quick-campaign'].includes(route)) {
        history.push({ pathname: `/${item?.portalURL}` });
      } else if (route === 'quick-review') {
        history.push({
          pathname: `quick-reviews/${item?.portalURL}`,
        });
      } else {
        history.push({
          pathname: `${route}/${item?.portalURL}`,
        });
      }
      break;
  }
};
