import EmailOrPhone from './EmailOrPhone';
import OtpAndPassword from './OtpAndPassword';
import SignUpPage from './SignUpPage';
import UserInformation from './UserInformation';

const SignUpConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'signup',
      element: <SignUpPage />,
    },
    {
      path: 'sign-up',
      element: <EmailOrPhone />,
    },
    {
      path: 'verifyOtp',
      element: <OtpAndPassword />,
    },
    {
      path: 'userInformation',
      element: <UserInformation />,
    },
    {
      path: 'sign-up/:id',
      element: <SignUpPage />,
    },
  ],
};

export default SignUpConfig;
