import Matrices from './matrices/Matrices';
import BotDetails from './BotDetails';
import ChatInbox from './ChatInbox';
import Dashboards from './Dashboards';

const QuickChatConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-chat/dashboard/:id?',
      element: <Dashboards />,
    },
    {
      path: 'quick-chat/matrices/:id?',
      element: <Matrices />,
    },
    {
      path: 'quick-chat/bot-details/:id?',
      element: <BotDetails />,
    },
    {
      path: 'quick-chat/chat/:id?',
      element: <ChatInbox />,
    },
  ],
};

export default QuickChatConfigs;
