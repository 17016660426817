import Plan from './Plan';
import ManageAddon from './ManageAddon';
import SelectPlan from './selectPlan/SelectPlan';
import OffersAndPricing from './OffersAndPricing';

const planConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'your-plan/:id?',
      element: <Plan />,
    },
    {
      path: 'manage-plan/:id?',
      element: <ManageAddon />,
    },
    {
      path: 'offer-pricing/:id?',
      element: <OffersAndPricing />,
    },
    {
      path: 'selectProduct/:id?',
      element: <SelectPlan />,
    },
  ],
};

export default planConfig;
