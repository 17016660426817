import { Button, CircularProgress, Dialog, DialogActions, Icon, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useSnackbar } from 'notistack';
import selectedBusinessData from '../../query/selectedBusiness';
import { handleApiRequest } from '../../common/common';

const QrCodePopup = (props) => {
  const { openValue, value, cancelCall, productName } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [copy, setCopy] = useState(false);
  const [businessQrImg, setBusinessQrImg] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const handleDownload = () => {
    window.open(businessQrImg, '_blank');
  };

  useEffect(() => {
    try {
      const fetchQrCode = async () => {
        const payload = {
          query: selectedBusinessData.createQRCodePdfWithInfo,
          variables: {
            businessQRId: value?.split('/').pop(),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.createQRCodePdfWithInfo?.status === 200) {
          setBusinessQrImg(result?.createQRCodePdfWithInfo?.data?.pdf);
        } else {
          enqueueSnackbar(result?.createQRCodePdfWithInfo?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      };
      if (openValue && value?.split('/').pop()) {
        fetchQrCode();
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [enqueueSnackbar, openValue, value]);

  const handleCopyClick = async () => {
    navigator.clipboard.writeText('').then(() => {
      setCopy(true);
      return navigator.clipboard.writeText(value);
    });
    await setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <Dialog
      classes={{
        paper:
          'm-24 sm:p-28 p-10 max-w-640 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      fullWidth
      maxWidth="xs"
    >
      <Icon
        onClick={() => cancelCall()}
        className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
      >
        cancel
      </Icon>
      <Typography className=" text-16 font-600">{t('qrCode.title')}</Typography>
      <div className="px-3">
        <hr
          className="w-full border-t-2 border-dashed border-gray-500 my-24"
          style={{ borderStyle: 'dashed' }}
        />
      </div>
      <div className="w-full m-auto mt-12">
        <div className="bg-white flex flex-col gap-12">
          <div className="bg-cover w-288 sm:w-400 h-auto m-auto bg-no-repeat rounded-6 bg-[url('https://quick-review.s3.amazonaws.com/qr-code/qrcode-bg.png')]">
            <div className="border-2 border-white pt-16 w-3/4 rounded-br-24 rounded-tl-6 rounded-t-none border-t-0 border-l-0 bg-quick-review" />
            <div className="text-center">
              <img
                className="pt-40 w-136 m-auto"
                src="/assets/images/business/logo.svg"
                alt="PDF Page"
              />
              <Typography className="text-gray-A600 text-16 pt-14 tracking-widest font-medium">
                {t('qrCode.reviewMessage')} <br /> {t('qrCode.us')}{' '}
                <span className="font-bold text-white text-24">{t('qrCode.review')}</span>
              </Typography>
              <div className="text-center flex justify-center items-center m-auto bg-white max-w-208 max-h-208 p-14 rounded-md my-12">
                <QRCode value={value} className="w-auto" />
              </div>
              <div className="flex gap-8 pt-16 pb-14 justify-center">
                {[...Array(5)].map((_, i) => (
                  <img className="w-20" src="/assets/images/icon/simple-star.svg" alt="" />
                ))}
              </div>
              <Typography className="text-white font-bold text-14 px-10 truncate">
                {productName}
              </Typography>

              <Typography className="w-full text-center py-20 z-99 mt-12 bg-[url('https://quick-review.s3.amazonaws.com/qr-code/qrcode-name.png')]">
                Effortlessly manage your review <br /> with quick reviews.
              </Typography>
            </div>
            <div className="ml-auto border-2 border-white mt-[17px] border-b-0 pb-16 border-r-0 rounded-br-6 rounded-tl-24 rounded-tr-none rounded-b-none w-3/4 bg-quick-review bottom-0 right-0" />
          </div>
        </div>
      </div>
      <DialogActions className="w-full mt-20 flex justify-center">
        <div className="w-5/6 flex justify-evenly">
          <Button
            variant="outlined"
            className="rounded-md px-20"
            color="secondary"
            size="small"
            type="button"
            onClick={() => handleCopyClick('domain')}
            disabled={!userCurrentBusinessDetails?.id || !userCurrentBusinessDetails?.businessQRId}
          >
            <ContentCopyRoundedIcon className="text-15 mr-8" /> {t('partners.action.copyLink')}
            {!userCurrentBusinessDetails?.businessQRId && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
            {copy && (
              <span className="absolute bg-black text-white text-12 px-8 rounded -top-0 duration-300 animate-bounce">
                {t('business.selectedBusinessDetail.labels.copied')}
              </span>
            )}
          </Button>
          <Button
            variant="contained"
            className="rounded-md px-20"
            color="secondary"
            size="small"
            type="button"
            disabled={!userCurrentBusinessDetails?.id || !businessQrImg}
            onClick={() => handleDownload()}
          >
            <FileDownloadOutlinedIcon className="mr-4 text-24" />
            {t('qrCode.button.downloadQrCode')}
            {!businessQrImg && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodePopup;
