import { useState } from 'react';

const ReviewSentiment = ({ reviewSentiment, sentimentId, onSelectSentiment, dashboard }) => {
  const [reviewSentimentId, setReviewSentimentId] = useState(sentimentId[0]);

  const filteredReviewSentiment = reviewSentiment?.filter((reviewSentimentPeriod) =>
    sentimentId?.includes(reviewSentimentPeriod.id)
  );

  const handleSentimentClick = (id) => {
    setReviewSentimentId(id);
    if (onSelectSentiment) {
      onSelectSentiment(id);
    }
  };

  return (
    <div className="mb-16">
      {filteredReviewSentiment &&
        filteredReviewSentiment?.length > 0 &&
        filteredReviewSentiment?.map((reviewSentimentPeriod, i) => (
          <li
            className="inline-block mr-12 cursor-pointer"
            onClick={() => handleSentimentClick(reviewSentimentPeriod?.id)}
            aria-hidden="true"
            key={i}
          >
            <span
              className={`rounded-full inline-block border-1 border-solid border-grey-500 px-20 py-4 capitalize text-12  ${
                reviewSentimentId === reviewSentimentPeriod?.id
                  ? 'bg-quick-review-100 font-bold'
                  : 'bg-white font-medium'
              }`}
            >
              {dashboard
                ? reviewSentimentPeriod?.name === 'Weekly'
                  ? '1 Week'
                  : '1 Month'
                : reviewSentimentPeriod?.name}
            </span>
          </li>
        ))}
    </div>
  );
};

export default ReviewSentiment;
