import { useEffect, useState } from 'react';
import {
  TableCell,
  Typography,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  TableSortLabel,
  Table,
  Button,
  TextField,
} from '@mui/material';
import Delete from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/EditOutlined';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import adminData from '../../query/admin';
import { capitalizeFirstLetter, getDataToServer, handleApiRequest } from '../../common/common';

const ComparePlanAndFeature = () => {
  const [featureData, setFeatureData] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [planData, setPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addOrEditData, setAddOrEditData] = useState(false);
  const [isEditId, setIsEditId] = useState('');
  const [editingRow, setEditingRow] = useState({
    index: null,
    id: null,
  });
  const [featureValue, setFeatureValue] = useState('');
  const [information, setInformation] = useState('');
  const [indexValue, setIndexValue] = useState();
  const [inputData, setInputData] = useState([
    {
      packageTypeId: 0,
      value: '',
    },
  ]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const payload = {
        query: adminData.getPlansAndFeaturesData,
        variables: {
          pageNumber: 1,
          pageSize: 50,
        },
      };
      const res = await getDataToServer(payload);
      setLoading(false);
      if (res?.getPlansAndFeaturesData?.status === 200) {
        const data = res?.getPlansAndFeaturesData?.data?.heading.filter(
          (item) => item.planName !== 'Free Trial'
        );
        setFeatureData(res?.getPlansAndFeaturesData?.data?.featuresData);
        setPlanData(data);
      }
    }
    fetchData();
  }, []);

  const handleAddAnother = () => {
    setAddOrEditData(true);
    setFeatureData([...featureData, {}]);
  };

  function handleCancelAdd() {
    setFeatureValue('');
    setInformation('');
    setIsEditId('');
    setIndexValue();
    setEditingRow({});
    setInputData([]);
    setAddOrEditData(false);
    const newArray = [...featureData];
    newArray.pop();
    setFeatureData(newArray);
  }

  const handleInputChange = (j, event) => {
    const findPackageTypeId = planData?.slice().sort((a, b) => a.packageTypeId - b.packageTypeId);
    const values = [...inputData];
    values[j] = {
      ...values[j],
      packageTypeId: parseInt(findPackageTypeId[j].packageTypeId, 10),
      value: event.target.value,
    };
    setInputData(values);
  };

  const handleSubmit = async () => {
    if ((featureValue?.trim() && information?.trim()) || inputData?.length > 1) {
      const findPackageTypeId = planData?.slice().sort((a, b) => a.packageTypeId - b.packageTypeId);

      const existingPackageTypeIds = new Set(
        inputData.filter((item) => item !== undefined).map((item) => item.packageTypeId)
      );
      const resultArray = findPackageTypeId.map((item) => {
        if (!existingPackageTypeIds.has(item.packageTypeId)) {
          return { packageTypeId: item.packageTypeId, value: '' };
        }
        const existingItem = inputData.find(
          (inputItem) => inputItem?.packageTypeId === item.packageTypeId
        );
        return existingItem || null;
      });

      const isValuePresent = resultArray.every((item) => !item.value);

      if (isValuePresent) {
        enqueueSnackbar(t('comparePlanAndFeature.validationMessage.selectPackage'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else {
        const payload = {
          query: adminData.addPlanAndFeatureByAdmin,
          variables: {
            featureName: featureValue?.trim(),
            description: information?.trim(),
            index: parseInt(indexValue, 10),
            input: resultArray,
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.addPlanAndFeatureByAdmin?.status === 200) {
          enqueueSnackbar(res.addPlanAndFeatureByAdmin?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          setFeatureValue('');
          setInformation('');
          setIndexValue();
          setInputData([]);
          await setAddOrEditData(false);
          const obj = {
            query: adminData.getPlansAndFeaturesData,
            variables: {
              pageNumber: 1,
              pageSize: 50,
            },
          };
          const response = await getDataToServer(obj);
          if (response?.getPlansAndFeaturesData?.status === 200) {
            const data = response?.getPlansAndFeaturesData?.data?.heading.filter(
              (item) => item.planName !== 'Free Trial'
            );
            await setFeatureData(response?.getPlansAndFeaturesData?.data?.featuresData);
            await setPlanData(data);
          }
        } else {
          enqueueSnackbar(res.addPlanAndFeatureByAdmin?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } else {
      enqueueSnackbar(t('comparePlanAndFeature.validationMessage.featureName'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const deletePlanAndFeature = async (feature) => {
    const packageTypeId = feature.value.map((item) => {
      return { packageTypeId: parseInt(item.packageTypeId, 10) };
    });
    const payload = {
      query: adminData.deletePlanAndFeatureByAdmin,
      variables: {
        packagesFeatureId: parseInt(feature.id, 10),
        input: packageTypeId,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.deletePlanAndFeatureByAdmin?.status === 200) {
      enqueueSnackbar(res.deletePlanAndFeatureByAdmin?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      await setAddOrEditData(false);
      const obj = {
        query: adminData.getPlansAndFeaturesData,
        variables: {
          pageNumber: 1,
          pageSize: 50,
        },
      };
      const response = await getDataToServer(obj);
      if (response?.getPlansAndFeaturesData?.status === 200) {
        const data = response?.getPlansAndFeaturesData?.data?.heading.filter(
          (item) => item.planName !== 'Free Trial'
        );
        await setFeatureData(response?.getPlansAndFeaturesData?.data?.featuresData);
        await setPlanData(data);
      }
    } else {
      enqueueSnackbar(res.deletePlanAndFeatureByAdmin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const editPlanAndFeature = async (feature, i) => {
    const hasBlankObject = featureData.some((obj) => Object.keys(obj).length === 0);

    if (hasBlankObject) {
      const newArray = featureData.filter((obj) => Object.keys(obj).length !== 0);
      setFeatureData(newArray);
    }
    await setAddOrEditData(false);
    await setIsEditId(feature.id);
    await setEditingRow({
      index: i,
      id: feature.id,
    });
    const findPackageTypeId = planData?.slice().sort((a, b) => a.packageTypeId - b.packageTypeId);
    const data = findPackageTypeId.map((item) => {
      return {
        packageTypeId: item.packageTypeId,
      };
    });
    const featureMap = new Map(feature.value.map((item) => [item.packageTypeId, item.value]));
    const updatedData = data.map((item) => ({
      ...item,
      value: featureMap.get(item.packageTypeId) || null,
    }));
    await setInputData(updatedData);
    await setFeatureValue(feature.featureName);
    await setIndexValue(feature.index);
    await setInformation(feature.description);
  };

  const submit = async () => {
    const payload = {
      query: adminData.updatePlanAndFeatureByAdmin,
      variables: {
        featureName: featureValue,
        description: information,
        packagesFeatureId: isEditId,
        index: parseInt(indexValue, 10),
        input: inputData,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.updatePlanAndFeatureByAdmin?.status === 200) {
      await setFeatureValue('');
      await setInformation('');
      await setEditingRow({});
      await setInputData([]);
      await setAddOrEditData(false);
      await setIndexValue();
      await setIsEditId('');
      enqueueSnackbar(res.updatePlanAndFeatureByAdmin?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const obj = {
        query: adminData.getPlansAndFeaturesData,
        variables: {
          pageNumber: 1,
          pageSize: 50,
        },
      };
      const response = await getDataToServer(obj);
      if (response?.getPlansAndFeaturesData?.status === 200) {
        const filterData = response?.getPlansAndFeaturesData?.data?.heading.filter(
          (item) => item.planName !== 'Free Trial'
        );
        await setFeatureData(response?.getPlansAndFeaturesData?.data?.featuresData);
        await setPlanData(filterData);
      }
    }
  };

  const handleCancel = () => {
    setFeatureValue('');
    setInformation('');
    setIsEditId('');
    setIndexValue();
    setEditingRow({});
    setInputData([]);
  };

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-32">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('comparePlanAndFeature.comparePlanAndFeatureList.title')}
          </Typography>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="medium">
            <TableHead>
              <TableCell className="text-16 w-[10%] h-12 border-b-1 border-r-1 border-grey-300 font-medium">
                <TableSortLabel hideSortIcon>
                  {t('comparePlanAndFeature.comparePlanAndFeatureList.column.srNo')}
                </TableSortLabel>
              </TableCell>
              <TableCell className="text-16 w-[15%] h-12 border-b-1 border-r-1 border-grey-300 font-medium">
                <TableSortLabel hideSortIcon>
                  {t('comparePlanAndFeature.comparePlanAndFeatureList.column.features')}
                </TableSortLabel>
              </TableCell>
              <TableCell className="text-16 w-[20%] h-12 border-b-1 border-r-1 border-grey-300 font-medium">
                <TableSortLabel hideSortIcon>
                  {t('comparePlanAndFeature.comparePlanAndFeatureList.column.information')}
                </TableSortLabel>
              </TableCell>
              {planData
                ?.slice()
                .sort((a, b) => a.packageTypeId - b.packageTypeId)
                .map((item, i) => {
                  return (
                    <TableCell
                      key={i}
                      className="text-16 w-[10%] h-12 border-b-1 border-r-1 border-grey-300 font-medium"
                    >
                      <TableSortLabel hideSortIcon>{item?.planName}</TableSortLabel>
                    </TableCell>
                  );
                })}
              <TableCell className="border-b-1 w-[10%] h-12 border-r-1 border-grey-300">
                <TableSortLabel hideSortIcon>
                  {t('comparePlanAndFeature.comparePlanAndFeatureList.column.action')}
                </TableSortLabel>
              </TableCell>
            </TableHead>
            <TableBody>
              {featureData?.map((feature, i) => (
                <TableRow key={i}>
                  <TableCell className="text-black text-16 border-b-1 border-r-1 border-grey-300 font-bold">
                    {feature?.featureName && i !== editingRow.index
                      ? feature.index
                      : (!feature?.featureName || i === editingRow.index) && (
                          <TextField
                            className="w-full bg-[#DCDCDC]"
                            placeholder="Enter Here"
                            type="number"
                            value={indexValue}
                            onChange={(event) => setIndexValue(event.target.value)}
                          />
                        )}
                  </TableCell>
                  <TableCell className="text-black text-16 border-b-1 border-r-1 border-grey-300 font-bold">
                    {feature?.featureName && i !== editingRow.index
                      ? feature.featureName
                          .split(' ')
                          .map((word) => capitalizeFirstLetter(word))
                          .join(' ')
                      : (!feature?.featureName || i === editingRow.index) && (
                          <TextField
                            className="w-full bg-[#DCDCDC]"
                            placeholder="Enter Here"
                            value={featureValue}
                            onChange={(event) => setFeatureValue(event.target.value)}
                          />
                        )}
                  </TableCell>
                  <TableCell className="text-black text-16 border-b-1 border-r-1 border-grey-300 font-bold">
                    {feature?.featureName && i !== editingRow.index
                      ? feature.description
                      : (!feature?.featureName || i === editingRow.index) && (
                          <TextField
                            className="w-full bg-[#DCDCDC]"
                            placeholder="Enter Here"
                            value={information}
                            onChange={(event) => setInformation(event.target.value)}
                          />
                        )}
                  </TableCell>
                  {planData
                    ?.slice()
                    .sort((a, b) => a.packageTypeId - b.packageTypeId)
                    .map((plan, j) => {
                      const valueForPlan = feature?.value?.find(
                        (value) => value.packageTypeId === plan.packageTypeId
                      );
                      return (
                        <TableCell
                          key={j}
                          className="text-black text-16 border-b-1 border-r-1 border-grey-300 font-bold"
                        >
                          {feature?.featureName && i !== editingRow.index ? (
                            valueForPlan?.value ? (
                              valueForPlan?.value
                            ) : (
                              <span> - </span>
                            )
                          ) : (
                            (!feature?.featureName || i === editingRow.index) && (
                              <TextField
                                className="w-full bg-[#DCDCDC]"
                                placeholder="Enter Here"
                                onChange={(event) => handleInputChange(j, event)}
                                value={inputData?.[j]?.value || ''}
                              />
                            )
                          )}
                        </TableCell>
                      );
                    })}
                  <TableCell className="text-black text-16 border-b-1 border-r-1 border-grey-300 font-bold">
                    {feature?.featureName && i !== editingRow.index ? (
                      <div className="flex items-center">
                        <Button type="button" onClick={() => editPlanAndFeature(feature, i)}>
                          <Edit />
                        </Button>
                        <Button type="button" onClick={() => deletePlanAndFeature(feature)}>
                          <Delete />
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="text-left mt-12">
        {!isEditId && (
          <>
            {addOrEditData ? (
              <div className="mb-4 mt-24">
                <Button
                  className="w-[10%] bg-darkgreen rounded-md text-white md:text-18 font-medium hover:bg-darkgreen focus:ring focus:ring-darkgreen focus:ring-opacity-50"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t('comparePlanAndFeature.button.save')}
                </Button>

                <Button
                  className="bg-white rounded-md ml-8 border border-solid !border-[#707070] px-40 py-4 text-[#707070] md:text-18 font-medium hover:bg-white focus:ring focus:ring-darkgreen focus:ring-opacity-50"
                  onClick={() => handleCancelAdd()}
                >
                  cancel
                </Button>
              </div>
            ) : (
              <Button
                className="w-[10%] bg-darkgreen mt-24 rounded-md text-white md:text-18 font-medium hover:bg-darkgreen focus:ring focus:ring-darkgreen focus:ring-opacity-50"
                onClick={handleAddAnother}
              >
                {t('comparePlanAndFeature.button.addAnother')}
              </Button>
            )}
          </>
        )}
        <div>
          {isEditId && (
            <div className="mt-24">
              <button
                className="bg-white rounded-md mr-8 border border-[#707070] px-40 py-4 text-[#707070] md:text-18 font-medium hover:bg-white focus:ring focus:ring-darkgreen focus:ring-opacity-50"
                type="button"
                onClick={() => handleCancel()}
              >
                {t('comparePlanAndFeature.button.cancel')}
              </button>

              <button
                className="bg-darkgreen rounded-md px-52 py-6 text-white md:text-18 font-medium hover:bg-darkgreen focus:ring focus:ring-darkgreen focus:ring-opacity-50"
                type="button"
                onClick={() => submit()}
              >
                {t('comparePlanAndFeature.button.save')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComparePlanAndFeature;
