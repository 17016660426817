import UserNumber from './UserNumber';

const UserNumbersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/userNumber/:id?',
      element: <UserNumber />,
    },
  ],
};

export default UserNumbersConfig;
