import ReviewScreenMode from './ReviewScreenMode';

const ReviewScreenModeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/reviewScreenMode/:id?',
      element: <ReviewScreenMode />,
    },
  ],
};

export default ReviewScreenModeConfig;
