import Faq from './faq/Faq';
import BusinessCompetitors from './businessCompetitors/BusinessComepetitors';
import ScanHistory from './scanHIstory.js/ScanHistory';
import ScanHistoryDetails from './scanHIstory.js/ScanHistoryDetails';

const GeoGridConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/businessCompetitor/:id?',
      element: <BusinessCompetitors />,
    },
    {
      path: 'quick-reviews/scanHistory/:id?',
      element: <ScanHistory />,
    },
    {
      path: 'quick-reviews/scanHistoryDetails/:id?',
      element: <ScanHistoryDetails />,
    },
    {
      path: 'quick-reviews/faq/:id?',
      element: <Faq />,
    },
  ],
};

export default GeoGridConfigs;
