import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Grid, Button, Icon, Slider as MSlider } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest, getParamsId } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import SharePost from './SharePost';

const PrevArrow = ({ onClick }) => (
  <Button
    className="absolute left-10 top-1/2 -translate-y-1/2 bg-opacity-50 rounded-full flex justify-center items-center"
    style={{ zIndex: 1 }}
    onClick={onClick}
    aria-label="Previous slide"
  >
    <span className="text-28 w-40 pb-3 pr-3 h-40 flex justify-center items-center rounded-full text-white bg-darkgreen">
      <ArrowBackIosNewIcon />
    </span>
  </Button>
);

const NextArrow = ({ onClick }) => (
  <Button
    className="absolute right-10 top-1/2 transform -translate-y-1/2 bg-opacity-50 rounded-full flex justify-center items-center"
    style={{ zIndex: 1 }}
    onClick={onClick}
    aria-label="Next slide"
  >
    <span className="text-28 w-40 pb-3 pl-3 h-40 flex justify-center items-center rounded-full text-white bg-darkgreen">
      <ArrowForwardIosIcon />
    </span>
  </Button>
);

const CreatePost = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const navigate = useNavigate();
  const [createPostDetail, setCreatePostDetail] = useState('');
  const [testimonials, setTestimonials] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [avialabaleTempImage, setAvialabaleTempImage] = useState([]);
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const { postData, additionalApiData } = location.state;
  const isFromNotification = additionalApiData === 'share_post';

  let htmlTemplate;
  let review;
  let reviewPlatformTypeId;
  if (isFromNotification) {
    ({ htmlTemplate, review, reviewPlatformTypeId } = postData);
  }

  const [imgTempValue, setImgTempVal] = useState(
    isFromNotification ? htmlTemplate || '' : location?.state?.htmlTemplate || ''
  );

  const [shareImage, setShareImage] = useState(
    location?.state?.sharePostImage ? location?.state?.sharePostImage : ''
  );
  const [postJsonData, setPostJsonData] = useState(
    isFromNotification
      ? postData
      : location?.state?.postJsonData
      ? JSON.parse(location?.state?.postJsonData)
      : ''
  );

  const [suggestedText, setSuggestedText] = useState(
    postJsonData?.review ? postJsonData?.review : isFromNotification ? review : ''
  );

  const handleBackClick = () => {
    const id = getParamsId();
    if (id) {
      navigate(`/quick-post/${id}`, { state: true });
    } else {
      navigate('/quick-post/', { state: true });
    }
  };

  useEffect(() => {
    if (!userCurrentBusinessDetails?.id) {
      const id = getParamsId();
      if (id) {
        navigate(`/quick-post/${id}`, { state: true });
      } else {
        navigate('/quick-post/', { state: true });
      }
    }
  }, [userCurrentBusinessDetails, navigate]);

  const convertedSharedPostImage = useCallback(
    async (templateImageValue, suggestedTextVal) => {
      try {
        setLoadingPage(true);
        const payloadData = {
          query: quickPostQuery.getConvertedSharePostImage,
          variables: {
            businessName: postJsonData?.businessName,
            htmlTemplate: encodeURIComponent(
              isFromNotification ? templateImageValue ?? htmlTemplate : templateImageValue
            ),
            reviewPlatformTypeId: isFromNotification
              ? reviewPlatformTypeId
              : postJsonData?.platformId,
            reviewRating: postJsonData?.reviewRating,
            reviewerName: postJsonData?.reviewerName,
            review: isFromNotification
              ? suggestedTextVal ?? review
              : suggestedTextVal ?? postJsonData?.review,
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoadingPage(false);
        if (resultData?.getConvertedSharePostImage?.status === 200) {
          setCreatePostDetail(resultData?.getConvertedSharePostImage?.data?.socialPostUrl);
        }
      } catch (error) {
        setLoadingPage(false);
        console.error('Error fetching data:', error);
      }
    },
    [
      htmlTemplate,
      isFromNotification,
      postJsonData?.businessName,
      postJsonData?.platformId,
      postJsonData?.review,
      postJsonData?.reviewRating,
      postJsonData?.reviewerName,
      review,
      reviewPlatformTypeId,
    ]
  );
  useEffect(() => {
    if (imgTempValue) {
      convertedSharedPostImage(imgTempValue, suggestedText);
    }
  }, [imgTempValue, suggestedText, convertedSharedPostImage]);

  useEffect(() => {
    if (isFromNotification) {
      setPostJsonData(postData);
    }
  }, [postData, isFromNotification]);

  const changeImg = async (img) => {
    setImgTempVal(img?.template);
    setShareImage(img?.templateImage);
    setLoadingPage(true);
  };

  const regenerateText = async () => {
    try {
      setLoadingPage(true);
      const getRandomToken = () => {
        const tokens = ['small', 'medium', 'large'];
        const randomIndex = Math.floor(Math.random() * tokens.length);
        return tokens[randomIndex];
      };

      const payloadData = {
        query: quickPostQuery.sharePostGenerateSummarizeReviews,
        variables: {
          review: postJsonData?.review ? postJsonData?.review : postJsonData?.text.review,
          tokens: getRandomToken(),
        },
      };

      const resultData = await handleApiRequest(payloadData);
      setLoadingPage(false);
      if (resultData?.sharePostGenerateSummarizeReviews?.status === 200) {
        setSuggestedText(resultData?.sharePostGenerateSummarizeReviews?.data?.summarizeReviews);
      }
    } catch (error) {
      setLoadingPage(false);
      console.error('Error fetching data:', error);
    }
  };
  const handleShare = async () => {
    setShareCompOpen(true);
    const payloadData = {
      query: quickPostQuery.saveViewedPosts,
      variables: {
        userBusinessesId: parseInt(userCurrentBusinessDetails?.id, 10),
        sharePostTemplateId: postJsonData?.sharePostTemplateId,
        reviewText: {
          review: postJsonData?.review,
          reviewPlatformTypeId: postJsonData?.platformId,
          reviewRating: postJsonData?.reviewRating,
          reviewerName: postJsonData?.reviewerName,
        },
      },
    };
    const result = await handleApiRequest(payloadData);
  };
  const handaleClose = () => {
    setShareCompOpen(false);
  };

  const handleDownload = async () => {
    if (createPostDetail) {
      const payloadData = {
        query: quickPostQuery.saveViewedPosts,
        variables: {
          userBusinessesId: parseInt(userCurrentBusinessDetails?.id, 10),
          sharePostTemplateId: postJsonData?.sharePostTemplateId,
          reviewText: {
            review: suggestedText,
            reviewPlatformTypeId: postJsonData?.platformId,
            reviewRating: postJsonData?.reviewRating,
            reviewerName: postJsonData?.reviewerName,
          },
        },
      };
      const result = await handleApiRequest(payloadData);
      const link = document.createElement('a');
      link.href = createPostDetail;
      link.download = 'post_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const similarPost = useCallback(async () => {
    try {
      const payloadData = {
        query: quickPostQuery.getSharePostTemplate,
        variables: {
          pageNumber: 1,
          pageSize: 15,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          rating: 5,
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (resultData?.getSharePostTemplate?.status === 200) {
        setAvialabaleTempImage(resultData?.getSharePostTemplate?.data);
      }
    } catch (error) {
      setLoadingPage(false);
      console.error('Error fetching data:', error);
    } finally {
      console.log('Finally blocked executed');
    }
  }, [userCurrentBusinessDetails]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      similarPost();
    }
  }, [userCurrentBusinessDetails?.id, similarPost]);

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}

      <div className="sm:flex w-full items-center justify-between mb-36">
        <Typography className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0">
          <Icon className="text-20 cursor-pointer mr-14 mt-10" onClick={handleBackClick}>
            west
          </Icon>
          {t('createPost.title')}
        </Typography>

        <div>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            className="md:text-16 font-medium rounded-md me-14"
            onClick={handleDownload}
          >
            {t('createPost.downloadBtn')}
          </Button>

          <Button
            className="bg-darkgreen rounded-md text-white px-20 hover:bg-darkgreen "
            onClick={handleShare}
          >
            {t('createPost.shareBtn')}
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-md relative shadow md:p-24 sm:p-16">
        <div className="grid lg:grid-cols-2 grid-cols-1">
          <div className="space-y-6 bg-gray-100 p-16 rounded-md lg:w-3/5 flex flex-col justify-evenly">
            <Typography className=" text-lg font-quicksand text-black capitalize font-bold">
              {t('createPost.suggested')}
            </Typography>

            <div className="bg-white rounded-md relative shadow md:p-24 flex flex-col justify-between sm:p-16 mt-4 h-256">
              <Typography>{suggestedText}</Typography>
              <Button
                className="bg-darkgreen rounded-md text-white px-20 hover:bg-darkgreen w-full"
                onClick={regenerateText}
              >
                <svg
                  className="w-5 h-5 mx-1"
                  style={{ width: '1em', height: '1em' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="mx-5">{t('createPost.textBtn')} </span>
              </Button>
            </div>

            <div className="bg-white rounded-md relative shadow md:p-24 sm:p-16 mt-4 h-256">
              <Typography className=" text-lg font-quicksand text-black capitalize font-bold">
                {t('createPost.originalReview')}
              </Typography>
              <hr className="text-grey mt-10" />
              <Typography className="mt-10">{postJsonData?.review}</Typography>
            </div>
          </div>

          <div className="flex items-center justify-center lg:w-4/5">
            {createPostDetail && (
              <img
                src={createPostDetail}
                className=" rounded-md shadow-md object-cover"
                alt="Preview"
              />
            )}
          </div>
        </div>

        <div className="relative">
          <Typography className="font-bold text-20 my-20">
            {t('createPost.similarTemplate')}
          </Typography>
          <div className="relative">
            <Slider {...settings}>
              {avialabaleTempImage?.map((img, index) => (
                <div key={index} className="relative group cursor-pointer p-14">
                  <div
                    className="w-full h-full p-0 cursor-pointer"
                    onClick={() => changeImg(img)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        changeImg(img);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    aria-label={`Preview ${index}`}
                  >
                    <img
                      src={img?.templateImage}
                      alt={`Preview ${index}`}
                      className="w-full h-320 sm:h-288 md:h-224 lg:h-288 object-cover rounded"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      {shareCompOpen && (
        <SharePost
          url={createPostDetail}
          title={t('createPost.checkThisPost')}
          image={shareImage}
          open={shareCompOpen}
          onClose={handaleClose}
        />
      )}
    </div>
  );
};

export default CreatePost;
