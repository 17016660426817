import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import * as React from 'react';
import Switch from '@mui/material/Switch';
import businessData from '../../query/business';
import {
  getAllBusinessType,
  addBusinessType,
  resetBusiness,
  updateBusinessType,
  openNewBusinessDialog,
  closeNewBusinessDialog,
  openEditBusinessDialog,
  closeEditBusinessDialog,
  enableDisableBusinessType,
} from './store/businessSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const defaultValues = {
  bType: '',
};

function BusinessType() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    bType: yup.string().required(t('business.businessType.validationMessage.required')),
  });

  const businessTypeData = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessTypeData?.data?.getAllBusinessType?.data || ''
  );

  const addBusinessTypeStatus = useSelector(
    ({ businessReducer }) => businessReducer?.business?.addBusinessTypeData?.data?.addBusinessType
  );

  const updateBusinessTypeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.updateBusinessTypeData?.data?.updateBusinessType
  );

  const enableDisableBusinessTypeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.enableDisableBusinessTypeData?.data?.enableDisableBusinessType
  );

  const totalBusinessType = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessTypeData?.data?.getAllBusinessType?.totalCount || ''
  );

  const appDialog = useSelector(({ businessReducer }) => businessReducer.business.businessDialog);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('all');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [actionValue, setActionValue] = useState('action');
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [businessTypeList, setBusinessTypeList] = useState(businessTypeData);
  const [refreshIcon, setrefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = businessTypeData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (appDialog.type === 'edit' && appDialog.data) {
      reset({
        ...appDialog.data,
        name: appDialog.data.bType,
      });
    }

    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...appDialog.data,
      });
    }
  }, [appDialog.data, appDialog.type, reset]);

  /**
	/**
	 * On Dialog Open
	 */
  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
    }
  }, [appDialog.props.open, dispatch, initDialog]);

  useEffect(() => {
    let unmounted = false;

    const payload = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: 'id',
        sortOrder: 'desc',
      },
    };
    dispatch(getAllBusinessType(payload));

    return () => {
      unmounted = true;
      dispatch(resetBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    if (businessTypeData && businessTypeData.length) {
      setBusinessTypeList(businessTypeData);
    } else {
      setBusinessTypeList();
    }
  }, [businessTypeData]);

  useEffect(() => {
    if (businessTypeData || businessTypeData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [businessTypeData]);

  useEffect(() => {
    if (addBusinessTypeStatus && addBusinessTypeStatus.status === 400) {
      enqueueSnackbar(addBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      reset(defaultValues);
    } else if (addBusinessTypeStatus && addBusinessTypeStatus.status === 200) {
      enqueueSnackbar(addBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      reset(defaultValues);
    }
  }, [reset, dispatch, addBusinessTypeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (enableDisableBusinessTypeStatus && enableDisableBusinessTypeStatus.status === 400) {
      enqueueSnackbar(enableDisableBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (enableDisableBusinessTypeStatus && enableDisableBusinessTypeStatus.status === 200) {
      enqueueSnackbar(enableDisableBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [enableDisableBusinessTypeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateBusinessTypeStatus && updateBusinessTypeStatus.status === 400) {
      enqueueSnackbar(updateBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      reset(defaultValues);
    } else if (updateBusinessTypeStatus && updateBusinessTypeStatus.status === 200) {
      enqueueSnackbar(updateBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      reset(defaultValues);
    }
  }, [reset, dispatch, updateBusinessTypeStatus, enqueueSnackbar, closeSnackbar]);

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    let active = {};
    if (status === 'true') {
      active = {
        isActive: true,
      };
    }
    if (status === 'false') {
      active = {
        isActive: false,
      };
    }
    const payload = {
      query: businessData.getAllBusinessType,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        ...active,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    await dispatch(getAllBusinessType(payload));
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const handleSorting = (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...businessTypeList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setBusinessTypeList(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...businessTypeList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setBusinessTypeList(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }
    let active = {};
    if (status === 'true') {
      active = {
        isActive: true,
      };
    }
    if (status === 'false') {
      active = {
        isActive: false,
      };
    }
    const payload = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: page,
        ...active,
        searchText: '',
        sortBy: sortColumnName,
        sortOrder: order,
      },
    };
    dispatch(getAllBusinessType(payload));
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    let active = {};
    if (status === 'true') {
      active = {
        isActive: true,
      };
    }
    if (status === 'false') {
      active = {
        isActive: false,
      };
    }
    const payload = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        ...active,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessType(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    let active = {};
    if (status === 'true') {
      active = {
        isActive: true,
      };
    }
    if (status === 'false') {
      active = {
        isActive: false,
      };
    }
    const payload = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        ...active,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessType(payload));
      setLoadingPage(false);
    });
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all Business Type',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('bType');
              }}
            >
              {t('business.businessType.column.businessTypeName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('business.businessType.column.createdDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('business.businessType.column.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('business.businessType.column.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    return appDialog.type === 'edit'
      ? dispatch(closeEditBusinessDialog())
      : dispatch(closeNewBusinessDialog());
  }

  /**
   * Remove Event
   */
  function handleRemove() {
    closeComposeDialog();
    reset(defaultValues);
  }

  function handleActiveInactive(id, currentStatus) {
    const payload = {
      query: businessData.enableDisableBusinessType,
      variables: { businessId: id, isEnable: !currentStatus },
    };
    dispatch(enableDisableBusinessType(payload));
    let active = {};
    if (status === 'true') {
      active = {
        isActive: true,
      };
    }
    if (status === 'false') {
      active = {
        isActive: false,
      };
    }
    const payloadData = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: page,
        searchText: searchValue,
        sortBy: orderBy,
        ...active,
        sortOrder: orderNow,
      },
    };
    setTimeout(() => {
      dispatch(getAllBusinessType(payloadData));
    }, 800);
  }

  function onSubmit(submitData) {
    setrefreshIcon(true);
    if (appDialog.type === 'new') {
      const payload = {
        query: businessData.addBusinessType,
        variables: { businessType: submitData.bType },
      };
      dispatch(addBusinessType(payload));
      let active = {};
      if (status === 'true') {
        active = {
          isActive: true,
        };
      }
      if (status === 'false') {
        active = {
          isActive: false,
        };
      }
      const payloadData = {
        query: businessData.getAllBusinessType,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          ...active,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
      setTimeout(() => {
        dispatch(getAllBusinessType(payloadData));
      }, 800);
    } else {
      const payload = {
        query: businessData.updateBusinessType,
        variables: { businessType: submitData.bType, businessTypeId: parseInt(submitData.id, 10) },
      };
      dispatch(updateBusinessType(payload));
      let active = {};
      if (status === 'true') {
        active = {
          isActive: true,
        };
      }
      if (status === 'false') {
        active = {
          isActive: false,
        };
      }
      const payloadData = {
        query: businessData.getAllBusinessType,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          ...active,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
      setTimeout(() => {
        dispatch(getAllBusinessType(payloadData));
      }, 800);
    }
  }

  function handleStatusDropdownChange(event) {
    const statusValue = event.target.value;
    setStatus(statusValue);
    setLoading(true);
    setPage(1);
    let active = {};
    if (statusValue === 'true') {
      active = {
        isActive: true,
      };
    }
    if (statusValue === 'false') {
      active = {
        isActive: false,
      };
    }
    const payloadData = {
      query: businessData.getAllBusinessType,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        ...active,
        sortOrder: orderNow,
      },
    };
    dispatch(getAllBusinessType(payloadData));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('business.businessType.label.businessType')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.businessType.placeholder.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-136 leading-5 shadow-none mr-16"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={status}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>
            <MenuItem value="true" className="capitalize">
              {t('menuItems.active')}
            </MenuItem>
            <MenuItem value="false" className="capitalize">
              {t('menuItems.inActive')}
            </MenuItem>
          </Select>
          <Button
            variant="contained"
            color="secondary"
            className="w-full min-w-224 md:text-18 font-medium disabled:text-black"
            aria-label="Add Business Type"
            onClick={() => {
              dispatch(openNewBusinessDialog());
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">
              &nbsp; {t('business.businessType.label.addBusinessType')}
            </span>
            <span className="flex sm:hidden">&nbsp; {t('business.businessType.label.new')}</span>
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {businessTypeData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={businessTypeData?.length}
                />
                <TableBody>
                  {businessTypeList &&
                    businessTypeList?.length > 0 &&
                    businessTypeList?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row?.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.bType}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.isActive ? 'Active' : 'Inactive'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Icon
                              className="cursor-pointer text-grey-600 hover:text-darkgreen align-middle"
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(openEditBusinessDialog(row));
                              }}
                            >
                              edit
                            </Icon>
                            <Switch
                              checked={row.isActive}
                              onChange={() => handleActiveInactive(row?.id, row?.isActive)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('business.businessType.validationMessage.noBusinessType')}
            </Typography>
          </motion.div>
        )}
      </div>

      {businessTypeData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalBusinessType}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog
        classes={{
          paper: 'm-24 p-28',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            {appDialog.type === 'new' ? 'Add Business Type' : 'Edit Business Type'}
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent className="p-0 mb-24">
            <div className="block">
              <InputLabel htmlFor="bType" className="text-16 font-medium mb-12 text-grey-900">
                {t('business.businessType.label.enterBusinessTypeName')}
              </InputLabel>
              <Controller
                control={control}
                name="bType"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('business.businessType.placeholder.enterBusinessType')}
                    size="small"
                    id="bType"
                    error={!!errors.bType}
                    helperText={errors?.bType?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
            >
              {appDialog.type === 'new' ? 'Create' : 'Update'}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('business.businessType.buttons.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('businessReducer', reducer)(BusinessType);
