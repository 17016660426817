import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import history from '@history';
import businessData from 'src/app/main/query/business';
import { handleApiRequest } from 'src/app/main/common/common';
import { useParams } from 'react-router-dom';

const DashboardAlerts = () => {
  const params = useParams();
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const newSettings = {
    dots: true,
    infinite: cardData?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCardV1,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
            productTypeId: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.getBusinessWhatsNewCardV1?.status === 200 &&
          result.getBusinessWhatsNewCardV1?.data
        ) {
          setCardData(JSON.parse(result.getBusinessWhatsNewCardV1?.data?.cardJson));
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchCardData();
    }
  }, [userCurrentBusinessDetails?.id]);

  return (
    <>
      {cardData?.length > 0 && (
        <div className="bg-white rounded-md relative shadow p-10 md:p-16 w-full">
          <Slider {...newSettings}>
            {cardData?.map(
              (item, index) =>
                item && (
                  <div className="pr-0 md:pr-16" key={index}>
                    <div
                      className="flex gap-16 items-center justify-between w-full min-h-144 max-h-144 md:min-h-200 md:max-h-200 p-20 py-16 rounded-md"
                      style={{ backgroundColor: item?.backgroundColor }}
                    >
                      <div>
                        <Typography
                          className="font-semibold text-20"
                          style={{ color: item?.textColor }}
                        >
                          {item?.description}
                        </Typography>
                        <Button
                          className="rounded-md px-10 md:px-32 text-quick-review font-600 mt-8"
                          style={{
                            backgroundColor: item?.buttonColor,
                            '--hover-bg': item?.buttonColor,
                            color: item?.buttonTextColor,
                          }}
                          onClick={() => {
                            if (item?.redirection === 'scheduleCampaignPage') {
                              const selectTab = 1;
                              history.push(
                                {
                                  pathname: `/${item?.portalURL}/${params?.id ? params.id : ''}`,
                                },
                                { selectTab }
                              );
                            } else {
                              history.push({
                                pathname: `/${item?.portalURL}/${params?.id ? params.id : ''}`,
                              });
                            }
                          }}
                        >
                          {item?.ctaButton}
                        </Button>
                      </div>
                      <div>
                        <img
                          className="hidden w-80 md:w-112 border-2 border-white rounded-12 p-2 md:block"
                          src={item?.icon}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </Slider>
        </div>
      )}
    </>
  );
};

export default DashboardAlerts;
