import Staff from './Staff';
import StaffProfile from './StaffProfile';

const StaffConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/staff/:id?',
      element: <Staff />,
    },
    {
      path: 'quick-reviews/staffProfile/:profileId/:id?',
      element: <StaffProfile />,
    },
  ],
};

export default StaffConfig;
