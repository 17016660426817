import PlanUsage from './PlanUsage';

const PlanUsageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/planUsage/:id?',
      element: <PlanUsage />,
    },
  ],
};

export default PlanUsageConfig;
