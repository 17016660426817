import ManageReviewSite from './ManageReviewSite';

const ManageReviewSiteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/manageReviewSite/:id?',
      element: <ManageReviewSite />,
    },
  ],
};

export default ManageReviewSiteConfig;
