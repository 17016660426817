import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest, handleApiRequest } from '../../common/common';

const AddVariable = (props) => {
  const { openValue, cancelCall, popUpMessage, onVariableSelect } = props;

  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [variableList, setVariableList] = useState([]);

  useEffect(() => {
    try {
      const whatAppVariable = async () => {
        const payload = {
          query: wabaQuery.getTemplateVariable,
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getTemplateVariable?.status === 200) {
          setVariableList(res?.getTemplateVariable?.data);
        }
      };
      whatAppVariable();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  const handleVariableClick = (variable) => {
    onVariableSelect(variable);
  };

  return (
    <Dialog
      classes={{
        paper: 'm-24 p-20 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 mb-28">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <ul>
        {variableList &&
          variableList?.length > 0 &&
          variableList?.map((variable, i) => (
            <li
              className="bg-darkgreen-100 rounded-full text-black font-semibold text-14 px-16 py-8 inline-block cursor-pointer"
              onClick={() => handleVariableClick(variable)}
              aria-hidden
              key={i}
            >
              {variable?.name}
            </li>
          ))}
      </ul>
    </Dialog>
  );
};

export default AddVariable;
