import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import appWebsiteData from '../../query/appWebsite';
import cmsData from '../../query/cms';
import UserService from '../../../services/userService';
import {
  getDemoThemes,
  resetAppWebsite,
  updateUserTemplateBasicDetails,
} from './store/appWebsiteSlice';
import reducer from './store';

function SelectTheme(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginUserData = UserService.getUserData();

  const domainAndDeploymentStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getDemoThemesData?.data?.getDemoThemes?.data
  );

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: appWebsiteData.getDemoThemes,
    };
    dispatch(getDemoThemes(payload));

    return () => {
      unmounted = true;
      dispatch(resetAppWebsite());
    };
  }, [dispatch]);

  function SelectUserTheme(themeId) {
    const payload = {
      query: cmsData.updateUserTemplateBasicDetails,
      variables: {
        domainRegisterId:
          parseInt(loginUserData?.domainRegisterId, 10) ||
          parseInt(localStorage.getItem('domainRegistrationId'), 10),
        themeId: parseInt(themeId, 10),
      },
    };
    dispatch(updateUserTemplateBasicDetails(payload));
  }

  return (
    <div className="p-20 lg:p-32 h-full">
      <>
        <div className="items-center mb-36 text-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-16 md:text-24 font-bold mb-20"
          >
            {t('appWebsite.selectTheme.title')}
          </Typography>
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="block text-14 md:text-20 font-semibold"
          >
            {t('appWebsite.selectTheme.shortDesc')}
          </Typography>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
          {domainAndDeploymentStatus &&
            domainAndDeploymentStatus?.length > 0 &&
            domainAndDeploymentStatus?.map((businessTemplate, index) => (
              <div className="bg-white rounded-md relative shadow" key={index}>
                <img src={businessTemplate?.image} className="img-fluid mx-auto w-full" alt="..." />
                <div className="p-16">
                  <h2 className="text-16 font-semibold mb-16">{businessTemplate?.name}</h2>
                  <div className="flex">
                    <Button
                      className="md:text-14 font-medium bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen w-full mr-8 py-2"
                      variant="contained"
                      color="primary"
                      type="button"
                      size="small"
                      href={businessTemplate?.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      role="button"
                    >
                      {t('appWebsite.selectTheme.buttons.livePreview')}
                    </Button>
                    <Button
                      className="md:text-14 font-medium disabled:text-black rounded-md w-full ml-8 py-2"
                      variant="contained"
                      color="secondary"
                      type="submit"
                      size="small"
                      onClick={() => SelectUserTheme(businessTemplate?.id)}
                    >
                      {t('appWebsite.selectTheme.buttons.selectTheme')}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </>
    </div>
  );
}

export default withReducer('appsWebsitesReducer', reducer)(SelectTheme);
