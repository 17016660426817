import PendingTask from './PendingTask';

const PrimaryDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'pending-task/:id?',
      element: <PendingTask />,
    },
  ],
};

export default PrimaryDetailsConfig;
