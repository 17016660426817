import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import negativeReviewsData from '../../query/negativeReviews';
import { getAllNegativeReview, reSetNegativeReviews } from './store/negativeReviewsSlice';
import reducer from './store';

function NegativeReviewDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const negativeReviewsIdData = useSelector(
    ({ negativeReviewsReducer }) =>
      negativeReviewsReducer?.negativeReviews?.getAllNegativeReviewData?.data?.getAllNegativeReview
        ?.data[0] || ''
  );

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageNumber: 1,
        pageSize: 10,
        searchText: '',
        redirectNegativeReviewId: parseInt(id, 10),
      },
    };
    dispatch(getAllNegativeReview(payload));

    return () => {
      unmounted = true;
      dispatch(reSetNegativeReviews());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (negativeReviewsIdData || negativeReviewsIdData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [negativeReviewsIdData]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <div className="flex justify-between items-center">
        <div>
          <Toolbar className="px-0 font-semibold min-h-fit mb-20">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('negativeReviews.negativeReviewsList.title')}
            </Typography>
          </Toolbar>
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-16 md:text-28 font-bold"
              >
                <span className="inline-block bg-white rounded-md w-48 h-48 text-center flex items-center mr-10">
                  <Icon className="text-32 mx-auto">group</Icon>
                </span>{' '}
                {negativeReviewsIdData?.businessName}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow p-24">
        {negativeReviewsIdData?.autoGeneratedReview && (
          <div className="bg-grey-100 rounded-md p-20 max-w-640">
            <h2 className="text-18 font-semibold border-b-1 border-dashed border-grey-500 pb-20 mb-20">
              {t('negativeReviews.negativeReviewsDetails.customerReview')}
            </h2>
            <Typography className="font-medium">
              {negativeReviewsIdData?.autoGeneratedReview}
            </Typography>
          </div>
        )}
        {negativeReviewsIdData?.reason && (
          <div className="bg-grey-100 rounded-md p-20 mt-20 max-w-640">
            <h2 className="text-18 font-semibold border-b-1 border-dashed border-grey-500 pb-20 mb-20">
              {t('negativeReviews.negativeReviewsDetails.note')}
            </h2>
            <Typography className="font-medium">{negativeReviewsIdData?.reason}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default withReducer('negativeReviewsReducer', reducer)(NegativeReviewDetails);
