import Dashboards from './Dashboards';

const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/dashboard/:id?',
      element: <Dashboards />,
    },
  ],
};

export default DashboardConfig;
