import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import businessData from '../../query/business';
import {
  getAllBusinessAttributes,
  addBusinessAttributes,
  getBusinessAttributeValue,
  resetBusiness,
  updateBusinessAttribute,
  openNewBusinessDialog,
  closeNewBusinessDialog,
  openEditBusinessDialog,
  closeEditBusinessDialog,
} from './store/businessSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const defaultValues = {
  bAttribute: '',
  icon: '',
};

function BusinessAttribute() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    bAttribute: yup
      .string()
      .trim() // Trim leading and trailing spaces
      .required(t('business.businessAttribute.formField.businessAttributeName.validationMessage'))
      .matches(
        /\S/,
        t('business.businessAttribute.formField.businessAttributeName.validationMessage')
      ), // Ensures there is at least one non-whitespace character
    // icon: yup
    //   .string()
    //   .required(t('business.businessAttribute.formField.businessAttributeIcon.validationMessage')),
  });

  const businessAttributeData = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessAttributesData?.data?.getAllBusinessAttributes
        ?.data || ''
  );

  const businessAttributeSelectValue = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getBusinessAttributeValueData?.data?.getBusinessAttributeValue
        ?.data || []
  );

  const addBusinessAttributeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.addBusinessAttributesData?.data?.addBusinessAttributes
  );

  const updateBusinessAttributeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.updateBusinessAttributeData?.data?.updateBusinessAttribute
  );

  const totalBusinessAttribute = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessAttributesData?.data?.getAllBusinessAttributes
        ?.totalCount || ''
  );

  const appDialog = useSelector(({ businessReducer }) => businessReducer.business.businessDialog);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [actionValue, setActionValue] = useState('action');
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('cts');
  const [businessAttributeValue, selectBusinessAttributeValue] = useState(0);
  const [businessAttributeList, setBusinessAttributeList] = useState(businessAttributeData);
  const [refreshIcon, setrefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = businessAttributeData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (appDialog.type === 'edit' && appDialog.data) {
      selectBusinessAttributeValue(appDialog?.data?.businessAttributeValueId);
      reset({
        ...appDialog.data,
        name: appDialog.data.bAttribute,
      });
    }

    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...appDialog.data,
      });
    }
  }, [appDialog.data, appDialog.type, reset]);

  /**
	/**
	 * On Dialog Open
	 */
  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
      const payload = {
        query: businessData.getBusinessAttributeValue,
      };
      dispatch(getBusinessAttributeValue(payload));
    }
  }, [appDialog.props.open, dispatch, initDialog]);

  useEffect(() => {
    let unmounted = false;

    const payload = {
      query: businessData.getAllBusinessAttributes,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    dispatch(getAllBusinessAttributes(payload));

    return () => {
      unmounted = true;
      dispatch(resetBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    if (businessAttributeData && businessAttributeData.length) {
      setBusinessAttributeList(businessAttributeData);
    } else {
      setBusinessAttributeList();
    }
  }, [businessAttributeData]);

  useEffect(() => {
    if (businessAttributeData || businessAttributeData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [businessAttributeData]);

  useEffect(() => {
    if (addBusinessAttributeStatus && addBusinessAttributeStatus.status === 400) {
      enqueueSnackbar(addBusinessAttributeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      reset(defaultValues);
    } else if (addBusinessAttributeStatus && addBusinessAttributeStatus.status === 200) {
      enqueueSnackbar(addBusinessAttributeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      selectBusinessAttributeValue(0);
      reset(defaultValues);
    }
  }, [reset, dispatch, addBusinessAttributeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateBusinessAttributeStatus && updateBusinessAttributeStatus.status === 400) {
      enqueueSnackbar(updateBusinessAttributeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      reset(defaultValues);
    } else if (updateBusinessAttributeStatus && updateBusinessAttributeStatus.status === 200) {
      enqueueSnackbar(updateBusinessAttributeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      selectBusinessAttributeValue(0);
      reset(defaultValues);
    }
  }, [reset, dispatch, updateBusinessAttributeStatus, enqueueSnackbar, closeSnackbar]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: businessData.getAllBusinessAttributes,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(getAllBusinessAttributes(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  function handleActionDropdownChange(event) {
    setActionValue(event.target.value);
  }

  const handleSorting = (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...businessAttributeList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setBusinessAttributeList(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...businessAttributeList].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setBusinessAttributeList(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }

    const payload = {
      query: businessData.getAllBusinessAttributes,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
        sortBy: sortColumnName,
        sortOrder: order,
      },
    };
    dispatch(getAllBusinessAttributes(payload));
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: businessData.getAllBusinessAttributes,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessAttributes(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: businessData.getAllBusinessAttributes,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessAttributes(payload));
      setLoadingPage(false);
    });
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          <TableCell className="text-16 font-medium  max-w-136" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('bAttribute');
              }}
            >
              {t('business.businessAttribute.column.businessAttributeName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {' '}
              {t('business.businessAttribute.column.type')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('business.businessAttribute.column.createdDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.businessAttribute.column.status')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>
              {t('business.businessAttribute.column.action')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    selectBusinessAttributeValue(0);
    return appDialog.type === 'edit'
      ? dispatch(closeEditBusinessDialog())
      : dispatch(closeNewBusinessDialog());
  }

  /**
   * Remove Event
   */
  function handleRemove() {
    closeComposeDialog();
    reset(defaultValues);
  }

  function onSubmit(submitData) {
    setrefreshIcon(true);
    if (appDialog.type === 'new') {
      const payload = {
        query: businessData.addBusinessAttributes,
        variables: {
          bAttributes: submitData.bAttribute,
          businessAttributeValueId: parseInt(businessAttributeValue, 10),
        },
      };
      dispatch(addBusinessAttributes(payload));
      const payloadData = {
        query: businessData.getAllBusinessAttributes,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
      setTimeout(() => {
        dispatch(getAllBusinessAttributes(payloadData));
      }, 800);
    } else {
      const payload = {
        query: businessData.updateBusinessAttribute,
        variables: {
          bAttributes: submitData.bAttribute,
          businessAttributeValueId: parseInt(businessAttributeValue, 10),
          bAttributeId: parseInt(submitData.id, 10),
        },
      };
      dispatch(updateBusinessAttribute(payload));
      const payloadData = {
        query: businessData.getAllBusinessAttributes,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
      setTimeout(() => {
        dispatch(getAllBusinessAttributes(payloadData));
      }, 800);
    }
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('business.businessAttribute.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.businessAttribute.placeholder.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end">
          <Button
            variant="contained"
            color="secondary"
            className="w-full md:text-18 font-medium disabled:text-black"
            aria-label="Add Business Type"
            onClick={() => {
              dispatch(openNewBusinessDialog());
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">
              &nbsp;{t('business.businessAttribute.buttons.addBusinessAttribute')}
            </span>
            <span className="flex sm:hidden">
              &nbsp; {t('business.businessAttribute.buttons.new')}
            </span>
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {businessAttributeData.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={businessAttributeData.length}
                />
                <TableBody>
                  {businessAttributeList &&
                    businessAttributeList?.length > 0 &&
                    businessAttributeList?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            className="text-16 color-black font-semibold capitalize max-w-136"
                            align="left"
                          >
                            {row?.bAttribute}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.type}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.isActive ? 'Active' : 'Inactive'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Icon
                              className="cursor-pointer text-grey-600 hover:text-darkgreen align-middle"
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(openEditBusinessDialog(row));
                              }}
                            >
                              edit
                            </Icon>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('business.businessAttribute.validationMessage.noBusinessAttribute')}
            </Typography>
          </motion.div>
        )}
      </div>
      {businessAttributeList?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalBusinessAttribute}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        classes={{
          paper: 'm-24 p-28',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            {appDialog.type === 'new' ? 'Add Business Attribute' : 'Edit Business Attribute'}
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent className="p-0 mb-24">
            <div className="block mb-24">
              <InputLabel htmlFor="bAttribute" className="text-16 font-medium mb-12 text-grey-900">
                {t('business.businessAttribute.column.businessAttributeName')}
              </InputLabel>
              <Controller
                control={control}
                name="bAttribute"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Enter Business Attribute"
                    size="small"
                    id="bAttribute"
                    error={!!errors.bAttribute}
                    helperText={errors?.bAttribute?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block">
              <InputLabel htmlFor="bAttribute" className="text-16 font-medium mb-12 text-grey-900">
                {t('business.businessAttribute.formField.selectFormate')}
              </InputLabel>
              <Controller
                name="businessAttributeValue"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-full"
                    id="businessAttributeValue"
                    size="small"
                    value={businessAttributeValue}
                    displayEmpty
                    // error={!!errors.country}
                    onChange={(e) => {
                      selectBusinessAttributeValue(e?.target?.value);
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {t('business.businessAttribute.formField.select')}
                    </MenuItem>
                    {businessAttributeSelectValue?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.type}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              // disabled={appDialog.type === 'new' ? _.isEmpty(dirtyFields) || !isValid || refreshIcon : false}
              disabled={
                appDialog.type === 'new'
                  ? businessAttributeValue === 0 ||
                    _.isEmpty(dirtyFields) ||
                    !isValid ||
                    refreshIcon
                  : appDialog.type === 'edit'
                  ? refreshIcon
                  : false
              }
            >
              {appDialog.type === 'new' ? 'Create' : 'Update'}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('business.businessAttribute.buttons.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('businessReducer', reducer)(BusinessAttribute);
