import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import history from '@history';
import Button from '@mui/material/Button';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import qrcodeData from '../../query/qrcodeOrder';
import {
  getBusinessQrOrder,
  getBusinessQrOrderStatusField,
  updateBusinessQrOrderStatus,
} from './store/orderedQrcodeSlice';
import reducer from './store';

function QrcodeOrderDetail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [orderStatusId, setOrderStatusId] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const payload = {
        query: qrcodeData.getBusinessQrOrder,
        variables: {
          pageSize: 10,
          pageNumber: 1,
          searchText: '',
          businessQrOrderId: parseInt(routeParams?.businessId, 10),
        },
      };
      const res = await dispatch(getBusinessQrOrder(payload));
      if (res?.payload?.data?.getBusinessQrOrder?.status === 200) {
        setData(res?.payload?.data?.getBusinessQrOrder?.data[0]);
        setLoading(false);
        setOrderStatusId(
          res?.payload?.data?.getBusinessQrOrder?.data[0]?.qrCodeOrderStatusField?.id
        );
      }

      const payloadData = {
        query: qrcodeData.getBusinessQrOrderStatusField,
      };
      const result = await dispatch(getBusinessQrOrderStatusField(payloadData));
      if (result?.payload?.data?.getBusinessQrOrderStatusField?.status === 200) {
        setOrderStatus(result?.payload?.data?.getBusinessQrOrderStatusField?.data);
        setLoading(false);
      }
    }
    fetchData();
  }, [dispatch, routeParams?.businessId]);

  useEffect(() => {
    if (data || data === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleOrderStatus = async () => {
    setRefreshIcon(true);
    const payload = {
      query: qrcodeData.updateBusinessQrOrderStatus,
      variables: {
        businessQrcodeOrdersId: parseInt(routeParams?.businessId, 10),
        statusFieldId: parseInt(orderStatusId, 10),
      },
    };
    const result = await dispatch(updateBusinessQrOrderStatus(payload));
    if (result?.payload?.data?.updateBusinessQrOrderStatus?.status === 200) {
      enqueueSnackbar(result?.payload?.data?.updateBusinessQrOrderStatus?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
      const payloadData = {
        query: qrcodeData.getBusinessQrOrder,
        variables: {
          pageSize: 10,
          pageNumber: 1,
          searchText: '',
          businessQrOrderId: parseInt(routeParams?.businessId, 10),
        },
      };
      await dispatch(getBusinessQrOrder(payloadData));
    } else {
      setRefreshIcon(false);
      enqueueSnackbar(result?.payload?.data?.updateBusinessQrOrderStatus?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  };

  return (
    <div className="p-20 lg:p-32">
      <Toolbar className="px-0 font-semibold min-h-fit mb-20">
        <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
          west
        </Icon>
        <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-bold text-18">
          {t('orderedQrcode.orderList.title')}
        </Typography>
      </Toolbar>
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            <span className="inline-block bg-white rounded-md text-center flex items-center mr-10 capitalize">
              <img
                src="https://quick-review.s3.amazonaws.com/qrcode_preview.jpg"
                className="img-fluid mx-auto max-w-48 rounded-md "
                alt="..."
              />
            </span>
            {t('orderedQrcode.orderDetail.title')}
          </Typography>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow max-w-[800px] p-20">
        {data && (
          <>
            <div>
              <h2 className="text-18 font-bold pb-12 border-b-1 border-dashed border-grey mb-16">
                {t('orderedQrcode.orderDetail.secTitle')}
              </h2>{' '}
            </div>
            <div className="md:flex justify-between items-center md:gap-40">
              <div className="md:w-1/2">
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.name')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.fullName}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">
                    {t('orderedQrcode.column.businessName')}
                  </h3>
                  <div className="text-14 font-medium text-grey">{data?.businessName}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.phone')}</h3>
                  <div className="text-14 font-medium text-grey">
                    {data?.countryCode} {data?.phoneNumber}
                  </div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.mail')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.email}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.quantity')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.quantity}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.status')}</h3>
                  <div className="text-14 font-medium text-grey">
                    <Select
                      id="order"
                      name="order"
                      sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                      className="w-full"
                      size="small"
                      value={orderStatusId}
                      onChange={(e) => {
                        setOrderStatusId(e.target.value);
                      }}
                    >
                      {orderStatus?.map((option) => (
                        <MenuItem
                          className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                          key={option.id}
                          value={option.id}
                        >
                          {option.status}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="md:w-1/2">
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.address')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.address}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.landmark')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.landmark}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.country')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.countries?.name}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.state')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.states?.name}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.city')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.city}</div>
                </div>
                <div className="mb-20">
                  <h3 className="text-16 font-medium mb-5">{t('orderedQrcode.column.ZipCode')}</h3>
                  <div className="text-14 font-medium text-grey">{data?.zipCode}</div>
                </div>
              </div>
            </div>
            {orderStatusId !== data?.qrCodeOrderStatusField?.id && (
              <div className="mt-48">
                <Button
                  className="md:text-18 font-semibold bg-white rounded-full hover:text-white hover:bg-darkgreen mr-16"
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() => setOrderStatusId(data?.qrCodeOrderStatusField?.id)}
                >
                  {t('orderedQrcode.orderDetail.cancel')}
                </Button>
                <Button
                  className="md:text-18 font-semibold disabled:text-black rounded-full"
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => handleOrderStatus()}
                  disabled={refreshIcon}
                >
                  {t('orderedQrcode.orderDetail.saveChanges')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withReducer('orderedQrcodeReducer', reducer)(QrcodeOrderDetail);
