import Reviews from './Reviews';

const ReviewsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/reviews/:id?',
      element: <Reviews />,
    },
  ],
};

export default ReviewsConfig;
