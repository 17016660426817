import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import * as yup from 'yup';
import FuseLoading from '@fuse/core/FuseLoading';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import queryData from '../query/common';
import { capitalizeFirstLetter, handleApiRequest, imageDataToSend } from '../common/common';
import UserProfile from '../common/userProfile';

const AgencyProfile = () => {
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [image, setImage] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [profileRemove, setProfileRemove] = useState(false);

  useEffect(() => {
    if (userDetails?.profileUrl) {
      setImageUrl(userDetails?.profileUrl);
    }
  }, [userDetails?.profileUrl]);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('agencyProfile.field.firstName.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('agencyProfile.field.lastName.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    agencyName: yup.string().required(t('agencyProfile.field.agencyName.required')),
    agencyAddress: yup.string().required(t('agencyProfile.field.agencyAddress.required')),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  const fetchProfileDetails = useCallback(async () => {
    setLoading(true);
    const payload = {
      query: queryData.getProfile,
    };
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getProfile?.status === 200) {
      setUserDetails(result?.getProfile?.data);
      const data = result?.getProfile?.data;
      const setValueAndDirty = (field, value) => {
        setValue(field, value || '', {
          shouldDirty: true,
          shouldValidate: true,
        });
      };
      setValueAndDirty('firstName', data.firstName);
      setValueAndDirty('lastName', data.lastName);
      setValueAndDirty('agencyName', data.agencyName);
      setValueAndDirty('agencyUrl', data.agencyUrl);
      setValueAndDirty('agencyAddress', data.agencyAddress);
    } else {
      enqueueSnackbar(result?.getProfile?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  }, [setValue, enqueueSnackbar]);

  useEffect(() => {
    fetchProfileDetails();
  }, [fetchProfileDetails]);

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      setImageUrl(reader.result);
      setProfileRemove(false);
      setImage(file);
    };
    reader.readAsDataURL(file);
  };

  const submit = async (data) => {
    setRefreshIcon(true);
    const payload = {
      query: queryData.editAgentProfile,
      variables: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        agencyName: data?.agencyName,
        agencyUrl: data?.agencyUrl,
        agencyAddress: data?.agencyAddress,
        profileImage: image,
        isProfileRemove: profileRemove,
      },
    };

    const result = await imageDataToSend(payload);
    setRefreshIcon(false);
    if (result?.editAgentProfile?.status === 200) {
      handleRemove();
      setProfileRemove(false);
      window.location.reload();
    } else {
      enqueueSnackbar(result?.editAgentProfile?.message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  const removeImage = () => {
    setImageUrl();
    setProfileRemove(true);
    setImage();
  };

  const handleRemove = () => {
    setImageUrl();
    setOpenDialogue(false);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
      >
        {t('agencyProfile.title')}
      </Typography>
      <div className="bg-white p-28 md:p-48 mt-40 rounded-md">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div className="sm:flex gap-40 w-full">
            {userDetails?.profileUrl ? (
              <img
                className="rounded-xl w-136 h-128 my-20 sm:0"
                src={userDetails?.profileUrl}
                alt=""
              />
            ) : (
              <UserProfile
                name={userDetails?.firstName}
                width="100px"
                height="100px"
                fontSize="65px"
                fontWeight="bold"
              />
            )}
            <div className="mt-6 w-full">
              <Typography className="font-semibold text-24">
                {userDetails?.firstName && capitalizeFirstLetter(userDetails.firstName)}{' '}
                {userDetails?.lastName && capitalizeFirstLetter(userDetails.lastName)}
              </Typography>
              <div className="md:grid grid-cols-4 gap-x-28 w-full items-center mt-16 max-w-screen-md">
                {userDetails?.email && (
                  <Typography className="font-semibold col-span-2 text-16 ">
                    <EmailIcon className="text-28 bg-gray-200 p-6 rounded-full mr-8" />
                    {userDetails?.email}
                  </Typography>
                )}
                {userDetails?.phoneNumber?.phoneNumber && (
                  <Typography className="font-semibold col-span-2 text-16 mt-16 md:mt-0">
                    <LocalPhoneIcon className="text-28 bg-gray-200 p-6 rounded-full mr-10" />
                    {userDetails?.phoneNumber?.countryCode} {userDetails?.phoneNumber?.phoneNumber}
                  </Typography>
                )}
                {userDetails?.agencyName && (
                  <Typography className="font-semibold col-span-2 text-16 mt-16">
                    <HomeIcon className="text-28 bg-gray-200 p-6 rounded-full mr-10" />
                    {userDetails?.agencyName}
                  </Typography>
                )}
                {userDetails?.agencyAddress && (
                  <Typography className="font-semibold col-span-2 text-16 mt-16">
                    <LocationOnIcon className="text-28 bg-gray-200 p-6 rounded-full mr-10" />
                    {userDetails?.agencyAddress}
                  </Typography>
                )}
                {userDetails?.agencyUrl && (
                  <Typography className="font-semibold col-span-2 text-16 mt-16">
                    <LinkIcon className="text-28 bg-gray-200 p-6 rounded-full mr-10" />
                    {userDetails?.agencyUrl}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="text-end">
            <Button
              className="bg-darkgreen-100 hover:bg-darkgreen-100 max-w-160 w-160 text-darkgreen font-semibold text-16"
              onClick={() => {
                setOpenDialogue(true);
                setImageUrl(userDetails?.profileUrl);
              }}
            >
              {t('agencyProfile.button.editProfile')}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 p-28 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 sm:mb-8">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
        </DialogTitle>
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-24 font-semibold sm:mb-16 md:mb-0"
        >
          {t('agencyProfile.editProfile')}
        </Typography>
        <hr className="border-dashed border-gray my-16" />
        <form
          noValidate
          className="flex flex-col md:overflow-hidden"
          onSubmit={handleSubmit(submit)}
        >
          <DialogContent className="p-0 mb-24">
            <div className="text-center">
              <label htmlFor="button-file" className="cursor-pointer">
                <input
                  id="button-file"
                  className="hidden"
                  onChange={(event) => {
                    handleFileChange(event);
                  }}
                  type="file"
                  accept="image/*"
                />
                {imageUrl ? (
                  <img className="rounded-full m-auto w-96 h-96" src={imageUrl} alt="" />
                ) : (
                  <div className="flex justify-center">
                    <UserProfile
                      name={userDetails?.firstName}
                      width="100px"
                      height="100px"
                      fontSize="65px"
                      fontWeight="bold"
                    />
                  </div>
                )}
              </label>
              <div className="col-span-4 sm:col-span-3 lg:col-span-2 relative">
                <div className="flex gap-10 justify-center mt-10">
                  <label htmlFor="button-file">
                    <input
                      id="button-file"
                      className="hidden"
                      onChange={(event) => {
                        handleFileChange(event);
                      }}
                      type="file"
                      accept="image/*"
                    />
                    <Button
                      className="text-darkgreen font-semibold text-16 hover:text-darkgreen-900 text-center"
                      component="span"
                      variant="outlined"
                    >
                      <Icon className="text-darkgreen font-normal cursor-pointer hover:text-darkgreen-900">
                        {imageUrl ? 'edit' : 'add'}
                      </Icon>

                      {!imageUrl
                        ? t('agencyProfile.button.addProfilePicture')
                        : t('agencyProfile.button.changeProfilePicture')}
                    </Button>
                  </label>
                  {imageUrl && (
                    <Button
                      className="text-red font-semibold text-16 hover:text-red-700 text-center"
                      component="span"
                      variant="outlined"
                      onClick={() => removeImage()}
                    >
                      <Icon
                        className={`font-normal ${
                          !imageUrl ? 'text-gray-400' : 'text-red cursor-pointer hover:text-red-700'
                        }`}
                      >
                        delete
                      </Icon>
                      {t('agencyProfile.button.removeProfilePicture')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="block mb-24 mt-20">
              <Typography className="text-14">
                {t('agencyProfile.field.firstName.name')}
                <span className="text-red">*</span>
              </Typography>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-6 max-w-sm"
                    placeholder={t('agencyProfile.field.firstName.placeHolder')}
                    type="text"
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
            <div className="block mb-24">
              <Typography className="text-14">
                {t('agencyProfile.field.lastName.name')}
                <span className="text-red">*</span>
              </Typography>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-6 max-w-sm"
                    placeholder={t('agencyProfile.field.lastName.placeHolder')}
                    type="text"
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
            <div className="block mb-24">
              <Typography className="text-14">
                {t('agencyProfile.field.agencyName.name')}
                <span className="text-red">*</span>
              </Typography>
              <Controller
                name="agencyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-6 max-w-sm"
                    placeholder={t('agencyProfile.field.agencyName.placeHolder')}
                    type="text"
                    error={!!errors.agencyName}
                    helperText={errors?.agencyName?.message}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
            <div className="block mb-24">
              <Typography className="text-14">{t('agencyProfile.field.agencyUrl.name')}</Typography>
              <Controller
                name="agencyUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-6 max-w-sm"
                    placeholder={t('agencyProfile.field.agencyUrl.placeHolder')}
                    type="text"
                    error={!!errors.agencyUrl}
                    helperText={errors?.agencyUrl?.message}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </div>
            <div className="block mb-24">
              <Typography className="text-14">
                {t('agencyProfile.field.agencyAddress.name')}
                <span className="text-red">*</span>
              </Typography>
              <Controller
                name="agencyAddress"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mt-6 max-w-sm"
                    placeholder={t('agencyProfile.field.agencyAddress.placeHolder')}
                    type="text"
                    error={!!errors.agencyAddress}
                    helperText={errors?.agencyAddress?.message}
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
              variant="contained"
              color="secondary"
              type="submit"
              size="small"
              disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
            >
              {t('manageBusiness.button.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AgencyProfile;
