import MySubscription from './MySubscription';

const MySubscriptionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'mySubscription/:id?',
      element: <MySubscription />,
    },
  ],
};

export default MySubscriptionConfig;
