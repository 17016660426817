import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import promotionQuery from '../../query/promotion';
import { getPromotion } from './store/promotionSlice';
import userService from '../../../services';

const PromotionDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [promotionDetailsData, setPromotionDetailsData] = useState();
  const [loading, setLoading] = useState(false);
  const loginUserData = userService.getUserData()?.countryId;
  useEffect(() => {
    setLoading(true);
    const fetchPromotionDetails = async () => {
      const payload = {
        query: promotionQuery.getPromotions,
        variables: {
          promotionInfoId: parseInt(params?.promotionId, 10),
        },
      };
      const result = await dispatch(getPromotion(payload));
      setLoading(false);
      if (result?.payload?.getPromotions?.status === 200) {
        setPromotionDetailsData(result?.payload?.getPromotions?.data?.[0]);
      }
    };
    fetchPromotionDetails();
  }, [dispatch, params?.promotionId]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 flex flex-col items-center lg:p-40">
      <div className="flex w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('promotion.promotionOverview')}
          </Typography>
        </div>
      </div>
      <div className="w-full flex flex-noWrap flex-col sm:flex-row items-center">
        <div className="bg-white rounded-lg relative w-full max-w-3xl md:max-w-5xl shadow p-20">
          <div className="border-b-2 border-black border-dotted">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-16 md:text-18 pb-16 font-medium"
            >
              {t('promotion.businessDetails')}
            </Typography>
          </div>
          <div className="flex justify-between mt-20">
            <Typography className="font-medium w-1/2 text-18 items-center">
              {t('promotion.businessName')}
            </Typography>
            <Typography className="font-medium w-1/2 text-18 break-all items-center">
              {promotionDetailsData?.businessName}
            </Typography>
          </div>
          <div className="flex justify-between mt-20">
            <Typography className="font-medium text-18 items-center">
              {t('promotion.promotionType')}
            </Typography>
            <Typography className="font-medium text-18 items-center">
              {promotionDetailsData?.promotionType}
            </Typography>
          </div>
        </div>
        <div className="w-full max-w-480 h-auto">
          <div dangerouslySetInnerHTML={{ __html: promotionDetailsData?.htmlTemplate }} />
        </div>
      </div>
      <div className="w-full flex flex-noWrap flex-col sm:flex-row items-center mt-12">
        <div className="w-full max-w-512 bg-white rounded-lg col-span-5 relative shadow p-20">
          <div className="border-b-2 border-black border-dotted">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-16 md:text-18 pb-16 font-medium"
            >
              {t('promotion.promotionDetails')}
            </Typography>
          </div>
          {promotionDetailsData?.promotionType === 'Fixed Discount' && (
            <div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.discountValues')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.discountValue}%
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.minimumCartValues')}
                  <span className=" text-2xl text-red-500">*</span>
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {parseInt(loginUserData, 10) === 1 ? '$' : '₹'}
                  {promotionDetailsData?.minimumCartValue}
                </Typography>
              </div>
            </div>
          )}
          {promotionDetailsData?.promotionType === 'Percentage Discount' && (
            <div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.discountPercentage')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.discountValue}%
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.maximumDiscounts')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {parseInt(loginUserData, 10) === 1 ? '$' : '₹'}{' '}
                  {promotionDetailsData?.maximumDiscount}
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.minimumCartValues')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {parseInt(loginUserData, 10) === 1 ? '$' : '₹'}{' '}
                  {promotionDetailsData?.minimumCartValue}
                </Typography>
              </div>
            </div>
          )}
          {promotionDetailsData?.promotionType === 'Buy X Get Y Free' && (
            <div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.buyQuantities')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.buyQuantity}
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.getQuantities')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.getQuantity}
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.buyProducts')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.buyProductName}
                </Typography>
              </div>
              <div className="flex justify-between mt-20">
                <Typography className="font-medium text-18 items-center">
                  {t('promotion.getProducts')}
                </Typography>
                <Typography className="font-medium text-18 items-center">
                  {promotionDetailsData?.getProductName}
                </Typography>
              </div>
            </div>
          )}
          {promotionDetailsData?.promotionType === 'Loyalty Points' &&
            promotionDetailsData?.loyalityType === 'Points with every purchase' && (
              <div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.applyTo')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.applyTo}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getPoints')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.points}
                  </Typography>
                </div>
              </div>
            )}
          {promotionDetailsData?.promotionType === 'Loyalty Points' &&
            promotionDetailsData?.loyalityType === 'Points with every purchase' &&
            promotionDetailsData?.applyTo === 'all_products' && (
              <div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.applyTo')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.applyTo}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getPoints')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.points}
                  </Typography>
                </div>
              </div>
            )}
          {promotionDetailsData?.promotionType === 'Loyalty Points' &&
            promotionDetailsData?.loyalityType === 'Points with every purchase' &&
            promotionDetailsData?.applyTo === 'specific_products' && (
              <div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.applyTo')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.applyTo}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.productsName')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.productName}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getPoints')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.points}
                  </Typography>
                </div>
              </div>
            )}
          {promotionDetailsData?.promotionType === 'Loyalty Points' &&
            promotionDetailsData?.loyalityType === 'Points per purchase' && (
              <div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.minimumPurchaseAmount')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {parseInt(loginUserData, 10) === 1 ? '$' : '₹'}
                    {promotionDetailsData?.minimumPurchaseAmount}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getPoints')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.points}
                  </Typography>
                </div>
              </div>
            )}
          {promotionDetailsData?.promotionType === 'Loyalty Points' &&
            promotionDetailsData?.loyalityType === 'Frequent buyer rewards' && (
              <div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.buyQuantities')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.buyQuantity}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getQuantities')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.getQuantity}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.buyProducts')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.buyProductName}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.getProducts')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.getProductName}
                  </Typography>
                </div>
                <div className="flex justify-between mt-20">
                  <Typography className="font-medium text-18 items-center">
                    {t('promotion.duration')}
                  </Typography>
                  <Typography className="font-medium text-18 items-center">
                    {promotionDetailsData?.duration}
                  </Typography>
                </div>
              </div>
            )}
          <div className="flex justify-between mt-20">
            <Typography className="font-medium text-18 items-center">
              {t('promotion.applicable')}
            </Typography>
            <Typography className="font-medium text-18 items-center">
              {promotionDetailsData?.applicableFor}
            </Typography>
          </div>
          <div className="flex justify-between mt-20">
            <Typography className="font-medium text-18 items-center">
              {t('promotion.buttonName')}
            </Typography>
            <Typography className="font-medium text-18 items-center">
              {promotionDetailsData?.button}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionDetails;
