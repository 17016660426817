import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Template from './Template';

const TemplatePopUp = ({ open, onClose, textColor }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown rounded-md bg-grey-A500`,
      }}
      PaperProps={{
        style: {
          width: '90%',
          maxWidth: '1500px',
          height: 'auto',
          maxHeight: '90vh',
        },
      }}
    >
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-32 h-32 text-grey-700" />
      </IconButton>

      <DialogContent className="p-0 mb-24">
        <Template />
      </DialogContent>
    </Dialog>
  );
};

export default TemplatePopUp;
