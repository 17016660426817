const createSubscriptionV1 = `
mutation createSubscriptionV1($data: String!) {
     createSubscriptionV1(data: $data) {
    message
    status
    data
  }
}
`;

const getPackageDataOnPackageIdAndCountryId = `
query getPackageDataOnPackageIdAndCountryId($data: String!) {
  getPackageDataOnPackageIdAndCountryId(data: $data) {
    message
    status
    data
    id
  }
}
`;

const getPackageAddOn = `
query getPackageAddOn($data: String!) {
  getPackageAddOn(data: $data) {
    message
    status
    data
    id
  }
}
`;

const addSubscriptionAddOn = `
mutation addSubscriptionAddOn($data: String!) {
  addSubscriptionAddOn(data: $data) {
    message
    status
    data
  }
}
`;

const checkPromoCode = `
query checkPromoCode($data: String!) {
  checkPromoCode(data: $data) {
    message
    status
    data
  }
}
`;

const getSubcriptionAndAddOnUsingUserId = `
query getSubcriptionAndAddOnUsingUserId($data: String!) {
  getSubcriptionAndAddOnUsingUserId(data: $data) {
    message
    status
    data
  }
}
`;

const upGradeAndDowngradeSubscriptionAddOn = `
mutation upGradeAndDowngradeSubscriptionAddOn($data: String!) {
  upGradeAndDowngradeSubscriptionAddOn(data: $data) {
    message
    status
    data
  }
}
`;

const upgradeAndDowngradeSubscription = `
mutation upgradeAndDowngradeSubscription($data: String!) {
  upgradeAndDowngradeSubscription(data: $data) {
    message
    status
    data
  }
}
`;

const validateWebhookSignature = `mutation  validateWebhookSignature($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!){
  validateWebhookSignature(
    razorpayOrderId: $razorpayOrderId,
    razorpayPaymentId: $razorpayPaymentId,
    razorpaySignature: $razorpaySignature
  ) {
    status
    message
  }
}
`;

const paymentData = {
  createSubscriptionV1,
  getPackageDataOnPackageIdAndCountryId,
  getPackageAddOn,
  addSubscriptionAddOn,
  checkPromoCode,
  getSubcriptionAndAddOnUsingUserId,
  upGradeAndDowngradeSubscriptionAddOn,
  upgradeAndDowngradeSubscription,
  validateWebhookSignature,
};

export default paymentData;
