import React, { useState, useEffect } from 'react';
import { Button, InputLabel, TextField } from '@mui/material';
import { t } from 'i18next';

const BotResponsesAddNew = ({
  initialQuestion = '',
  initialAnswer = '',
  isEditing,
  onSubmit,
  onClose,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [response, setResponse] = useState(initialAnswer);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setQuestion(initialQuestion);
      setResponse(initialAnswer);
    }
  }, [initialQuestion, initialAnswer, isEditing]);

  const handleSave = () => {
    setIsSubmitted(true);
    if (question?.length >= 10 && response?.length >= 10 && response?.length <= 250) {
      onSubmit({ question, response });
    }
  };

  return (
    <div className="lg:w-full md:w-full sm:w-full w-full bg-white lg:p-24 md:p-24 sm:p-24 p-20 lg:rounded-6 md:rounded-6 sm:rounded-6 rounded-6">
      <h1 className="lg:text-24 md:text-24 sm:text-16 text-12 font-semibold mb-24">
        {isEditing ? t('botResponses.editResponse') : t('botResponses.addResponse')}
      </h1>
      <div className="lg:mb-24 md:mb-24 sm:mb-24 mb-16 quick-chat">
        <InputLabel htmlFor="botQuestion" className="text-14 font-medium mb-10 text-black">
          {t('botResponses.enterQuestion')}
        </InputLabel>
        <div className="max-w-640 mb-24">
          <TextField
            placeholder={t('botResponses.enterYourQuestion')}
            variant="outlined"
            fullWidth
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="text-14"
            color="quickChat"
            error={
              (question?.length > 0 && question?.length < 10) ||
              question?.length > 120 ||
              (isSubmitted && question?.length === 0)
            }
            helperText={
              isSubmitted && question?.length === 0
                ? t('botResponses.questionIsRequired')
                : question?.length > 0 && question?.length < 10
                ? t('botResponses.minimumCharacters')
                : question?.length > 120
                ? t('botResponses.maximumCharacters')
                : ''
            }
          />
        </div>
      </div>
      <div className="lg:mb-24 md:mb-24 sm:mb-24 mb-16 quick-chat">
        <InputLabel htmlFor="botAnswer" className="text-14 font-medium mb-10 text-black">
          {t('botResponses.enterAnswer')}
        </InputLabel>
        <TextField
          placeholder={t('botResponses.typeTheBotResponse')}
          variant="outlined"
          fullWidth
          value={response}
          onChange={(e) => setResponse(e.target.value)}
          className="max-w-640 text-14"
          color="quickChat"
          multiline
          rows={6}
          error={
            (response?.length > 0 && response?.length < 10) ||
            response?.length > 120 ||
            (isSubmitted && response?.length === 0)
          }
          helperText={
            isSubmitted && response?.length === 0
              ? t('botResponses.responseIsRequired')
              : response?.length > 0 && response?.length < 10
              ? t('botResponses.minimumCharacters')
              : response?.length > 250
              ? t('botResponses.maximumCharactersResp')
              : ''
          }
        />
      </div>
      <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-row flex-col items-center">
        <Button
          variant="contained"
          color="quickChat"
          size="large"
          className={`lg:mr-16 md:mr-16 sm:mr-16 mr-16 lg:mb-0 md:mb-0 sm:mb-0 mb-16 rounded-md ${
            !question?.trim() || !response?.trim() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleSave}
          type="button"
          disabled={!question?.trim() || !response?.trim()}
        >
          {isEditing ? t('botResponses.saveResponse') : t('botResponses.addResponse')}
        </Button>
        <Button
          variant="outlined"
          color="quickChat"
          size="large"
          className="lg:ml-16 md:ml-16 sm:ml-16 ml-16 lg:mb-0 md:mb-0 sm:mb-0 mb-16 rounded-md border-1"
          onClick={onClose}
        >
          {t('botResponses.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default BotResponsesAddNew;
