import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from 'react-redux';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import {
  getDataToServer,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
} from '../../common/common';
import wabaQuery from '../../query/waba';
import UserService from '../../../services/userService';
import { selectDashboardData } from '../../../store/userSlice';

const defaultValues = {
  firstName: '',
  phone_number: '',
  mail: '',
};

const AddContact = (props) => {
  const {
    openValue,
    cancelCall,
    popUpMessage,
    popUpButtonName,
    type,
    initialValues,
    selectedRow,
    borderColor,
    textColor,
    callFrom,
    getData,
    fetchContactData,
    csvContactData,
    isEmailRequired,
  } = props;

  const loginUserData = UserService.getUserData();
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [errorMessage, setErrorMessage] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    phone_number: yup
      .string()
      .required(t('signUpPage.formField.phoneNumber.validationMessages.required')),
    mail: yup
      .string()
      .email(t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.validationMessage'))
      .trim(),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch, clearErrors } =
    useForm({
      mode: 'onChange',
      defaultValues: type === 'edit' ? { ...props.initialValues } : defaultValues,
      resolver: yupResolver(schema),
    });
  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (type === 'edit' && initialValues?.phone_number) {
      const dialCode = initialValues.phone_number.split(' ')[0] || '';
      const phoneNumber = initialValues.phone_number.split(' ')[1] || '';
      setPhoneValue(initialValues.phone_number);
      setPhone({ dialCode, phoneNumber });
      setValue('phone_number', phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [type, initialValues, setValue]);

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...data,
      phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10),
    });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    reset(defaultValues);
    clearErrors();
    cancelCall();
    setPhoneValue('');
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    if (type === 'add') {
      const obj = {
        query: wabaQuery.saveCampaignContact,
        variables: {
          callFrom,
          countryCode: `+${phone?.dialCode}`,
          email: data?.mail,
          firstName: data?.firstName,
          lastName: data?.lastName,
          phoneNumber: Number(phone?.phoneNumber),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await getDataToServer(obj);
      setRefreshIcon(false);
      if (res?.saveCampaignContact?.status === 200) {
        getData();
        if (fetchContactData) {
          fetchContactData();
        }

        enqueueSnackbar(res?.saveCampaignContact?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        reset(defaultValues);
        setPhoneValue('');
        handleClose();
        closeSnackbar(3000);
      } else {
        enqueueSnackbar(res?.saveCampaignContact?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
    } else {
      const obj = {
        query: wabaQuery.updateCsvContactOrCampaignContact,
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data?.mail || initialValues?.mail,
          countryCode: phone?.dialCode,
          phoneNumber: `${phone?.phoneNumber}`,
          oldPhoneNumber: `${selectedRow?.phoneNumber}`,
          oldEmail: selectedRow?.mail,
          dataFrom: selectedRow?.dataFrom,
          id: selectedRow?.id,
        },
      };
      const res = await handleApiRequest(obj);
      setRefreshIcon(false);
      if (res?.updateCsvContactOrCampaignContact?.status === 200) {
        getData();
        if (fetchContactData) {
          fetchContactData();
        }
        enqueueSnackbar(res?.updateCsvContactOrCampaignContact?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleClose();
      } else {
        enqueueSnackbar(res?.updateCsvContactOrCampaignContact?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  return (
    <Dialog
      classes={{
        paper: `m-24 p-28 border-t-8 border-solid rounded-md ${
          callFrom === 'whatsapp' ? 'border-t-quick-chat' : 'border-t-quick-campaign'
        }`,
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 mb-28">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
          {popUpMessage}
        </Typography>
      </DialogTitle>

      <form
        name="addClient"
        className={`${callFrom === 'whatsapp' ? 'quick-chat' : 'quick-campaign'}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="block mb-20">
          <InputLabel id="firstName" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.firstName.name')}
          </InputLabel>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('agencyBusiness.clients.form.firstName.name')}
                {...field}
                type="text"
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-20">
          <InputLabel id="firstName" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.lastName.name')}
          </InputLabel>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('agencyBusiness.clients.form.lastName.name')}
                {...field}
                type="text"
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
        <div className="block mb-20">
          <InputLabel id="phone_number" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.phone.name')}
          </InputLabel>
          <PhoneInput
            placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
            inputStyle={{
              width: '100%',
              height: '37px',
            }}
            className="w-full"
            name="phone_number"
            required
            error={!!errors.phone_number}
            helperText={errors?.phone_number?.message}
            value={phoneValue}
            size="small"
            country={loginUserData?.isoCode?.toLowerCase()}
            enableSearch="true"
            onChange={(value, data) => {
              handlePhoneChange(value, data);
              const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
              setErrorMessage(validPhoneNumber);
            }}
          />
          {errorMessage === false && phoneValue?.length > 0 && (
            <p className="text-red mt-5 text-16">{t('productSettings.InvalidPhoneNumber')}</p>
          )}
        </div>
        <div className="block mb-20">
          <InputLabel id="mail" className="text-16 font-medium mb-4 text-grey-900">
            {t('agencyBusiness.clients.form.email.name')} {!isEmailRequired && `(optional)`}
          </InputLabel>
          <Controller
            name="mail"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder={t('agencyBusiness.clients.form.email.name')}
                {...field}
                type="text"
                error={!!errors.mail}
                helperText={errors?.mail?.message}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>

        <DialogActions className="p-0 mt-20 justify-center">
          <Button
            className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
            variant="contained"
            color={callFrom === 'whatsapp' ? 'quickChat' : 'quickCampaign'}
            type="submit"
            disabled={
              _.isEmpty(dirtyFields) ||
              !isValid ||
              refreshIcon ||
              errorMessage === false ||
              (isEmailRequired && !getValues('mail')) ||
              phone?.phoneNumber?.length === 0
            }
          >
            {popUpButtonName}
            {refreshIcon && (
              <CircularProgress
                size={24}
                className={`absolute mx-auto ${
                  callFrom === 'whatsapp' ? 'text-quick-chat' : 'text-quick-campaign'
                }`}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddContact;
