// import { Link } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { deleteUserAccount, resetdeleteUser } from './store/deleteUserSlice';
import userData from '../query/user';
import reducer from './store';
/**
 * Form Validation Schema
 */

const defaultValues = {
  reason: '',
  email: '',
};

function DeleteUser() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .trim()
      .required(t('signInPage.formField.email.validationMessages.required')),
    reason: yup.string().required(t('users.deleteUser.validationMessages.deleteReason')),
  });

  const submitStatus = useSelector(({ deleteUserReducer }) =>
    deleteUserReducer?.deleteUser?.deleteUserAccountData?.data?.deleteUserAccount
      ? deleteUserReducer.deleteUser.deleteUserAccountData.data.deleteUserAccount
      : {}
  );
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [refreshIcon, setrefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    return () => {
      dispatch(resetdeleteUser());
    };
  }, [dispatch]);

  useEffect(() => {
    if (submitStatus?.status === 200) {
      setrefreshIcon(false);
    }
    if (submitStatus && submitStatus.status === 400) {
      enqueueSnackbar(submitStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
    } else if (submitStatus && submitStatus.status === 200) {
      enqueueSnackbar(submitStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
    }
  }, [reset, dispatch, submitStatus, enqueueSnackbar]);

  function onSubmit(data) {
    setrefreshIcon(true);
    const payload = {
      query: userData.deleteUserAccount,
      variables: {
        email: data.email,
        reason: data.reason,
      },
    };
    dispatch(deleteUserAccount(payload));
  }

  return (
    <div className="flex flex-col flex-auto items-center">
      <div
        className="top-cover bg-darkgreen-100 h-96 w-full"
        style={{ height: 'calc(100vh - 670px)' }}
      />
      <div className="md:flex md:flex-col block items-center justify-center w-full">
        <div>
          <Card className="relative -top-96 mx-16">
            <CardContent className="md:p-64 p-16 bg-white rounded-3xl md:w-3xl w-full md:mx-auto text-center">
              <div className="review-logo md:mb-60 mb-28">
                <img className="mx-auto" src="assets/images/logo/logo.png" alt="logo" />
              </div>

              <div className="reset-password-form">
                <Typography variant="h6" className="mb-24 font-bold text-18 sm:text-24">
                  {t('users.deleteUser.formFields.requestForDeleteAccount')}
                </Typography>
                <form
                  name="deleteUserForm"
                  noValidate
                  className="flex flex-col justify-center w-full"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <span className="block text-left text-16 font-medium mb-12">
                          {t('signInPage.formField.email.name')}
                        </span>
                        <TextField
                          {...field}
                          className="mb-40"
                          type="text"
                          placeholder={t('users.deleteUser.placeholder.enterEmail')}
                          name="email"
                          error={!!errors.email}
                          helperText={errors?.email?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="reason"
                    control={control}
                    render={({ field }) => (
                      <>
                        <span className="block text-left text-16 font-medium mb-12">
                          {' '}
                          {t('users.deleteUser.formFields.reason')}
                        </span>
                        <TextField
                          {...field}
                          className="mb-16"
                          type="text"
                          rows={3}
                          multiline
                          placeholder={t('users.deleteUser.placeholder.enterReason')}
                          error={!!errors.reason}
                          helperText={errors?.reason?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </>
                    )}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className="whitespace-nowrap rounded-md px-32 py-8 text-center bg-darkgreen text-white font-semibold text-18 mt-24 md:mb-56 mb-16"
                      aria-label="Reset"
                      disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                      type="submit"
                    >
                      {t('users.deleteUser.buttons.submit')}
                      {refreshIcon && (
                        <Icon
                          className={`text-14 ml-2 mt-1 absolute right-6 cursor-pointer text-white font-bold animate-spin ${
                            refreshIcon ? `animate-spin` : ''
                          }`}
                          title={t('users.deleteUser.title')}
                        >
                          <img
                            src="/assets/images/icon/loading.png"
                            alt="rating-5"
                            className="mx-auto"
                          />
                        </Icon>
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default withReducer('deleteUserReducer', reducer)(DeleteUser);
