import {
  Dialog,
  DialogTitle,
  Icon,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  TablePagination,
  Button,
} from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import wabaQuery from '../../query/waba';
import PreviewMessage from './PreviewMessage';
import { handleWabaApiRequest } from '../../common/common';

const ViewBroadcastDetailPopUp = (props) => {
  const { openValue, cancelCall, popUpMessage, waBroadcastId } = props;

  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [getBroadCastList, setGetBroadCastList] = useState([]);
  const [totalBroadCastList, setTotalBroadCastList] = useState();
  const [broadCastListStatusCount, setBroadCastListStatusCount] = useState();
  const [previewMessageDialog, setPreviewMessageDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleBroadCast = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getWABroadCastContactList,
        variables: {
          pageNumber: page,
          pageSize: rowsPerPage,
          waBroadcastId,
        },
      };
      const res = await handleWabaApiRequest(payload);

      if (res?.getWABroadCastContactList?.status === 200) {
        setGetBroadCastList(res?.getWABroadCastContactList?.data);
        setTotalBroadCastList(res?.getWABroadCastContactList?.totalCount);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [page, rowsPerPage, waBroadcastId]);

  useEffect(() => {
    try {
      const BroadCast = async () => {
        const payload = {
          query: wabaQuery.getWABroadCastPcData,
          variables: {
            waBroadcastId,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getWABroadCastPcData?.status === 200) {
          setBroadCastListStatusCount(res?.getWABroadCastPcData?.data);
        }
      };

      BroadCast();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [page, rowsPerPage, waBroadcastId]);

  useEffect(() => {
    handleBroadCast();
  }, [handleBroadCast]);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  }

  function EnhancedTableHead() {
    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.scheduled')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.myBroadcast.table.status')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Dialog
      classes={{
        paper:
          'm-24 max-w-screen-md width-full border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 py-16 px-20 border-b-1 border-solid border-grey-300">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full mb-0">
          {popUpMessage}
        </Typography>
      </DialogTitle>
      <div className="w-full p-20">
        <div className="md:grid grid-cols-3 gap-16 mb-28">
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">{broadCastListStatusCount?.sentCount}</span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.send')}
              </span>
            </div>
            <img
              src="/assets/images/business/send.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">
                {broadCastListStatusCount?.deliveredCount}
              </span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.delivered')}
              </span>
            </div>
            <img
              src="/assets/images/business/delivered.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">{broadCastListStatusCount?.readCount}</span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.read')}
              </span>
            </div>
            <img
              src="/assets/images/business/read.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">
                {broadCastListStatusCount?.repliedCount}
              </span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.replied')}
              </span>
            </div>
            <img
              src="/assets/images/business/replied.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">
                {broadCastListStatusCount?.sendingCount}
              </span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.sending')}
              </span>
            </div>
            <img
              src="/assets/images/business/sending.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
          <div className="bg-grey-100 border border-solid border-grey-300 rounded-md flex items-center justify-between px-20 py-16">
            <div>
              <span className="text-32 font-bold block">
                {broadCastListStatusCount?.failedCount}
              </span>
              <span className="text-20 font-semibold block text-grey-600">
                {t('waba.broadcast.myBroadcast.view.failed')}
              </span>
            </div>
            <img
              src="/assets/images/business/failed.png"
              className="img-fluid w-full max-w-48"
              alt="..."
            />
          </div>
        </div>
        <div className="mb-20 flex items-center justify-between">
          <span>
            <span className="font-medium text-16">
              {t('waba.broadcast.myBroadcast.table.contact')} :
            </span>{' '}
            <span className="font-medium text-16 text-darkgreen">{totalBroadCastList}</span>
          </span>
          <Button
            variant="contained"
            onClick={() => {
              setPreviewMessageDialog(true);
            }}
            color="secondary"
            size="small"
            className="rounded-md bg-darkgreen-100 text-darkgreen hover:text-white font-semibold"
          >
            {t('waba.broadcast.myBroadcast.view.msgPreview')}
          </Button>
        </div>

        <TableContainer className="border border-solid border-grey-300 rounded-md">
          <Table className="w-full" aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead />
            <TableBody className="block md:table-row-group">
              {getBroadCastList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    className="border border-t-2 border-t-grey-100 block md:table-row"
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell
                      className="text-14 color-black font-semibold block md:table-cell md:w-60 py-5 pt-16 md:py-16"
                      align="left"
                    >
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                      align="left"
                    >
                      {row?.customerName}
                    </TableCell>

                    <TableCell
                      className="text-14 color-black font-semibold block md:table-cell py-5 md:py-16"
                      align="left"
                    >
                      {row?.phoneNumber ? '+' : ''}
                      {row?.phoneNumber}
                    </TableCell>
                    <TableCell
                      className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16 capitalize"
                      align="left"
                    >
                      <span>{row?.status}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
            '.MuiToolbar-root': {
              minHeight: 0,
              padding: 0,
            },
          }}
          component="div"
          count={totalBroadCastList}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {previewMessageDialog && (
        <PreviewMessage
          openValue={previewMessageDialog}
          cancelCall={() => setPreviewMessageDialog(false)}
          waBroadcastId={waBroadcastId}
          popUpMessage={t('waba.broadcast.myBroadcast.view.msgPreview')}
        />
      )}
    </Dialog>
  );
};

export default ViewBroadcastDetailPopUp;
