import themesConfig from '../../../configs/themesConfig';
import BusinessList from './BusinessList';

const BusinessListConfig = {
  settings: {
    theme: {
      main: themesConfig.legacy,
    },
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'manageBusiness/:id?',
      element: <BusinessList />,
    },
  ],
};

export default BusinessListConfig;
