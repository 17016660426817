import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import history from '@history';
import { Typography } from '@mui/material';
import { findExpireDay, handleApiRequest } from '../../../common/common';
import businessData from '../../../query/business';
import quickHub from '../../../query/quickHub';
import CreateAndRecentCampaigns from './CreateAndRecentCampaigns';
import CommonPendingTask from '../../../CommonComponent/CommonPendingTask';
import DashboardAlerts from './DashboardAlert';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [loading, setLoading] = useState(true);
  const [campaignsDataPointData, setCampaignsDataPointData] = useState([]);

  useEffect(() => {
    const getCampaignsDataPoint = async () => {
      setLoading(true);
      try {
        const payloadData = {
          query: quickHub.getQuickCampaignDataPoint,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getQuickCampaignDataPoint?.status === 200) {
          setCampaignsDataPointData(resultData?.getQuickCampaignDataPoint?.data);
        } else {
          console.error('Unexpected status:', resultData?.getQuickCampaignDataPoint?.status);
        }
      } catch (error) {
        console.error('Error fetching suggestion posts:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getCampaignsDataPoint();
    }
  }, [userCurrentBusinessDetails]);

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-32 h-full l-padding">
      <div className="flex flex-col gap-32">
        {userSubscriptionData?.length &&
          userSubscriptionData
            ?.filter((subscriptionData) =>
              subscriptionData?.comboDetails?.length > 0
                ? subscriptionData.comboDetails.some((item) => item?.name === 'Quick Campaigns')
                : subscriptionData?.name === 'Quick Campaigns'
            )
            .map(
              (subscriptionData, i) =>
                subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
                subscriptionData?.isFreeTrial === true && (
                  <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                    <div>
                      {findExpireDay(subscriptionData?.expireDate) > 0 && (
                        <div className="w-full flex justify-center">
                          <div className="w-384 sm:w-640 items-center flex">
                            <img
                              className="block rounded max-w-28 sm:max-w-32"
                              name="logo"
                              src="/assets/images/icon/reviewNotificationIcon.png"
                              alt="bell notification icon"
                            />
                            <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                              {t('quickHubDashboard.your')}{' '}
                              {subscriptionData?.comboDetails?.length > 0
                                ? 'Quick Campaigns'
                                : subscriptionData?.name}{' '}
                              {t('quickHubDashboard.freeTrialExpire')}{' '}
                              {findExpireDay(subscriptionData?.expireDate)}{' '}
                              {t('business.searchBusiness.labels.days')}.
                            </p>
                            <Button
                              className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                              variant="contained"
                              size="small"
                              type="submit"
                              onClick={() => {
                                history.push(
                                  params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                                  {
                                    packageBundle: [8],
                                    intervalType: subscriptionData?.packageInterval,
                                    isBusinessAdded: true,
                                  }
                                );
                              }}
                            >
                              {t('quickHubDashboard.upgradeNow')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col gap-32">
              <CommonPendingTask
                productType="quick_campaigns"
                bgColor="quick-campaign"
                buttonColor="quickCampaign"
                videoImg="/assets/images/icon/playVideo-campaigns.svg"
              />
              {campaignsDataPointData?.length > 0 && (
                <div>
                  <p className="text-24 mb-10 font-700">{t('quickHubDashboard.matrices')}</p>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10 lg:grid-cols-3">
                    {campaignsDataPointData?.map((itemData) => (
                      <div
                        key={itemData?.name}
                        className="w-full p-14 flex items-center  h-144 rounded-4 justify-between space-y-4"
                        style={{ backgroundColor: itemData?.backgroundColor }}
                      >
                        <div>
                          {itemData?.key === 'Open Rate' || itemData?.key === 'Click Rate' ? (
                            <h1 className="text-28 sm:text-36 font-600">{itemData?.value} %</h1>
                          ) : (
                            <h1 className="text-28 sm:text-36 font-600">{itemData?.value}</h1>
                          )}
                          <p className="text-14 sm:text-20 font-600">{itemData?.key}</p>
                        </div>
                        <div className="rounded-full flex items-center justify-center  bg-white w-80 h-80">
                          <img src={itemData?.icon} alt={itemData?.name} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-32">
            <DashboardAlerts loading={loading} />
            <div className="bg-quick-campaign rounded-md relative shadow p-28 py-36 w-full">
              <div className="">
                <div className="flex items-center  w-full max-h-200 rounded-md">
                  <div className="w-2/3 flex flex-col gap-16 justify-center">
                    <Typography className="text-white font-500 text-14 sm:text-16">
                      {t('quickCampaigns.createCampaignsDesc')}
                    </Typography>
                    <Button
                      className="rounded-md text-quick-campaign text-14 sm:text-16 bg-white hover:bg-white max-w-112 min-w-112 md:max-w-192 md:min-w-192 mt-8"
                      variant="contained"
                      onClick={() =>
                        history.push(`quick-campaign/template/${params?.id ? params.id : ''}`)
                      }
                      aria-label="Edit"
                    >
                      <span className="text-16 sm:text-28 mr-5 sm:mr-10">+</span>
                      {t('quickCampaigns.createCampaigns')}
                    </Button>
                  </div>
                  <div className="w-1/3 flex justify-center">
                    <img
                      className="block rounded w-60 sm:w-112"
                      name="logo"
                      src="/assets/images/icon/createCampaigns.svg"
                      alt="bell notification icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <CreateAndRecentCampaigns loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboards;
