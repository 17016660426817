import { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import history from '@history';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  getAllUserBusinessSubscription,
  getUserBusinessDetail,
  setUserBusiness,
} from 'app/store/userSlice';
import { capitalizeFirstLetter, getParamsId, handleApiRequest } from '../../common/common';
import manageBusinessData from '../../query/manageBusiness';
import CheckPlanForAddNewBusiness from '../manage-business/checkPlanForAddNewBusiness/CheckPlanForAddNewBusiness';

const BusinessList = () => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const businessList = useSelector(getUserBusinessDetail);
  const [removeBusinessData, setRemoveBusinessIdData] = useState('');
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [planAvailableDialog, setPlanAvailableDialog] = useState(false);
  const [userAvailablePlan, setUserAvailablePlan] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const removeBusiness = async (id, isCancelOrNot) => {
    setRefreshIcon(true);

    try {
      const payload = {
        query: manageBusinessData.removeOrCancelUserBusinessRemoval,
        variables: {
          businessId: parseInt(id, 10),
          ...(isCancelOrNot && {
            cancelBusinessRemoval: isCancelOrNot,
          }),
        },
      };

      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.removeOrCancelUserBusinessRemoval?.status === 200) {
        setRemoveBusinessIdData();
        setOpenDialogue(false);
        const obj = {
          query: manageBusinessData.getUserBusinessDetail,
        };
        const res = await handleApiRequest(obj);
        if (res?.getUserBusinessDetail?.status === 200) {
          dispatch(setUserBusiness(res?.getUserBusinessDetail?.data));
        }
        enqueueSnackbar(result?.removeOrCancelUserBusinessRemoval?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.removeOrCancelUserBusinessRemoval?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      enqueueSnackbar('An error occurred while removing the business.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error(error);
    }
  };

  const handleCancel = () => {
    setRemoveBusinessIdData();
    setOpenDialogue(false);
  };

  const handleAddBusiness = () => {
    const findAvailablePlan = userSubscriptionData?.filter((item) => !item?.userBusinessId);
    if (findAvailablePlan?.length > 0) {
      setUserAvailablePlan(findAvailablePlan);
      setPlanAvailableDialog(true);
    } else {
      setOpenSelectProductDialog(true);
    }
  };

  const transformUserSubscriptionData = (removeBusinessId) => {
    return userSubscriptionData
      ?.filter((item) => item?.userBusinessId === removeBusinessId)
      .reduce((acc, item) => {
        const addToAcc = (details, key) => {
          details?.forEach((detail) => {
            if (detail[key]) {
              acc.push({ name: detail[key] });
            }
          });
        };
        if (item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
          acc.push({ name: item?.name });
        }
        addToAcc(item?.comboDetails, 'name');
        addToAcc(item?.packageBundleDetails, 'name');
        return acc;
      }, []);
  };

  return (
    <div className="p-24 bg-gray-A500 h-full">
      <div className="flex justify-between">
        <Typography className="font-bold text-24">{t('manageBusiness.manageBusiness')}</Typography>
        <Button
          className="bg-darkgreen text-white font-semibold rounded-md hover:bg-darkgreen px-20"
          onClick={() => handleAddBusiness()}
        >
          <AddIcon />
          {t('manageBusiness.button.addBusiness')}
        </Button>
      </div>
      {businessList?.length > 0 ? (
        <>
          {businessList?.map((item, index) => {
            return (
              <div className="bg-white p-16 w-[40%]" key={index}>
                <div className="flex gap-20">
                  <div className="">
                    <img
                      className="max-w-192 min-w-192 max-h-144 min-h-144 rounded-md"
                      src={item?.profileURL || 'assets/images/business/businessprofile.png'}
                      alt=""
                    />
                  </div>
                  <div className="grid">
                    <Typography className="font-semibold text-24">
                      {capitalizeFirstLetter(item?.name)}
                    </Typography>
                    {item?.address && (
                      <Typography className="text-14 mt-6 mb-12 max-w-384">
                        {t('manageBusiness.address')} - {item?.address}
                      </Typography>
                    )}
                    {item?.businessRemoveStatus?.isDelete ? (
                      <Button
                        className="bg-yellow-A600 text-white rounded px-40 disabled:bg-gray hover:bg-yellow-A600"
                        onClick={() =>
                          removeBusiness(item?.id, item?.businessRemoveStatus?.isDelete)
                        }
                        disabled={refreshIcon}
                      >
                        {t('manageBusiness.button.cancelRemoval')}
                        {refreshIcon && (
                          <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                        )}
                      </Button>
                    ) : (
                      <div className="flex gap-16">
                        <Button
                          className="border border-solid border-darkgreen text-darkgreen hover:bg-white rounded px-36 font-semibold"
                          onClick={() => {
                            setRemoveBusinessIdData(item);
                            setOpenDialogue(true);
                          }}
                        >
                          {t('manageBusiness.button.remove')}
                        </Button>
                        <Button
                          className="bg-darkgreen text-white rounded px-60 font-semibold hover:bg-darkgreen"
                          onClick={() =>
                            history.push(
                              getParamsId()
                                ? `/editBusinessDetails/${getParamsId()}`
                                : '/editBusinessDetails/',
                              { businessId: item?.id }
                            )
                          }
                        >
                          {t('manageBusiness.button.edit')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {item?.businessRemoveStatus?.isDelete && (
                  <Typography className="bg-yellow-A800 p-12 mt-8 rounded-md border border-yellow-A500 border-dashed border-solid">
                    {t('manageBusiness.deleteMessage')}
                  </Typography>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div className="text-center">
          <img className="m-auto mt-192" src="assets/images/business/addBusiness.svg" alt="" />
          <Typography className="font-bold text-24 text-center py-8">
            {t('manageBusiness.businessNotAdd')}
          </Typography>
          <Typography className="font-medium text-16 max-w-384 m-auto text-center py-12">
            {t('manageBusiness.notAddBusinessMessage')}
          </Typography>
          <Button className="bg-darkgreen text-white mt-16 font-semibold rounded-md hover:bg-darkgreen px-20">
            <AddIcon />
            {t('manageBusiness.button.addBusiness')}
          </Button>
        </div>
      )}
      <Dialog
        classes={{
          paper: '-mt-12 p-8 rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-16">
          <Icon
            onClick={() => setOpenDialogue(false)}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <DialogContent className="flex justify-center items-center">
            <div>
              <div className="text-center">
                <img className="m-auto" src="assets/images/icon/deleteIcon.svg" alt="" />
                <Typography className="text-18 font-semibold text-black w-full py-12 max-w-320 m-auto">
                  {t('manageBusiness.deleteWarningMessage')}
                </Typography>
                <Typography className="text-14 text-black max-w-384 m-auto font-semibold">
                  {t('manageBusiness.note')} : {t('manageBusiness.noteDeleteBusiness')}
                </Typography>
              </div>
              <div className="bg-gray-A500 p-16 my-8">
                <Typography className="mb-8 text-14">
                  {t('manageBusiness.currentProduct')}{' '}
                  {capitalizeFirstLetter(removeBusinessData?.name)}:
                </Typography>
                {transformUserSubscriptionData(removeBusinessData?.id)?.length > 0 &&
                  transformUserSubscriptionData(removeBusinessData?.id)?.map((item, index) => {
                    return (
                      <Typography key={index} className="mb-6 text-14">
                        - {item?.name}
                      </Typography>
                    );
                  })}
              </div>
              <Typography className="text-14 text-black">
                {t('manageBusiness.removeBusinessDescription')}
              </Typography>
              <div className="flex items-center justify-center gap-16 mt-20">
                <Button
                  className="border border-solid border-darkgreen text-darkgreen hover:bg-white rounded px-52 font-semibold"
                  onClick={() => handleCancel()}
                >
                  {t('manageBusiness.button.cancel')}
                </Button>
                <Button
                  className="bg-darkgreen text-white rounded px-28 disabled:bg-gray font-semibold hover:bg-darkgreen"
                  onClick={() => removeBusiness(removeBusinessData?.id)}
                  disabled={refreshIcon}
                >
                  {t('manageBusiness.button.removeBusiness')}
                  {refreshIcon && (
                    <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                  )}
                </Button>
              </div>
            </div>
          </DialogContent>
        </DialogTitle>
      </Dialog>
      <CheckPlanForAddNewBusiness
        openSelectProductDialog={openSelectProductDialog}
        planAvailableDialog={planAvailableDialog}
        userAvailablePlan={userAvailablePlan}
        setOpenSelectProductDialog={setOpenSelectProductDialog}
        setPlanAvailableDialog={setPlanAvailableDialog}
        setUserAvailablePlan={setUserAvailablePlan}
      />
    </div>
  );
};

export default BusinessList;
