import * as yup from 'yup';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useParams } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { motion } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import history from '@history';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import selectedBusinessData from '../../query/selectedBusiness';
import { getEncryptedData, handleApiRequest } from '../../common/common';
import BusinessInformation from './business-informaion-tabs/BusinessInformation';
import QrCode from './business-informaion-tabs/QrCode';
import AccountSettings from './business-informaion-tabs/AccountSettings';
import ConvertToRealAccount from './business-informaion-tabs/ConvertToRealAccount';

const schemaFeedback = yup.object().shape({
  note: yup.string().required('Note is required'),
  phone_number: yup.string().required('Phone Number is required'),
});

function SelectedBusinessDetails() {
  const routeParams = useParams();
  const { t } = useTranslation();
  const currentPath = useLocation();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [accountRefreshIcon, setAccountRefreshIcon] = useState(false);
  const [businessId, setBusinessId] = useState(parseInt(routeParams.businessId, 10));
  const [deactiveAccount, setDeactiveAccount] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedBusinessListDetails, setSelectedBusinessListDetails] = useState();
  const [getDemoUserAccountSettingDetails, setGetDemoUserAccountSettingDetails] = useState();
  const [getSalesPersonVisitedStatusDetails, setGetSalesPersonVisitedStatusDetails] = useState();
  const [getBusinessesOwnerFeedbackOptionDetails, setGetBusinessesOwnerFeedbackOptionDetails] =
    useState();
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const payload = {
        query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
        variables: {
          businessId,
          searchText: '',
          pageNumber: 1,
          pageSize: 10,
          ...(currentPath.pathname.includes('deactive-business-details') && {
            isDeactivated: true,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      await setLoading(false);
      if (result?.getDemoBusinessWithDemoUserDetail?.status === 200) {
        setSelectedBusinessListDetails(result?.getDemoBusinessWithDemoUserDetail?.data[0]);
      }
    }
    fetchData();
  }, [businessId, currentPath.pathname]);

  useEffect(() => {
    async function fetchSalesPersonVisitedStatusData() {
      const payload = {
        query: selectedBusinessData.getSalesPersonVisitedStatus,
      };
      const result = await handleApiRequest(payload);
      if (result?.getSalesPersonVisitedStatus?.status === 200) {
        setGetSalesPersonVisitedStatusDetails(result?.getSalesPersonVisitedStatus?.data);
      }
    }
    if (tabValue === 2) {
      fetchSalesPersonVisitedStatusData();
    }
  }, [tabValue]);

  useEffect(() => {
    async function fetchBusinessOwnerFeedbackOptionData() {
      const payload = {
        query: selectedBusinessData.getBusinessesOwnerFeedbackOption,
      };
      const result = await handleApiRequest(payload);
      if (result?.getBusinessesOwnerFeedbackOption?.status === 200) {
        setGetBusinessesOwnerFeedbackOptionDetails(result?.getBusinessesOwnerFeedbackOption?.data);
      }
    }
    if (tabValue === 2) {
      fetchBusinessOwnerFeedbackOptionData();
    }
  }, [tabValue]);

  useEffect(() => {
    async function fetchDemoUserSettingData() {
      const payload = {
        query: selectedBusinessData.getDemoUserAccountSetting,
        variables: {
          userId: parseInt(selectedBusinessListDetails?.users?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getDemoUserAccountSetting?.status === 200) {
        setGetDemoUserAccountSettingDetails(result?.getDemoUserAccountSetting?.data);
      }
    }
    if (selectedBusinessListDetails?.users?.id) {
      fetchDemoUserSettingData();
    }
  }, [selectedBusinessListDetails]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleDemoAccont = async () => {
    await setAccountRefreshIcon(true);
    await setDeactiveAccount(true);
    const data = getEncryptedData(
      `{"activate": false, "userId": ${parseInt(selectedBusinessListDetails?.users?.id, 10)}}`
    );
    const payload = {
      query: selectedBusinessData.updateDemoAccountBySalesUser,
      variables: { data },
    };
    const result = await handleApiRequest(payload);
    if (result?.updateDemoAccountBySalesUser?.status === 200) {
      const baseUrl = window.location.origin;
      const deactivateAccountUrl = `${baseUrl}/deactivated-account`;
      window.location.href = deactivateAccountUrl;
      enqueueSnackbar(result?.updateDemoAccountBySalesUser?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setAccountRefreshIcon(false);
      setOpenDialog(false);
      if (deactiveAccount) {
        if (getDemoUserAccountSettingDetails?.salesPersonVisited) {
          history.push('/visited-business');
        } else {
          history.push('/selected-business');
        }
      }
      closeSnackbar(3000);
    } else {
      enqueueSnackbar(result?.updateDemoAccountBySalesUser?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setAccountRefreshIcon(false);
      setOpenDialog(false);
      closeSnackbar(3000);
    }
  };

  const handleBookmark = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    const payload = {
      query: selectedBusinessData.addRemoveBookmark,
      variables: { businessId: id, isBookmark: newStatus },
    };
    const result = await handleApiRequest(payload);
    if (result?.addRemoveBookmark?.status === 200) {
      const payloadData = {
        query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
        variables: {
          businessId,
          searchText: '',
          pageNumber: 1,
          pageSize: 10,
        },
      };
      const res = await handleApiRequest(payloadData);
      setLoading(false);
      if (res?.getDemoBusinessWithDemoUserDetail?.status === 200) {
        setSelectedBusinessListDetails(res?.getDemoBusinessWithDemoUserDetail?.data[0]);
      }
    }
  };

  return (
    <div className="p-20 lg:p-32">
      <div className="flex flex-1 w-full items-center justify-between md:mb-36 mb-20">
        <div className="flex items-center">
          <div>
            <Toolbar className="px-0 font-semibold min-h-fit mb-16">
              <Icon
                className="text-20 cursor-pointer"
                onClick={() => {
                  history.go(-1);
                  setBusinessId();
                }}
              >
                west
              </Icon>
              <Typography
                variant="subtitle1"
                color="inherit"
                className="flex-1 px-12 font-bold text-18"
              >
                {t('business.title')}
              </Typography>
            </Toolbar>
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-16 md:text-28 font-bold"
            >
              {t('business.selectedBusinessDetail.labels.viewBusinessDetails')}&nbsp;
              <span>
                <IconButton
                  onClick={() =>
                    handleBookmark(
                      selectedBusinessListDetails?.id,
                      selectedBusinessListDetails?.isBookmark
                    )
                  }
                  className="bg-white"
                >
                  {selectedBusinessListDetails?.isBookmark ? (
                    <StarIcon className="text-darkgreen" />
                  ) : (
                    <StarBorderIcon className="text-darkgreen" />
                  )}
                </IconButton>
              </span>
            </Typography>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        <Tabs
          value={tabValue}
          className="bg-grey-50 rounded-tl-md rounded-tr-md overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: 'scroll !important',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={0}
            label={t('business.selectedBusinessDetail.labels.businessInformation')}
          />
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={1}
            label={t('business.selectedBusinessDetail.labels.qrCode')}
          />
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={2}
            label={t('business.selectedBusinessDetail.labels.accountSettings')}
          />
          {/* <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={3}
            label={t('business.selectedBusinessDetail.labels.convertToRealAccount')}
        /> */}
        </Tabs>
        <div className="p-20 lg:p-32">
          <div className={tabValue !== 0 ? 'hidden' : ''}>
            <BusinessInformation selectedBusinessListDetails={selectedBusinessListDetails} />
          </div>
          <div className={tabValue !== 1 ? 'hidden' : ''}>
            <QrCode selectedBusinessListDetails={selectedBusinessListDetails} />
          </div>
          <div className={tabValue !== 2 ? 'hidden' : ''}>
            <AccountSettings
              getDemoUserAccountSettingDetails={getDemoUserAccountSettingDetails}
              getSalesPersonVisitedStatusDetails={getSalesPersonVisitedStatusDetails}
              getBusinessesOwnerFeedbackOptionDetails={getBusinessesOwnerFeedbackOptionDetails}
              selectedBusinessListDetails={selectedBusinessListDetails}
            />
          </div>
          {/* <div className={tabValue !== 3 ? 'hidden' : ''}>
            <ConvertToRealAccount
              getDemoUserAccountSettingDetails={getDemoUserAccountSettingDetails}
              selectedBusinessListDetails={selectedBusinessListDetails}
            />
        </div> */}
          {!selectedBusinessListDetails && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full"
            >
              <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                {t('business.selectedBusinessDetail.validationMessage.noBusinessDetails')}
              </Typography>
            </motion.div>
          )}
        </div>
      </div>
      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-28 max-w-400',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 text-center">
            <Icon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <Icon className="mx-auto text-64 text-yellow-700 font-normal">info</Icon>
            <Typography className="text-18 font-bold text-black text-center w-full pb-10">
              {t('business.selectedBusinessDetail.labels.deactivatingAccount')}
            </Typography>
          </DialogTitle>
          <DialogContent className="p-0 mb-24">
            <DialogContentText
              id="alert-dialog-description"
              className="text-16 font-medium text-center text-black max-w-sm"
            >
              {t('business.selectedBusinessDetail.labels.removeFromAccount')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              variant="contained"
              color="secondary"
              className="md:text-18 font-semibold disabled:text-black rounded-3xl px-28 pb-6"
              aria-label="deactive account"
              onClick={() => {
                handleDemoAccont();
              }}
              disabled={accountRefreshIcon}
              type="button"
              size="small"
            >
              {t('business.selectedBusinessDetail.buttons.deactivateAccount')}
              {accountRefreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default SelectedBusinessDetails;
