import Dashboard from './dashboard/Dashboard';
import Campaign from './campaigns/Campaign';
import Contacts from './contacts/Contacts';
import CSVUploadProgress from './contacts/CSVUpload';
import CampaignDetails from './createCampaigns/CampaignDetails';
import CreateCampaign from './createCampaigns/CreateCampaign';
import Template from './template/Template';
import PlanUsage from './planUsage/PlanUsage';

const QuickCampaignsConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-campaign/Dashboard/:id?',
      element: <Dashboard />,
    },
    {
      path: 'quick-campaign/campaign/:id?',
      element: <Campaign />,
    },
    {
      path: 'quick-campaign/contact/:id?',
      element: <Contacts />,
    },
    {
      path: 'quick-campaign/template/:id?',
      element: <Template />,
    },
    {
      path: 'quick-campaign/campaign-csv/:id?',
      element: <CSVUploadProgress />,
    },
    {
      path: 'quick-campaign/createCampaign/:id?',
      element: <CreateCampaign />,
    },
    {
      path: 'quick-campaign/planUsage/:id?',
      element: <PlanUsage />,
    },
  ],
};

export default QuickCampaignsConfigs;
