import QrCode from './QrCode';

const QrCodeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/qrCode/:id?',
      element: <QrCode />,
    },
  ],
};

export default QrCodeConfig;
