import { t } from 'i18next';
import { Button, Checkbox, Icon, MenuItem, Select, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback } from 'react';
import history from '@history';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { connectSocket } from '../../../../socket';
import './Waba.css';
import TemplateList from './TemplateList';
import wabaQuery from '../../query/waba';
import { selectDashboardData } from '../../../store/userSlice';
import {
  handleWabaApiRequest,
  convertTemplateToHtml,
  getDecryptData,
  getParamsId,
  capitalizeFirstLetter,
} from '../../common/common';
import quickChatQuery from '../../query/quickChat';

const WabaInbox = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const chatScroll = useRef(null);
  const chatContainerRef = useRef(null);
  const [messageInput, setMessageInput] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [pageLoading, setPageLoading] = useState(false);
  const [chatType, setChatType] = useState(0);
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [isExpandedSearch, setIsExpandedSearch] = useState(false);
  const [openTemplateListDialog, setOpenTemplateListDialog] = useState(false);
  const [isExpandedNote, setIsExpandedNote] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [getChatNote, setGetChatNote] = useState([]);
  const [wpUnreadCount, setWpUnreadCount] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noteValue, setNoteValue] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false); // socket.connected
  const inputRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [editedNote, setEditedNote] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const emojiPickerRef = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const socket = connectSocket();
  const [filterIds, setFilterIds] = useState([]);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [statusList, setStatusList] = useState('');
  const [filteredData, setFilteredData] = useState(statusList.value);
  const [chatRoomId, setChatRoomId] = useState('');
  const [status, setStatus] = useState('');

  const whatAppUserDetails = useCallback(async () => {
    try {
      if (selectedContact) {
        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        const payload = {
          query: wabaQuery.getAllMessage,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
            sortBy: 'cts',
            sortOrder: 'desc',
            pageNumber: currPage,
            pageSize: 20,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getAllMessage?.status === 200) {
          setHasMore(true);
          if (!res?.getAllMessage?.data?.length) {
            setWasLastList(true);
            setHasMore(false);
            return;
          }
          setPrevPage(currPage);
          setSelectContactMessageList([
            ...(res?.getAllMessage?.data.reverse() || []),
            ...selectContactMessageList,
          ]);
          setTimeout(() => {
            const newScrollHeight = chatContainerRef.current?.scrollHeight;
            chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
          }, 0);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, currPage, selectContactMessageList]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setPageLoading(true);
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setPageLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data && Object.keys(res?.getUserDetail?.data)?.length > 0) {
              const decryptedData = JSON.parse(getDecryptData(res?.getUserDetail?.data));
              setUserDetails(decryptedData);
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            } else {
              const paramsId = getParamsId();
              history.push({
                pathname: `quick-chat/waba/${paramsId}`,
              });
            }
          }
        } catch (error) {
          setPageLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (selectedContact) {
      if (!wasLastList && prevPage !== currPage) {
        whatAppUserDetails();
      }
    }
  }, [
    selectedContact,
    currPage,
    wasLastList,
    prevPage,
    selectContactMessageList,
    whatAppUserDetails,
    hasMore,
  ]);

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop <= 4 && !wasLastList && hasMore) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  const whatAppUserChatRoom = useCallback(
    async (data) => {
      if (!userCurrentBusinessDetails?.id) return;
      try {
        const payload = {
          query: wabaQuery.getChatRoomWithLastMessage,
          variables: {
            isBroadcast: chatType === 2,
            isUnRead: chatType === 1,
            search: searchValue,
            status: data,
            userBusinessId: userCurrentBusinessDetails?.id,
          },
        };
        const res = await handleWabaApiRequest(payload);
        if (res?.getChatRoomWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getChatRoomWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [chatType, searchValue, userCurrentBusinessDetails?.id]
  );

  useEffect(() => {
    whatAppUserChatRoom();
  }, [whatAppUserChatRoom]);

  useEffect(() => {
    if (isExpandedSearch) {
      inputRef.current.focus();
    }
  }, [isExpandedSearch]);

  useEffect(() => {
    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleReceivedMessage = (message) => {
      if (message?.payload?.eventType === 'MESSAGES_STATUS') {
        const messageId = message?.payload?.data?.messageId;
        setSelectContactMessageList((prevList) =>
          prevList.map((msg) =>
            msg.messageId === messageId ? { ...msg, status: message?.payload?.data?.status } : msg
          )
        );
      }

      if (message?.payload?.eventType === 'MESSAGE_FROM_CLIENT') {
        if (message?.payload?.data?.waChatRoomId === selectedContact?.waChatRoomId) {
          setSelectContactMessageList((prevMessages) => [
            ...prevMessages,
            {
              ...message?.payload?.data,
              cts: Number(message?.payload?.data?.timestamp) * 1000,
            },
          ]);
        }
        setGetChatRoom((prevContacts) => {
          const contactExists = prevContacts?.some(
            (contact) => contact?.waChatRoomId === message?.payload?.data?.waChatRoomId
          );

          let updatedContacts;

          if (!contactExists) {
            updatedContacts = [
              {
                ...message?.payload?.data,
                cts: Number(message?.payload?.data?.timestamp) * 1000,
                unRead: 1,
              },
              ...prevContacts,
            ];
          } else {
            updatedContacts = prevContacts.map((contact) =>
              contact.waChatRoomId === message?.payload?.data?.waChatRoomId
                ? {
                    ...contact,
                    ...message?.payload?.data,
                    cts: Number(message?.payload?.data?.timestamp) * 1000,
                    unRead: 1,
                  }
                : contact
            );
          }
          return updatedContacts.sort((a, b) => b.cts - a.cts);
        });
      }
    };
    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on('receivedMessage', handleReceivedMessage);
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('receivedMessage', handleReceivedMessage);
      socket.off('disconnect', handleDisconnect);
    };
  }, [selectedContact, socket]);

  const handleClickOutside = (event) => {
    if (emojiPickerRef?.current && !emojiPickerRef?.current?.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document?.addEventListener('mousedown', handleClickOutside);
    } else {
      document?.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  const handleGetNote = useCallback(async () => {
    try {
      const payload = {
        query: wabaQuery.getChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
        },
      };
      const res = await handleWabaApiRequest(payload);
      if (res?.getChatRoomNote?.status === 200) {
        setGetChatNote(res?.getChatRoomNote?.data);
        setIsExpandedNote(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact?.waChatRoomId]);

  useEffect(() => {
    try {
      if (selectedContact) {
        handleGetNote();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, handleGetNote]);

  const handleContactClick = (contact) => {
    setSelectContactMessageList([]);
    setSelectedContact(contact);
    setGetChatRoom((prevList) =>
      prevList.map((roomData) =>
        roomData.waChatRoomId === contact?.waChatRoomId ? { ...roomData, unRead: 0 } : roomData
      )
    );
    setCurrPage(1);
    setPrevPage(0);
    setHasMore(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };

  // useEffect(() => {
  //   if (chatScroll.current) {
  //     chatScroll.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [selectContactMessageList]);

  const handleSendMessage = async (value, template) => {
    if (value === 'text' && (!messageInput || messageInput?.trim() === '')) {
      return;
    }
    setMessageInput('');
    const tempMessageId = `${Date.now()}${selectedContact?.id}`;

    let newMessage = {};
    let updatedTemplateData;
    if (value === 'template') {
      updatedTemplateData = { ...template };

      if (typeof updatedTemplateData?.dynamicData === 'string') {
        try {
          updatedTemplateData.dynamicData = JSON.parse(updatedTemplateData?.dynamicData);
        } catch (error) {
          console.error('Error parsing dynamicData:', error);
          return;
        }
      }

      if (updatedTemplateData?.dynamicData?.body) {
        updatedTemplateData.dynamicData.body = updatedTemplateData?.dynamicData?.body?.map(
          (item) => {
            if (item.type === 'text') {
              return { ...item, value: selectedContact?.name };
            }
            return item;
          }
        );
      } else {
        console.log('Body does not exist or is empty.');
      }

      updatedTemplateData.dynamicData = JSON?.stringify(updatedTemplateData?.dynamicData);

      newMessage = {
        type: 'template',
        templateId: template?.id,
        cts: moment.utc(),
        tempMessageId,
        status: 'sent',
        templateData: template?.templateData,
        dynamicData: updatedTemplateData?.dynamicData,
      };
    } else {
      newMessage = {
        type: 'text',
        message: messageInput,
        cts: moment?.utc(),
        tempMessageId,
        status: 'sent',
      };
    }
    setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    chatScroll?.current?.scrollIntoView({ behavior: 'smooth' });

    try {
      const payload = {
        query: wabaQuery.sendMessages,
        variables: {
          name: selectedContact?.name,
          phoneNumber: selectedContact?.phoneNumber,
          tempMessageId,
          type: value === 'template' ? 'template' : 'text',
          waBusinessPhoneNumberId: whatsAppPhoneNumberId,
        },
      };
      if (value === 'template' && template) {
        payload.variables.templateId = template?.id;
        payload.variables.templateComponents = updatedTemplateData?.dynamicData;
      }
      if (value === 'text') {
        payload.variables.message = messageInput;
      }
      const res = await handleWabaApiRequest(payload);
      if (res.sendMessages?.status === 200) {
        const updatedTempMessageId = res?.sendMessages?.data?.tempMessageId;
        const messageId = res?.sendMessages?.data?.messageId;
        setSelectContactMessageList((prevList) =>
          prevList.map((msg) =>
            msg.tempMessageId === updatedTempMessageId ? { ...msg, messageId } : msg
          )
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const createNote = async () => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.createChatRoomNote,
        variables: {
          chatRoomId: selectedContact?.waChatRoomId,
          note: noteValue,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.createChatRoomNote?.status === 200) {
        setNoteValue('');
        handleGetNote();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const deleteNote = async (id) => {
    setLoading(true);
    try {
      const payload = {
        query: wabaQuery.deleteChatRoomNote,
        variables: {
          noteId: id,
        },
      };
      const res = await handleWabaApiRequest(payload);
      setLoading(false);
      if (res?.deleteChatRoomNote?.status === 200) {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        handleGetNote();
      } else {
        enqueueSnackbar(res?.deleteChatRoomNote?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleEditClick = (note) => {
    setEditedNote(note?.id);
    setEditedContent(note?.note);
  };

  const handleTemplateSelect = async (template) => {
    if (template) {
      setSelectedTemplate(template);

      await handleSendMessage('template', template);
      setOpenTemplateListDialog(false);
    }
  };

  const handleSaveClick = async (note) => {
    try {
      if (note?.note !== editedContent) {
        const payload = {
          query: wabaQuery.editChatRoomNote,
          variables: {
            noteId: note?.id,
            note: editedContent,
          },
        };
        const res = await handleWabaApiRequest(payload);
        setLoading(false);
        if (res?.editChatRoomNote?.status === 200) {
          enqueueSnackbar(res?.editChatRoomNote?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          handleGetNote();
          setEditedNote(null);
          setEditedContent('');
        }
      } else {
        setEditedNote(null);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const formattedDate = (timestamp) => {
    const date = moment.unix(timestamp / 1000);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (date?.isSame(today, 'day')) {
      return date?.format('h:mm A');
    }
    if (date?.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('MMM DD, YYYY');
  };

  const groupMessagesByDate = (messages) => {
    return messages?.reduce((groups, message) => {
      const date = moment.unix(message.cts / 1000).format('MMM DD, YYYY');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date]?.push(message);
      return groups;
    }, {});
  };

  useEffect(() => {
    try {
      const getStatusList = async () => {
        const payload = {
          query: wabaQuery.getChatRoomResolveStatusList,
        };
        const result = await handleWabaApiRequest(payload);
        if (result?.getChatRoomResolveStatusList?.status === 200) {
          setStatusList(result?.getChatRoomResolveStatusList?.data);
        }
      };
      getStatusList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (!selectedContact?.waChatRoomId) {
      return;
    }
    const fetchUserDetails = async () => {
      try {
        const payload = {
          query: wabaQuery.getWaUserDetailsOnChatRoomId,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
          },
        };

        const result = await handleWabaApiRequest(payload);

        if (result?.getWaUserDetailsOnChatRoomId?.status === 200) {
          setChatRoomId(result?.getWaUserDetailsOnChatRoomId);
        } else {
          console.error('Failed to fetch user details:', result);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchUserDetails();
  }, [selectedContact?.waChatRoomId]);

  const updateStatus = useCallback(
    async (update) => {
      try {
        const payload = {
          query: wabaQuery.updateChatRoomResolveStatus,
          variables: {
            waChatRoomId: selectedContact?.waChatRoomId,
            status: update,
          },
        };
        const result = await handleWabaApiRequest(payload);
        if (result?.updateChatRoomResolveStatus?.status === 200) {
          whatAppUserChatRoom();
          enqueueSnackbar(result?.updateChatRoomResolveStatus?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result?.updateQuickChatResolveStatus?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [enqueueSnackbar, selectedContact?.waChatRoomId, whatAppUserChatRoom]
  );

  useEffect(() => {
    if (Array.isArray(statusList)) {
      const selectedStatus = statusList?.find(
        (state) => state?.value?.toLowerCase() === selectedContact?.chatResolveStatus?.toLowerCase()
      );
      if (selectedStatus) {
        setStatus(selectedStatus?.value);
      }
    }
  }, [selectedContact?.chatResolveStatus, statusList]);

  const handleSelectFilter = (selectedItem) => {
    const updatedFilterIds = filterIds?.some((filterId) => filterId?.key === selectedItem?.key)
      ? filterIds.filter((filterId) => filterId?.key !== selectedItem?.key)
      : [...filterIds, selectedItem];
    setFilterIds(updatedFilterIds);
    const selectedKeys = updatedFilterIds?.map((item) => item?.key);
    setFilteredData(selectedKeys);
  };

  const applyFilters = () => {
    setShowFilterOption(false);
    whatAppUserChatRoom(filteredData);
  };

  const changeReviewPlatForm = (value) => {
    setStatus(value);
    const selectedStatus = statusList?.find((state) => state?.value === value);

    if (selectedStatus) {
      updateStatus(selectedStatus.key);
    }
  };

  const searchHandle = (event) => {
    setSearchValue(event?.target?.value);
  };
  if (pageLoading) {
    return <FuseLoading />;
  }

  const replacePlaceholders = (text, replacement) => {
    if (!text || !replacement) return text;
    return text.replace('{{1}}', replacement);
  };

  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="flex w-full items-center justify-between mb-24">
          <div className="flex items-center justify-between">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('navigation.wabaInbox')}
            </Typography>
          </div>
        </div>
        <div className="bg-white rounded-md border-1 border-solid border-grey-300">
          {userDetails?.status !== 'PENDING' ? (
            <div className="flex h-screen chat-height">
              {/* Left Sidebar */}
              <div className="w-1/4 overflow-auto">
                <div>
                  <div>
                    {/* <Select
                      className="inline-block border-none text-black capitalize min-h-0 h-auto p-0 font-semibold text-16"
                      id="msgType"
                      value={chatType}
                      size="small"
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '& .MuiSelect-select': {
                          padding: '0px 0',
                          display: 'flex',
                          alignItems: 'center',
                          border: 'none',
                        },
                      }}
                      onChange={(e) => setChatType(e?.target?.value)}
                    >
                      <MenuItem value={0} className="capitalize">
                        {t('waba.inbox.allChat')}
                      </MenuItem>
                      <MenuItem value={1} className="capitalize">
                        {t('waba.inbox.unreadChat')}
                      </MenuItem>
                      <MenuItem value={2} className="capitalize">
                        {t('waba.inbox.broadcast')}
                      </MenuItem>
                    </Select> */}
                    <div className="flex items-center px-16 justify-between h-64 gap-5 ">
                      <div className="relative w-full">
                        <input
                          ref={inputRef}
                          type="text"
                          className="px-10 py-2 border rounded-md w-full h-36 border-none bg-gray-A500"
                          placeholder={t('waba.inbox.searchPlaceholder')}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setShowFilterOption(false);
                          }}
                          value={searchValue}
                        />

                        {searchValue ? (
                          <Icon
                            aria-hidden
                            className="absolute right-10 top-10 text-16 cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              setShowFilterOption(false);
                            }}
                          >
                            close
                          </Icon>
                        ) : (
                          <Icon
                            aria-hidden
                            className="absolute right-10 top-10 text-16 cursor-pointer"
                            onClick={() => {
                              setIsExpandedSearch(true);
                              setShowFilterOption(false);
                            }}
                          >
                            search
                          </Icon>
                        )}
                      </div>
                      <div className="flex gap-3">
                        <Icon
                          className="cursor-pointer justify-center items-center flex bg-gray-A500 rounded-md hover:bg-grey px-16 py-10 h-36 "
                          onClick={() => {
                            const id = getParamsId();
                            if (id) {
                              history.push(`quick-chat/waba-contacts/${id}`, {
                                openDialog: true,
                              });
                            } else {
                              history.push('quick-chat/waba-contacts', {
                                openDialog: true,
                              });
                            }
                          }}
                          aria-hidden
                        >
                          add
                        </Icon>

                        <div className="flex items-center justify-center ">
                          <Button
                            className={` bg-gray-A500 ${
                              filterIds?.length > 0 ? 'text-quick-review' : 'border-gray-500'
                            } rounded-md w-28 min-w-28 p-16 h-24 min-h-24 hover:bg-grey`}
                            onClick={() => setShowFilterOption((prev) => !prev)}
                          >
                            <FilterListIcon
                              className={`${
                                filterIds?.length > 0 ? 'text-quick-review' : 'text-gray-800'
                              } opacity-100`}
                            />
                          </Button>
                          {filterIds?.length > 0 && (
                            <Typography className="absolute left-36 top-8 text-12 font-semibold bg-quick-review text-white rounded-full flex items-center justify-center">
                              {filterIds?.length}
                            </Typography>
                          )}
                        </div>
                        <div>
                          {showFilterOption && (
                            <div className="absolute z-10 top-144 left-192 bg-white max-w-192 rounded-md shadow-3">
                              {statusList?.length > 0 &&
                                statusList?.map((item, i) => {
                                  return (
                                    <div key={i} className="flex items-center mt-16 px-7">
                                      <Checkbox
                                        className="p-0 text-grey-300"
                                        checked={filterIds.some(
                                          (filterId) => filterId?.key === item?.key
                                        )}
                                        onChange={() => handleSelectFilter(item)}
                                      />
                                      <Typography className="text-14 text-grey-800 ml-6 font-medium">
                                        {item?.value}
                                      </Typography>
                                    </div>
                                  );
                                })}
                              <div className="flex border-t-2 py-10 px-7 mt-10 gap-12 border-grey-200">
                                <Button
                                  className="rounded-md py-0 px-16"
                                  size="small"
                                  variant="outlined"
                                  color="quickChat"
                                  type="button"
                                  onClick={() => setShowFilterOption(false)}
                                >
                                  {t('quickChat.cancel')}
                                </Button>
                                <Button
                                  className="rounded-md py-0 px-24"
                                  size="small"
                                  variant="contained"
                                  color="quickChat"
                                  type="button"
                                  onClick={applyFilters}
                                >
                                  {t('quickChat.apply')}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-16">
                  {getChatRoom && getChatRoom?.length > 0 ? (
                    <>
                      {getChatRoom?.map((contact, index) => (
                        <div
                          key={index}
                          className={`mb-10 cursor-pointer hover:bg-quick-chat-100 border-1 border-solid  rounded-md p-10 ${
                            selectedContact?.id === contact?.id
                              ? 'bg-quick-chat-100 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                              : 'bg-white border-grey-300 pl-12'
                          }`}
                          aria-hidden
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="flex items-center">
                            <img
                              src="/assets/images/business/profile.png"
                              className="img-fluid w-full max-w-48 mr-10"
                              alt="..."
                            />

                            <div className="overflow-hidden w-full">
                              <div className="flex items-center justify-between">
                                <h3 className="text-16 font-semibold w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                  {contact?.name || contact?.phoneNumber || contact?.from}
                                </h3>
                                <span className="text-12 font-semibold w-112 text-right">
                                  {formattedDate(contact?.cts) ||
                                    formattedDate(Number(contact?.timestamp) * 1000)}
                                </span>
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="font-medium text-14 whitespace-nowrap overflow-hidden text-ellipsis mt-6 block text-grey-600">
                                  {contact?.message}
                                </span>
                                <span
                                  className={`text-right bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 ${
                                    contact?.unRead === 0 || !contact?.unRead ? 'hidden' : ''
                                  }`}
                                >
                                  New
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {searchValue ? (
                        <Typography className="text-center font-medium">
                          {t('waba.inbox.noResult')}
                        </Typography>
                      ) : (
                        <Typography className="text-center font-medium">
                          {t('waba.inbox.noContact')}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Middle Section */}
              <div
                className={`flex-grow relative ${
                  selectedContact ? 'w-3/5' : 'w-4/5'
                } border-x-1 border-solid border-grey-300`}
              >
                {selectedContact ? (
                  <>
                    <div className="border-b-1 border-solid border-grey-300 h-64 grid align-middle w-full px-16">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-center">
                          <img
                            src="/assets/images/business/profile.png"
                            className="img-fluid w-full max-w-40 mr-10"
                            alt="..."
                          />
                          <h3 className="text-16 font-bold mb-4">
                            {selectedContact?.name || selectedContact?.phoneNumber}
                          </h3>
                        </div>
                        <div>
                          {' '}
                          <Select
                            className="rounded-md h-40 bg-grey-50 text-16 w-full sm:min-w-256 leading-5 capitalize "
                            variant="filled"
                            sx={{
                              '& .MuiFilledInput-input': {
                                pt: '8px',
                                zIndex: '10',
                              },
                            }}
                            value={status}
                            fullWidth
                            onChange={(e) => changeReviewPlatForm(e?.target?.value)}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon className="text-28 align-middle absolute right-6 text-black ml-2" />
                            )}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  borderRadius: '2px',
                                  marginTop: '1px',
                                  boxShadow: 'none',

                                  '& .MuiList-root': {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                  },
                                },
                              },
                            }}
                          >
                            {statusList &&
                              statusList
                                ?.slice()
                                .sort((a, b) => b.defaultReview - a.defaultReview)
                                .map((review, i) => (
                                  <MenuItem
                                    className="capitalize hover:bg-white bg-grey-100"
                                    value={review?.value}
                                    key={i}
                                  >
                                    <div className="flex gap-8">
                                      <div
                                        className={`w-7 h-7 rounded-full justify-center items-center flex mt-7 ${
                                          review?.value === 'Unresolved by bot' ||
                                          review?.value === 'Unresolved by human'
                                            ? 'bg-red-500'
                                            : review?.value === 'Resolved by bot' ||
                                              review?.value === 'Resolved by human'
                                            ? 'bg-darkgreen'
                                            : ''
                                        }`}
                                      >
                                        {' '}
                                      </div>
                                      <Typography>
                                        {review?.value && capitalizeFirstLetter(review?.value)}
                                      </Typography>
                                    </div>
                                  </MenuItem>
                                ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="inbox-height flex flex-col bg-gray-A300 relative bg-[url('/public/assets/images/background/whatsapp-background.png')]">
                      <div
                        className="flex-grow overflow-auto p-20 pb-0"
                        onScroll={onScroll}
                        ref={chatContainerRef}
                      >
                        {Object.entries(groupMessagesByDate(selectContactMessageList))?.map(
                          ([date, messages], index) => (
                            <div key={index}>
                              <div className="text-center">
                                <div className="text-center text-grey-600 text-12 mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                                  {moment(date).calendar(null, {
                                    sameDay: '[Today]',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'dddd',
                                    sameElse: 'MMM DD, YYYY',
                                  })}
                                </div>
                              </div>
                              {messages?.map((message, idx) => (
                                <div className="mb-12 block" key={idx}>
                                  <div
                                    key={message?.id}
                                    className={`rounded-md mb-4 inline-block px-8 py-8 text-14 font-semibold ${
                                      !message?.from
                                        ? 'self-end text-right float-right break-all bg-quick-chat text-white rounded-br-none'
                                        : 'self-start text-left break-all bg-white rounded-bl-none'
                                    }`}
                                  >
                                    <div>
                                      {message?.type === 'text'
                                        ? message?.message
                                        : message?.templateData &&
                                          JSON.parse(message?.templateData)?.components?.map(
                                            (templateValue, i) => (
                                              <div className="block max-w-320 text-left" key={i}>
                                                <div>
                                                  {templateValue?.type === 'HEADER' && (
                                                    <>
                                                      {templateValue?.format === 'TEXT' && (
                                                        <Typography className="mb-10 font-bold">
                                                          {templateValue?.text}
                                                        </Typography>
                                                      )}
                                                      {templateValue?.format === 'IMAGE' && (
                                                        <>
                                                          {JSON.parse(
                                                            message?.dynamicData
                                                          )?.header?.map((dynamicValue) => (
                                                            <>
                                                              {dynamicValue?.type === 'image' && (
                                                                <img
                                                                  src={dynamicValue?.value}
                                                                  className="mb-10 rounded-md"
                                                                  alt="..."
                                                                />
                                                              )}
                                                            </>
                                                          ))}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                  {templateValue?.type === 'BODY' && (
                                                    <>
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html: convertTemplateToHtml(
                                                            replacePlaceholders(
                                                              templateValue?.text,
                                                              selectedContact?.name
                                                            )
                                                          ),
                                                        }}
                                                      />
                                                    </>
                                                  )}
                                                  {templateValue?.type === 'FOOTER' && (
                                                    <>
                                                      <Typography className="mt-6 text-grey-700">
                                                        {templateValue?.text}
                                                      </Typography>
                                                    </>
                                                  )}
                                                  {templateValue?.type === 'BUTTONS' &&
                                                    templateValue?.buttons?.map(
                                                      (buttonValue, b) => (
                                                        <div
                                                          className="text-center border-t-1 border-solid border-grey-300 py-4 mt-12"
                                                          key={b}
                                                        >
                                                          <a
                                                            className="font-bold text-white text-16"
                                                            href={`${
                                                              buttonValue?.phone_number
                                                                ? `tel:${buttonValue?.phone_number}`
                                                                : ''
                                                            }`}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            role="button"
                                                          >
                                                            <img
                                                              src={
                                                                buttonValue?.phone_number
                                                                  ? '/assets/images/business/telephone.png'
                                                                  : '/assets/images/business/view.png'
                                                              }
                                                              className="w-14 h-14 align-middle mr-6 inline-block relative -top-1 img-filter"
                                                              alt="..."
                                                            />
                                                            {buttonValue?.text}
                                                          </a>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div
                                    className={`text-12 text-gray-700 font-medium clear-both     ${
                                      !message?.from ? 'text-right' : 'text-left'
                                    }`}
                                  >
                                    {moment
                                      .utc(Number(message?.cts))
                                      .tz(timeZone)
                                      .format('hh:mm A')}
                                    {!message?.from && message?.status === 'sent' && (
                                      <Icon className="text-14">done</Icon>
                                    )}
                                    {!message?.from && message?.status === 'delivered' && (
                                      <DoneAllIcon className="text-14 ml-3" />
                                    )}
                                    {!message?.from && message?.status === 'read' && (
                                      <DoneAllIcon className="text-blue text-14 ml-3" />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                        <div ref={chatScroll} className="h-32" />
                      </div>
                      <div className="mt-4 flex bg-white items-center px-20">
                        <input
                          type="text"
                          className="flex-grow p-2 border-none rounded-none h-60"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          placeholder={t('waba.inbox.typeMessage')}
                          onKeyDown={handleKeyPress}
                        />
                        <div>
                          <span
                            aria-hidden
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            className="bg-gray-A500 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">emoji_emotions</Icon>
                          </span>
                          {showEmojiPicker && (
                            <div ref={emojiPickerRef} className="absolute bottom-60 right-16">
                              <EmojiPicker
                                onEmojiClick={(emojiObject) => {
                                  setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
                                  setShowEmojiPicker(false);
                                }}
                              />
                            </div>
                          )}
                          <span
                            aria-hidden
                            onClick={() => setOpenTemplateListDialog(true)}
                            title="Template"
                            className="bg-gray-A500 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">text_snippet</Icon>
                          </span>

                          <Button
                            className="rounded-md md:px-24"
                            onClick={() => handleSendMessage('text')}
                            size="small"
                            variant="contained"
                            color="quickChat"
                          >
                            Send
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="h-full flex items-center justify-center bg-gray-A300">
                    <div className="mx-auto text-center">
                      <img
                        src="/assets/images/business/conversation.png"
                        className="img-fluid mx-auto mb-48"
                        alt="..."
                      />
                      <Typography className="block text-24 font-semibold mb-12">
                        {t('waba.inbox.starAConversation')}
                      </Typography>
                      <Typography className="block font-medium text-16 text-grey-600 mb-28">
                        {t('waba.inbox.startDesc')}
                      </Typography>
                      <Button
                        className="rounded-md sm:px-68"
                        size="medium"
                        variant="contained"
                        color="quickChat"
                        onClick={props?.openContactTab}
                      >
                        {t('waba.inbox.addNewContact')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {/* Right Sidebar */}
              {selectedContact && (
                <div className="w-1/5 p-16">
                  <div>
                    <div className="border-b-1 border-solid border-grey-300 pb-16 pt-0 mb-16">
                      <div className="flex items-center">
                        <img
                          src="/assets/images/business/profile.png"
                          className="img-fluid w-full max-w-48 mr-8"
                          alt="..."
                        />

                        <div>
                          <h3 className="text-16 font-bold">
                            {selectedContact?.name || selectedContact?.phoneNumber}
                          </h3>
                          {/* <span className="font-medium text-14 text-grey-800 capitalize">
                          {selectedContact?.status}
                        </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="bg-grey-100 mt-12 rounded-md p-14">
                      <span className="text-16 font-semibold block mb-8">
                        {t('waba.inbox.info')}
                      </span>
                      <span className="font-medium text-12 text-grey-600 block">
                        {t('waba.inbox.phone')}
                      </span>
                      <Typography className="text-14 font-semibold">
                        {selectedContact?.phoneNumber}
                      </Typography>
                    </div>
                    <div className="flex items-center justify-between mt-20">
                      <span className="text-16 font-semibold block mb-8">
                        {t('waba.inbox.notes')}
                      </span>
                      <span className="bg-quick-chat w-24 h-24 rounded-md inline-block text-white text-center pt-4">
                        <Icon
                          onClick={() => setIsExpandedNote(true)}
                          aria-hidden
                          className="text-16"
                        >
                          add
                        </Icon>
                      </span>
                    </div>

                    <input
                      // ref={inputRef}
                      type="text"
                      className="px-10 py-2 border rounded-md w-full h-36 border-quick-chat bg-quick-chat-100 mt-10"
                      onChange={(event) => setNoteValue(event?.target?.value)}
                      value={noteValue}
                      style={{ display: isExpandedNote ? 'block' : 'none' }}
                    />
                    {getChatNote?.length === 0 && (
                      <Typography className="text-12 font-medium text-grey-600">
                        {t('waba.inbox.noteInfo')}
                      </Typography>
                    )}
                    {isExpandedNote && (
                      <div className="text-right mt-10">
                        <Icon
                          onClick={() => {
                            setIsExpandedNote(false);
                            setNoteValue('');
                          }}
                          aria-hidden
                          className="text-12 pt-3 w-20 h-20 text-red border-1 border-solid border-red rounded-md inline-block"
                        >
                          close
                        </Icon>
                        {noteValue && (
                          <Icon
                            onClick={() => createNote()}
                            className="text-12 pt-3 w-20 h-20 text-green border-1 border-solid border-green rounded-md inline-block ml-10 relative"
                          >
                            check
                            {loading && (
                              <CircularProgress
                                size={12}
                                className="text-quick-chat absolute mx-auto right-3"
                              />
                            )}
                          </Icon>
                        )}
                      </div>
                    )}
                    {getChatNote &&
                      getChatNote?.length > 0 &&
                      getChatNote?.map((note, index) => (
                        <div className="bg-quick-chat-100 p-12 rounded-md mb-12" key={index}>
                          {editedNote === note?.id ? (
                            <input
                              type="text"
                              value={editedContent}
                              onChange={(e) => setEditedContent(e.target.value)}
                              className="w-full px-10 py-2 border rounded-md h-36 border-quick-chat bg-quick-chat-100"
                            />
                          ) : (
                            <span className="block">{note?.note}</span>
                          )}
                          <span className="flex mt-12 items-center justify-between">
                            {moment(note?.uts).format('MMM DD, YYYY h:MM A')}
                            <span>
                              {editedNote === note?.id ? (
                                <Icon
                                  onClick={() => handleSaveClick(note)}
                                  aria-hidden
                                  className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block cursor-pointer"
                                >
                                  {note?.note !== editedContent ? 'save' : 'close'}
                                </Icon>
                              ) : (
                                <>
                                  <Icon
                                    onClick={() => handleEditClick(note)}
                                    aria-hidden
                                    className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block cursor-pointer"
                                  >
                                    edit
                                  </Icon>
                                  <Icon
                                    onClick={() => deleteNote(note?.id)}
                                    aria-hidden
                                    className="text-12 pt-3 w-20 h-20 text-grey-500 border-1 border-solid border-grey-500 rounded-md inline-block ml-10"
                                  >
                                    delete
                                  </Icon>
                                </>
                              )}
                            </span>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="h-full flex items-center justify-center bg-white rounded-md h-screen chat-height">
              <div className="mx-auto text-center">
                <img
                  src="/assets/images/business/conversation.png"
                  className="img-fluid mx-auto mb-48"
                  alt="..."
                />
                <Typography className="block text-24 font-semibold mb-12">
                  {t('waba.inbox.approveHead')}
                </Typography>
                <Typography className="block font-medium text-16 text-grey-600 mb-28">
                  {t('waba.inbox.approveDesc')}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {openTemplateListDialog && (
          <TemplateList
            openValue={openTemplateListDialog}
            cancelCall={() => setOpenTemplateListDialog(false)}
            popUpMessage={t('waba.inbox.chooseTemplate')}
            onTemplateSelect={handleTemplateSelect}
          />
        )}
      </div>
    </>
  );
};

export default WabaInbox;
