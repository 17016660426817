import { t } from 'i18next';

const PolicySection = () => {
  return (
    <div className="text-12 mt-20 max-w-320 mx-auto">
      By continuing you agree to our
      <a
        href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        target="_blank"
        rel="noreferrer noopener"
        role="button"
        className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium ml-5"
      >
        {t('signUpPage.labels.privacyPolicy')}
      </a>
      ,{' '}
      <a
        href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
        target="_blank"
        rel="noreferrer noopener"
        role="button"
        className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
      >
        {t('signUpPage.labels.termsService')}
      </a>
      ,{' '}
      <a
        href={process.env.REACT_APP_EULA_LINK}
        target="_blank"
        rel="noreferrer noopener"
        role="button"
        className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
      >
        {t('signUpPage.labels.eula')}
      </a>{' '}
      and{' '}
      <a
        href={process.env.REACT_APP_DISCLAIMER_LINK}
        target="_blank"
        rel="noreferrer noopener"
        role="button"
        className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
      >
        {t('signUpPage.labels.disclaimers')}
      </a>{' '}
    </div>
  );
};

export default PolicySection;
