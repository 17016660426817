import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const AutoGenerateText = () => {
  const params = useParams();
  const [isAutoGenerateText, setIsAutoGenerateText] = useState(false);
  const [reviewComplexityTypeData, setReviewComplexityTypeData] = useState();
  const [complexityTypeId, setComplexityTypeId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [preComplexityId, setPreComplexityId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const { plateFormID } = location.state || {};
  const productId = parseInt(params?.productId, 10);

  useEffect(() => {
    const fetchAutoReviewComplexityType = async () => {
      const payload = {
        query: manageBusinessData.getAutoReviewComplexityType,
      };
      const result = await handleApiRequest(payload);
      if (result?.getAutoReviewComplexityType?.status === 200) {
        const sortData = result.getAutoReviewComplexityType?.data?.sort((a, b) => b.id - a.id);
        setReviewComplexityTypeData(sortData);
      } else {
        enqueueSnackbar(result?.getAutoReviewComplexityType?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    fetchAutoReviewComplexityType();
  }, [enqueueSnackbar]);

  const fetchReviewComplexityType = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      if (productId) {
        const payload = {
          query: manageBusinessData.getAmazonAndFlipkartSelectedReviewComplexityType,
          variables: {
            productId,
            type: plateFormID === 5 ? 'amazon' : 'flipkart',
          },
        };

        const result = await handleApiRequest(payload);
        setRefreshIcon(false);

        if (result?.getAmazonAndFlipkartSelectedReviewComplexityType?.status === 200) {
          setComplexityTypeId(
            result?.getAmazonAndFlipkartSelectedReviewComplexityType?.data
              ?.autoGenerateReviewComplexityTypeId
          );
          setPreComplexityId(
            result?.getAmazonAndFlipkartSelectedReviewComplexityType?.data
              ?.autoGenerateReviewComplexityTypeId
          );
        } else {
          enqueueSnackbar(result?.getAmazonAndFlipkartSelectedReviewComplexityType?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.getSelectedReviewComplexityType,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getSelectedReviewComplexityType?.status === 200) {
          setComplexityTypeId(
            result?.getSelectedReviewComplexityType?.data?.autoGenerateReviewComplexityTypeId
          );
          setPreComplexityId(
            result?.getSelectedReviewComplexityType?.data?.autoGenerateReviewComplexityTypeId
          );
        } else {
          enqueueSnackbar(result?.getSelectedReviewComplexityType?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    }
  }, [userCurrentBusinessDetails?.id, productId, plateFormID, enqueueSnackbar]);

  useEffect(() => {
    fetchReviewComplexityType();
  }, [fetchReviewComplexityType]);

  const fetchAutoGenerateText = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      try {
        setPageLoading(true);

        if (plateFormID) {
          const variables = { userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10) };

          if (userCurrentBusinessDetails?.isOnlineBusiness && productId) {
            if (plateFormID === 5) {
              variables.amazonProductId = productId;
            } else if (plateFormID === 6) {
              variables.flipkartProductId = productId;
            }
          }
          const payload = {
            query:
              parseInt(plateFormID, 10) === 5
                ? manageBusinessData.getAmazonProduct
                : parseInt(plateFormID, 10) === 6 && manageBusinessData.getFlipkartProduct,
            variables,
          };

          const result = await handleApiRequest(payload);
          const res =
            parseInt(plateFormID, 10) === 5 ? result?.getAmazonProduct : result?.getFlipkartProduct;

          setPageLoading(false);

          if (res?.status === 200) {
            setIsAutoGenerateText(res?.data[0]?.isAutoTextGenerate);
          }
        } else {
          const payload = {
            query: manageBusinessData.getBusinessAutoTextGenerateStatus,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };

          const result = await handleApiRequest(payload);
          setPageLoading(false);
          if (result?.getBusinessAutoTextGenerateStatus?.status === 200) {
            setIsAutoGenerateText(
              result?.getBusinessAutoTextGenerateStatus?.data?.isAutoTextGenerate
            );
          } else {
            enqueueSnackbar(result?.getBusinessAutoTextGenerateStatus?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    }
  }, [
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    plateFormID,
    productId,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    fetchAutoGenerateText();
  }, [fetchAutoGenerateText]);

  const enableDisable = async () => {
    try {
      if (productId) {
        const payload = {
          query: manageBusinessData.setAmazonAndFlipkartAutoTextGenerate,
          variables: {
            isActive: !isAutoGenerateText,
          },
        };
        if (plateFormID === 5) {
          payload.variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          payload.variables.flipkartProductId = productId;
        }
        const result = await handleApiRequest(payload);
        setRefreshIcon(false);

        if (result?.setAmazonAndFlipkartAutoTextGenerate?.status === 200) {
          fetchAutoGenerateText();
          enqueueSnackbar(result?.setAmazonAndFlipkartAutoTextGenerate?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result?.setAmazonAndFlipkartAutoTextGenerate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.setBusinessAutoTextGenerate,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            isActive: !isAutoGenerateText,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.setBusinessAutoTextGenerate?.status === 200) {
          fetchAutoGenerateText();
        } else {
          enqueueSnackbar(result?.setBusinessAutoTextGenerate?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const saveComplexityType = async (id) => {
    setComplexityTypeId(id);
    setRefreshIcon(true);
    if (productId) {
      const payload = {
        query: manageBusinessData.setAmazonAndFlipkartReviewComplexityType,
        variables: {
          productId,
          type: plateFormID === 5 ? 'amazon' : 'flipkart',
          typeId: Number(id),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);

      if (result?.setAmazonAndFlipkartReviewComplexityType?.status === 200) {
        fetchReviewComplexityType();
        enqueueSnackbar(result?.setAmazonAndFlipkartReviewComplexityType?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.setAmazonAndFlipkartReviewComplexityType?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      const payload = {
        query: manageBusinessData.setReviewComplexityType,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          typeId: parseInt(id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.setReviewComplexityType?.status === 200) {
        fetchReviewComplexityType();
        enqueueSnackbar(result?.setReviewComplexityType?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.setReviewComplexityType?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {params?.productId && (
        <div className="pl-16">
          <Toolbar className="pt-32 font-semibold min-h-fit">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('products.productsettings')}
            </Typography>
          </Toolbar>
        </div>
      )}
      <div className={`${!productId ? 'p-20 lg:p-32 h-max' : 'h-max p-32'}`}>
        <div className="flex items-center justify-between">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('autoGenerateText.title')}
          </Typography>
        </div>
        <div className="bg-white rounded-md p-36 mt-28">
          <div className="flex items-center justify-between">
            <Typography className="text-20 font-semibold">{t('autoGenerateText.title')}</Typography>
            <Switch checked={isAutoGenerateText} onChange={() => enableDisable()} />
          </div>
          <Typography className="text-16 font-medium sm:w-5/6 mt-20">
            {t('autoGenerateText.enableDisableMessage')}
          </Typography>
        </div>
        {isAutoGenerateText && (
          <div className="bg-white rounded-md p-36 mt-28">
            <Typography className="text-20 font-semibold">
              {t('autoGenerateTextComplexity.setTextComplexity')}
            </Typography>
            <hr className="border-dashed mt-16 !border-gray" />
            <div className="mt-20">
              {reviewComplexityTypeData?.length > 0 &&
                reviewComplexityTypeData?.map((reviewType, index) => {
                  return (
                    <div className="mt-8" key={index}>
                      <Typography className="text-16 font-semibold">
                        {reviewType?.typeName}
                      </Typography>
                      <FormControl className="block">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          className="block"
                          value={parseInt(complexityTypeId, 10)}
                          onChange={(event) => saveComplexityType(event?.target?.value)}
                        >
                          <FormControlLabel
                            value={reviewType?.id}
                            control={<Radio className="p-0 absolute bottom-2 right-0" />}
                            className="w-full block m-0"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoGenerateText;
