import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import history from '@history';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Pagination from 'src/app/component/table/Pagination';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { selectDashboardData } from 'app/store/userSlice';
import { useLocation } from 'react-router-dom';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';
import CreatePostPopUp from './CreatePostPopUp';
import Loading from '../manage-business/Loading';
import CreateReviewPost from './CreateReviewPost';

const Template = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [getSocialTemplateCateData, setGetSocialTemplateCateData] = useState('');
  const [getSocialTemplateSubCateData, setGetSocialTemplateSubCateData] = useState('');
  const [favTemplate, setFavTemplate] = useState([]);
  const [isFavoriteTemp, setIsFavoriteTemp] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [favTotal, setFavTotal] = useState();
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [templateImage, setTemplateImage] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    location?.state?.selectedCategoryId || 1
  );
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [reviewPopUp, setReviewPopUp] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedCategory =
    getSocialTemplateCateData &&
    getSocialTemplateCateData?.find((cate) => cate?.id === selectedCategoryId);

  useEffect(() => {
    const getQuickSocialTemplatesCategoryData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickSocialTemplatesCategory,
        };

        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialTemplatesCategory?.status === 200) {
          setGetSocialTemplateCateData(result?.getQuickSocialTemplatesCategory?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getQuickSocialTemplatesCategoryData();
  }, []);

  useEffect(() => {
    const getQuickSocialTemplatesSubCategoryData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickSocialTemplatesSubCategory,
          variables: {
            socialTemplatesCategoryId: selectedCategoryId,
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialTemplatesSubCategory?.status === 200) {
          setGetSocialTemplateSubCateData(result?.getQuickSocialTemplatesSubCategory?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (selectedCategory?.isSubCategory) {
      getQuickSocialTemplatesSubCategoryData();
    }
  }, [selectedCategoryId, userCurrentBusinessDetails?.id, selectedCategory]);

  const getSharePostTemplateData = useCallback(async () => {
    setLoading(true);
    try {
      const variables =
        selectedCategoryId === 1
          ? {
              pageNumber,
              pageSize,
              quickSocialTemplatesCategoryId: selectedCategoryId,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            }
          : {
              pageNumber,
              pageSize,
              quickSocialTemplatesCategoryId: selectedCategoryId,
              quickSocialTemplatesSubCategoryId: selectedSubcategoryId,
            };
      const payload = {
        query: manageBusinessData.getSharePostTemplate,
        variables,
      };
      const result = await handleApiRequest(payload);
      if (result?.getSharePostTemplate?.status === 200) {
        setTemplateImage(result?.getSharePostTemplate?.data);
        setTotalCount(result?.getSharePostTemplate?.totalCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [
    pageNumber,
    pageSize,
    selectedCategoryId,
    selectedSubcategoryId,
    userCurrentBusinessDetails?.id,
  ]);

  useEffect(() => {
    getSharePostTemplateData();
  }, [getSharePostTemplateData]);

  const getFavoriteTemplateData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getFavoriteTemplate,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber,
          pageSize,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getFavoriteTemplate?.status === 200) {
        setFavTemplate(result?.getFavoriteTemplate?.data);
        setFavTotal(result?.getFavoriteTemplate?.totalCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [pageNumber, pageSize, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getFavoriteTemplateData();
  }, [getFavoriteTemplateData]);

  const handleFavTemp = async () => {
    setPageNumber(1);
    setIsFavoriteTemp(true);
    setSelectedCategoryId(null);
    setPageSize(10);
    getFavoriteTemplateData();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleFavTemp();
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialogueForDelete(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForDelete(false);
  };

  const handleCategoryClick = (id) => {
    setPageNumber(1);
    setSelectedCategoryId(id);
    setSelectedSubcategoryId(null);
    setPageNumber(1);
    setPageSize(10);
    setIsFavoriteTemp(false);
  };

  const handleSubcategoryClick = (id) => {
    setSelectedSubcategoryId(id);
  };

  const handleFavoriteToggle = async (id, data) => {
    try {
      const isAlreadyFavorite = favTemplate?.some(
        (template) => template?.sharePostTemplateId === id
      );
      if (isAlreadyFavorite) {
        const payload = {
          query: manageBusinessData.deleteFavoriteTemplate,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            sharePostTemplateId: id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.deleteFavoriteTemplate?.status === 200) {
          getFavoriteTemplateData();
          setFavTemplate((prevFavorites) =>
            prevFavorites?.filter((template) => template?.sharePostTemplateId !== id)
          );
        } else {
          getFavoriteTemplateData();
          enqueueSnackbar(result?.deleteFavoriteTemplate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.addFavoriteTemplate,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            sharePostTemplateId: id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.addFavoriteTemplate?.status === 200) {
          setFavTemplate((prevFavorites) => [...prevFavorites, data]);
          getFavoriteTemplateData();
        } else {
          getFavoriteTemplateData();
          enqueueSnackbar(result?.addFavoriteTemplate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const isFavorite = (id) => favTemplate?.some((template) => template?.id === id);

  const handleCreatePost = () => {
    if (
      selectedCategoryId === 5 ||
      selectedImage?.share_post_template?.editableText === null ||
      selectedImage?.editableText === null
    ) {
      setReviewPopUp(true);
      setOpenDialogueForDelete(false);
    } else {
      history.push('/quick-social/post', {
        data: selectedImage,
      });
      setOpenDialogueForDelete(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };
  const handleChangeRowsPerPage = async (event) => {
    setPageSize(Number(event.target.value));
    setPageNumber(1);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-32 md:p-32 p-16  bg-gray-A500">
      <div>
        <h1 className="text-28 font-bold mb-32">{t('quickSocialTemplate.template')}</h1>
      </div>
      <div className="lg:flex md:flex gap-24 ">
        <div className="mb-10 sm:mb-10 overflow-x-auto md:overscroll-hidden  md:min-w-216 lg:w-216 w-full">
          <div className="sm:flex flex md:block lg:block  ">
            <div
              role="button"
              tabIndex={0}
              className={`px-12 py-16 flex cursor-pointer gap-8 items-center flex-shrink-0  ${
                isFavoriteTemp ? 'bg-quick-social text-white' : ' bg-white'
              }`}
              onClick={handleFavTemp}
              onKeyDown={handleKeyDown}
              aria-pressed={isFavoriteTemp}
            >
              <img src="/assets/images/icon/favorite.png" alt="Favorite" className="h-32 w-32" />
              <h3 className="text-16 text-ellipsis whitespace-nowrap">
                {t('quickSocialTemplate.favorite')}
              </h3>
            </div>
            {getSocialTemplateCateData?.length > 0 &&
              getSocialTemplateCateData
                ?.sort((a, b) => a.id - b.id)
                ?.map((cate, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    className={`flex px-12 py-16 cursor-pointer gap-8  items-center flex-shrink-0  ${
                      selectedCategoryId === cate?.id ? 'bg-quick-social text-white ' : ' bg-white '
                    }`}
                    onClick={() => handleCategoryClick(cate?.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleCategoryClick(cate?.id);
                      }
                    }}
                  >
                    <span
                      className={`h-32 w-32 bg-grey-100 rounded-full p-5 ${
                        selectedCategoryId === cate?.id ? ' bg-blue-200' : ''
                      }`}
                    >
                      <img src={cate?.icon} alt={cate?.name} />
                    </span>

                    <h3 className="text-16 flex-grow">
                      {cate?.name?.toLowerCase() === 'for you' ? 'Suggested For You' : cate?.name}
                    </h3>
                  </div>
                ))}
          </div>
        </div>
        <div className="w-full">
          <div className="w-auto overflow-x-auto">
            {selectedCategory?.isSubCategory === true && (
              <div className=" gap-10 flex ">
                {getSocialTemplateSubCateData &&
                  getSocialTemplateSubCateData
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((subcategory) => (
                      <div
                        key={subcategory?.id}
                        role="button"
                        tabIndex={0}
                        className={` flex gap-8 border-1 px-12 p-16 text-14 rounded-full justify-center items-center cursor-pointer mb-24 h-32 ${
                          selectedSubcategoryId === subcategory?.id
                            ? 'bg-quick-social '
                            : 'border-grey-300'
                        }`}
                        onClick={() => handleSubcategoryClick(subcategory?.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleSubcategoryClick(subcategory?.id);
                          }
                        }}
                      >
                        <span className="h-24 w-24 bg-white p-4 rounded-full">
                          <img src={subcategory?.icon} alt={subcategory?.name} className="" />
                        </span>

                        <p className="text-ellipsis whitespace-nowrap ">{subcategory?.name}</p>
                      </div>
                    ))}
              </div>
            )}
          </div>
          {!loading ? (
            <div
              className={`${
                (templateImage?.length > 0 && !isFavoriteTemp) ||
                (isFavoriteTemp && favTemplate?.length > 0)
                  ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16'
                  : ''
              }`}
            >
              {templateImage?.length > 0 && !isFavoriteTemp ? (
                templateImage.map((item) => (
                  <div className="">
                    <div key={item?.sharePostTemplateId} className="relative">
                      <button
                        onClick={() => handleFavoriteToggle(item?.id, item)}
                        className={`absolute top-10 right-10 rounded-full border-2 h-20 w-20 ${
                          favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                            ? 'bg-gray-300'
                            : 'bg-gray-300'
                        }`}
                        type="button"
                        aria-label={
                          favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                            ? 'Unfavorite template'
                            : 'Favorite template'
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-16 w-16 ${
                            favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                              ? 'text-red-500'
                              : 'text-gray-500'
                          }`}
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                        </svg>
                      </button>
                      <div
                        role="button"
                        tabIndex={0}
                        className="w-full object-cover cursor-pointer"
                        onClick={() => handleImageClick(item)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleImageClick(item);
                          }
                        }}
                      >
                        <img
                          src={item?.templateImage}
                          alt={item?.name}
                          className="w-full object-cover rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : !isFavoriteTemp ? (
                <div className="flex w-full justify-center items-center mt-112">
                  <div className="flex flex-col items-center justify-center text-center w-7xl">
                    <img
                      src="assets/images/icon/socialNoTemp.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16">
                      {t('quickSocialTemplate.noTemplate')}
                    </h1>
                    <p className="text-gray-600 font-medium mb-16 text-20">
                      {t('quickSocialTemplate.noTemplateContent')}
                    </p>
                  </div>
                </div>
              ) : null}
              {isFavoriteTemp ? (
                <>
                  {favTemplate?.length > 0 ? (
                    favTemplate?.map((item) => (
                      <div key={item?.id} className="relative">
                        <button
                          onClick={() => handleFavoriteToggle(item?.sharePostTemplateId, item)}
                          className={`absolute top-10 right-10 h-20 w-20 rounded-full border-2 ${
                            isFavorite(item?.sharePostTemplateId) ? 'bg-grey-300' : 'bg-grey-300'
                          }`}
                          type="button"
                          aria-label={
                            isFavorite(item?.sharePostTemplateId)
                              ? 'Unfavorite template'
                              : 'Favorite template'
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-16 w-16 ${
                              isFavorite(item?.sharePostTemplateId) ? 'text-white' : ' text-red-500'
                            }`}
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                          </svg>
                        </button>
                        <div
                          role="button"
                          tabIndex={0}
                          className="w-full object-cover cursor-pointer rounded-md"
                          onClick={() => handleImageClick(item)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleImageClick(item);
                            }
                          }}
                        >
                          <img
                            src={item?.share_post_template?.templateImage}
                            alt="Template"
                            className="w-full object-cover"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex max-w-7xl justify-center items-center mt-112">
                      <div className=" ml-500 flex flex-col items-center justify-center text-center w-7xl">
                        <img
                          src="assets/images/icon/socialNoTemp.png"
                          alt="connect"
                          className="mb-16"
                        />
                        <h1 className="text-24 font-bold mb-16">
                          {t('quickSocialTemplate.noTemplate')}
                        </h1>
                        <p className="text-gray-600 font-medium text-20 mb-16">
                          {t('quickSocialTemplate.noTemplateContent')}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          ) : (
            <div className="w-7xl h-640 justify-center items-center flex">
              <FuseLoading />
            </div>
          )}
          {selectedImage && (
            <CreatePostPopUp
              open={openDialogueForDelete}
              onClose={handleCloseDeleteDialog}
              onCreatePost={handleCreatePost}
              temp={
                selectedImage?.templateImage || selectedImage?.share_post_template?.templateImage
              }
              alt={t(`quickSocialTemplate.selectedTemplate`)}
              backgroundColor="quick-social"
              textColor="white"
              buttonText="Create Post"
            />
          )}
          {selectedImage && (
            <CreateReviewPost
              open={reviewPopUp}
              onClose={() => setReviewPopUp(false)}
              selectedImage={selectedImage}
            />
          )}
          {(!isFavoriteTemp && totalCount > 10) || (isFavoriteTemp && favTotal > 10) ? (
            <Pagination
              totalCount={!isFavoriteTemp ? totalCount : favTotal}
              rowsPerPage={pageSize}
              page={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Template;
