import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Typography,
  Icon,
  Toolbar,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import history from '@history';
import manageBusinessData from '../../../query/manageBusiness';
import { getParamsId, handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const ReviewScreenMode = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [reviewModeData, setReviewModemData] = useState([]);
  const [reviewModeId, setReviewModeId] = useState(3);
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const { plateFormID } = location.state || {};
  const productId = parseInt(params?.productId, 10);
  const agentId = parseInt(params?.id, 10);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getReviewPlatfromModeType,
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        const id = getParamsId();
        if (result?.getReviewPlatfromModeType?.status === 200) {
          const sortedSliders = result?.getReviewPlatfromModeType?.data?.sort((a, b) =>
            a.id === 3 ? -1 : b.id === 3 ? 1 : a.id - b.id
          );
          if (sortedSliders) {
            let finalData = sortedSliders;

            if (userCurrentBusinessDetails?.isOnlineBusiness && !productId) {
              finalData = sortedSliders?.filter((i) => i?.id !== 5);
            }
            setReviewModemData(finalData);
          }
        } else {
          enqueueSnackbar(result?.getReviewPlatfromModeType?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [enqueueSnackbar, userCurrentBusinessDetails?.isOnlineBusiness, location.pathname, productId]);
  const fetchBusinessData = useCallback(async () => {
    try {
      if (
        userCurrentBusinessDetails?.id &&
        userCurrentBusinessDetails?.isOnlineBusiness &&
        productId
      ) {
        setLoading(true);
        const payload = {
          query:
            parseInt(plateFormID, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormID, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        if (plateFormID === 5) {
          payload.variables.amazonProductId = productId;
        } else if (plateFormID === 6) {
          payload.variables.flipkartProductId = productId;
        }
        const result = await handleApiRequest(payload);
        setLoading(false);
        const res =
          parseInt(plateFormID, 10) === 5
            ? result?.getAmazonProduct
            : parseInt(plateFormID, 10) === 6 && result?.getFlipkartProduct;
        if (res?.status === 200) {
          setReviewModeId(parseInt(res?.data[0]?.reviewModeTypeId, 10));
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else if (userCurrentBusinessDetails?.id) {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessDetail?.status === 200) {
          setReviewModeId(parseInt(result?.getUserBusinessDetail?.data?.[0]?.reviewModeTypeId, 10));
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.isOnlineBusiness,
    productId,
    plateFormID,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    fetchBusinessData();
  }, [fetchBusinessData]);

  const saveReviewMode = async (id) => {
    setReviewModeId(id);
    try {
      if (productId) {
        const queryKey =
          parseInt(plateFormID, 10) === 5
            ? 'updateAmazonProductScreenMode'
            : 'updateFlipkartProductScreenMode';

        const payload = {
          query:
            parseInt(plateFormID, 10) === 5
              ? manageBusinessData.updateAmazonProductScreenMode
              : manageBusinessData.updateFlipkartProductScreenMode,
          variables: {
            [`${parseInt(plateFormID, 10) === 5 ? 'amazonProductId' : 'flipkartProductId'}`]:
              productId,
            reviewModeTypeId: parseInt(id, 10),
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };

        const result = await handleApiRequest(payload);
        const res = result?.[queryKey];
        enqueueSnackbar(res?.message, {
          variant: res?.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
        });
      } else {
        const payload = {
          query: manageBusinessData.updateUserBusinessesPlatformModeTypeV2,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            reviewModeTypeId: parseInt(id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        enqueueSnackbar(result?.updateUserBusinessesPlatfromModeType?.message, {
          variant:
            result?.updateUserBusinessesPlatfromModeType?.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {params?.productId && (
        <Toolbar className="pl-36 pt-32 font-semibold min-h-fit">
          <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
            west
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 px-12 font-bold text-18"
          >
            {t('products.productsettings')}
          </Typography>
        </Toolbar>
      )}
      <div className={`${!params?.productId ? 'p-20 lg:p-32' : 'h-max p-32'}`}>
        <>
          <div className="flex flex-1 w-full items-center justify-between mb-36">
            <div className="flex items-center">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t('reviewScreenMode.title')}
              </Typography>
            </div>
          </div>
          <div className="bg-white rounded-md p-36">
            {reviewModeData?.length > 0 ? (
              <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-1 md:gap-36 gap-10 mx-auto my-24">
                {reviewModeData?.length > 0 &&
                  reviewModeData?.map((item, index) => {
                    return (
                      <div className="rounded-md bg-gray-A500 md:p-24 p-10" key={index}>
                        <FormControl className="block">
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className="block"
                            value={reviewModeId}
                            onChange={(event) => saveReviewMode(event?.target?.value)}
                          >
                            <FormControlLabel
                              value={item?.id}
                              control={<Radio className="p-0 absolute bottom-2 right-0" />}
                              label={
                                <>
                                  <span className="mx-auto block">
                                    <img
                                      className={`${
                                        location.pathname === 'quick-reviews/reviewScreenMode' ||
                                        location.pathname ===
                                          `/productDetails/reviewMode/${productId}/${agentId}` ||
                                        location.pathname ===
                                          `/productDetails/reviewMode/${productId}` ||
                                        location.pathname ===
                                          `quick-reviews/reviewScreenMode/${getParamsId()}`
                                          ? 'w-192'
                                          : 'w-128'
                                      } mx-auto`}
                                      src={item?.image?.[0]}
                                      alt={item?.name}
                                    />
                                  </span>
                                  <span className="block md:text-18 text-14 font-semibold text-left mt-48">
                                    {item?.name}
                                  </span>
                                </>
                              }
                              className="w-full block m-0"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                <h1 className="text-16 font-bold text-center">{t('manageBusiness.message')}</h1>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default ReviewScreenMode;
