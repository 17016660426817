import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoData from './noDataScreen';
import metricsData from '../../query/metrics';
import { handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import ReviewSentiment from './reviewSentiment';

const ReviewSentimentOverTime = (props) => {
  const { reviewSentiment, reviewPlatformId } = props;
  const [newGraphData, setNewGraphData] = useState([]);
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [xAxisData, setXAxisData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (userCurrentBusinessDetails?.id && reviewPlatformId) {
          const payload = {
            query: metricsData.getReviewSentimentOverTime,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
              reviewPlatformId: parseInt(reviewPlatformId, 10),
            },
          };

          const result = await handleApiRequest(payload);
          const convertKeyValueObj =
            result?.getReviewsSentimentOverTimeDataV1?.data?.reviewSentimentOverTimeDataV1.map(
              (item) => {
                const obj = {};
                item.forEach(({ key, value }) => {
                  obj[key] = value;
                });
                return obj;
              }
            );
          if (result?.getReviewsSentimentOverTimeDataV1?.status === 200) {
            const sentimentData = convertKeyValueObj?.map((item) => item.sentimentMean);
            const xData = convertKeyValueObj?.map((item) =>
              moment.unix(item.timestamp).format('MMM-YY')
            );
            setNewGraphData(sentimentData);
            setXAxisData(xData);
          }
        } else {
          setNewGraphData([]);
          setXAxisData([]);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId, selectedReviewSentimentId]);

  function getChartSimple(data, categories, yAxis, index, color, types) {
    const chart = {
      series: [
        {
          name: 'Sentiment',
          data,
        },
      ],
      options: {
        chart: {
          type: types,
          height: 350,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: '#0BA861',
        },
        markers: {
          size: 0,
        },
        grid: {
          show: true,
          strokeDashArray: 3,
        },
        xaxis: {
          categories,
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          min: -1,
          max: 1,
          labels: {
            style: {
              fontSize: '14px',
            },
            formatter: (val) => val.toFixed(1),
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => `${val}`,
          },
        },
        annotations: {
          yaxis: yAxis.map((y) => ({
            strokeDashArray: 5,
            label: {
              borderColor: '#D900BA',
              style: {
                color: '#fff',
                background: '#D900BA',
              },
            },
          })),
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="650px"
          width="100%"
        />
      </div>
    );
  }

  return (
    <div className="">
      <ReviewSentiment
        reviewSentiment={reviewSentiment}
        sentimentId={[7, 6]}
        onSelectSentiment={setSelectedReviewSentimentId}
      />
      {newGraphData && newGraphData.length > 0 ? (
        getChartSimple(
          newGraphData,
          xAxisData,
          [1.0, 0.8, 0.6, 0.4, 0.2, 0, -0.2, -0.4, -0.6, -0.8, -1.0],
          3,
          ['#0BA861'],
          'line'
        )
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default ReviewSentimentOverTime;
