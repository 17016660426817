import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import Pagination from 'src/app/component/table/Pagination';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';

const Notification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [getNotification, setGetNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const getNotificationData = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.getNotification,
        variables: {
          pageNumber,
          pageSize,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getNotification?.status === 200) {
        // setTotal(result?.getNotification?.totalCount);
        setGetNotification(result?.getNotification?.data);
      } else {
        enqueueSnackbar(result?.getNotification?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, pageNumber, pageSize]);

  useEffect(() => {
    getNotificationData();
  }, [getNotificationData]);

  const handleRequest = async (id, MappingId, state) => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.acceptAndDeleteInvitation,
        variables: {
          status: state,
          userBusinessRoleMappingId: MappingId,
          notificationId: id,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.acceptAndDeleteInvitation?.status === 200) {
        getNotificationData();
      } else {
        enqueueSnackbar(result?.acceptAndDeleteInvitation?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewPostClick = async (id, index) => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.redirectToSharePost,
        variables: {
          reviewId: parseInt(id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.redirectToSharePost?.status === 200) {
        history.push(`/create-post`, {
          postData: result?.redirectToSharePost?.data,
          additionalApiData: getNotification[index]?.type,
        });
      } else {
        console.error('Error: Unexpected status code', result?.redirectToSharePost?.status);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 ">
      <h1 className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-32">
        {t('notification.notification')}
      </h1>
      <div className="">
        {getNotification?.length > 0 ? (
          getNotification?.map((detail, index) => (
            <div key={index} className="bg-white mb-24 lg:p-24 md:p-24 p-16 rounded-8 items-center">
              <div className=" text-14 font-medium flex gap-5 mb-12">
                <p>
                  {moment(detail?.cts).isSame(moment(), 'day')
                    ? 'Today'
                    : moment(detail?.cts).format('DD MMM,')}
                </p>
                <p>{moment(detail?.cts).format('h:MM A')}</p>
              </div>
              <div className="flex flex-col sm:flex-row items-center sm:justify-between md:justify-between lg:justify-between ">
                <div className="w-full lg:w-2/3 md:w-2/3 sm:w-2/3">
                  <h1 className="sm:flex text-20 lg:text-24 md:text-24 font-semibold mb-16 md:mb-0">
                    {detail?.details?.title}
                  </h1>
                  <p className="text-16 font-regular lg:mb-0 md:mb-0 sm:mb-0 mb-14">
                    "{detail?.details?.body}!"
                  </p>
                </div>
                {detail?.type === 'share_post' ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="text-14 font-medium rounded-md px-36 border-0"
                    aria-label="view-post"
                    type="button"
                    onClick={() => handleViewPostClick(detail?.details?.warehouseReviewId, index)}
                  >
                    {t('notification.viewPost')}
                  </Button>
                ) : (
                  <div className="flex flex-col sm:flex-row w-full sm:w-auto ">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="text-14 font-medium rounded-md px-36 border-0 lg:mb-0 md:mb-0 sm:mb-0 mb-10  "
                      aria-label="accept"
                      type="button"
                      onClick={() =>
                        handleRequest(detail?.id, detail?.userBusinessRoleMappingId, 'accepted')
                      }
                    >
                      {t('notification.accept')}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      className="text-14 font-medium rounded-md px-36 border-0  sm:ml-4 lg:ml-4 md:ml-4 "
                      aria-label="reject"
                      type="button"
                      onClick={() =>
                        handleRequest(detail?.id, detail?.userBusinessRoleMappingId, 'rejected')
                      }
                    >
                      {t('notification.reject')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className=" flex flex-col  justify-center items-center pt-76 ">
            <img
              src="/assets/images/icon/bell.png"
              alt="No Notification"
              className="mb-32 lg:h-256 lg:w-256"
            />
            <p className="text-center lg:text-24 md:text-24 sm:text-24 text-20 font-semibold mb-24 ">
              {t('notification.noNotificationFound')}
            </p>
            <p className="text-center text-16 font-medium max-w-400">
              {t('notification.noNotificationPara')}
            </p>
          </div>
        )}
        {getNotification?.length > 0 ? (
          <Pagination
            totalCount={total}
            rowsPerPage={pageSize}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Notification;
