import AutoGenerateText from './AutoGenerateText';

const AutoGenerateTextConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/autoGenerateText/:id?',
      element: <AutoGenerateText />,
    },
  ],
};

export default AutoGenerateTextConfig;
