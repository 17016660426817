import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { handleApiRequest } from 'src/app/main/common/common';
import selectedBusinessData from 'src/app/main/query/selectedBusiness';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { t } from 'i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';
import FuseLoading from '@fuse/core/FuseLoading';

const BusinessPerformanceReports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment().month() + 1);
  const [defaultMonth, setDefaultMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [defaultYear, setDefaultYear] = useState(moment().year());
  const [overallBusinessReport, setOverallBusinessReport] = useState(null);
  const [monthlyBusinessReport, setMonthlyBusinessReport] = useState([]);
  const [timeStamp, setTimeStamp] = useState([]);
  const [userData, setUserData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [cts, setCts] = useState([]);
  const [showSelection, setShowSelection] = useState(false);
  const [availableMonths, setAvailableMonths] = useState(
    new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
  );
  const [monthsList, setMonthsList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [hasOverallReport, setHasOverallReport] = useState();
  const createdCts = new Date(cts[0] || Date.now()).getFullYear();
  const currentCts = new Date().getFullYear();
  const yearsList =
    currentCts >= createdCts
      ? [...Array(currentCts - createdCts + 1)]?.map((_, index) => createdCts + index)
      : [];

  useEffect(() => {
    const generatedMonthsList = [
      { value: 0, label: t('businessPerformanceReports.selectMonth') },
      ...Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        label: new Date(0, i).toLocaleString('en-US', { month: 'long' }),
      })),
    ];
    setMonthsList(generatedMonthsList);
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true);
        const payload = {
          query: selectedBusinessData.businessPerformanceReports,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);

        if (result?.businessPerformanceReports?.status === 200) {
          const overallReportAvailable =
            result?.businessPerformanceReports?.data?.overallBusinessReport?.url;
          const monthlyReportsAvailable =
            result?.businessPerformanceReports?.data?.monthlyBusinessReport?.length > 0;

          setHasOverallReport(overallReportAvailable);

          if (overallReportAvailable) {
            setOverallBusinessReport(
              result?.businessPerformanceReports?.data?.overallBusinessReport
            );
          }
          if (monthlyReportsAvailable) {
            setMonthlyBusinessReport(
              result?.businessPerformanceReports?.data?.monthlyBusinessReport?.map((e) => ({
                url: e.url,
                timestamp: e.timestamp,
              }))
            );
            setTimeStamp(
              result?.businessPerformanceReports?.data?.monthlyBusinessReport?.map(
                (e) => e.timestamp
              )
            );
          }
        } else {
          enqueueSnackbar(result?.businessPerformanceReports?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching reports:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchReports();
    }
  }, [userCurrentBusinessDetails?.id, month, year, enqueueSnackbar]);

  useEffect(() => {
    const fetchBusinessUserData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);

        if (result?.getUserBusinessDetail?.status === 200) {
          setCts(result?.getUserBusinessDetail?.data?.map((e) => e.cts));
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching User Business Details:', error);
      }
    };
    fetchBusinessUserData();
  }, [enqueueSnackbar, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (!isEmpty(timeStamp)) {
      const userTimeStampData = timeStamp?.map((e, i) => ({
        year: moment(e * 1000).format('YYYY'),
        month: moment(e * 1000).format('MMMM'),
        monthlyBusinessReport: monthlyBusinessReport[i].url,
      }));
      setUserData(userTimeStampData);
      const monthsSet = new Set(
        userTimeStampData?.map((data) => monthsList?.find((m) => m.label === data.month)?.value)
      );
      setAvailableMonths(monthsSet);
    }
  }, [monthlyBusinessReport, monthsList, timeStamp]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
    setDefaultYear(event.target.value);
    setMonth(0);
    setDefaultMonth(0);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setMonth(selectedMonth);
    setDefaultMonth(selectedMonth);
    setButtonDisabled(false);
  };

  const handleOverallPerformanceReportDownload = () => {
    if (overallBusinessReport?.url) {
      window.open(overallBusinessReport.url);
    }
  };

  const handleMonthlyPerformanceReportDownload = (url) => {
    if (url) {
      window.open(url);
    }
  };

  const handleSelectedMonthlyPerformanceReportView = () => {
    const selectedReport = userData?.find(
      (data) =>
        parseInt(data?.year, 10) === year &&
        moment().month(data?.month).format('M') === String(month)
    );

    if (selectedReport?.monthlyBusinessReport) {
      window.open(selectedReport.monthlyBusinessReport);
    } else {
      enqueueSnackbar(t('businessPerformanceReports.noReportForSelectedMonthYear'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const toggleSelection = () => {
    setShowSelection(!showSelection);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <div className="flex w-full items-center justify-center md:justify-start mb-36">
        <Typography className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0">
          {t('businessPerformanceReports.title')}
        </Typography>
      </div>
      {!hasOverallReport ? (
        <div className="h-full flex justify-center bg-white rounded-md py-224 items-center">
          <Typography className="text-32 font-semibold">
            {t('businessPerformanceReports.overAllReport.noReportAvailable')}
          </Typography>
        </div>
      ) : (
        <>
          <div className="mt-28 bg-white rounded-md w-full">
            <div className="bg-white rounded-md p-36 mt-28">
              <Typography className="text-20 text-gray-700 flex justify-center md:justify-start font-semibold">
                {t('businessPerformanceReports.overAllReport.title')}
              </Typography>
              <div className="border bg-white rounded-md p-20 mt-28 flex flex-col md:flex-row items-center">
                <div className="w-full md:w-9/12 flex items-center flex-col md:flex-row">
                  <img
                    className="w-60 h-60 rounded-3xl mr-0 md:mr-6 mb-6 md:mb-0"
                    src="/assets/images/business/businessperformancereport.png"
                    alt="Overall Report"
                  />
                  <div className="w-full text-center md:text-left">
                    <Typography className="text-20 font-semibold">
                      {t('businessPerformanceReports.overAllReport.overAllBusinessReport')}
                    </Typography>
                    <Typography className="text-16 sm:w-5/6 mt-10 mx-auto md:mx-0">
                      {t('businessPerformanceReports.overAllReport.description')}
                    </Typography>
                  </div>
                </div>
                <div className="px-10 md:pl-44 w-full md:w-3/12 mt-6 md:mt-0">
                  <div className="flex flex-row items-center justify-center md:justify-end w-full">
                    <Button
                      className="rounded text-quick-review"
                      variant="outlined"
                      type="button"
                      onClick={handleOverallPerformanceReportDownload}
                    >
                      {t('businessPerformanceReports.overAllReport.viewReport')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-28 bg-white rounded-md w-full">
            <div className="bg-white rounded-md p-36 mt-28">
              <Typography className="text-20 text-gray-700 flex justify-center md:justify-start font-semibold">
                {t('businessPerformanceReports.monthlyReports.title')}
              </Typography>
              <div className="border bg-white rounded-md p-14 mt-16">
                <div className="flex flex-row items-center justify-between">
                  <Typography className="text-20 font-semibold pl-16">
                    {t('businessPerformanceReports.chooseMonth')}
                  </Typography>
                  <Button onClick={toggleSelection} className="hover:bg-transparent mt-6 md:mt-0">
                    <img
                      className="w-28 h-28"
                      src="/assets/images/business/chooseMonth.svg"
                      alt="Choose Month"
                    />
                  </Button>
                </div>
                {showSelection && (
                  <>
                    <div className="px-16">
                      <hr className="border border-solid my-14 !border-gray-400" />
                    </div>
                    <div className="flex flex-col md:flex-row items-center mt-10 md:items-end justify-between px-20">
                      {yearsList?.length > 0 && (
                        <div className="w-full md:w-4/12 mb-6 md:mb-0">
                          <InputLabel id="year-select-label" className="text-black font-600">
                            {t('businessPerformanceReports.chooseYear')}
                          </InputLabel>
                          <FormControl className="w-full mt-8 sm:0" size="small">
                            <Select
                              labelId="year-select-label"
                              value={defaultYear || ''}
                              onChange={handleYearChange}
                              renderValue={(selected) =>
                                yearsList?.find((y) => y === selected) ||
                                `${t('businessPerformanceReports.selectYear')}`
                              }
                            >
                              {yearsList?.map((y) => (
                                <MenuItem key={y} value={y}>
                                  <FormControlLabel
                                    value={y}
                                    control={<Radio checked={year === y} />}
                                    label={y}
                                    style={{ width: '100%' }}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}

                      <div className="w-full md:w-4/12 mb-16 mt-10 md:mb-0">
                        <InputLabel id="month-select-label" className="text-black font-600">
                          {t('businessPerformanceReports.chooseMonth')}
                        </InputLabel>
                        <FormControl className="w-full mt-8 sm:0" size="small">
                          <Select
                            labelId="month-select-label"
                            value={defaultMonth}
                            onChange={(event) => {
                              handleMonthChange(event);
                              setButtonDisabled(true);
                            }}
                            renderValue={(selected) => {
                              if (selected === 0 || !availableMonths.has(Number(selected))) {
                                return `${t('businessPerformanceReports.selectMonth')}`;
                              }
                              return (
                                monthsList?.find((m) => m.value === Number(selected))?.label ||
                                `${t('businessPerformanceReports.selectMonth')}`
                              );
                            }}
                          >
                            {monthsList?.map((m, i) => (
                              <MenuItem
                                key={i}
                                value={m.value}
                                disabled={!availableMonths.has(m.value) && m.value !== 0}
                              >
                                <FormControlLabel
                                  value={m.value}
                                  control={<Radio checked={month === m.value} />}
                                  label={m.label}
                                  style={{ width: '100%' }}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="flex flex-row items-center justify-center md:justify-end w-full md:w-auto">
                        <Button
                          className="rounded text-quick-review"
                          variant="outlined"
                          type="button"
                          onClick={handleSelectedMonthlyPerformanceReportView}
                          disabled={!availableMonths.size || defaultMonth === 0}
                        >
                          {t('businessPerformanceReports.monthlyReports.viewReport')}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {userData?.map((m, index) => (
                <div
                  className="border bg-white rounded-md p-20 mt-28 flex flex-col md:flex-row items-center"
                  key={index}
                >
                  <div className="w-full md:w-9/12 flex items-center flex-col md:flex-row">
                    <img
                      className="w-60 h-60 rounded-3xl mr-0 md:mr-6 mb-6 md:mb-0"
                      src="/assets/images/business/businessperformancereport.png"
                      alt="Monthly Report"
                    />
                    <div className="w-full text-center md:text-left">
                      <Typography className="text-20 font-semibold">
                        {m.month} {m.year} Report
                      </Typography>
                      <Typography className="text-16 sm:w-5/6 mt-10 mx-auto md:mx-0">
                        {t('businessPerformanceReports.monthlyReports.description')} {m.month}.
                      </Typography>
                    </div>
                  </div>
                  <div className="px-10 md:pl-44 w-full md:w-3/12 mt-6 md:mt-0">
                    <div className="flex flex-row items-center justify-center md:justify-end w-full">
                      <Button
                        className="rounded text-quick-review"
                        variant="outlined"
                        type="button"
                        onClick={() =>
                          handleMonthlyPerformanceReportDownload(m?.monthlyBusinessReport)
                        }
                      >
                        {t('businessPerformanceReports.monthlyReports.viewReport')}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessPerformanceReports;
