import SignInDeepLink from './SignInDeepLink';
import SignInPage from './SignInPage';
import SignInTokenPage from './SignInTokenPage';
import WhatsappSign from './WhatsappSignIn';

const SignInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'sign-in',
      element: <SignInPage />,
    },
    {
      path: 'sign-in-token/:token/:page?/:businessId?',
      element: <SignInTokenPage />,
    },
    {
      path: 'whatsapp/:token',
      element: <SignInDeepLink />,
    },
    {
      path: 'sign-in/:token',
      element: <SignInPage />,
    },
    {
      path: 'whatsappSignIn',
      element: <WhatsappSign />,
    },
    {
      path: 'sign-in/apple/:token',
      element: <SignInPage />,
    },
    {
      path: 'qrcode-order/:token/:businessId',
      element: <SignInTokenPage />,
    },
  ],
};

export default SignInConfig;
