import { Button, Dialog, DialogContent, DialogTitle, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import moment from 'moment';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import userService from '../../../../services';

const CheckPlanForAddNewBusiness = (props) => {
  const {
    openSelectProductDialog,
    planAvailableDialog,
    setPlanAvailableDialog,
    setOpenSelectProductDialog,
    userAvailablePlan,
  } = props;
  const loginUserData = userService.getUserData();

  const transformUserSubscriptionData = (plans) => {
    return [plans]?.reduce((acc, item) => {
      const addToAcc = (details, key) => {
        details?.forEach((detail) => {
          if (detail?.[key]) {
            acc.push({ name: detail?.[key] });
          }
        });
      };
      if (item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
        acc.push({ name: item?.name });
      }
      addToAcc(item?.comboDetails, 'name');
      addToAcc(item?.packageBundleDetails, 'name');
      return acc;
    }, []);
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: '-mt-12 p-16 rounded-md',
        }}
        open={planAvailableDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-16">
          <Icon
            onClick={() => setPlanAvailableDialog(false)}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <DialogTitle className="font-semibold text-16 border-b pb-12 border-dashed border-black p-0">
            {t('manageBusiness.button.addBusiness')}
          </DialogTitle>
          <DialogContent className="mt-16 p-0">
            {userAvailablePlan?.every((user) => !user?.isFreeTrial) && (
              <div className="flex gap-6 bg-[#FFF2D4] border border-solid border-quick-chat rounded-md p-8">
                <WarningAmberRoundedIcon />
                <div>
                  <Typography className="font-bold text-14">
                    {t('manageBusiness.subscriptionActiveButNotAdd')}
                  </Typography>
                  <Typography className="font-medium text-12">
                    {t('manageBusiness.subscriptionMessage')}
                  </Typography>
                </div>
              </div>
            )}
            {userAvailablePlan?.length > 0 &&
              userAvailablePlan?.map((item, index) => {
                return (
                  <div className="bg-gray-A500 mt-8 p-12" key={index}>
                    <div className="text-center bg-white p-16 rounded-md">
                      <Button
                        className="bg-darkgreen text-white w-full font-semibold rounded-md hover:bg-darkgreen px-20"
                        onClick={() =>
                          history.push('manage-business', {
                            usePackageSubscriptionId: item?.userPackageSubscriptionId,
                            addNewBusiness: 'addNewBusiness',
                          })
                        }
                      >
                        {t('manageBusiness.button.addBusiness')}
                      </Button>
                    </div>
                    <Typography className="font-bold text-16 my-10">
                      {t('manageBusiness.activePlans')}
                    </Typography>
                    {transformUserSubscriptionData(item)?.length > 0 &&
                      transformUserSubscriptionData(item)?.map((data, i) => {
                        const offerAmount =
                          item.packageBundleDetails?.find((amount) => amount.packageTypeId)
                            ?.offerAmount || item?.offerAmount;
                        return (
                          <div className="p-12 bg-white rounded-md mb-8" key={i}>
                            <div className="flex justify-between items-center">
                              <Typography className="font-semibold text-16">
                                {data?.name}
                              </Typography>
                              <Typography className="font-semibold text-16">
                                {parseInt(loginUserData?.countryId, 10) === 2 ? '₹' : '$'}
                                {offerAmount}/{data?.interval === 'year' ? 'year' : 'mon'}
                              </Typography>
                            </div>
                            <Typography className="pt-6 text-14">
                              <span className="font-medium">{t('manageBusiness.expireOn')}: </span>{' '}
                              <span className="font-semibold">
                                {' '}
                                {moment(item?.expireDate).format('MMM DD, YYYY')}{' '}
                              </span>
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            <Button
              className="bg-white text-darkgreen border border-darkgreen border-solid mt-16 w-full font-semibold rounded-md hover:bg-white px-20"
              onClick={() => {
                setOpenSelectProductDialog(true);
                setPlanAvailableDialog(false);
              }}
            >
              <AddRoundedIcon /> {t('manageBusiness.button.addNewBusiness')}
            </Button>
          </DialogContent>
        </DialogTitle>
      </Dialog>
      <Dialog
        classes={{
          paper: '-mt-12 p-24 rounded-md',
        }}
        open={openSelectProductDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0">
          <Icon
            onClick={() => setOpenSelectProductDialog(false)}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <DialogContent className="flex justify-center items-center">
            <div className="text-center">
              <img className="m-auto" src="assets/images/business/subscribe.svg" alt="" />
              <Typography className="text-18 font-bold text-black w-full py-12">
                {t('manageBusiness.subscribeToAddBusiness')}
              </Typography>
              <Typography className="text-14 font-semibold text-black max-w-288">
                {t('manageBusiness.subscribeToAddBusinessMsg')}
              </Typography>
              <Button
                className="bg-darkgreen hover:bg-darkgreen text-white rounded-md px-40 mt-20"
                onClick={() => {
                  history.push('selectProduct/', { isBusinessAdded: false });
                  setOpenSelectProductDialog(false);
                }}
              >
                {t('manageBusiness.button.selectProduct')}
              </Button>
            </div>
          </DialogContent>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default CheckPlanForAddNewBusiness;
