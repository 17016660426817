import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentUpdate from './PaymentUpdate';
import SubscriptionUpdate from './SubscriptionUpdate';
import IndianPayment from './IndianPayment';
import OneTimePayment from './quickhub/OneTimePayment';
import Payment from './Payment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'pay/:token',
      element: (
        <Elements stripe={stripePromise}>
          <Payment />
        </Elements>
      ),
    },
    {
      path: 'payment/:token', // For indian payment
      element: <IndianPayment />,
    },
    {
      path: 'pay-update/:token',
      element: <PaymentUpdate />,
    },
    {
      path: 'addon-update/:token',
      element: <PaymentUpdate />,
    },
    {
      path: 'subscription-update/:token',
      element: <SubscriptionUpdate />,
    },
    {
      path: 'subscribe/razorpay/onetime',
      element: <OneTimePayment />,
    },
  ],
};

export default PaymentConfig;
