import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import history from '@history';
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useRef, useState, useCallback } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CKEditor from 'react-ckeditor-component';
import FuseLoading from '@fuse/core/FuseLoading';
import { Close } from '@mui/icons-material';
import { getParamsId, handleApiRequest } from '../../../common/common';
import businessData from '../../../query/business';
import { storeCustomerList, storeSendSmsOrEmailDetails } from '../store/targetMessageSlice';
import reducer from '../store';

const CreateReviewsOrMessage = () => {
  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  const customerList = useSelector(({ state }) => state.targetMessageSlice?.customerListData);
  const [messages, setMessages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [emailContent, setEmailContent] = useState(customerList?.htmlTemplate);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectBusinessTypeValue, setSelectBusinessTypeValue] = useState('Select Business Type');
  const [businessTypeArray, setBusinessTypeArray] = useState([]);
  const editorRef = useRef(null);

  useEffect(() => {
    const id = getParamsId();
    if (
      (location.pathname === '/create-new-review' ||
        location.pathname === `/create-new-review/${id}`) &&
      ((!customerList?.queue?.length > 0 &&
        sendSmsOrEmailDetailsData?.serviceType === 'bulk_type') ||
        !sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews' ||
        !sendSmsOrEmailDetailsData?.businessId)
    ) {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    }
    if (
      (location.pathname === '/create-new-message' ||
        location.pathname === `/create-new-message/${id}`) &&
      ((!customerList?.queue?.length > 0 &&
        sendSmsOrEmailDetailsData?.serviceType === 'bulk_type') ||
        !sendSmsOrEmailDetailsData?.targetMessageType === 'send_messages' ||
        !sendSmsOrEmailDetailsData?.businessId)
    ) {
      if (id) {
        history.push(`send-message/${id}`);
      } else {
        history.push('send-message/');
      }
    }
  }, [
    location.pathname,
    sendSmsOrEmailDetailsData?.serviceType,
    sendSmsOrEmailDetailsData?.targetMessageType,
    sendSmsOrEmailDetailsData?.businessId,
    customerList?.queue?.length,
  ]);

  const fetchMessage = useCallback(
    async (item) => {
      setLoading(true);
      const messageId = {
        messagesBusinessTypeId: item?.id,
      };
      const payload = {
        query: businessData.getCampaignStandardMessages,
        variables: {
          userBusinessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
          campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
          serviceType: sendSmsOrEmailDetailsData?.targetMessageType,
          ...(item?.id !== undefined && messageId),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getCampaignStandardMessages?.status === 200) {
        const data = result?.getCampaignStandardMessages?.data;
        const reviewScannerUrl = `${sendSmsOrEmailDetailsData?.reviewUrl}`;
        const updatedArray = data.map((value) => {
          return {
            ...value,
            htmlTemplate: value.htmlTemplate?.replace('[Review Link]', reviewScannerUrl),
          };
        });
        setMessages(updatedArray);
      }
    },
    [
      sendSmsOrEmailDetailsData?.businessId,
      sendSmsOrEmailDetailsData?.campaignPlatformId,
      sendSmsOrEmailDetailsData?.targetMessageType,
      sendSmsOrEmailDetailsData?.reviewUrl,
    ]
  );

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);

  const campaignServiceMessagesBusinessTypeCall = async () => {
    setLoading(true);
    const payload = {
      query: businessData.getCampaignServicesMessagesBusinessType,
    };
    const result = await handleApiRequest(payload);
    setLoading(false);
    if (result?.getCampaignServicesMessagesBusinessType?.status === 200) {
      setBusinessTypeArray(result?.getCampaignServicesMessagesBusinessType?.data);
    }
  };
  useEffect(() => {
    campaignServiceMessagesBusinessTypeCall();
  }, []);
  const handleMessage = (value) => {
    dispatch(
      storeCustomerList({
        ...customerList,
        message: value,
        commonMessageStore: value,
      })
    );
  };

  const handleTemplateName = (value) => {
    dispatch(
      storeCustomerList({
        ...customerList,
        templateName: value,
      })
    );
  };

  const handleSubject = (value) => {
    dispatch(
      storeCustomerList({
        ...customerList,
        emailSubject: value,
      })
    );
  };

  const onSelectMessage = async (value) => {
    if (sendSmsOrEmailDetailsData?.campaignPlatformId === 1) {
      setEmailContent(
        sendSmsOrEmailDetailsData?.serviceType === 'individual_type'
          ? value?.htmlTemplate
              ?.replace(
                /\[Customers Name\]/g,
                `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`
              )
              .replace(/\[Review Link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
              .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData.businessName)
              .replace(/\[review link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
          : value?.htmlTemplate
              .replace(/\[Review Link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
              .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData.businessName)
              .replace(/\[review link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
      );
    }
    await dispatch(
      storeCustomerList({
        ...customerList,
        templateName: value?.title,
        messageCampaignData: value,
        existingMessageId: parseInt(value?.id, 10),
        isEmailContentValue: sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && true,
        commonMessageStore:
          sendSmsOrEmailDetailsData?.campaignPlatformId === 1
            ? value?.htmlTemplate
            : value?.messages?.replace(/\[Review Link\]/g, '').replace(/\[review link\]/g, ''),
        htmlTemplate:
          sendSmsOrEmailDetailsData?.campaignPlatformId === 1 &&
          (sendSmsOrEmailDetailsData?.serviceType === 'individual_type'
            ? value?.htmlTemplate
                ?.replace(
                  /\[Customers Name\]/g,
                  `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`
                )
                .replace(/\[Review Link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
                .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData.businessName)
                .replace(/\[review link\]/g, `${sendSmsOrEmailDetailsData?.reviewUrl}`)
            : value?.htmlTemplate),
        message:
          parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2 &&
          (sendSmsOrEmailDetailsData?.serviceType === 'individual_type'
            ? value?.messages
                ?.replace(
                  /\[Customers Name\]/g,
                  `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`
                )
                .replace(/\[Review Link\]/g, '')
                .replace(/\[Business Name\]/g, sendSmsOrEmailDetailsData.businessName)
                .replace(/\[review link\]/g, '')
            : value?.messages?.replace(/\[Review Link\]/g, '').replace(/\[review link\]/g, '')),
        emailSubject:
          sendSmsOrEmailDetailsData?.serviceType === 'individual_type'
            ? value?.htmlSubject
                ?.replace('[Business Name]', sendSmsOrEmailDetailsData.businessName)
                .replace(
                  '[Customers Name]',
                  `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`
                )
            : value?.htmlSubject?.replace(
                '[Business Name]',
                sendSmsOrEmailDetailsData.businessName
              ),
      })
    );
  };
  const handleEmailContent = async (event) => {
    setEmailContent(event?.editor?.getData());
  };

  const handleSendSms = async () => {
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.serviceType === 'bulk_type') {
      if (sendSmsOrEmailDetailsData?.targetMessageType === 'send_messages') {
        if (sendSmsOrEmailDetailsData?.campaignPlatformId === 1) {
          dispatch(
            storeCustomerList({
              ...customerList,
              htmlTemplate: emailContent,
              commonMessageStore: emailContent,
            })
          );
        }
        if (id) {
          history.push(`send-messages/${id}`);
        } else {
          history.push('send-messages/');
        }
      }
      if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
        if (sendSmsOrEmailDetailsData?.campaignPlatformId === 1) {
          dispatch(
            storeCustomerList({
              ...customerList,
              htmlTemplate: emailContent,
              commonMessageStore: emailContent,
            })
          );
        }
        if (id) {
          history.push(`send-reviews/${id}`);
        } else {
          history.push('send-reviews/');
        }
      }
    }
    if (sendSmsOrEmailDetailsData?.serviceType === 'individual_type') {
      setRefreshIcon(true);
      let campaignMessageId = '';
      if (!customerList?.existingMessageId) {
        const payload = {
          query: businessData.createCampaignServicesStandardMessages,
          variables: {
            ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && {
              messages:
                sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
                  ? customerList?.message
                      .replace(
                        new RegExp(
                          `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`,
                          'g'
                        ),
                        '[Customers Name]'
                      )
                      .replace(
                        new RegExp(
                          sendSmsOrEmailDetailsData.businessName?.replace(
                            /[.*+?^${}()|[\]\\]/g,
                            '\\$&'
                          ),
                          'g'
                        ),
                        '[Business Name]'
                      )
                  : customerList?.message
                      .replace(
                        new RegExp(
                          `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`,
                          'g'
                        ),
                        '[Customers Name]'
                      )
                      .replace(
                        new RegExp(
                          sendSmsOrEmailDetailsData.businessName?.replace(
                            /[.*+?^${}()|[\]\\]/g,
                            '\\$&'
                          ),
                          'g'
                        ),
                        '[Business Name]'
                      ),
            }),
            ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && {
              htmlTemplate: emailContent
                .replace(
                  new RegExp(`${sendSmsOrEmailDetailsData?.reviewUrl}`, 'g'),
                  '[Review Link]'
                )
                .replace(
                  new RegExp(`${sendSmsOrEmailDetailsData?.reviewUrl}`, 'g'),
                  '[review link]'
                )
                .replace(
                  new RegExp(
                    `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`,
                    'g'
                  ),
                  '[Customers Name]'
                )
                .replace(
                  new RegExp(
                    sendSmsOrEmailDetailsData.businessName?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
                    'g'
                  ),
                  '[Business Name]'
                ),
              htmlSubject: customerList?.emailSubject,
            }),
            userBusinessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
            serviceType: sendSmsOrEmailDetailsData?.targetMessageType,
            campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
            title: customerList?.templateName,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.createCampaignServicesStandardMessages?.status === 200) {
          campaignMessageId = result?.createCampaignServicesStandardMessages?.data?.id;
        }
      }
      const payload = {
        query: businessData.sendCampaignMessagesV1,
        variables: {
          businessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
          campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
          isSchedule: false,
          campaignServicesStandardMessageId:
            parseInt(campaignMessageId, 10) || parseInt(customerList?.messageCampaignData?.id, 10),
          servicesType: sendSmsOrEmailDetailsData?.targetMessageType,
          callFrom: 'portal',
          queue: [
            {
              ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 2 && {
                countryCode: `+${sendSmsOrEmailDetailsData?.phone?.dialCode}`,
                phoneNumber:
                  sendSmsOrEmailDetailsData.phone.phoneNumber?.includes(
                    sendSmsOrEmailDetailsData?.phone?.dialCode
                  ) &&
                  parseInt(
                    sendSmsOrEmailDetailsData?.phone?.phoneNumber
                      ?.toString()
                      .slice(sendSmsOrEmailDetailsData?.phone?.dialCode.length),
                    10
                  ),
                title:
                  sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
                    ? customerList.message.concat(`${sendSmsOrEmailDetailsData?.reviewUrl}`)
                    : customerList.message,
              }),
              name: `${sendSmsOrEmailDetailsData.firstName} ${sendSmsOrEmailDetailsData.lastName}`,
              ...(sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && {
                email: sendSmsOrEmailDetailsData?.email,
                htmlSubject: customerList?.emailSubject,
                htmlTemplate: emailContent,
              }),
            },
          ],
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.sendCampaignMessagesV1?.status === 200) {
        setOpenDialog(true);
      } else {
        enqueueSnackbar(result?.sendCampaignMessagesV1?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };
  const handleCopyClick = (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      return navigator.clipboard.writeText(textToCopy);
    });
  };

  const handleDialog = () => {
    dispatch(storeSendSmsOrEmailDetails());
    dispatch(storeCustomerList());
    setOpenDialog(false);
  };

  const businessTypeChangeCall = (event) => {
    const item = businessTypeArray?.find((value) => value.type === event.target.value);
    setSelectBusinessTypeValue(event.target.value);
    fetchMessage(item);
  };

  const closeClick = () => {
    setSelectBusinessTypeValue('Select Business Type');
    fetchMessage();
  };

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div>
      <div>
        <div className="p-20 lg:p-32">
          <div className="flex justify-between items-center pb-40">
            <Typography className="text-20 md:text-28 text-black font-bold">
              {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
                ? t('inviteReviews.title')
                : t('sendMessage.title')}
            </Typography>
            <Button
              className="bg-darkgreen hover:bg-darkgreen disabled:text-black disabled:bg-gray px-20 md:px-48 text-white rounded-4"
              onClick={() => handleSendSms()}
              disabled={
                (sendSmsOrEmailDetailsData?.campaignPlatformId === 2
                  ? !customerList?.message || !customerList?.templateName
                  : !emailContent || !customerList?.templateName || !customerList?.emailSubject) ||
                refreshIcon
              }
            >
              {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2
                ? t('sendMessage.sendSms')
                : t('sendMessage.sendEmail')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
          <div className="grid grid-cols-10 gap-24">
            <div
              className={`bg-white ${
                sendSmsOrEmailDetailsData?.serviceType === 'bulk_type'
                  ? 'col-span-6'
                  : 'col-span-10'
              } rounded-md relative shadow p-20`}
            >
              <Typography className="text-20 text-black font-medium pb-16">
                {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2
                  ? t('inviteReviews.createNewSms')
                  : t('inviteReviews.createNewEmail')}
              </Typography>
              <hr className="border-dashed border-1 !border-[#707070]" />
              <div className="mt-20">
                <Typography className="text-14 text-black font-[400] pb-8">
                  {t('inviteReviews.templateName')}
                  <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  className="w-full"
                  value={customerList?.templateName}
                  onChange={(e) => handleTemplateName(e.target?.value)}
                />
              </div>
              {sendSmsOrEmailDetailsData?.campaignPlatformId === 1 && (
                <div className="mt-20">
                  <Typography className="text-14 text-black font-[400] pb-8">
                    {t('inviteReviews.subject')}
                    <span className="text-red-500">*</span>
                  </Typography>
                  <TextField
                    className="w-full"
                    value={customerList?.emailSubject}
                    onChange={(e) => handleSubject(e.target?.value)}
                  />
                </div>
              )}
              <div className="mt-20">
                <Typography className="text-14 text-black font-[400] pb-8">
                  {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2
                    ? t('inviteReviews.messageContent')
                    : t('inviteReviews.emailContent')}
                  <span className="text-red-500">*</span>
                </Typography>
                {sendSmsOrEmailDetailsData?.campaignPlatformId === 1 ? (
                  <CKEditor
                    activeClass="editor"
                    config={{
                      allowedContent: true,
                      versionCheck: false,
                    }}
                    content={emailContent || ''}
                    events={{
                      change: handleEmailContent,
                    }}
                  />
                ) : (
                  <div className="border !border-gray-500 border-solid rounded-md">
                    <TextField
                      className="w-full"
                      value={customerList?.message}
                      onChange={(e) => handleMessage(e.target?.value)}
                      rows={3}
                      multiline
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                    />
                    {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews' && (
                      <Typography className="max-content bg-gray-200 rounded-md mt-2 mb-16 mx-12 p-8">
                        {`${sendSmsOrEmailDetailsData?.reviewUrl}`}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </div>
            {sendSmsOrEmailDetailsData?.serviceType === 'bulk_type' && (
              <div className="bg-darkgreen-100 col-span-4 md:h-[400px] rounded-md relative shadow border border-darkgreen border-dashed p-20">
                <Typography className="text-18 text-black font-bold pb-16">
                  {t('inviteReviews.templatePlaceHolder')}
                </Typography>
                <hr className="border-dashed border-[#707070]" />
                <div className="mt-20">
                  <Typography className="text-14 text-black font-[600] pb-8">
                    {t('inviteReviews.message.fullName.message')}
                  </Typography>
                  <div className="bg-white p-8 flex justify-between items-center">
                    <Typography id="firstName" className="pl-8">
                      {`{${t('inviteReviews.message.firstName.name')}} {${t(
                        'inviteReviews.message.lastName.name'
                      )}}`}
                    </Typography>
                    <Button
                      className="p-0 min-w-[35px] min-h-[0px] hover:bg-white max-h-[25px] h-[25px] w-[0px]"
                      onClick={() => handleCopyClick('firstName')}
                    >
                      <ContentCopyRoundedIcon className="text-darkgreen" />
                    </Button>
                  </div>
                </div>
                <div className="mt-20">
                  <Typography className="text-14 text-black font-[600] pb-8">
                    {`${t('inviteReviews.message.businessName.message')}`}
                  </Typography>
                  <div className="bg-white p-8 flex justify-between items-center">
                    <Typography id="businessName" className="pl-8">
                      {`{${t('inviteReviews.message.businessName.name')}}`}
                    </Typography>
                    <Button
                      className="p-0 min-w-[35px] min-h-[0px] hover:bg-white max-h-[25px] h-[25px] w-[0px]"
                      onClick={() => handleCopyClick('businessName')}
                    >
                      <ContentCopyRoundedIcon className="text-darkgreen" />
                    </Button>
                  </div>
                </div>
                <div className="mt-20">
                  <Typography className="text-14 text-black font-[600] pb-8">
                    {t('inviteReviews.message.reviewUrl.message')}
                  </Typography>
                  <div className="bg-white p-8 flex justify-between items-center">
                    <Typography id="reviewUrl" className="pl-8">
                      {`{${t('inviteReviews.message.reviewUrl.name')}}`}
                    </Typography>
                    <Button
                      className="p-0 min-w-[35px] min-h-[0px] hover:bg-white max-h-[25px] h-[25px] w-[0px]"
                      onClick={() => handleCopyClick('reviewUrl')}
                    >
                      <ContentCopyRoundedIcon className="text-darkgreen" />
                    </Button>
                  </div>
                </div>
                <div>
                  <Typography className="pt-12">{t('inviteReviews.placeholderMessage')}</Typography>
                </div>
              </div>
            )}
          </div>
          <div className="bg-white rounded-md relative shadow p-20 mt-20">
            <div className="flex justify-between pb-16 items-center">
              <Typography className="text-20 text-black font-medium">
                {parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10) === 2
                  ? t('sendMessage.existingSMS')
                  : t('sendMessage.existingEmail')}
              </Typography>
              {sendSmsOrEmailDetailsData?.targetMessageType === 'send_messages' && (
                <Select
                  className="border border-darkgreen text-darkgreen h-40 bg-white text-16 min-w-192 leading-5 shadow-none justify-end p-5 "
                  variant="outlined"
                  color="secondary"
                  sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
                  value={selectBusinessTypeValue}
                  onChange={businessTypeChangeCall}
                  IconComponent={() =>
                    selectBusinessTypeValue === 'Select Business Type' ? (
                      <ArrowDropDownIcon className="cursor-pointer text-darkgreen" />
                    ) : (
                      <Close
                        onClick={() => closeClick()}
                        className="cursor-pointer text-darkgreen"
                      />
                    )
                  }
                >
                  <MenuItem value="Select Business Type">{t('sendMessage.BusinessType')}</MenuItem>
                  {businessTypeArray?.map((item, index) => (
                    <MenuItem key={index} value={item?.type} className="hover:text-darkgreen">
                      {item?.type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>

            <hr className="border-dashed border-1 !border-[#707070]" />
            <div>
              {messages?.length > 0 ? (
                <div className="mt-20 grid grid-cols-6 gap-16">
                  {messages.map((item, i) => {
                    return (
                      <div key={i} className="col-span-6 sm:col-span-3 md:col-span-2">
                        <Typography className="text-14 text-black pb-4">{item.title}</Typography>
                        <div
                          className={`border border-black  rounded-[6px] py-6 pl-8 ${
                            customerList?.messageCampaignData?.id === item.id
                              ? 'bg-darkgreen text-white'
                              : 'bg-white text-black'
                          }`}
                          onClick={() => onSelectMessage(item)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              onSelectMessage();
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          {sendSmsOrEmailDetailsData?.campaignPlatformId === 1 ? (
                            <div className="h-[400px] overflow-scroll w-full">
                              <Typography className="font-bold pb-16">
                                {t('inviteReviews.subject')}: {item?.htmlSubject}
                              </Typography>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item?.htmlTemplate,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="h-[100px] overflow-scroll w-full">
                              <Typography>{item.messages}</Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className="text-16 text-center pt-12"
                >
                  There are no any template!
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-0 rounded-md',
        }}
        open={openDialog}
        fullWidth
        maxWidth="xs"
      >
        <div className="bg-darkgreen text-darkgreen">none</div>
        <div className="px-28 pb-28 pt-12">
          <DialogTitle className="p-0 text-right">
            <Icon
              onClick={() => handleDialog()}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </DialogTitle>
          <DialogContent className="p-0 mb-24">
            <div className="block text-center mb-24">
              <div className="">
                <DoneIcon className="text-[120px] text-[#20be79] border-[#20be79] font-bold border-8 rounded-[50%] p-16" />
              </div>
              <h2 className="font-bold mt-40">{t('inviteReviews.smsSendSuccessFully')}</h2>
              <Typography className="font-medium mt-20 max-w-[80%] m-auto">
                {t('sendMessage.sendSuccessFullyMessage')}
              </Typography>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default withReducer('state', reducer)(CreateReviewsOrMessage);
