import ExploreFeatures from './ExploreFeatures';

const ExploreFeaturesConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'explore-features/:id?',
      element: <ExploreFeatures />,
    },
  ],
};

export default ExploreFeaturesConfigs;
