import { Button, Dialog, DialogContent, DialogTitle, Icon, Typography } from '@mui/material';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { useLocation } from 'react-router-dom';
import AllPost from './AllPost';
import PostSettingsDialog from './PostSettingsDialog';

const QuickPost = () => {
  const location = useLocation();
  const [postGroup, setPostGroup] = useState(location?.state);
  const [tabValue, setTabValue] = useState(0);
  const [openContact, setOpenContact] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpen = () => {
    setOpenContact(true);
  };
  function handleClose() {
    setOpenContact(false);
  }
  return (
    <div className="p-20 lg:p-32">
      {postGroup ? (
        <>
          <div className="sm:flex w-full items-center justify-between mb-36">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('quickPost.title')}
            </Typography>
            {tabValue === 0 && (
              <Button
                type="button"
                className="rounded-md"
                variant="contained"
                color="secondary"
                onClick={handleOpen}
              >
                {t('quickPost.postSettings')}
              </Button>
            )}

            {openContact && (
              <PostSettingsDialog
                open={openContact}
                setOpenContact={setOpenContact}
                onClose={handleClose}
              />
            )}
          </div>
          <div className="bg-white rounded-md">
            <Tabs
              value={tabValue}
              className="sm:overflow-hidden overflow-scroll p-0"
              sx={{
                '& .MuiTabs-scroller': {
                  borderBottom: '1px solid #eee',
                  padding: '0px',
                },
              }}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className="md:text-18 text-14 font-semibold py-20 px-5 md:px-16"
                value={0}
                label={t('quickPost.suggestPost')}
              />
              <Tab
                className="md:text-18 text-14 font-semibold py-20 px-5 md:px-16"
                value={1}
                label={t('quickPost.viewPost')}
              />
              <Tab
                className="md:text-18 text-14 font-semibold py-20 px-5 md:px-16"
                value={2}
                label={t('quickPost.availableTemplate')}
              />
              <Tab
                className="md:text-18 text-14 font-semibold py-20 px-5 md:px-16"
                value={3}
                label={t('quickPost.favoriteTemplates')}
              />
            </Tabs>
            <div className="p-20 lg:p-28">
              <div>
                <AllPost tabValue={tabValue} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white shadow rounded-md py-28 lg:px-80 p-20 mb-28">
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center">
              <div>
                <h2 className="text-32 font-bold mb-32">{t('quickPost.title')}</h2>
                <Typography className="font-medium text-16 border-l-2 border-solid border-darkgreen pl-16 max-w-586">
                  {t('quickPost.desc')}
                </Typography>
                <Button
                  type="button"
                  onClick={() => setPostGroup(true)}
                  className="rounded-md mt-56 md:px-40"
                  variant="contained"
                  color="secondary"
                >
                  {t('quickPost.btnLabel')}
                </Button>
              </div>
              <div>
                <img
                  src="/assets/images/business/quick-post.png"
                  className="img-fluid w-full mx-auto max-w-586"
                  alt="..."
                />
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-md py-28 lg:px-80 p-20">
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center">
              <div>
                <img
                  src="/assets/images/business/quick-post-feature.png"
                  className="img-fluid w-full max-w-586"
                  alt="..."
                />
              </div>
              <div>
                <h2 className="text-28 font-semibold mb-32">{t('quickPost.feature.title')}</h2>
                <ul className="list-disc list-outside pl-16 text-16 font-medium">
                  <li
                    className="mb-16"
                    dangerouslySetInnerHTML={{
                      __html: t('quickPost.feature.fea1'),
                    }}
                  />
                  <li
                    className="mb-16"
                    dangerouslySetInnerHTML={{
                      __html: t('quickPost.feature.fea2'),
                    }}
                  />
                  <li
                    className="mb-16"
                    dangerouslySetInnerHTML={{
                      __html: t('quickPost.feature.fea3'),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t('quickPost.feature.fea4'),
                    }}
                  />
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuickPost;
