import { memo } from 'react';
import Box from '@mui/material/Box';

function FuseSplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <div className="logo">
        <img width="128" src="assets/images/logo/logo.png" alt="logo" />
      </div>
      <Box
        id="spinner"
        sx={{
          '& > .bounce1': {
            backgroundColor: '#2DB67C',
          },
          '& > .bounce2': {
            backgroundColor: '#EBB32F',
          },
          '& > .bounce3': {
            backgroundColor: '#219EBC',
          },
          '& > .bounce4': {
            backgroundColor: '#8B78DC',
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <div className="bounce4" />
      </Box>
    </div>
  );
}

export default memo(FuseSplashScreen);
