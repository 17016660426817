import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import history from '@history';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import businessData from '../../query/business';
import { handleApiRequest } from '../../common/common';

const DashboardAlerts = ({ loading }) => {
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const params = useParams();
  const isFirstItem = cardData?.length === 1;
  const settings = {
    dots: false,
    infinite: !isFirstItem,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      if (!userCurrentBusinessDetails?.id || loading) return;

      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCardV1,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
            productTypeId: 8,
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.getBusinessWhatsNewCardV1?.status === 200 &&
          result.getBusinessWhatsNewCardV1?.data
        ) {
          setCardData(JSON.parse(result.getBusinessWhatsNewCardV1?.data?.cardJson));
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };
    if (userCurrentBusinessDetails?.id && !loading) {
      fetchCardData();
    }
  }, [loading, userCurrentBusinessDetails?.id]);

  const handleSocialCardClick = (item) => {
    const platformMap = {
      socialFollowerFacebook: 9,
      socialFollowerInstagram: 10,
      socialFollowerLinkedin: 12,
    };

    const { cardName, portalURL } = item;
    const { id } = params;
    let mediaChannelId;
    const basePath = `/${portalURL}/${id || ''}`;

    switch (cardName) {
      case 'socialFollowerFacebook':
      case 'socialFollowerInstagram':
      case 'socialFollowerLinkedin':
        mediaChannelId = platformMap[cardName];
        history.push(
          {
            pathname: `/quick-social/social-connect/${id || ''}`,
          },
          { mediaChannelId }
        );
        break;

      case 'socialFestivalPost':
        history.push(
          {
            pathname: basePath,
          },
          { selectedCategoryId: 4 }
        );
        break;

      default:
        history.push({ pathname: basePath });
        break;
    }
  };

  return (
    <>
      {cardData?.length > 0 && (
        <Slider {...settings}>
          {cardData
            .filter((item) => item?.cardName !== 'defaultRatingCounter')
            .map((item, index) => (
              <div key={index}>
                {item && (
                  <div
                    className="flex gap-16 items-center justify-between w-full min-h-144 max-h-144 sm:min-h-200 sm:max-h-200 p-20 py-16 rounded-md"
                    style={{ backgroundColor: item?.backgroundColor }}
                  >
                    <div className="flex items-center w-full max-h-200 rounded-md">
                      <div className="w-2/3 flex flex-col gap-16 justify-center">
                        <Typography
                          className="font-semibold text-20"
                          style={{ color: item?.textColor }}
                        >
                          {item?.description}
                        </Typography>
                        <div className="relative">
                          <Button
                            className="rounded-md text-quick-social relative bg-white hover:bg-white max-w-88 min-w-88 sm:max-w-112 sm:min-w-112 mt-8"
                            style={{
                              backgroundColor: item?.buttonColor,
                              '--hover-bg': item?.buttonColor,
                              color: item?.buttonTextColor,
                            }}
                            onClick={() => handleSocialCardClick(item)}
                          >
                            {item?.ctaButton}
                          </Button>
                        </div>
                      </div>
                      <div className="w-1/3 flex justify-center">
                        <img className="hidden w-192 sm:block" src={item?.icon} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </Slider>
      )}
    </>
  );
};

export default DashboardAlerts;
