import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

const SharePost = ({ url, title, image, open, onClose, dialogTitle }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" PaperProps={{ style: { width: '500px' } }}>
      <DialogTitle>
        {dialogTitle || `${t('createPost.sharePosts')}`}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="absolute right-2 top-2 text-gray-500"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="flex space-x-4 mb-4">
          <FacebookShareButton url={image}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={image} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton url={image}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <WhatsappShareButton url={image} title={title}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharePost;
