import withReducer from 'app/store/withReducer';
import { useEffect, useState, useMemo, useCallback } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import history from '@history';
import { useSnackbar } from 'notistack';
import reducer from './store';

function Payment() {
  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [razorpayIntant, setRazorpayIntant] = useState('');
  const [routeToken] = useState(jwtDecode(routeParams?.token));
  const [payment, setPayment] = useState(false);

  const loadScript = async (src) => {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        console.log('Razorpay script already loaded');
        resolve(true);
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        console.log('Razorpay loaded successfully');
        resolve(true);
      };
      script.onerror = () => {
        console.error('Error loading Razorpay script');
        reject(new Error('Razorpay SDK failed to load.'));
      };
      document.body.appendChild(script);
    });
  };

  const onCloseEvent = (paymentSuccess) => {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (isMobile) {
      const mobileUrl =
        'firstTimeSubscription://quickReviewsPurchasePlanSuccess' ||
        'subscription://quickreviewsSubscriptionSuccess';
      history.push(mobileUrl);
    } else {
      const REDIRECT_URL = `${process.env.REACT_APP_PORTAL_URL}/mySubscription`;
      const REDIRECT_DELAY = 2000;

      if (paymentSuccess) {
        setPayment(true);
        setTimeout(() => {
          window.location.href = REDIRECT_URL;
        }, REDIRECT_DELAY);
      } else {
        window.location.href = REDIRECT_URL;
      }
    }
  };

  const handleRazorpaySubscription = useCallback(async () => {
    if (razorpayIntant) {
      console.log('Razorpay instance already exists');
      return;
    }

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }
    if (routeToken?.getWaySupport === 'razorpay' && !razorpayIntant) {
      setLoading(false);
      const options = {
        key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
        subscription_id: routeToken?.razorpaySubscriptionId,
        name: 'Quick Hub',
        prefill: {
          name: `${routeToken?.firstName}${routeToken?.lastName}`,
          email: routeToken?.email,
          contact: routeToken?.phoneNumber,
        },
        theme: {
          color: '#0BA861',
        },
        handler: (response) => {
          if (response) {
            onCloseEvent(true);
          }
        },
        timeout: 600,
        modal: {
          confirm_close: true,
          ondismiss: async (reason) => {
            onCloseEvent();
          },
        },
      };
      const paymentObject = new window.Razorpay(options);
      setRazorpayIntant(paymentObject);
      paymentObject.on('modal.closed', () => {
        console.log('Modal closed by the user');
      });

      paymentObject.on('modal.render', () => {
        console.log('Modal rendered');
      });
      paymentObject.open();
    }
  }, [
    razorpayIntant,
    routeToken?.email,
    routeToken?.firstName,
    routeToken?.getWaySupport,
    routeToken?.lastName,
    routeToken?.phoneNumber,
    routeToken?.razorpaySubscriptionId,
  ]);

  useEffect(() => {
    handleRazorpaySubscription();
  }, [handleRazorpaySubscription]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      {payment && (
        <div className="payment-success">
          <div className="success-icon">
            <div className="circle" />
            <div className="checkmark" />
          </div>
          <h2>Payment Successful</h2>
          <p>Your transaction was completed successfully!</p>
        </div>
      )}
    </>
  );
}

export default withReducer('paymentReducer', reducer)(Payment);
