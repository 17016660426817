import { Dialog, DialogContent } from '@mui/material';
import Pagination from 'src/app/component/table/Pagination';
import history from '@history';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';

const ReviewTemplatePopup = ({ open, onClose, textColor, reviewData, plateFormID, logo }) => {
  const [templateImage, setTemplateImage] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchSharePostTemplateData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getSharePostTemplate,
          variables: {
            quickSocialTemplatesCategoryId: 5,
            pageNumber,
            pageSize,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getSharePostTemplate?.status === 200) {
          setTemplateImage(result?.getSharePostTemplate?.data || []);
          setTotalCount(result?.getSharePostTemplate?.totalCount || 0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (open) {
      fetchSharePostTemplateData();
    }
  }, [open, pageNumber, pageSize]);

  const handleImageClick = (image) => {
    history.push('/quick-social/post', {
      data: image,
      reviewTemplate: reviewData,
      PFlogo: logo,
      plateFormID,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="review-template-dialog"
      PaperProps={{
        style: {
          maxWidth: '1750px',
        },
        className: `m-24 p-28 border-t-8 rounded-md border-t-${textColor} border-${textColor}`,
      }}
    >
      <div className="relative mb-10">
        <h3 className="text-left font-semibold text-28">Select Reviews Template</h3>
        <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
          <CloseIcon className="w-32 h-32 text-grey-700" />
        </IconButton>
      </div>
      <DialogContent className="p-0 mb-24">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-16 min-h-640">
          {templateImage?.length > 0 ? (
            templateImage?.map((item) => (
              <div key={item?.sharePostTemplateId} className="relative">
                <div
                  role="button"
                  tabIndex={0}
                  className="w-full cursor-pointer"
                  onClick={() => handleImageClick(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleImageClick(item);
                    }
                  }}
                >
                  <img
                    src={item?.templateImage}
                    alt={item?.name || 'Template'}
                    className="w-full object-cover rounded-md"
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="col-start-2 flex flex-col items-center justify-center text-center mt-112">
              <img src="assets/images/icon/socialNoTemp.png" alt="connect" className="mb-16" />
              <h1 className="text-24 font-bold mb-16">No Template Added Yet!</h1>
              <p className="text-gray-600 font-medium mb-16">
                Explore our template library to get started with your next post.
              </p>
            </div>
          )}
        </div>
        {totalCount > 0 && (
          <div className="flex justify-end mt-4">
            <Pagination
              totalCount={totalCount}
              rowsPerPage={pageSize}
              page={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ReviewTemplatePopup;
