import { Dialog, Icon, Typography } from '@mui/material';

const SuccessMessagePopup = (props) => {
  const { openValue, cancelCall, popUpMessage, image } = props;

  return (
    <Dialog
      classes={{
        paper:
          'm-24 sm:p-28 p-10 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openValue}
      fullWidth
      maxWidth="xs"
    >
      <Icon
        onClick={() => cancelCall()}
        className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
      >
        cancel
      </Icon>
      <div className="w-full m-auto mt-24">
        <img className="w-144 text-center m-auto" src={image} alt="" />
      </div>
      <Typography className="text-20 font-semibold text-black text-center w-full my-28">
        {popUpMessage}
      </Typography>
    </Dialog>
  );
};

export default SuccessMessagePopup;
