import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import {
  Typography,
  Icon,
  Select,
  MenuItem,
  TextField,
  Input,
  Paper,
  CircularProgress,
} from '@mui/material';
import '../MangeBusiness.css';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import axios from 'axios';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhoneInput from 'react-phone-input-2';
import history from '@history';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import manageBusinessData from '../../../query/manageBusiness';
import reducer from '../store';
import {
  capitalizeFirstLetter,
  fetchBusinesses,
  getDecryptData,
  getParamsId,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
  reviewFormate,
  sortOptions,
} from '../../../common/common';
import queryData from '../../../query/common';
import userService from '../../../../services';
import {
  getAllUserBusinessSubscription,
  setUserCurrentBusinessDetailsValue,
} from '../../../../store/userSlice';

function BusinessLocation(prop) {
  const {
    packageTypeId,
    businessAddInProgress,
    setBusinessAddInProgress,
    physicalPlatFrom,
    setPhysicalPlatForm,
    clientId,
    setIsBusinessSuccessFullyAdded,
  } = prop;
  const { t } = useTranslation();
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [isZomato, setIsZomato] = useState(false);
  const [isYelp, setIsYelp] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [isBusinessFetch, setIsBusinessFetch] = useState(false);
  const [yelpData, setYelpData] = useState([]);
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [searchBusinessName, setSearchBusinessName] = useState();
  const [googleData, setGoogleData] = useState({});
  const [addBusinessData, setAddBusinessData] = useState({
    address: '',
    businessName: '',
    websiteUrl: '',
    businessType: '',
    phone: '',
    googlePhoneNumber: '',
    businessEmail: '',
    email: '',
    country: '',
    city: '',
    businessImage: '',
    reviewPlatformId: '',
  });
  const [zomatoBusiness, setZomatoBusiness] = useState();
  const [zomatoVerified, setZomatoVerified] = useState(false);
  const [zomatoUrl, setZomatoUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [stateName, setStateName] = useState();
  const [stateData, setStateData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [businessType, setBusinessType] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [zipCode, setZipCode] = useState();
  const [dataAvailable, setDataAvailable] = useState(false);
  const [isValidZomatoUrl, setIsValidZomatoUrl] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isGoogleBusinessAdd, setIsGoogleBusinessAdd] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [number, setNumber] = useState(1);
  const [countryList, setCountryList] = useState([]);
  const [previousCountryId, setPreviousCountryId] = useState();
  const [countryLocation, setCountryLocation] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isTrustPilot, setIsTrustPilot] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(true);
  const [phoneValue, setPhoneValue] = useState();
  const [phones, setPhones] = useState();
  const [businessList, setBusinessList] = useState([]);
  const [isBusinessConnectWithGoogle, setIsBusinessConnectWithGoogle] = useState(false);
  const [selectGoogleBusinessData, setSelectGoogleBusinessData] = useState();
  const [trustPilotData, setTrustPilotData] = useState([]);
  const [selectedTrustPilotData, setSelectedTrustPilotData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const loginUserData = userService.getUserData();
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [physicalBusinessTypeList, setPhysicalBusinessTypeList] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('physicalBusinessType')) {
      setPhysicalBusinessTypeList(JSON.parse(localStorage?.getItem('physicalBusinessType')));
    }
  }, []);

  useEffect(() => {
    if (physicalBusinessTypeList?.length > 0) {
      const businessTypeData = physicalBusinessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessType(businessTypeData);
    }
  }, [physicalBusinessTypeList]);

  useEffect(() => {
    const fetchBusinessType = async () => {
      const payload = {
        query: queryData.getBusinessType,
      };
      const res = await handleApiRequest(payload);
      if (res?.getBusinessType?.status === 200) {
        const businessTypeData = res?.getBusinessType?.data?.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }));
        setBusinessType(businessTypeData);
      }
    };
    fetchBusinessType();
  }, []);

  useEffect(() => {
    const findCountry = countryList?.find(
      (country) => parseInt(country?.id, 10) === parseInt(loginUserData?.countryId, 10)
    );
    setCountryLocation(findCountry?.isoCode);
    setSelectedCountry(findCountry);
    setCountryId(findCountry?.id);
  }, [loginUserData?.countryId, countryList]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: queryData.getCountries,
      };
      const res = await handleApiRequest(payload);
      if (res?.getCountries?.status === 200) {
        setCountryList(res?.getCountries?.data);
      } else {
        enqueueSnackbar(res?.getCountries?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectBusinessType = (selectedOption) => {
    setAddBusinessData({
      ...addBusinessData,
      businessType: selectedOption ? selectedOption.value : null,
    });
  };

  const formatRating = (value) => {
    return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!loading) {
      setLoading(true);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchCountryData = async () => {
      const payload = {
        query: queryData.getStates,
        variables: {
          countryId: parseInt(countryId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getStates?.status === 200) {
        setStateData(result.getStates?.data);
      }
    };
    if (countryId) {
      fetchCountryData();
    }
  }, [countryId]);

  const fetchYelpData = useCallback(
    async (page) => {
      try {
        const payload = {
          query: manageBusinessData.searchYelpBusiness,
          variables: {
            location: countryLocation,
            search: searchBusinessName,
            pageNumber: page,
          },
        };
        const result = await handleApiRequest(payload);
        setPreviousCountryId(countryId);
        setLoading(false);
        if (result?.searchYelpBusiness?.status === 200) {
          if (result?.searchYelpBusiness?.data?.length > 0) {
            const yelpDataArray = Array.isArray(result?.searchYelpBusiness?.data)
              ? result.searchYelpBusiness.data
              : [];
            setDataAvailable(yelpDataArray.length === 0 && true);
            setYelpData((prevData) => [...prevData, ...yelpDataArray]);
          }
        }
      } catch (error) {
        console.error('Error fetching Yelp data:', error);
      }
    },
    [countryLocation, searchBusinessName, countryId]
  );

  useEffect(() => {
    if (!dataAvailable && isYelp) {
      fetchYelpData();
    }
  }, [fetchYelpData, dataAvailable, isYelp, countryLocation]);

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });

      if (!place?.place_id) {
        enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }

      setSelectedMapFullAddress(place.formatted_address);
      let formattedAddress = '';

      try {
        const googleBusinessData = await axios({
          url: process.env.REACT_APP_AI_GOOGLE_PLACE,
          method: 'post',
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            place_id: place?.place_id,
            place_name: place.name,
          }),
        });

        const googleDetails = googleBusinessData?.data?.data?.data;
        formattedAddress = place.formatted_address;
        const tempPhone = place?.international_phone_number
          ?.split(' ')
          .join('')
          .replace(/[^0-9]/g, '');

        if (googleBusinessData?.status === 200) {
          const findCountry = countryList?.find(
            (country) => country?.name === googleDetails?.country
          );
          setSelectedCountry(findCountry);
          setCountryId(findCountry?.id);
          await setGoogleData({
            address: formattedAddress,
            businessName: place?.name,
            url: place?.url,
            businessImage: googleDetails?.profile_image,
            rating: place?.rating,
            userTotalRating: place?.user_ratings_total,
            phone: {
              ...(tempPhone && {
                phoneNumber: tempPhone,
                countryCode: place?.international_phone_number?.split(' ')[0],
                isoCode: findCountry?.isoCode,
              }),
            },
            country: googleDetails?.country,
          });
        }
      } catch (error) {
        console.error('An error occurred', error);
      }

      const isAllFalse = physicalPlatFrom?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = physicalPlatFrom?.map((ele) => {
        if (ele.name === 'google') {
          return {
            ...ele,
            address: formattedAddress,
            businessName: place?.name,
            placeId: place.place_id,
            url: place.url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setPhysicalPlatForm(data);
      setDataLoading(false);
    }
  };
  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onDragChanged = async (event) => {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
    }
  };
  const handleRemoveBusiness = async (data) => {
    const removeBusinessInformation = physicalPlatFrom?.map((ele) => {
      if (ele.id === data.id) {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      if (ele.businessName) {
        return {
          ...ele,
          isBusinessDetailsAdded: true,
        };
      }
      return ele;
    });

    const findBusinessDetails = await removeBusinessInformation?.find(
      (ele) => ele.isBusinessDetailsAdded === true
    );
    if (findBusinessDetails) {
      addBusinessInformation(findBusinessDetails);
    }
    const findData = physicalPlatFrom?.find((item) => item?.id === data?.id);
    if (findData?.isBusinessDetailsAdded === true) {
      setAddBusinessData();
    }
    if (data.name === 'google') {
      setGoogleData();
    }
    if (data.name === 'zomato') {
      setZomatoUrl();
      setZomatoVerified(false);
      setZomatoBusiness();
    }
    if (data.name === 'trustpilot') {
      setSelectedTrustPilotData();
      setSearchValue('');
      setTrustPilotData([]);
    }
    setPhysicalPlatForm(removeBusinessInformation);
    const findBusinessName = physicalPlatFrom?.find((item) => item.businessName)?.businessName;
    if (findBusinessName) {
      setBusinessAddInProgress({
        ...businessAddInProgress,
        isPhysical: false,
      });
    }
  };
  const handleBusinessLocation = async (data) => {
    setNumber(1);
    setBusinessAddInProgress({ ...businessAddInProgress, isPhysical: true });
    if (data.name === 'zomato') {
      setIsZomato(true);
    }
    if (data.name === 'google') {
      setIsGoogle(true);
    }
    if (data.name === 'trustpilot') {
      setIsTrustPilot(true);
    }
    if (data.name === 'yelp') {
      setIsYelp(true);
      setPageNumber(1);
      const payload = {
        query: manageBusinessData.searchYelpBusiness,
        variables: {
          location: countryLocation,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.data) {
        setYelpData(result?.searchYelpBusiness?.data);
      }
    }
  };
  const addBusinessInformation = async (businessData, yelpValues) => {
    try {
      setIsGoogleBusinessAdd(true);
      let url = {};
      if (businessData?.name === 'google') {
        setIsGoogle(false);
        url = {
          url: googleData?.url,
        };
      }
      if (businessData?.name === 'zomato') {
        setIsZomato(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      if (businessData?.name === 'yelp') {
        setIsYelp(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      if (businessData?.name === 'connectWithGoogle') {
        setIsBusinessConnectWithGoogle(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      const businessDetails = await axios({
        url:
          businessData?.name === 'google'
            ? process.env.REACT_APP_AI_GOOGLE_BUSINESS_TYPE
            : process.env.REACT_APP_AI_YELP_ZOMATO_BUSINESS_TYPE,
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(url),
      });
      setNumber(2);
      if (businessDetails?.data?.status === 200) {
        const data = businessDetails.data?.data;
        const findBusinessType = businessType?.filter(
          (item) =>
            item?.label === (businessData?.name === 'google' ? data?.business_type : data?.type)
        );
        const phone = {
          phoneNumber:
            data?.phone_number &&
            data?.phone_number !== null &&
            `${data?.country_code?.slice(1)}${data?.phone_number}`,
          countryCode: data?.country_code,
          isoCode: data?.iso_code,
        };
        setAddBusinessData({
          ...addBusinessData,
          reviewPlatformId: businessData?.id || 2,
          businessType: findBusinessType?.[0]?.value,
          businessName: businessData?.businessName,
          phone: addBusinessData?.phone
            ? addBusinessData?.phone
            : businessData?.name === 'google'
            ? googleData?.phone
            : phone,
          googlePhoneNumber: businessData?.name === 'google' ? googleData?.phone : phone,
          websiteUrl: businessData?.url,
          address: businessData?.address,
          country:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato' || businessData?.name === 'trustpilot'
              ? data?.country
              : yelpValues?.location?.country,
          city:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.city
              : yelpValues?.location?.city,
          businessImage:
            businessData?.name === 'google'
              ? googleData?.businessImage
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.businessImage
              : yelpValues?.image_url,
        });
        const countryName =
          businessData?.name === 'google'
            ? googleData?.country
            : businessData?.name === 'zomato' || businessData?.name === 'trustpilot'
            ? data?.country
            : yelpValues?.location?.country;
        const findCountry = countryList?.find((item) => item?.name === countryName);
        if (findCountry) {
          setCountryId(findCountry?.id);
        }
        setTimeout(() => {
          setNumber(3);
          setTimeout(() => {
            setIsBusinessFetch(true);
            setIsGoogleBusinessAdd(false);
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setNumber(3);
        setTimeout(() => {
          setIsBusinessFetch(true);
          setIsGoogleBusinessAdd(false);
        }, 1000);
      }, 2000);
      setAddBusinessData({
        ...addBusinessData,
        reviewPlatformId: businessData?.id || 2,
        businessName: businessData?.businessName,
        websiteUrl: businessData?.url,
        address: businessData?.address,
        city:
          businessData?.name === 'google'
            ? googleData?.country
            : businessData?.name === 'zomato'
            ? zomatoBusiness?.city
            : yelpValues?.location?.city,
        businessImage:
          businessData?.name === 'google'
            ? googleData?.businessImage
            : businessData?.name === 'zomato'
            ? zomatoBusiness?.businessImage
            : yelpValues?.image_url,
      });
    }
  };
  const addBusinessDetails = async (value, yelpValues) => {
    let result = [];
    if (value === 'yelp') {
      result = await handleYelpAddBusiness(yelpValues);
    }
    const arrayData = value === 'yelp' ? result : physicalPlatFrom;
    const checkBusinessData = await arrayData.find((ele) => ele.name === value);
    if (checkBusinessData?.isBusinessDetailsAdded === true) {
      addBusinessInformation(checkBusinessData, yelpValues);
    } else {
      if (value === 'google') {
        setIsGoogle(false);
      }
      if (value === 'zomato') {
        setIsZomato(false);
      }
      if (value === 'yelp') {
        setIsYelp(false);
      }
    }
  };

  const handleYelpAddBusiness = async (value) => {
    const isAllFalse = physicalPlatFrom?.every((ele) => ele.isBusinessDetailsAdded === false);
    const data = await physicalPlatFrom?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          ...ele,
          address: value?.location?.display_address.join(' '),
          businessName: value?.name,
          placeId: value?.id,
          url: value?.url,
          isBusiness: true,
          isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
        };
      }
      return ele;
    });
    setPhysicalPlatForm(data);
    return data;
  };

  const handleSelectCountry = (id) => {
    setCountryId(id);
    setPageNumber(1);
    setYelpData([]);
    const findCountry = countryList?.find((country) => country?.id === id);
    setSelectedCountry(findCountry);
    setCountryLocation(findCountry?.isoCode);
  };

  const selectState = async (state) => {
    setStateId(parseInt(state, 10) !== 0 ? state : 0);
    const findState = stateData.find((ele) => ele.id === state);
    setStateName(findState?.name || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${findState?.name || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      setYelpData([]);
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleZipCode = async (value) => {
    if (value?.length < 7) {
      setZipCode(value);
      const payload = {
        query: manageBusinessData.searchYelpBusiness,
        variables: {
          location: `${countryLocation}, ${stateName || ''}, ${value}`,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.status === 200) {
        setYelpData(result?.searchYelpBusiness?.data);
      }
    }
  };

  const handleSearchBusinessName = async (value) => {
    setSearchBusinessName(value || '');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: value,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    }
  };

  const cancelYelp = () => {
    const removeBusinessInformation = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    const findBusinessName = physicalPlatFrom?.find((item) => item.businessName);
    if (!findBusinessName?.businessName) {
      setBusinessAddInProgress({
        isPhysical: false,
        isOnline: false,
      });
    }
    setPhysicalPlatForm(removeBusinessInformation);
    setIsYelp(false);
    setDataAvailable(false);
    setPageNumber(1);
  };

  const clearSearch = async () => {
    setSearchBusinessName('');
    const payload = {
      query: manageBusinessData.searchYelpBusiness,
      variables: {
        location: `${countryLocation}, ${stateName || ''}, ${zipCode || ''}`,
        search: '',
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    } else {
      enqueueSnackbar(result?.searchYelpBusiness?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const verifyZomatoLink = async () => {
    setRefreshIcon(true);
    setIsValidZomatoUrl(false);
    const payload = {
      query: manageBusinessData.validateZomatoUrl,
      variables: {
        url: zomatoUrl,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.validateZomatoUrl?.status === 200 && result?.validateZomatoUrl?.data) {
      const businessDetails = result?.validateZomatoUrl?.data;
      setZomatoVerified(true);
      setZomatoBusiness({
        businessName: businessDetails?.name,
        url: businessDetails?.business_url,
        address: businessDetails?.address,
        businessImage: businessDetails?.business_image_url,
        city: businessDetails?.city_name,
      });
      const isAllFalse = physicalPlatFrom?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = physicalPlatFrom?.map((ele) => {
        if (ele.name === 'zomato') {
          return {
            ...ele,
            address: businessDetails?.address,
            businessName: businessDetails?.name,
            placeId: businessDetails?.res_id.toString(),
            url: businessDetails?.business_url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setPhysicalPlatForm(data);
    }
    if (result?.validateZomatoUrl?.data === null) {
      setIsValidZomatoUrl(true);
      enqueueSnackbar(result?.validateZomatoUrl?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    const phoneNumber = event;
    const countryCode = `+${data.dialCode}`;
    const isoCode = data.countryCode;
    const checkPhoneNumber = phoneNumber.includes(countryCode?.slice(1))
      ? phoneNumber.slice(countryCode?.slice(1).length)
      : phoneNumber;
    setAddBusinessData({
      ...addBusinessData,
      phone: {
        phoneNumber,
        countryCode,
        isoCode,
      },
    });
  };

  const skipGoogleBusiness = () => {
    setIsGoogle(false);
    setGoogleData();
    const removeBusinessInformation = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'google') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    const findBusinessName = removeBusinessInformation?.find((item) => item.businessName);
    if (!findBusinessName?.businessName) {
      setBusinessAddInProgress({
        isPhysical: false,
        isOnline: false,
      });
    }
    setPhysicalPlatForm(removeBusinessInformation);
  };

  const skipZomatoBusiness = () => {
    setIsZomato(false);
    const removeBusinessInformation = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'zomato') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    const findBusinessName = removeBusinessInformation?.find((item) => item.businessName);
    if (!findBusinessName?.businessName) {
      setBusinessAddInProgress({
        isPhysical: false,
        isOnline: false,
      });
    }
    setZomatoUrl();
    setZomatoVerified(false);
    setZomatoBusiness();
    setPhysicalPlatForm(removeBusinessInformation);
  };

  const submitBusinessDetails = async () => {
    setRefreshIcon(true);
    const isFreeTrial = userSubscriptionData?.find(
      (item) => item.isFreeTrial && !item?.businessName
    );
    const reviewPlateForm = physicalPlatFrom?.filter((item) => item.businessName);
    const phoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };
    const googlePhoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };
    const businessDetail = {
      address: addBusinessData?.address,
      address2: addBusinessData?.address,
      businessTypeId: parseInt(addBusinessData?.businessType, 10),
      businessQRId: '',
      city: addBusinessData?.city || '',
      countryId: parseInt(countryId, 10),
      email: addBusinessData?.email,
      name: addBusinessData?.businessName?.toString(),
      phoneNumber: phoneObj,
      googlePhoneNumber: googlePhoneObj,
      profileURL: addBusinessData?.businessImage || '',
      reviewPlatformId: parseInt(addBusinessData?.reviewPlatformId, 10),
      website: addBusinessData?.websiteUrl,
      reviewPlatform: reviewPlateForm?.map((item) => ({
        placeId: item.placeId,
        reviewPlatformId: parseInt(item.id, 10),
        url: item.url || '',
      })),
    };
    const payload = {
      query: manageBusinessData.addBusinessDetailV2,
      variables: {
        businessDetail,
        isQuickHubProduct: true,
        isAddedByGoogleConnect: false,
        userPackageSubscriptionId:
          location.state?.addNewBusiness === 'addNewBusiness'
            ? [location.state.usePackageSubscriptionId]
            : userSubscriptionData
                .filter((item) => !item?.isFreeTrial)
                .map((item) => item?.userPackageSubscriptionId),
        addForFreeTrial: isFreeTrial?.isFreeTrial || false,
      },
    };
    if (clientId) {
      payload.variables.userId = clientId;
      payload.variables.isClient = true;
    }
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.addBusinessDetailV2?.status === 200) {
      setAddBusinessData('');
      setGoogleData('');
      setZomatoBusiness('');
      setIsBusinessSuccessFullyAdded(true);
      const userBusinesses = await fetchBusinesses();
      const findBusiness = userBusinesses?.find(
        (item) =>
          Number(item?.id) ===
          Number(result?.addBusinessDetailV2?.data?.existsAndAddedData?.businessId)
      );
      dispatch(setUserCurrentBusinessDetailsValue(findBusiness));

      await localStorage.setItem('businesses', JSON.stringify(userBusinesses));
      if (clientId) {
        history.push('/agency/business');
      }
      if (parseInt(packageTypeId, 10) === 5) {
        const id = getParamsId();
        if (id) {
          history.push(`/business/dashboard/${id}`);
        } else {
          history.push('/business/dashboard');
        }
      }
    } else {
      enqueueSnackbar(result?.addBusinessDetailV2?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleSkipStep = () => {
    setAddBusinessData('');
    setGoogleData('');
    setZomatoBusiness('');
    const id = getParamsId();
    if (id) {
      history.push(`/business/dashboard/${id}`);
    } else {
      history.push('/business/dashboard');
    }
  };

  const getTrustPilotData = async (value) => {
    try {
      const payload = {
        query: manageBusinessData.searchTrustPilot,
        variables: {
          searchText: value,
          countryCode: selectedCountry?.isoCode,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchTrustPilot?.status === 200) {
        setTrustPilotData(result?.searchTrustPilot?.data);
      }
    } catch (error) {
      console.log('Error fetching campaign data:', error);
    }
  };
  const handleSearchEvent = (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    setSelectedTrustPilotData();
    getTrustPilotData(searchText);
  };

  const selectTrustPilotData = (data) => {
    setSelectedTrustPilotData(data);
  };

  const addTrustPilotData = () => {
    setIsGoogleBusinessAdd(true);
    setIsTrustPilot(false);
    const isAllFalse = physicalPlatFrom?.every((ele) => ele.isBusinessDetailsAdded === false);
    const updateTrustPilot = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'trustpilot') {
        return {
          ...ele,
          address: selectedTrustPilotData?.address?.street,
          businessName: selectedTrustPilotData?.displayName,
          placeId: selectedTrustPilotData?.placeId,
          url: selectedTrustPilotData?.websiteUrl,
          isBusiness: true,
          isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
        };
      }
      const findCountry = countryList?.find(
        (country) => country?.isoCode === selectedTrustPilotData?.countryCode
      );
      setSelectedCountry(findCountry);
      setCountryId(findCountry?.id);
      const phone = selectedTrustPilotData?.phoneNumber && {
        phoneNumber: findCountry?.countryCode
          .toString()
          .concat(selectedTrustPilotData?.phoneNumber?.replace(/\s+/g, '')),
        countryCode: `+${findCountry?.countryCode}`,
        isoCode: findCountry?.isoCode,
      };
      setAddBusinessData({
        ...addBusinessData,
        phone,
        address: selectedTrustPilotData?.address?.street,
        businessName: selectedTrustPilotData?.displayName,
        websiteUrl: selectedTrustPilotData?.websiteUrl,
        businessType: '',
        email: selectedTrustPilotData?.email || '',
        country: findCountry?.countryCode,
        city: selectedTrustPilotData?.address?.city || '',
        businessImage: selectedTrustPilotData?.logoUrl || '',
        reviewPlatformId: 8,
      });
      return ele;
    });
    setPhysicalPlatForm(updateTrustPilot);
    setTimeout(() => {
      setNumber(3);
      setTimeout(() => {
        setIsBusinessFetch(true);
        setIsGoogleBusinessAdd(false);
      }, 1000);
    }, 2000);
  };

  const skipTrustPilotBusiness = () => {
    setIsTrustPilot(false);
    setGoogleData();
    setSelectedTrustPilotData();
    const findBusinessName = physicalPlatFrom?.find((item) => item.businessName);
    if (!findBusinessName?.businessName) {
      setBusinessAddInProgress({
        isPhysical: false,
        isOnline: false,
      });
    }
    const removeBusinessInformation = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'trustpilot') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setPhysicalPlatForm(removeBusinessInformation);
  };

  const GoogleLoginButton = ({ onSuccess, onError }) => {
    const handleGoogleLogin = useGoogleLogin({
      flow: 'auth-code',
      onSuccess,
      onError,
      scope: ['https://www.googleapis.com/auth/business.manage'],
    });

    return (
      <div className="w-full flex justify-center">
        <Button
          className="w-3/4 bg-darkgreen hover:bg-darkgreen disabled:bg-gray disabled:text-black rounded-md mt-20 px-16 flex items-center gap-16"
          onClick={() => handleGoogleLogin()}
          disabled={refreshIcon}
        >
          <div className="bg-white p-4 rounded-full">
            <img
              className={`w-24 ${refreshIcon && 'opacity-60'}`}
              src="assets/images/icon/googlelogo.svg"
              alt=""
            />
          </div>
          <Typography className="text-white font-semibold">
            {t('manageBusiness.connectWithGoogle')}
          </Typography>
          {refreshIcon && (
            <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
          )}
        </Button>
      </div>
    );
  };

  const responseGoogle = async (response) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.getUserRegisteredGoogleBusiness,
        variables: {
          authorizationCode: response?.code,
          isAddedByGoogleConnect: true,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.getUserRegisteredGoogleBusiness?.status === 200) {
        setIsBusinessConnectWithGoogle(true);
        setIsGoogle(false);
        const filterData = result.getUserRegisteredGoogleBusiness?.data?.filter(
          (item) => item?.businessPlaceId
        );
        setBusinessList(filterData);
      } else {
        enqueueSnackbar(result?.getUserRegisteredGoogleBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error fetching registered Google Business:', error);
    }
  };

  const handleError = (error) => {
    console.log('Login Failed:', error);
  };

  const handleConnectBusiness = async () => {
    // setIsGoogleBusinessAdd(true);
    // setIsGoogleBusinessAdd
    setBusinessList([]);
    addBusinessInformation({
      name: 'connectWithGoogle',
      businessName: selectGoogleBusinessData?.businessName,
      address: selectGoogleBusinessData?.businessAddress,
    });
    setAddBusinessData({
      ...addBusinessData,
      reviewPlatformId: 2,
      businessName: selectGoogleBusinessData?.businessName,
      address: selectGoogleBusinessData?.businessAddress,
    });
    const isAllFalse = physicalPlatFrom?.every((ele) => ele.isBusinessDetailsAdded === false);
    const data = physicalPlatFrom?.map((ele) => {
      if (ele.name === 'google') {
        return {
          ...ele,
          address: selectGoogleBusinessData?.businessAddress,
          businessName: selectGoogleBusinessData?.businessName,
          placeId: selectGoogleBusinessData?.businessPlaceId,
          isBusiness: true,
          isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
        };
      }
      return ele;
    });
    setPhysicalPlatForm(data);
  };

  return (
    <div className="text-left w-full">
      {!isGoogle &&
        !isYelp &&
        !isZomato &&
        !isGoogleBusinessAdd &&
        !isBusinessFetch &&
        !isBusinessConnectWithGoogle &&
        !isTrustPilot && (
          <>
            {physicalPlatFrom?.length > 0 &&
              physicalPlatFrom?.map((item, index) => {
                return (
                  <div
                    className={`border-1 flex justify-between mb-28 ${
                      businessAddInProgress.isPhysical ||
                      physicalPlatFrom?.find((value) => value.businessName)?.businessName
                        ? 'w-1/2'
                        : 'w-full'
                    } items-center border-solid !border-darkgreen rounded-md mx-auto py-6 px-16 ${
                      item?.businessName ? 'bg-darkgreen-100' : ''
                    }`}
                    key={index}
                  >
                    <div className="flex items-center">
                      <img
                        src={item?.logo}
                        alt={item?.name}
                        className="md:max-w-24 max-w-24 md:mr-20 mr-10 rounded-full"
                      />
                      <span className="block md:text-16 text-14 font-bold">
                        {capitalizeFirstLetter(item?.name)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      {item?.businessName && addBusinessData?.businessType && (
                        <>
                          <img
                            src="/assets/images/business/check_circle.svg"
                            className="img-fluid"
                            alt="..."
                          />
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="flex items-center rounded-full h-32 md:text-14 font-semibold disabled:text-black sm:min-w-60 bg-transparent border-darkgreen text-darkgreen md:mx-16 mx-6"
                            onClick={() => handleBusinessLocation(item)}
                          >
                            <span>{t('manageBusiness.button.edit')}</span>
                          </Button>
                          <Button
                            size="small"
                            className="flex items-center h-36 w-36 min-w-0 p-0 hover:bg-transparent"
                            onClick={() => handleRemoveBusiness(item)}
                          >
                            <img
                              className="w-32 h-32"
                              src="assets/images/icon/deleteicon.svg"
                              alt=""
                            />
                          </Button>
                        </>
                      )}
                      {(!item?.businessName || !addBusinessData?.businessType) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="inline-block align-middle rounded-3xl md:text-14 py-1 px-12 font-medium disabled:text-black min-h-24"
                          onClick={() => handleBusinessLocation(item)}
                        >
                          <span>{t('manageBusiness.button.addLocation')}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      {isGoogle && (
        <div className="flex w-full gap-60">
          <div className="mt-28 w-full">
            <div className="w-full relative">
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-lg"
                  zoom={8}
                  center={currentLocation}
                  options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                  }}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder={t(
                          'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                        )}
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `300px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `5px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
              {googleData?.businessName && (
                <div className="border-1 left-60 bg-white absolute top-48 sm:w-320 h-max-content m-auto md:w-3/4 rounded-md p-8 border-solid !border-darkgreen">
                  <img className="rounded-md h-112 w-full" src={googleData?.businessImage} alt="" />
                  <div className="flex justify-between pt-8">
                    <Typography className="font-bold w-full text-12 max-w-160">
                      {googleData?.businessName}
                    </Typography>
                    <div className="flex">
                      {googleData?.rating > 0 && (
                        <Typography className="font-medium text-12">
                          <Icon className="text-14 text-yellow-800 align-middle mr-2 -mt-2">
                            star
                          </Icon>{' '}
                          {googleData?.rating}{' '}
                          <span className="font-normal pl-4 text-12">
                            ({googleData?.userTotalRating} reviews)
                          </span>
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Typography className="text-12 py-8">{googleData?.address}</Typography>
                </div>
              )}
            </div>
            <div className="text-right mt-48">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                onClick={() => skipGoogleBusiness()}
                type="button"
              >
                {t('manageBusiness.button.back')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
                aria-label="confirm"
                disabled={!googleData?.businessName}
                onClick={() => addBusinessDetails('google')}
                type="button"
              >
                {t('manageBusiness.button.next')}
                {dataLoading && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </div>
          </div>
          <div className="border-r-2 border-gray" />
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <GoogleLoginButton onSuccess={responseGoogle} onError={handleError} />
          </GoogleOAuthProvider>
        </div>
      )}
      {isBusinessConnectWithGoogle && (
        <>
          {businessList?.length > 0 ? (
            <div className="bg-gray-50">
              <Typography className="font-bold text-20 text-center mb-16">
                {t('manageBusiness.selectBusiness')}
              </Typography>
              <div className="h-512 overflow-scroll">
                {businessList?.map((item, index) => {
                  return (
                    <div
                      className={`flex max-w-384 p-16 gap-16 rounded-md mb-16 items-center m-auto items-center ${
                        selectGoogleBusinessData?.businessName === item.businessName
                          ? 'bg-darkgreen-100 border border-darkgreen'
                          : 'bg-white'
                      }`}
                      key={index}
                      onClick={() => setSelectGoogleBusinessData(item)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          setSelectGoogleBusinessData(item);
                        }
                      }}
                    >
                      <div className="">
                        <img
                          className="max-w-112 min-w-112 h-76 rounded"
                          src="assets/images/business/businessShop.svg"
                          alt=""
                        />
                      </div>
                      <div>
                        <Typography className="font-bold text-16">{item?.businessName}</Typography>
                        <Typography className="font-medium text-12">
                          {item?.businessAddress}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="text-center max-w-216 m-auto mt-16">
                <Button
                  className="text-white bg-darkgreen hover:bg-darkgreen disabled:bg-gray rounded-md text-white w-full font-semibold"
                  disabled={!selectGoogleBusinessData || refreshIcon}
                  onClick={() => handleConnectBusiness()}
                >
                  {t('manageBusiness.button.confirm')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <Typography className="font-bold text-center text-16">
              {t('manageBusiness.noDataAvailable')}
            </Typography>
          )}
        </>
      )}
      {isGoogleBusinessAdd && (
        <div className="bg-gray-50 mt-28 flex items-center h-400 justify-center">
          <div className="">
            <div className="flex w-256 m-auto relative">
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number !== 1 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div className="absolute left-0 text-center w-20 h-20 border border-darkgreen rounded-xl bg-darkgreen text-white">
                  1
                </div>
              </div>
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number === 3 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div
                  className={`absolute left-0 text-center w-20 h-20 ${
                    number !== 1 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  2
                </div>
              </div>
              <div className={`pl-20 pt-12 relative ${number === 3 ? 'border-darkgreen' : ''}`}>
                <div
                  className={`absolute left-0 top-0 text-center w-20 h-20 ${
                    number === 3 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  3
                </div>
              </div>
            </div>

            <Typography className="text-darkgreen text-center py-28 font-bold text-16">
              {number === 1 && t('manageBusiness.addBusinessSteps.businessLocation.fetchingData')}
              {number === 2 && t('manageBusiness.addBusinessSteps.businessLocation.addingBusiness')}
              {number === 3 &&
                t('manageBusiness.addBusinessSteps.businessLocation.businessIsReady')}
            </Typography>

            <Typography className="text-black font-medium text-center pb-8 text-16">
              {t('manageBusiness.addBusinessSteps.businessLocation.processYourRequest')}
            </Typography>

            <img className="w-480 m-auto" src="/assets/video/Transferfilegif.gif" alt="" />
          </div>
        </div>
      )}

      {isBusinessFetch && (
        <div>
          <div className="mt-48">
            <div className="bg-gray-50 p-24">
              <Typography className="text-20 font-bold">
                {t('manageBusiness.addBusinessSteps.businessLocation.businessDetails')}
              </Typography>
              <hr className="border-1 my-20 !border-gray border-solid" />
              <div className="flex gap-28">
                <div className="w-full">
                  <Typography className="text-16 mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.businessName')}
                  </Typography>
                  <TextField
                    className="w-full"
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.addBusiness.form.businessName.placeHolder'
                    )}
                    value={addBusinessData?.businessName}
                    onChange={(e) => {
                      setAddBusinessData({
                        ...addBusinessData,
                        businessName: e.target.value,
                      });
                    }}
                    size="small"
                  />
                </div>
                <div className="w-full">
                  <Typography className="text-16 mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.websiteUrl')}{' '}
                    <span className="text-14">({t('manageBusiness.optional')})</span>
                  </Typography>
                  <TextField
                    className="w-full"
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.addBusiness.form.website.placeHolder'
                    )}
                    value={addBusinessData?.websiteUrl}
                    onChange={(e) => {
                      setAddBusinessData({
                        ...addBusinessData,
                        websiteUrl: e.target.value,
                      });
                    }}
                    size="small"
                  />
                </div>
              </div>
              <div className="flex gap-28 my-8">
                <div className="w-full">
                  <Typography className="text-16 mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.businessType')}
                  </Typography>
                  <ReactSelect
                    labelId="businessType"
                    placeholder={t('business.selectBusinessType')}
                    id="businessType"
                    name="businessType"
                    onChange={handleSelectBusinessType}
                    onInputChange={handleInputChange}
                    value={businessType?.find((op) => {
                      return parseInt(op.value, 10) === parseInt(addBusinessData.businessType, 10);
                    })}
                    options={filteredOptions}
                    components={{
                      MenuList: CustomMenuList,
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography className="text-16 mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.phone')}
                  </Typography>
                  <PhoneInput
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                    )}
                    inputStyle={{
                      width: '100%',
                      height: '40px',
                      color: 'black',
                      fontWeight: '400',
                    }}
                    className="w-full "
                    name="phone_number"
                    required
                    size="small"
                    value={addBusinessData.phone?.phoneNumber?.toString()}
                    country={
                      addBusinessData?.phone?.isoCode || selectedCountry?.isoCode.toLowerCase()
                    }
                    enableSearch="true"
                    onChange={(value, newData) => {
                      handlePhoneChange(value, newData);
                      const validPhoneNumber = handlePhoneNumberValidationCheck(value, newData);
                      setErrorMessage(validPhoneNumber);
                      setPhoneValue(value);
                      setPhones(newData);
                    }}
                  />
                  {errorMessage === false && phoneValue?.length > 0 && (
                    <p className="text-red mt-5 text-16">
                      {t('productSettings.InvalidPhoneNumber')}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-28">
                <div className="w-full">
                  <Typography className="text-16 mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.businessEmail')}{' '}
                    <span className="text-14">({t('manageBusiness.optional')})</span>
                  </Typography>
                  <TextField
                    className="w-full"
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.placeHolder'
                    )}
                    size="small"
                    value={addBusinessData.email}
                    onChange={(e) => {
                      setAddBusinessData({
                        ...addBusinessData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography className="text-16 disabled:text-black disabled:font-semibold text-black mb-8">
                    {t('manageBusiness.addBusinessSteps.businessLocation.country')}
                  </Typography>
                  <Select
                    className="w-full"
                    id="country"
                    value={parseInt(countryId, 10)}
                    size="small"
                    displayEmpty
                    disabled
                    onChange={(e) => {
                      setCountryId(e.target.value);
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {t('qrcodeOrder.form.country.placeHolder')}
                    </MenuItem>
                    {countryList?.map((option, i) => (
                      <MenuItem key={i} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="text-right mt-28">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium hover:bg-darkgreen disabled:bg-gray disabled:text-black bg-darkgreen text-white rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                disabled={
                  !addBusinessData?.businessName ||
                  !addBusinessData?.businessType ||
                  errorMessage === false ||
                  addBusinessData?.phone?.phoneNumber === null ||
                  !addBusinessData?.phone?.phoneNumber ||
                  handlePhoneNumberValidationCheck(
                    addBusinessData?.phone?.phoneNumber,
                    addBusinessData?.phone?.countryCode?.includes('+')
                      ? addBusinessData?.phone?.countryCode?.slice(1)
                      : addBusinessData?.phone?.countryCode
                  )
                }
                onClick={() => {
                  setIsBusinessFetch(false);
                }}
                type="button"
              >
                {t('manageBusiness.button.addLocation')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isYelp && (
        <div>
          <div className="p-24 mt-28 rounded-lg bg-gray-200">
            <div className="h-480 overflow-scroll" onScroll={handleScroll}>
              <div className="flex gap-16 w-full">
                <Paper
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-full sm:w-1/2 px-8 border border-solid border-gray-500 rounded-8 shadow-none"
                >
                  <Input
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                    )}
                    className="flex flex-1 mx-8 text-black text-14"
                    disableUnderline
                    name="searchText"
                    fullWidth
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={(e) => handleSearchBusinessName(e.target.value)}
                    value={searchBusinessName}
                  />
                  {searchBusinessName ? (
                    <Icon
                      color="action"
                      className="cursor-pointer"
                      onClick={() => {
                        clearSearch();
                      }}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="action">search</Icon>
                  )}
                </Paper>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(countryId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={countryId}
                  onChange={(e) => handleSelectCountry(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0">
                    Select country
                  </MenuItem>
                  {countryList?.length > 0 &&
                    countryList?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(stateId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={stateId}
                  onChange={(e) => selectState(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0">
                    Select State
                  </MenuItem>
                  {stateData?.length > 0 &&
                    stateData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              <div className="mt-12">
                <TextField
                  placeholder="Zip code"
                  value={zipCode}
                  type="number"
                  onChange={(e) => handleZipCode(e.target.value)}
                  className="w-full bg-white"
                  size="small"
                />
              </div>
              {yelpData?.length > 0 ? (
                yelpData?.map((item, i) => {
                  return (
                    <div
                      className="sm:flex justify-between items-center p-16 bg-white rounded mt-16"
                      key={i}
                    >
                      <div className="flex items-center gap-24">
                        <div>
                          <img
                            className="w-120 rounded rounded-md h-80"
                            src={item.image_url || 'assets/images/business/addBusiness.svg'}
                            alt=""
                          />
                        </div>
                        <div className="text-start">
                          <Typography className="font-bold">{item.name}</Typography>
                          <Typography className="text-black pt-4 text-14">
                            <LocationOnOutlinedIcon className="text-gray-500 text-20" />{' '}
                            {item.location?.display_address.join(' ')}
                          </Typography>
                        </div>
                      </div>
                      <div className="text-right mt-24 sm:mt-0">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                          onClick={() => addBusinessDetails('yelp', item)}
                        >
                          <span>{t('manageBusiness.button.addBusiness')}</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Typography className="font-semibold pt-80 text-16 flex items-center justify-center">
                  {t('manageBusiness.addBusinessSteps.businessLocation.noDataAvailable')}
                </Typography>
              )}
            </div>
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => cancelYelp()}
              type="button"
            >
              {t('manageBusiness.button.back')}
            </Button>
          </div>
        </div>
      )}
      {!isGoogle &&
        !isYelp &&
        !isZomato &&
        !isGoogleBusinessAdd &&
        !isBusinessFetch &&
        !isTrustPilot &&
        addBusinessData?.businessName && (
          <div className="text-center mt-48">
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:bg-gray hover:bg-darkgreen rounded-md w-1/2"
              aria-label="confirm"
              onClick={() => submitBusinessDetails()}
              type="button"
              disabled={refreshIcon}
            >
              {t('manageBusiness.button.next')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        )}
      {isZomato && (
        <div className="p-24 mt-28 rounded-lg bg-gray-200">
          <div className="text-start">
            <Typography className="text-black text-16 font-medium mb-8">
              {t('manageBusiness.addBusinessSteps.businessLocation.enterZomatoUrl')}{' '}
              <a
                href="https://www.zomato.com/"
                target="_blank"
                className="!bg-transparent !text-darkgreen underline text-12"
                rel="noreferrer"
              >
                (Go to Zomato)
              </a>
            </Typography>
            <TextField
              className="w-full text-black text-16 font-medium bg-white"
              value={zomatoUrl}
              onChange={(e) => {
                setZomatoUrl(e.target.value);
                setZomatoVerified(false);
              }}
              placeholder="https://"
              size="small"
            />
            {zomatoVerified && zomatoUrl ? (
              <>
                <Typography className="text-black text-16 font-medium my-8">
                  {t('manageBusiness.addBusinessSteps.businessLocation.businessName')}
                </Typography>
                <Typography className="text-black border text-black text-14 bg-white border-gray rounded border solid py-10 pl-12 my-8">
                  {zomatoBusiness?.businessName}
                </Typography>
              </>
            ) : (
              <>
                <Button
                  className="border border-solid rounded-4xl mt-20 bg-darkgreen hover:bg-darkgreen disabled:border-none disabled:text-black disabled:bg-gray text-white font-medium px-40"
                  onClick={() => verifyZomatoLink()}
                  disabled={refreshIcon || !zomatoUrl}
                >
                  {t('manageBusiness.button.verifiedZomatoLink')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <img
                  src="/assets/images/business/zomatoGuide.png"
                  className="img-fluid w-full mt-32 max-w-360"
                  alt="..."
                />
              </>
            )}
            {isValidZomatoUrl && (
              <Typography className="text-red mt-16 font-medium text-14">
                <WarningAmberIcon className="text-red" />
                {t('manageBusiness.addBusinessSteps.businessLocation.zomatoAlertMessage')}
              </Typography>
            )}
            <div className="text-right mt-60">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                onClick={() => skipZomatoBusiness()}
                type="button"
              >
                {t('manageBusiness.button.back')}
              </Button>
              {zomatoVerified && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 px-28 font-medium rounded-md inline-block align-middle"
                  onClick={() => addBusinessDetails('zomato')}
                >
                  <span>{t('manageBusiness.button.addReviewSite')}</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {isTrustPilot && (
        <div className="p-24 mt-28 rounded-lg bg-gray-200 h-512">
          <div className="flex gap-20">
            <div className="border !border-gray bg-white rounded items-center justify-center w-full">
              <Paper
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex items-center w-full !px-8 rounded-8 shadow-none"
              >
                <Input
                  placeholder={t('manageBusiness.searchBusinessNameHere')}
                  className="flex flex-1 text-black text-14"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchEvent}
                  value={searchValue}
                />
                {searchValue ? (
                  <Icon
                    color="action"
                    className="cursor-pointer"
                    onClick={() => {
                      setSearchValue('');
                      getTrustPilotData('');
                      setSelectedTrustPilotData();
                      setTrustPilotData([]);
                    }}
                  >
                    close
                  </Icon>
                ) : (
                  <Icon color="action">search</Icon>
                )}
              </Paper>
            </div>
            <div>
              <Button className="bg-darkgreen hover:bg-darkgreen text-white px-48 rounded-lg">
                Search
              </Button>
            </div>
          </div>
          {trustPilotData?.length > 0 && !selectedTrustPilotData && (
            <div className="bg-white h-400 overflow-scroll border border-solid border-gray mt-16">
              {trustPilotData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    onClick={() => selectTrustPilotData(item)}
                    onKeyDown={(e) => e.key === 'Enter' && selectTrustPilotData(item)}
                  >
                    <div className="flex p-20 items-center justify-between mb-8">
                      <div>
                        <Typography>{item?.displayName}</Typography>
                        <Typography>
                          {item?.identifyingName}.{' '}
                          <span className="pl-8">{item.reviewCount} reviews</span>
                        </Typography>
                      </div>
                      <div
                        className={`flex rounded-md p-6 gap-2 items-center ${
                          item.rating > 4
                            ? 'bg-[#00B67A]'
                            : item.rating > 3
                            ? 'bg-[#FFCE00]'
                            : item.rating > 2
                            ? 'bg-[#FF8622]'
                            : 'bg-[#FF3722]'
                        } opacity-55 text-center w-48 h-32`}
                      >
                        <div className="">
                          <StarIcon className="text-white text-16" />
                        </div>
                        <Typography className="font-bold text-14">{item?.rating}</Typography>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
          {searchValue && trustPilotData?.length === 0 && (
            <Typography className="text-center font-normal pt-28 font-semibold text-16">
              {t('manageBusiness.noDataAvailable')}
            </Typography>
          )}
          {selectedTrustPilotData && (
            <div className="p-12 flex items-center gap-8 border border-solid border-darkgreen mt-16 rounded-md bg-white">
              <div>
                <img
                  className="w-96 h-96 rounded-md"
                  src={
                    selectedTrustPilotData?.logoUrl
                      ? `https:${selectedTrustPilotData?.logoUrl}`
                      : 'assets/images/business/addBusiness.svg'
                  }
                  alt=""
                />
              </div>
              <div>
                <Typography>{selectedTrustPilotData?.displayName}</Typography>
                <div className="flex gap-2 my-10">
                  {reviewFormate(selectedTrustPilotData?.rating, 56)}
                </div>
                <div className="flex gap-24">
                  <Typography className="border-r pr-16 border-solid border-gray">
                    TrustScore {selectedTrustPilotData.rating}
                  </Typography>
                  <Typography className="border-r pr-16 border-solid border-gray">
                    Reviews {formatRating(selectedTrustPilotData?.reviewCount)}
                  </Typography>
                  <Typography>{selectedTrustPilotData?.identifyingName}</Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isTrustPilot && (
        <div className="text-right mt-60">
          {!selectedTrustPilotData && (
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => skipTrustPilotBusiness('trustpilot')}
              type="button"
            >
              {t('manageBusiness.button.back')}
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            className="md:text-16 px-60 font-medium rounded-md inline-block align-middle"
            onClick={() => addTrustPilotData()}
            disabled={!selectedTrustPilotData}
          >
            <span>{t('manageBusiness.button.next')}</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(BusinessLocation);
