import PostSection from './postUpdates/PostSection';
import PrimaryDetails from './primaryDetails/PrimaryDetails';

const ListManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-reviews/primaryDetails/:id?',
      element: <PrimaryDetails />,
    },
    {
      path: 'quick-reviews/posts/:id?',
      element: <PostSection />,
    },
  ],
};

export default ListManagementConfig;
