import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import Input from '@mui/material/Input';
import { useSnackbar } from 'notistack';

const PostEditDialogBox = ({ open, onClose, post }) => {
  const [descriptionValue, setDescriptionValue] = useState(post?.description || post?.caption);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchEvent = (event) => {
    setDescriptionValue(event?.target.value);
  };
  const handleSubmit = () => {
    onClose({ ...post, description: descriptionValue, caption: descriptionValue });
    enqueueSnackbar('Post edit successfully.', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `business-type-dropdown rounded-md`,
      }}
      PaperProps={{
        style: {
          width: '750px',
          maxWidth: '100%',
          height: '750px',
          maxHeight: '94vh',
          padding: '20px',
        },
      }}
    >
      <div className="flex justify-between mb-10 ">
        <Typography className="text-20 font-600">{t('quickSocial.editPost')}</Typography>
        <IconButton className="" onClick={onClose} color="inherit">
          <CloseIcon className="w-20 h-20 text-grey-700" />
        </IconButton>
      </div>

      <div className="bg-indigo-50 bg-opacity-50 p-20 rounded-md mb-16 flex gap-16">
        <div className="w-full flex flex-col justify-between">
          <div className="flex flex-col gap-20">
            <div className="flex justify-between">
              <Input
                placeholder="Add description"
                className="text-black text-16 h-40  px-12"
                disableUnderline
                name="searchText"
                fullWidth
                onChange={handleSearchEvent}
                value={descriptionValue}
                inputProps={{
                  'aria-label': 'Search',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        className="md:text-18 px-40 font-semibold bg-white hover:bg-white text-darkgreen border border-solid border-darkgreen rounded-md"
        variant="contained"
        color="primary"
        type="button"
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
    </Dialog>
  );
};

export default PostEditDialogBox;
