import { Button, Typography } from '@mui/material';
import { t } from 'i18next';

const WabaDashBoard = (props) => {
  return (
    <>
      <div className="p-20 lg:p-28">
        <div className="text-right text-16 font-semibold mb-20">
          {t('waba.dashboard.status')}
          <span className={`${props?.status === 'PENDING' ? 'text-red' : 'text-green'}`}>
            {props?.status === 'PENDING' ? 'PENDING' : 'LIVE'}
          </span>
        </div>
        <div className="bg-white shadow rounded-md p-20 md:p-28 mb-28">
          <Typography className="text-24 font-semibold mb-12 mt-0">
            {t('waba.dashboard.welcome')}
          </Typography>
          <Typography className="text-18 font-medium text-grey-600 mb-24">
            {t('waba.dashboard.welcomeDesc')}
          </Typography>
          <ul>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-1.png"
                  className="img-fluid w-full max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Apply for WhatsApp Business API
              </span>{' '}
              <Button
                type="text"
                variant="contained"
                className="min-w-96 text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                Apply
              </Button>
            </li>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-2.png"
                  className="img-fluid w-full max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Phone & Display Name Verification
              </span>
              <Button
                type="text"
                variant={props?.status === 'PENDING' ? 'outlined' : 'contained'}
                className="min-w-96 text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                {props?.status === 'PENDING' ? 'Pending' : 'Approved'}
              </Button>
            </li>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center justify-between">
              <span>
                <img
                  src="/assets/images/business/waba-dash-3.png"
                  className="img-fluid w-full max-w-28 mr-12 inline-block align-middle"
                  alt="..."
                />{' '}
                Facebook Manager Verification
              </span>
              <Button
                type="text"
                variant={props?.status === 'PENDING' ? 'outlined' : 'contained'}
                className="min-w-96 text-14 font-semibold rounded-md"
                size="small"
                color="quickChat"
              >
                {props?.status === 'PENDING' ? 'Pending' : 'Verified'}
              </Button>
            </li>
            {/* <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 md:mb-20 mb-10 flex items-center">
              <img
                src="/assets/images/business/waba-dash-4.png"
                className="img-fluid w-full max-w-28 mr-12"
                alt="..."
              />{' '}
              Setup Your Profile
            </li>
            <li className="text-18 font-semibold text-black border-2 border-solid rounded-md md:p-20 p-10 flex items-center">
              <img
                src="/assets/images/business/waba-dash-5.png"
                className="img-fluid w-full max-w-28 mr-12"
                alt="..."
              />{' '}
              Apply for Green Tick
            </li> */}
          </ul>
        </div>
        {/* <div className="bg-white shadow rounded-md p-20 md:p-28 mb-28">
          <div className="flex items-center justify-between">
            <Typography className="text-24 font-semibold mb-12 mt-0">
              {t('waba.dashboard.conversationCredit')}
            </Typography>
            <span className="semi-bold text-24 font-bold">45/1000 Used</span>
          </div>
          <div className="block w-full mb-24">
            <Slider
              aria-label="Temperature"
              className="block pb-0"
              defaultValue={30}
              value={50}
              // onChange={valueText}
              size="large"
              min={1}
              max={1000}
              color="secondary"
              sx={{
                height: 12,
                '& .MuiSlider-thumb': {
                  backgroundColor: 'secondary.main',
                  '&:last-of-type': {
                    borderRadius: 0,
                    width: 2,
                    height: 24,
                  },
                },
              }}
            />
          </div>
          <div className="bg-grey-100 rounded-lg w-full px-20 py-16 mb-28">
            <span className="font-bold text-24">$ 4591</span>
          </div>
          <div className="bg-grey-100 rounded-lg w-full px-20 py-16">
            <h4 className="font-semibold text-18 mb-12">{t('waba.dashboard.enterAmount')}</h4>
            <Typography className="text-18 font-medium text-grey-600 mb-24">
              {t('waba.dashboard.minimum')}
            </Typography>
            <Input
              placeholder={t('appPromotionSlider.search')}
              className="flex flex-1 mx-8 text-black text-16 bg-white"
              disableUnderline
              name="searchText"
              fullWidth
              // onChange={handleSearchEvent}
              // value={searchValue}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default WabaDashBoard;
