import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { useEffect, useState, useRef } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import history from '@history';
import cmsData from '../../query/cms';
import {
  resetCmsWebsite,
  addDomainFeatures,
  deleteDomainFeatures,
  updateDomainFeatures,
  getDomainFeatures,
} from './store/cmsWebsiteSlice';
import UserService from '../../../services/userService';
import reducer from './store';

function Features() {
  const formRef = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form Validation Schema
   */

  const defaultValues = {};

  const schema = yup.object().shape({
    mainTitle: yup.string(),
    mainDescription: yup.string(),
    formData: yup.array().of(
      yup.object().shape({
        title: yup.string(),
        description: yup.string(),
      })
    ),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formData',
  });

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [imagePreviewName, setImagePreviewName] = useState([]);
  const [noImgMsg, setNoImgMsg] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isEditSection, setIsEditSection] = useState(0);
  const [isEditMainTitle, setIsEditMainTitle] = useState(false);
  const [editFeaturesId, setEditFeaturesId] = useState(null);
  const [actionValue, setActionValue] = useState('action');
  const loginUserData = UserService.getUserData();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const featuresDetails = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getDomainFeaturesData?.data?.getDomainFeatures?.data || ''
  );

  const featuresMainDetails = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getDomainFeaturesData?.data?.getDomainFeatures
        ?.domainDetails || ''
  );

  const deleteStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.deleteDomainFeaturesData?.data?.deleteDomainFeatures || ''
  );

  const updateStatus = useSelector(
    ({ cmsReducer }) => cmsReducer?.cms?.updateDomainFeaturesData?.data?.updateDomainFeatures || ''
  );

  const addStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.addDomainFeaturesData?.data?.addDomainFeatures || ''
  );

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getDomainFeatures,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getDomainFeatures(payload));
    }
    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (featuresDetails?.length === 0) {
      append({ title: '', description: '' });
    }
  }, [featuresDetails?.length, append]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (featuresDetails || featuresDetails === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [featuresDetails, isValidDomainRegisterId]);

  useEffect(() => {
    if (deleteStatus && deleteStatus.status === 400) {
      enqueueSnackbar(deleteStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    } else if (deleteStatus && deleteStatus.status === 200) {
      enqueueSnackbar(deleteStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    }
  }, [dispatch, deleteStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateStatus && updateStatus.status === 400) {
      enqueueSnackbar(updateStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    } else if (updateStatus && updateStatus.status === 200) {
      enqueueSnackbar(updateStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    }
  }, [dispatch, updateStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (addStatus && addStatus.status === 400) {
      enqueueSnackbar(addStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (addStatus && addStatus.status === 200) {
      enqueueSnackbar(addStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setIsEdit(false);
      setRefreshIcon(false);
      closeSnackbar(3000);
    }
  }, [dispatch, addStatus, enqueueSnackbar, closeSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }

  function resetForm() {
    setEditFeaturesId(null);
    reset({ formData: [{ title: '', description: '' }] });
    setImages([]);
    setImagePreviewName([]);
  }

  function handleFileChange(event, index) {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }

    imagePreviewName[index] = event?.target?.files[0]?.name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };

    setImages(images);
    setNoImgMsg('');
  }

  function handleDrop(event, index) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    imagePreviewName[index] = event?.dataTransfer?.files[0]?.name;
    setImagePreviewName([...imagePreviewName]);
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      images[index] = file;
    };
    setImages(images);
    setNoImgMsg('');
  }

  const formsData = [{ title: '', description: '' }];

  const onSubmit = async (values) => {
    setRefreshIcon(true);
    try {
      if (editFeaturesId || isEditSection === 1) {
        const payload = {
          query: cmsData.updateDomainFeatures,
          variables: {
            featureId: editFeaturesId,
            description: values?.formData[0]?.description,
            title: values?.formData[0]?.title,
            mainDescription: values.mainDescription,
            mainTitle: values.mainTitle,
          },
          fileData: images,
        };
        if (isEditMainTitle) {
          payload.variables.domainRegisterId = userDomainRegisterId;
        }
        await dispatch(updateDomainFeatures(payload));
        setImages([]);
        setImagePreviewName([]);
        setIsEdit(false);
      } else {
        if (images.length < values.formData.length) {
          enqueueSnackbar(t('appWebsite.bannerRequiredMessage'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setRefreshIcon(false);
          return;
        }
        const newValue = values.formData.map((item) => {
          return {
            ...item,
          };
        });
        const payloadValue = {
          query: cmsData.addDomainFeatures,
          variables: {
            domainRegisterId: userDomainRegisterId,
            input: newValue,
            mainDescription: values.mainDescription,
            mainTitle: values.mainTitle,
          },
        };
        const dataObj = {
          payload: payloadValue,
          fileData: images,
        };
        await dispatch(addDomainFeatures(dataObj));
      }
      setIsEdit(false);
      setRefreshIcon(false);
      reset({ formData: [{ title: '', description: '' }] });

      const payload = {
        query: cmsData.getDomainFeatures,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      await dispatch(getDomainFeatures(payload));

      reset();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  const handleDelete = async (featuresId) => {
    try {
      const payloadValue = {
        query: cmsData.deleteDomainFeatures,
        variables: {
          domainFeaturesId: featuresId,
        },
      };
      await dispatch(deleteDomainFeatures(payloadValue));

      const payload = {
        query: cmsData.getDomainFeatures,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      await dispatch(getDomainFeatures(payload));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  function handleEditClick(featuresId) {
    setEditFeaturesId(featuresId);

    const featuresToEdit = featuresDetails.find((features) => features.id === featuresId);

    if (featuresToEdit) {
      const forms = [{ title: featuresToEdit.title, description: featuresToEdit.description }];
      reset({ formData: forms });
    }
  }
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-32 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center mb-36">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.features.title')}
            </Typography>
            <>
              {(isEdit || isEditMainTitle) && (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-15 font-semibold"
                    variant="outlined"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => {
                      setIsEdit(false);
                      setIsEditMainTitle(false);
                      setIsEditSection(0);
                      reset({ formData: [{ title: '', description: '' }] });
                    }}
                  >
                    {t('business.selectedBusinessDetail.buttons.cancel')}
                  </Button>
                </div>
              )}
            </>
          </div>
          <div>
            {!featuresMainDetails || isEdit ? (
              <form
                name="registerForm"
                noValidate
                className="w-full"
                defaultValue={{ forms: formsData }}
                onSubmit={handleSubmit(onSubmit)}
                ref={formRef}
              >
                <div>
                  <div>
                    {(isEditSection === 0 || isEditSection === 1) && (
                      <div className="bg-white rounded-md relative shadow p-24 mb-40">
                        <div className="block">
                          <InputLabel
                            htmlFor="mainTitle"
                            className="text-16 font-medium mb-12 text-grey-900"
                          >
                            {t('appWebsite.features.formField.mainTitle.name')}*
                          </InputLabel>
                          <Controller
                            name="mainTitle"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                sx={{
                                  '& .MuiInputBase-input': {
                                    background: 'white',
                                  },
                                }}
                                type="text"
                                size="small"
                                value={undefined}
                                defaultValue={featuresMainDetails?.mainTitle}
                                error={!!errors?.mainTitle}
                                helperText={errors?.mainTitle?.message}
                                variant="outlined"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="block">
                          <InputLabel
                            htmlFor="mainDescription"
                            className="text-16 font-medium mb-12 text-grey-900"
                          >
                            {t('appWebsite.features.formField.mainDescription.name')}*
                          </InputLabel>
                          <Controller
                            name="mainDescription"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="mb-24"
                                sx={{
                                  '& .MuiInputBase-input': {
                                    background: 'white',
                                  },
                                }}
                                type="text"
                                size="small"
                                value={undefined}
                                defaultValue={featuresMainDetails?.mainDescription}
                                error={!!errors?.mainDescription}
                                helperText={errors?.mainDescription?.message}
                                variant="outlined"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                    {(isEditSection === 0 || isEditSection === 2) && (
                      <>
                        {fields.map((fieldInput, index) => (
                          <div
                            className="bg-white rounded-md relative shadow p-24 mb-40"
                            key={index}
                          >
                            <div className="flex">
                              <div className="w-full">
                                <div className="block">
                                  <InputLabel
                                    htmlFor="title"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.features.formField.featuresTitle.name')}*
                                  </InputLabel>
                                  <Controller
                                    // name="bannerTitle"
                                    name={`formData[${index}].title`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="text"
                                        size="small"
                                        value={undefined}
                                        defaultValue={fieldInput.title}
                                        error={!!errors?.formData?.[index]?.title}
                                        helperText={errors?.formData?.[index]?.title?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <div className="block">
                                  <InputLabel
                                    htmlFor="description"
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.features.formField.description.name')}*
                                  </InputLabel>
                                  <Controller
                                    name={`formData[${index}].description`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        className="mb-24"
                                        sx={{
                                          '& .MuiInputBase-input': {
                                            background: 'white',
                                          },
                                        }}
                                        type="text"
                                        size="small"
                                        multiline
                                        rows={4}
                                        value={undefined}
                                        defaultValue={fieldInput.description}
                                        error={!!errors?.formData?.[index]?.description}
                                        helperText={errors?.formData?.[index]?.description?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <motion.div className="block mb-24">
                                  <InputLabel
                                    htmlFor={`formData[${index}].uploadImage`}
                                    className="text-16 font-medium mb-12 text-grey-900"
                                  >
                                    {t('appWebsite.service.formField.uploadImage.name')}
                                  </InputLabel>
                                  <Paper
                                    className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                                    role="button"
                                    onDrop={(event) => {
                                      handleDrop(event, index);
                                    }}
                                    onDragOver={(e) => e.preventDefault()}
                                  >
                                    <div className="inline-block">
                                      {/* <img
                             className="w-2/4 block rounded max-h-96"
                             name="logo"
                             src={imagePreview}
                             alt="logo"
                           /> */}
                                      <Controller
                                        name={`formData[${index}].uploadImage`}
                                        control={control}
                                        error={!!errors?.formData?.[index]?.uploadImage}
                                        helperText={errors?.formData?.[index]?.uploadImage?.message}
                                        render={({ field }) => (
                                          <label htmlFor={`formData[${index}].uploadImage`}>
                                            <input
                                              {...field}
                                              accept="image/*"
                                              className="hidden"
                                              name={`formData[${index}].uploadImage`}
                                              id={`formData[${index}].uploadImage`}
                                              type="file"
                                              onChange={(event) => {
                                                handleFileChange(event, index);
                                              }}
                                            />
                                            <Button
                                              size="small"
                                              className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                                              variant="contained"
                                              color="secondary"
                                              component="span"
                                            >
                                              <Icon color="action material-symbols-outlined align-middle">
                                                upload
                                              </Icon>{' '}
                                              {t('reviewPlatform.buttons.uploadReviewIcon')}
                                            </Button>
                                          </label>
                                        )}
                                      />
                                    </div>
                                    <div className="inline-block ml-16 font-medium">
                                      {imagePreviewName[index] ||
                                        `${t('appWebsite.about.formField.OrDropImage')}`}
                                    </div>
                                    {noImgMsg && (
                                      <span className="text-red block mt-16">{noImgMsg}</span>
                                    )}
                                  </Paper>
                                </motion.div>
                              </div>
                              {!editFeaturesId && index !== 0 && (
                                <Button
                                  onClick={() => remove(index)}
                                  className="ml-24 w-44 px-0 min-w-44 h-44 min-h-44 rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                                  type="button"
                                  color="secondary"
                                  variant="contained"
                                >
                                  <Icon>delete</Icon>
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {!editFeaturesId && !isEditMainTitle && (
                      <div className="text-right">
                        <Button
                          className="rounded-md font-medium text-16 bg-darkgreen-100 text-darkgreen hover:bg-darkgreen hover:text-white"
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            append({ title: '', description: '' });
                          }}
                        >
                          <Icon>add</Icon> {t('appWebsite.features.formField.addNew')}hr
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {fields.length > 0 && (
                  <div className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                      aria-label="Register"
                      disabled={
                        editFeaturesId !== '' || isEditMainTitle
                          ? refreshIcon
                          : _.isEmpty(dirtyFields) || !isValid || refreshIcon
                      }
                      type="submit"
                      size="small"
                    >
                      {t('appWebsite.service.formField.button')}{' '}
                      {refreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>
                  </div>
                )}
              </form>
            ) : (
              <>
                <div className="bg-white rounded-md relative shadow p-24 mb-40">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-20 font-semibold mb-10">
                        {featuresMainDetails?.mainTitle}
                      </h3>
                      <Typography className="text-16 mb-0 font-medium">
                        {featuresMainDetails?.mainDescription}
                      </Typography>
                    </div>
                    <div className="inline-block items-center w-auto">
                      <Button
                        className="text-18 px-15 font-semibold"
                        variant="outlined"
                        color="secondary"
                        aria-label="Edit"
                        onClick={(e) => {
                          setIsEditMainTitle(true);
                          setIsEdit(true);
                          setIsEditSection(1);
                        }}
                      >
                        <Icon>edit</Icon>
                        {t('users.userDetails.buttons.edit')}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md relative shadow p-24">
                  <div className="items-center mb-36">
                    <Typography
                      component={motion.span}
                      initial={{ x: -20 }}
                      animate={{ x: 0, transition: { delay: 0.2 } }}
                      delay={300}
                      className="text-16 md:text-24 font-semibold mb-20"
                    >
                      {t('appWebsite.features.homeFeatures')}
                    </Typography>
                    <div className="inline-block items-center w-auto float-right">
                      <Button
                        className="text-16 px-16 rounded-md"
                        variant="contained"
                        color="secondary"
                        aria-label="Edit"
                        onClick={() => {
                          setIsEdit(true);
                          resetForm();
                          setIsEditSection(2);
                        }}
                        // onClick={(e) => setIsEdit(true)}
                      >
                        {t('appWebsite.features.formField.addNew')}
                      </Button>
                    </div>
                  </div>
                  <TableContainer className="border-b-1 border-solid border-grey-200">
                    <Table aria-labelledby="tableTitle" size="medium">
                      <TableHead>
                        <TableRow className="bg-grey-200 rounded-tr-md">
                          <TableCell className="text-16 font-medium rounded-tl-md" />
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.features.column.name')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10">
                            {t('appWebsite.features.column.date')}
                          </TableCell>
                          <TableCell className="text-16 font-medium py-10 rounded-tr-md">
                            {t('appWebsite.features.column.action')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {featuresDetails &&
                          featuresDetails?.length > 0 &&
                          featuresDetails?.map((featuresData, i) => (
                            <TableRow hover className="border border-t-2 border-t-grey-100" key={i}>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <img
                                  src={featuresData?.icon}
                                  className="img-fluid max-w-80 rounded-md"
                                  alt="..."
                                />
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {featuresData?.title}
                              </TableCell>
                              <TableCell className="text-16 color-black font-semibold" align="left">
                                {moment(featuresData?.cts).format('MMM DD, YYYY')}
                              </TableCell>

                              <TableCell className="text-16 color-black font-semibold" align="left">
                                <Select
                                  className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                  sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                  variant="filled"
                                  value={actionValue}
                                  fullWidth
                                >
                                  <MenuItem
                                    value="action"
                                    className="capitalize mx-5 rounded-md"
                                    disabled
                                  >
                                    {t('menuItems.action')}
                                  </MenuItem>
                                  <MenuItem
                                    value="view"
                                    className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                    onClick={() => {
                                      handleEditClick(featuresData?.id);
                                      setIsEditSection(2);
                                      setIsEdit(true);
                                    }}
                                  >
                                    {t('menuItems.edit')}
                                  </MenuItem>
                                  <MenuItem
                                    value="delete"
                                    className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                    onClick={() => handleDelete(featuresData?.id)}
                                  >
                                    {t('menuItems.delete')}
                                  </MenuItem>
                                </Select>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(Features);
