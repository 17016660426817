import { useTranslation } from 'react-i18next';

function NoData(props) {
  const { t } = useTranslation();

  return (
    <div className={`text-center ${props.fromDashboard ? 'py-20' : 'pt-96 min-h-360'}`}>
      <img
        src="/assets/images/business/chart.png"
        className={`img-fluid mx-auto mb-28 ${props.fromDashboard ? 'max-w-84' : 'max-w-96'}`}
        alt="No Data Available"
      />
      <h2 className="text-16 font-semibold text-grey-500">{t('metrics.noData')}</h2>
    </div>
  );
}

export default NoData;
