import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import queriesData from '../../query/queries';
import {
  getQuickReviewContactUsData,
  reSetQueries,
  openQueriesDialog,
  closeQueriesDialog,
  updateQuickReviewContactStatus,
} from './store/queriesSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Queries() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const queriesListData = useSelector(
    ({ queriesReducer }) =>
      queriesReducer?.queries?.getQuickReviewContactUsDataValue?.data?.getQuickReviewContactUsData
        ?.data || ''
  );

  const totalQueries = useSelector(
    ({ queriesReducer }) =>
      queriesReducer?.queries?.getQuickReviewContactUsDataValue?.data?.getQuickReviewContactUsData
        ?.totalCount || 0
  );

  const updateQuickReviewContactStatusValue = useSelector(
    ({ queriesReducer }) =>
      queriesReducer?.queries?.updateQuickReviewContactStatusData?.data
        ?.updateQuickReviewContactStatus
  );

  const appDialog = useSelector(({ queriesReducer }) => queriesReducer.queries.queriesDialog);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [viewMessage, setViewMessage] = useState('');
  const [viewMessageStatus, setViewMessageStatus] = useState('');
  const [viewMessageId, setViewMessageId] = useState();
  const [data, setData] = useState(queriesListData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = queriesListData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: queriesData.getQuickReviewContactUsData,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
      },
    };
    dispatch(getQuickReviewContactUsData(payload));

    return () => {
      unmounted = true;
      dispatch(reSetQueries());
    };
  }, [dispatch]);

  useEffect(() => {
    if (queriesListData && queriesListData?.length) {
      setData(queriesListData);
    } else {
      setData();
    }
  }, [queriesListData]);

  useEffect(() => {
    if (queriesListData || queriesListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [queriesListData]);

  useEffect(() => {
    if (updateQuickReviewContactStatusValue && updateQuickReviewContactStatusValue.status === 400) {
      enqueueSnackbar(updateQuickReviewContactStatusValue.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (
      updateQuickReviewContactStatusValue &&
      updateQuickReviewContactStatusValue.status === 200
    ) {
      enqueueSnackbar(updateQuickReviewContactStatusValue.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateQuickReviewContactStatusValue, enqueueSnackbar, closeSnackbar]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: queriesData.getQuickReviewContactUsData,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    dispatch(getQuickReviewContactUsData(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: queriesData.getQuickReviewContactUsData,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getQuickReviewContactUsData(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: queriesData.getQuickReviewContactUsData,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getQuickReviewContactUsData(payload));
      setLoadingPage(false);
    });
  }

  const handleQuerySolvedStatus = () => {
    const resolveStatusValue = viewMessageStatus === 'open' ? 'solved' : 'open';
    setViewMessageStatus(resolveStatusValue);
    const payload = {
      query: queriesData.updateQuickReviewContactStatus,
      variables: {
        quickReviewContactId: parseInt(viewMessageId, 10),
        resolveStatus: resolveStatusValue,
      },
    };

    dispatch(async (dispatchValue) => {
      await dispatchValue(updateQuickReviewContactStatus(payload));
      closeComposeDialog();
      const payloadValue = {
        query: queriesData.getQuickReviewContactUsData,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          searchText: searchValue,
        },
      };
      dispatch(getQuickReviewContactUsData(payloadValue));
    });
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.name')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.emailId')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.mobile')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.date')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium min-w-136" align="left">
            <TableSortLabel hideSortIcon>{t('queries.column.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('queries.column.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    return dispatch(closeQueriesDialog());
  }

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('queries.queriesList.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('queries.queriesList.placeholder.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end" />
      </div>
      <div className="bg-white rounded-md relative shadow">
        {queriesListData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={queriesListData?.length}
                />
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.email}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.phoneNumber?.phoneNumber ? '+' : ''}
                            {row?.phoneNumber?.countryCode} {row?.phoneNumber?.phoneNumber}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell
                            className={`text-16 color-black font-semibold ${
                              row.resolveStatus === 'open' ? 'text-green' : 'text-yellow'
                            }`}
                            align="left"
                          >
                            {row.resolveStatus}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-16 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                              aria-hidden="true"
                              onClick={() => {
                                dispatch(openQueriesDialog());
                                setViewMessage(row.message);
                                setViewMessageStatus(row.resolveStatus);
                                setViewMessageId(row.id);
                              }}
                            >
                              {t('queries.queriesList.view')}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('queries.queriesList.messages.noQueries')}
            </Typography>
          </motion.div>
        )}
      </div>

      {queriesListData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalQueries}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={() => closeComposeDialog()}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-dashed border-grey-500 w-full pb-10">
            {t('queries.queriesList.view')}
          </Typography>
        </DialogTitle>

        <DialogContent className={` p-0 mb-24`}>
          <div className="bg-grey-200 rounded-md p-16 text-16 font-medium text-black">
            {viewMessage}
          </div>
        </DialogContent>
        <DialogActions className="p-0 justify-between flex">
          <span className="text-16 font-semibold">{t('queries.queriesList.querysolve')}</span>
          <Switch
            checked={viewMessageStatus === 'solved'}
            onChange={() => handleQuerySolvedStatus(viewMessageStatus)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withReducer('queriesReducer', reducer)(Queries);
