import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Icon from '@mui/material/Icon';
import appWebsiteData from '../../query/appWebsite';
import cmsData from '../../query/cms';
import UserService from '../../../services/userService';
import {
  getAppTemplate,
  getBusinessUserSelectedAppTemplet,
  resetAppWebsite,
  updateUserTemplateBasicDetails,
} from './store/appWebsiteSlice';
import reducer from './store';

function SelectApp(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectThemeId, setSelectThemeId] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loginUserData = UserService.getUserData();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const domainAndDeploymentStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getAppTemplateData?.data?.getAppTemplate?.data
  );

  const selectedTemplate = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.getBusinessUserSelectedAppTempletData?.data
        ?.getBusinessUserSelectedAppTemplet?.data
  );

  const updateTemplateStatus = useSelector(
    ({ appsWebsitesReducer }) =>
      appsWebsitesReducer?.AppWebsite?.updateUserTemplateBasicDetailsData?.data
        ?.updateUserTemplateBasicDetails
  );

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: appWebsiteData.getAppTemplate,
    };
    dispatch(getAppTemplate(payload));

    if (isValidDomainRegisterId) {
      const payloadData = {
        query: appWebsiteData.getBusinessUserSelectedAppTemplet,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getBusinessUserSelectedAppTemplet(payloadData));
    }

    return () => {
      unmounted = true;
      dispatch(resetAppWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (updateTemplateStatus?.status) {
      enqueueSnackbar(updateTemplateStatus?.message, {
        variant: updateTemplateStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
    }
  }, [updateTemplateStatus, enqueueSnackbar, closeSnackbar]);

  const SelectUserTheme = async (appId) => {
    setRefreshIcon(true);
    const payload = {
      query: cmsData.updateUserTemplateBasicDetails,
      variables: {
        domainRegisterId: userDomainRegisterId,
        appTemplateId: parseInt(appId, 10),
      },
    };
    await dispatch(updateUserTemplateBasicDetails(payload));
    const payloadData = {
      query: appWebsiteData.getBusinessUserSelectedAppTemplet,
      variables: { domainRegisterId: userDomainRegisterId },
    };
    dispatch(getBusinessUserSelectedAppTemplet(payloadData));
  };
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-32 h-full">
      {isValidDomainRegisterId ? (
        <>
          {!selectedTemplate && (
            <div className="items-center mb-36 text-left">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-16 md:text-24 font-bold mb-20"
              >
                {t('appWebsite.selectApp.title')}
              </Typography>
            </div>
          )}
          <div className="float-right rounded-md bg-darkgreen text-white font-medium p-14 pt-12 flex items-center justify-between">
            <Icon className="bg-white text-darkgreen rounded-full mr-10 text-center pt-4 w-32 h-32">
              call
            </Icon>
            <div>
              <div>
                {' '}
                Please call on this no.{' '}
                <span className="text-16 font-semibold">+91 8087569265</span>
              </div>
              <div>For the next steps to publish your app</div>
            </div>
          </div>
          {selectedTemplate && (
            <>
              <div className="items-center mb-36 text-left">
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="text-16 md:text-24 font-bold mb-20"
                >
                  {t('appWebsite.selectApp.selectedApp')}
                </Typography>
              </div>
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
                <div className="bg-white rounded-md relative shadow mb-40">
                  <img
                    src={selectedTemplate?.app_template?.image}
                    className="img-fluid mx-auto w-full"
                    alt="..."
                  />
                  <div className="p-16">
                    <h2 className="text-16 font-semibold mb-16">
                      {selectedTemplate?.Template?.app_template?.name}
                    </h2>
                    <div className="flex">
                      <Button
                        className="md:text-14 font-medium bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen w-full mr-8 py-2"
                        variant="contained"
                        color="primary"
                        type="button"
                        size="small"
                        href={selectedTemplate?.Template?.app_template?.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        role="button"
                      >
                        {t('appWebsite.selectTheme.buttons.livePreview')}
                      </Button>
                      <Button
                        className="md:text-14 font-medium disabled:text-black rounded-md w-full ml-8 py-2"
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="small"
                        onClick={() => history.push('/business-information')}
                      >
                        {t('appWebsite.selectApp.buttons.createApp')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedTemplate && (
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20 md:mb-36 block"
            >
              {t('appWebsite.selectApp.changeApp')}
            </Typography>
          )}
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
            {domainAndDeploymentStatus &&
              domainAndDeploymentStatus?.length > 0 &&
              domainAndDeploymentStatus?.map((businessTemplate, index) => (
                <div className="bg-white rounded-md relative shadow" key={index}>
                  <img
                    src={businessTemplate?.image}
                    className="img-fluid mx-auto w-full"
                    alt="..."
                  />
                  <div className="p-16">
                    <h2 className="text-16 font-semibold mb-16">{businessTemplate?.name}</h2>
                    <div className="flex">
                      <Button
                        className="md:text-14 font-medium bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen w-full mr-8 py-2"
                        variant="contained"
                        color="primary"
                        type="button"
                        size="small"
                        href={businessTemplate?.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        role="button"
                      >
                        {t('appWebsite.selectApp.buttons.livePreview')}
                      </Button>
                      <Button
                        className="md:text-14 font-medium disabled:text-black rounded-md w-full ml-8 py-2"
                        variant="contained"
                        color="secondary"
                        type="submit"
                        size="small"
                        disabled={refreshIcon && selectThemeId === businessTemplate?.id}
                        onClick={() => {
                          SelectUserTheme(businessTemplate?.id);
                          setSelectThemeId(businessTemplate?.id);
                        }}
                      >
                        {t('appWebsite.selectApp.buttons.selectApp')}
                        {refreshIcon && selectThemeId === businessTemplate?.id && (
                          <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('appsWebsitesReducer', reducer)(SelectApp);
