import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { Grid, TablePagination } from '@mui/material';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import usersFeedbackData from '../../query/usersFeedback';
import { getAllUserFeedback, reSetUsers } from './store/userFeedbackSlice';
import reducer from './store';

function UserFeedbackList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userFeedbackListData = useSelector(
    ({ userFeedbackReducer }) =>
      userFeedbackReducer?.userFeedback?.getAllUserFeedbackData?.data?.getUserFeedback?.data || ''
  );

  const totalUser = useSelector(
    ({ userFeedbackReducer }) =>
      userFeedbackReducer?.userFeedback?.getAllUserFeedbackData?.data?.getUserFeedback
        ?.totalCount || 0
  );

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: usersFeedbackData.getAllUserFeedback,
      variables: {
        pageNumber: 1,
        pageSize: 10,
        searchText: '',
      },
    };
    dispatch(getAllUserFeedback(payload));
    setLoading(false);
    return () => {
      unmounted = true;
      dispatch(reSetUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (userFeedbackListData || userFeedbackListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [userFeedbackListData]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: usersFeedbackData.getAllUserFeedback,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    dispatch(getAllUserFeedback(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: usersFeedbackData.getAllUserFeedback,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllUserFeedback(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: usersFeedbackData.getAllUserFeedback,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllUserFeedback(payload));
      setLoadingPage(false);
    });
  }

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            User Feedback
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('navigation.searchUserName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  handleClearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {userFeedbackListData?.length > 0 ? (
          <>
            {userFeedbackListData.map((item, index) => {
              return (
                <TableContainer key={index} style={{ padding: '25px' }}>
                  <div className="border-[1px] !border-gray-400 rounded px-[30px] py-[25px]">
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item>
                            {item.users.profileUrl ? (
                              <Avatar
                                className="mx-auto w-45 h-45 mb-16 capitalize text-40"
                                src={item.users.profileUrl}
                              />
                            ) : (
                              <Avatar
                                className="mx-auto w-45 h-45 mb-16 capitalize text-30"
                                alt={item.users.firstName}
                                src={item.users.firstName}
                              />
                            )}
                          </Grid>
                          <Grid item>
                            <Typography className="text-black text-base font-medium">
                              {item.users.firstName} {item.users.lastName}
                            </Typography>
                            <Typography className="text-black text-xs font-normal">
                              {moment(item.cts).format('MMM DD, YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography className="text-blue-500 text-base font-normal">
                          {item.users.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className="text-black text-base font-medium">
                      {item.feedback}
                    </Typography>
                  </div>
                </TableContainer>
              );
            })}
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              There are no any Feedback!
            </Typography>
          </motion.div>
        )}
      </div>
      {userFeedbackListData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUser}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('userFeedbackReducer', reducer)(UserFeedbackList);
