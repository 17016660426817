import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Switch,
  Table,
  TableBody,
  Icon,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
} from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import Pagination from 'src/app/component/table/Pagination';
import { selectDashboardData } from '../../../../store/userSlice';
import manageBusinessData from '../../../query/manageBusiness';
import { capitalizeFirstLetter, handleApiRequest } from '../../../common/common';

const AutoReply = () => {
  const [autoThankYouData, setAutoThankYouData] = useState([]);
  const [isAutoThankYouReply, setIsAutoThankYouReply] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [selectAnotherMessage, setSelectAnotherMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [thankYouDefaultMessage, setThankYouDefaultMessage] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const fetchBusinessDetails = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.getUserBusinessDetail?.status === 200) {
          setIsAutoThankYouReply(result?.getUserBusinessDetail?.data?.[0]?.isAutoThankYouReply);
        } else {
          enqueueSnackbar(result?.getUserBusinessDetail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails, enqueueSnackbar]);

  const fetchMessage = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id) {
        const payloadForThankYouMessage = {
          query: manageBusinessData.getAutoThankYouDefaultMessages,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultThankMessage = await handleApiRequest(payloadForThankYouMessage);
        setPageLoading(false);
        if (resultThankMessage?.getAutoThankYouDefaultMessages?.status === 200) {
          setThankYouDefaultMessage(
            resultThankMessage?.getAutoThankYouDefaultMessages?.data?.messages?.replace(
              '[Business Name]',
              userCurrentBusinessDetails.name
            )
          );
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails]);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);

  useEffect(() => {
    const fetchAutoThankYouHistory = async () => {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessAutoThanksHistory,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAutoThanksHistory?.status === 200) {
        setAutoThankYouData(result?.getBusinessAutoThanksHistory?.data);
        setTotalCount(result?.getBusinessAutoThanksHistory?.totalCount);
      } else {
        enqueueSnackbar(result?.getBusinessAutoThanksHistory?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchAutoThankYouHistory();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, page, rowsPerPage]);

  const enableDisableAutoThankYou = async () => {
    const payload = {
      query: manageBusinessData.setBusinessAutoThankSupport,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        isAutoThankYouReply: !isAutoThankYouReply,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.setBusinessAutoThankSupport?.status === 200) {
      setIsAutoThankYouReply(!isAutoThankYouReply);
    } else if (result?.setBusinessAutoThankSupport?.status === 401) {
      enqueueSnackbar(t('autoReply.errorMessageForPlanExpire'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.setBusinessAutoThankSupport?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  const onSelectMessage = async (id) => {
    try {
      setSelectedId(id);
    } catch (error) {
      console.error('Failed to select message:', error);
    }
  };
  const selectAnotherMessageClick = () => {
    setSelectAnotherMessage(true);
    fetchAutoThankYouStandardMessageCall();
  };
  const fetchAutoThankYouStandardMessageCall = async () => {
    setPageLoading(true);
    const payload = {
      query: manageBusinessData.getAutoThankYouStandardMessages,
    };
    const result = await handleApiRequest(payload);
    setPageLoading(false);
    if (result?.getAutoThankYouStandardMessages?.status === 200) {
      const foundItem = result?.getAutoThankYouStandardMessages?.data.find(
        (item) =>
          item.messages?.replace('[Business Name]', userCurrentBusinessDetails.name) ===
          thankYouDefaultMessage
      );
      setMessages(result?.getAutoThankYouStandardMessages?.data);
      setSelectedId(foundItem?.id);
    } else {
      enqueueSnackbar(result?.getAutoThankYouStandardMessages?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const saveCall = async () => {
    try {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.setBusinessAutoThankSupport,
        variables: {
          isAutoThankYouReply,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          defaultAutoReplyStandardMessageId: selectedId,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.setBusinessAutoThankSupport?.status === 200) {
        setSelectAnotherMessage(false);
        setPageLoading(false);
        setSelectedId();
        fetchMessage();
      } else {
        enqueueSnackbar(result?.setBusinessAutoThankSupport?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setPageLoading(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className="p-20 lg:p-32">
      {selectAnotherMessage ? (
        <div>
          <div className="flex  justify-between items-center">
            <Toolbar className="px-0 font-semibold min-h-fit mb-20">
              <Icon
                className="text-20 cursor-pointer mr-8"
                onClick={() => setSelectAnotherMessage(false)}
              >
                west
              </Icon>
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                className="sm:flex text-16 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t('autoReply.autoMessage')}
              </Typography>
            </Toolbar>

            <Button
              className={`bg-darkgreen text-white rounded min-w-144 ${
                selectedId !== null ? 'hover:bg-darkgreen' : ''
              } group`}
              variant="contained"
              disabled={selectedId === null}
              onClick={() => saveCall()}
            >
              {t('autoReply.saveBtn')}
            </Button>
          </div>
          <div className="bg-white rounded-md relative shadow p-20 mt-20">
            <div className="mt-20 grid grid-cols-6 gap-16">
              {messages?.length > 0 &&
                messages.map((item, i) => {
                  return (
                    <div key={i} className="col-span-6 sm:col-span-3 md:col-span-2">
                      <Typography className="text-14 text-black pb-4">{item.title}</Typography>
                      <div
                        className={`border border-black rounded-md py-6 pl-8 ${
                          selectedId === item.id ? 'bg-darkgreen text-white' : 'bg-white text-black'
                        }`}
                        onClick={() => onSelectMessage(item.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSelectMessage();
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div className="h-96 overflow-scroll w-full">
                          <Typography>
                            {item.messages?.replace(
                              '[Business Name]',
                              userCurrentBusinessDetails.name
                            )}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('autoReply.title')}
          </Typography>
          <div className="bg-white rounded-md p-36 mt-28">
            <div className="flex items-center justify-between">
              <Typography className="text-20 font-bold">
                {t('autoReply.thankYouMessage')}
              </Typography>
              <Switch checked={isAutoThankYouReply} onChange={() => enableDisableAutoThankYou()} />
            </div>
            <Typography className="text-14 font-semibold sm:w-5/6 mt-20">
              {t('autoReply.featureMessage')}
            </Typography>
          </div>

          {isAutoThankYouReply && (
            <div className="bg-white rounded-md p-36 mt-28">
              <div className="flex items-center justify-between">
                <Typography className="text-20 font-bold">
                  {t('autoReply.fedbackMessage')}
                </Typography>
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={() => selectAnotherMessageClick()}
                  className="md:text-16 font-medium rounded-md"
                >
                  {t('autoReply.anotherMsg')}
                </Button>
              </div>
              <Typography className="text-14 font-semibold sm:w-5/6 mt-20">
                {thankYouDefaultMessage}
              </Typography>
            </div>
          )}
          {autoThankYouData?.length > 0 ? (
            <div className="mt-28 bg-white rounded-md w-full">
              <TableContainer>
                <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                  <TableHead className="bg-grey-50">
                    <TableCell className="w-1/4 text-gray font-medium">
                      {t('autoReply.column.srNo')}
                    </TableCell>
                    <TableCell className="w-1/4 text-gray font-medium">
                      {t('autoReply.column.contactNumber')}
                    </TableCell>
                    <TableCell className="w-1/4 text-gray font-medium">
                      {t('autoReply.column.date')}
                    </TableCell>
                    <TableCell className="w-1/4 text-gray font-medium text-center">
                      {t('autoReply.column.status')}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {autoThankYouData?.map((item, i) => {
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                        >
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + (i + 1)}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {item?.phoneNumber?.phoneNumber &&
                              `${item?.phoneNumber?.countryCode} ${item?.phoneNumber?.phoneNumber}`}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold capitalize"
                            align="left"
                          >
                            {moment(item?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell
                            className={`text-16 color-black font-semibold ${
                              item?.messagesStatus === 'delivered'
                                ? 'text-green-A300'
                                : 'text-red-A300'
                            }`}
                            align="center"
                          >
                            {item?.messagesStatus && capitalizeFirstLetter(item.messagesStatus)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="bg-white mt-28">
              <div className="py-160">
                <img className="m-auto w-144" src="/assets/images/business/message.png" alt="" />
                <Typography className="text-20 text-center font-semibold mt-28">
                  {t('autoReply.notMessageSend')}
                </Typography>
                <Typography className="text-16 max-w-640 text-center m-auto mt-20">
                  {t('autoReply.message')}
                </Typography>
              </div>
            </div>
          )}
        </div>
      )}
      {autoThankYouData?.length > 0 && (
        <Pagination
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default AutoReply;
