import Dashboards from './Dashboards';
import SocialConnects from './SocialConnects';
import SocialProfile from './SocialProfile';
import SocialReviews from './SocialReviews';
import CreatePost from './CreatePost';
import Template from './Template';
import Analytics from './Analytics';
import AllSharePost from './AllSharePost';
import Scheduled from './Scheduled';

const QuickChatConfigs = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-social/dashboard/:id?',
      element: <Dashboards />,
    },
    {
      path: 'quick-social/social-connect/:id?',
      element: <SocialConnects />,
    },
    {
      path: 'quick-social/social-reviews/:id?',
      element: <SocialReviews />,
    },
    {
      path: 'quick-social/social-connect/profile/:id?',
      element: <SocialProfile />,
    },
    {
      path: 'quick-social/template/:id?',
      element: <Template />,
    },
    {
      path: 'quick-social/post/:id?',
      element: <CreatePost />,
    },
    {
      path: 'quick-social/analytics/:id?',
      element: <Analytics />,
    },
    {
      path: 'quick-social/share-post/:id?',
      element: <AllSharePost />,
    },
    {
      path: 'quick-social/schedule-post/:id?',
      element: <Scheduled />,
    },
  ],
};

export default QuickChatConfigs;
